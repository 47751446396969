import React from "react";

const CollectionIcon = ({ className, fillcolor }) => {
    return (

        <svg className={className} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path className={fillcolor} d="M14.5 7C14.5 6.75 14.25 6.5 14 6.5H2C1.71875 6.5 1.5 6.75 1.5 7V14C1.5 14.2812 1.71875 14.5 2 14.5H14C14.25 14.5 14.5 14.2812 14.5 14V7ZM14 5C15.0938 5 16 5.90625 16 7V14C16 15.125 15.0938 16 14 16H2C0.875 16 0 15.125 0 14V7C0 5.90625 0.875 5 2 5H14ZM13.75 2.5C14.1562 2.5 14.5 2.84375 14.5 3.25C14.5 3.6875 14.1562 4 13.75 4H2.25C1.8125 4 1.5 3.6875 1.5 3.25C1.5 2.84375 1.8125 2.5 2.25 2.5H13.75ZM12.25 0C12.6562 0 13 0.34375 13 0.75C13 1.1875 12.6562 1.5 12.25 1.5H3.75C3.3125 1.5 3 1.1875 3 0.75C3 0.34375 3.3125 0 3.75 0H12.25Z" fill="url(#paint0_linear_6929_15835)" />
            <defs>
                <linearGradient id="paint0_linear_6929_15835" x1="-4.81987" y1="5.30029" x2="8.17233" y2="21.0214" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#393E45" />
                    <stop offset="1" stopColor="#0A0D10" />
                </linearGradient>
            </defs>
        </svg>

    );
};

export default CollectionIcon;