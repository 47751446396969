import React from "react";

const BackArrow = ({ className }) => {
    return (
        <svg className={className} width="11" height="9" viewBox="0 0 11 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.17188 4.5L4.6875 7.85156C4.71094 7.875 4.73438 7.875 4.75781 7.875C4.82812 7.875 4.875 7.82812 4.875 7.78125V5.8125C4.875 5.50781 5.10938 5.25 5.4375 5.25H9.375V3.75H5.4375C5.10938 3.75 4.875 3.51562 4.875 3.1875V1.24219C4.875 1.17188 4.82812 1.125 4.75781 1.125C4.73438 1.125 4.71094 1.14844 4.6875 1.17188L1.17188 4.5ZM4.75781 0C5.4375 0 6 0.5625 6 1.24219V2.625H9.375C9.98438 2.625 10.5 3.14062 10.5 3.75V5.25C10.5 5.88281 9.98438 6.375 9.375 6.375H6V7.78125C6 8.46094 5.4375 9 4.75781 9C4.45312 9 4.14844 8.88281 3.91406 8.67188L0.304688 5.22656C0.09375 5.03906 0 4.78125 0 4.5C0 4.24219 0.09375 3.98438 0.304688 3.79688L3.91406 0.351562C4.125 0.140625 4.42969 0 4.75781 0Z" fill="#3B3B4D" />
        </svg>

    );
};

export default BackArrow;