import React, { useEffect, useState } from "react";
import Post from "../../api/models/Post"
import Card from "../../Components/Card";
import Banner from "../../Components/Banner";
import Footer from "../../Components/Footer";
import NavBar from "../../Components/NavBar";
import MetaTags from "../../Components/MetaTags";
import ButtonTag from "../../Components/ButtonTag";
import useStore from "../../providers/StoreProvider";
import lovuLogo from "../../assets/LOVU_full_logo.svg"
import ButtonSmall from "../../Components/ButtonSmall";
import { useLocation, useParams } from "react-router-dom";
import bannerImage from "../../assets/images/ZentiumSearchBanner.jpeg"
import "./style.css";

let page;
let lastPage;

const DestinationWishList = ({ setProgress }, params) => {

    const location = useLocation();
    const param = useParams();
    const searchUrl = new URLSearchParams(location.search);

    const filter = searchUrl.get('filter');

    const { store, setStore } = useStore();
    const [destinationId, setDestinationId] = useState();
    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [randomSeed, setRandomSeed] = useState(parseInt(Math.random() * 10000));


    useEffect(() => {
        setProgress(10)
        setProgress(20)
        setProgress(60)
        setProgress(100)
    }, [])

    useEffect(() => {
        setLoading(true);
        Post.showbyname(param?.id)
            .then((res) => setDestinationId(res?.id))
            .catch(() => { });
    }, [param.id]);

    useEffect(() => {
        setStore({ ...store, posts: filter == 'property' ? 'all' : filter })
    }, [filter])

    useEffect(() => {
        if (destinationId) {
            loadPage(true);
        }
    }, [store.posts, destinationId]);


    const WhishListTags = [
        {
            name: 'Where to stay',
            value: 'all',
        },
        {
            name: 'TRAVEL SPECIALISTS',
            value: 'advisor',
        },
    ];

    function loadPage(first = false, refresh = false) {
        setRandomSeed(parseInt(Math.random() * 1000000));
        if (first) {
            setPosts([])
            page = 1;
            lastPage = 1;
        }
        if (page > lastPage) {
            return;
        }
        setLoading(true);
        Post.setFilter('tags', []);
        Post.setFilter('categories', null);
        Post.when(params, model =>
            model
                .setFilter('types', store?.posts == 'all' ? 'property' : store?.posts)
                .setFilter('parent', destinationId),
        )

            .when(store.posts === 'favorites', model =>
                model.setFilter('include', store.user.posts),
            )
            .when(typeof store.posts === 'number', model =>
                model.setFilter('categories', store.posts),
            )
            .when(['advisor', 'offer'].includes(store.posts), model =>
                store.posts === 'offer' && !store?.token
                    ? model.setFilter('tags', [''])
                    : model.setFilter('types', store.posts),
            );
        Post.setFilter('seed', randomSeed)
            .all(page)
            .then(data => {
                page === 1
                    ? setPosts(data.data)
                    : setPosts(posts.concat(data.data));
                lastPage = data.meta.last_page;
                page = data.links.next == null ? page + 1 : data?.links?.next.split('?')[1].split('=')[1];
            })
            .catch(() => { })
            .finally(() => {
                setLoading(false);
            });
    }


    return (
        <>
            <MetaTags
                title="LOVU: Destination"
                imageScr={lovuLogo}
            />
            <div className="destinationwishlist">
                {/* <Banner
                    isSearch={true}
                    imageSrc={bannerImage}
                /> */}
                <div className="pageContent">
                    <div className="destinationwishlist-container"></div>
                    <div className="navigation">
                        <ButtonTag
                            data={WhishListTags}
                            selected={store?.posts}
                            handleClick={e => setStore({ ...store, posts: e })}
                        />
                    </div>
                    <div className="card-list-container">
                        <div className="card-list-wrap">
                            {posts.map((v, i) => <Card
                                key={i}
                                imageSrc={v?.featured_image?.original_url}
                                title={v?.title}
                                type={v?.type}
                                city={v?.city}
                                country={v?.country}
                                hasRatingPrice={v?.rating && true}
                                starRating={v?.rating}
                                price={v?.price}
                                backgroundColor={v?.image_color ? v?.image_color : null}
                                cardStyle={v?.template_type}
                                id={v?.id}
                            />)}
                        </div>
                        {loading ? <div className="card-list-wrap" style={{ marginTop: -38 }}>
                            <div className="card skeleton-box" />
                            <div className="card skeleton-box" />
                            <div className="card skeleton-box" />
                            <div className="card skeleton-box" />
                        </div> : page > lastPage ? null : <ButtonSmall handleClick={() => loadPage()} className="button-instance-black-outline " buttonstyle="button-outline" text="LOAD MORE" />}
                    </div>
                </div>
                <Footer className="design-component-instance-node-3" />
                <NavBar navigationDark={true} />

            </div >
        </>
    );
};

export default DestinationWishList;