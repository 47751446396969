import React from "react";

const Minus = ({ className }) => {
    return (
        <svg className={className} width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="x">
                <path id="Shape" d="M24.5 11.9829L1.50096 11.982" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
            </g>
        </svg>

    );
};

export default Minus