import React, { useEffect, useState } from 'react';
import moment from 'moment';
import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom';
import useStore from '../../providers/StoreProvider';
import Arrow from "../../assets/images/icons/arrow.png"
import NotificationModel from '../../api/models/Notification';
import "./style.css";

const Notification = ({ visible, onClose }) => {

    const navigate = useNavigate();

    const { store, setStore } = useStore();
    const [data, setData] = useState([]);
    const user = store.user;

    useEffect(() => {
        if (user?.email) {
            NotificationModel.all(1).then(data => {
                setData(data.data);
            });
        }
    }, [user]);

    const filteredData = data.reduce((uniqueData, item) => {
        const isDuplicate = uniqueData.some((existingItem) => existingItem.data.deal_id === item.data.deal_id);
        if (isDuplicate) {
            if (item.read_at == null) {
                item.update({ read_at: new Date() });
            }
        }
        if (!isDuplicate) {
            uniqueData.push(item);
        }
        return uniqueData;
    }, []);

    function onClick(notification, index) {
        notification.update({ read_at: new Date() });
        data[index].read_at = new Date();
        setData([...data]);
        store.user.unread_notifications_count =
            store.user.unread_notifications_count - 1;
        setStore({ ...store });
        navigate(`/offer/${notification?.data?.deal_id}`);
        onClose()
    }

    return (
        <div>
            <Modal
                isOpen={visible}
                onRequestClose={onClose}
                contentLabel="Example Modal"
                ariaHideApp={false}
            >
                <div className="notification-container">
                    <img onClick={onClose} className="arrow-img" src={Arrow} alt="arrow" />
                    <div className='notification-title'>Notifications</div>
                </div>
                <div className='main-container'>
                    {!data.length && (
                        <div className='notification-title'>
                            No notifications
                        </div>
                    )}
                    {
                        filteredData?.map((item, index) => {
                            return (
                                <div key={index} className='filtered-data-values' onClick={() => onClick(item, index)}>
                                    {item.data && (
                                        item.type === "App\\Notifications\\User\\DealCreatedNotification" ?
                                            (item?.data?.deal_created ?
                                                !item.read_at && (
                                                    <div className="Tag-container">
                                                        <h1 className="tagText">New Message</h1></div>
                                                ) : !item.read_at && (
                                                    <div className="Tag-container"><h1 className="tagText">New Offer</h1></div>
                                                )) : !item.read_at && (
                                                    <div className="Tag-container"><h1 className="tagText">New Message</h1></div>
                                                ))}
                                    <div className='dealContent'>
                                        {item.data && (
                                            item.type === "App\\Notifications\\User\\DealCreatedNotification" ?
                                                (item?.data?.deal_created ? (<div className="deal-content">
                                                    <span className="offerType" style={{ color: '#CDBA86' }}>
                                                        {item.data.occasion}
                                                    </span>{' '}
                                                    offer from{' '}
                                                    <span className="offer-author" style={{ color: '#CDBA86' }}>
                                                        {item.data.post_title}
                                                    </span>

                                                </div>) : (<div className="deal-content" >
                                                    <span className="offerType" style={{ color: '#CDBA86' }}>
                                                        {item.data.occasion}
                                                    </span>{' '}
                                                    offer recieved from{' '}
                                                    <span className="offer-author" style={{ color: '#CDBA86' }}>
                                                        {item.data.post_title}
                                                    </span>

                                                </div>)) :
                                                <div className="deal-content">
                                                    <span className="offerType" style={{ color: '#CDBA86' }}>
                                                        {item.data.occasion}
                                                    </span>{' '}
                                                    {item.data.is_provided ? 'offer received from ' : 'offer from '}
                                                    <span className="offer-author" style={{ color: '#CDBA86' }}>
                                                        {item.data.post_title}
                                                    </span>

                                                </div>
                                        )}
                                        <div className="deal-time">
                                            <h1 className="deal-time">
                                                {moment(item.created_at).format(
                                                    'MM/DD/YYYY [at] h:mmA',
                                                )}</h1>
                                        </div>

                                    </div>
                                    <div className="closeModalLine" style={{ background: "#EBF3E8" }} />
                                </div>
                            )
                        })
                    }
                </div>
            </Modal>
        </div>
    );
};

export default Notification;