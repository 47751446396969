import React from "react";

const UserProfile = ({ className, fillColor }) => {
    return (
        <svg className={className} width="20" height="20" viewBox="0 0 14 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.5 4.5C9.5 3.625 9 2.8125 8.25 2.34375C7.46875 1.90625 6.5 1.90625 5.75 2.34375C4.96875 2.8125 4.5 3.625 4.5 4.5C4.5 5.40625 4.96875 6.21875 5.75 6.6875C6.5 7.125 7.46875 7.125 8.25 6.6875C9 6.21875 9.5 5.40625 9.5 4.5ZM3 4.5C3 3.09375 3.75 1.78125 5 1.0625C6.21875 0.34375 7.75 0.34375 9 1.0625C10.2188 1.78125 11 3.09375 11 4.5C11 5.9375 10.2188 7.25 9 7.96875C7.75 8.6875 6.21875 8.6875 5 7.96875C3.75 7.25 3 5.9375 3 4.5ZM1.53125 15H12.4375C12.1562 13.0312 10.4688 11.5 8.40625 11.5H5.5625C3.5 11.5 1.8125 13.0312 1.53125 15ZM0 15.5938C0 12.5 2.46875 10 5.5625 10H8.40625C11.5 10 14 12.5 14 15.5938C14 16.0938 13.5625 16.5 13.0625 16.5H0.90625C0.40625 16.5 0 16.0938 0 15.5938Z" fill="url(#paint0_linear_5731_2695)" />
            <defs>
                <linearGradient id="paint0_linear_5731_2695" x1="-4.81987" y1="1.75074" x2="21.1188" y2="14.3055" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#393E45" />
                    <stop offset="1" stopColor="#0A0D10" />
                </linearGradient>
            </defs>
        </svg>

    );
};

export default UserProfile