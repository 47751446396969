import React from 'react';
import { Modal } from 'antd';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Wedding from "../../../../src/assets/images/occasion/wedding.jpg"
import Proposal from "../../../../src/assets/images/occasion/proposal.jpg"
import Babymoon from "../../../../src/assets/images/occasion/babymoon.jpg"
import Honeymoon from "../../../../src/assets/images/occasion/honeymoon.jpg"
import VowRenewal from "../../../../src/assets/images/occasion/vowRenewal.jpg"
import Anniversary from "../../../../src/assets/images/occasion/anniversary.jpg"
import NoOccassion from "../../../../src/assets/images/occasion/noOccassion.jpg"
import RomanticGetaway from "../../../../src/assets/images/occasion/romanticGetaway.jpg"
import "./style.css";

const OccasionModal = ({ visible, onClose, reservation, setReservation, setIsdestinationVisible, progress = 15 }) => {

    const occasions = [
        {
            value: 'Wedding',
            label: 'Wedding',
            url: Wedding,
        },
        {
            value: 'Honeymoon',
            label: 'Honeymoon',
            url: Honeymoon,
        },
        {
            value: 'Anniversary',
            label: 'Anniversary',
            url: Anniversary,
        },
        {
            value: 'Vow Renewal',
            label: 'Vow Renewal',
            url: VowRenewal,
        },
        {
            value: 'Proposal',
            label: 'Proposal',
            url: Proposal,
        },
        {
            value: 'Babymoon',
            label: 'Babymoon',
            url: Babymoon,
        },
        {
            value: 'Romantic Getaway',
            label: 'Romantic Getaway',
            url: RomanticGetaway,
        },
        {
            value: 'Celebrating Love',
            label: 'Celebrating Love',
            url: NoOccassion,
        },
    ];

    const saveOccasion = async (value) => {
        let previsonReservation = reservation;
        previsonReservation.occasion = value;
        setReservation(previsonReservation);
        onClose();
        setIsdestinationVisible(true);
    };

    const ProgressBar = ({ progress }) => {
        return (
            <div className="progress-bar">
                <div className="progress" style={{ width: `${progress}%` }}></div>
            </div>
        );
    };

    const handleBack = () => {
        onClose();
        setIsdestinationVisible(true);
    }

    return (
        <div>
            <Modal
                className='occasion-maincontainer'
                open={visible}
                onCancel={onClose}
                maskClosable={false}
                footer={[]}
            >
                <div className='occasion-modal'>
                    <ArrowBackIcon onClick={handleBack} className='back-arrow' />
                    <ProgressBar progress={progress} />
                    <h2 className='occasion-title'>What’s the occasion?</h2>
                    <div className='occasionContainer'>
                        {occasions.map((prop, key) => {
                            return (
                                <div
                                    key={key}
                                    className='occasionitem'
                                    onClick={async () => {
                                        await saveOccasion(prop['value']);
                                    }}
                                >
                                    <img key={key} src={prop['url']} alt='No images1 found' className='occasionImage' />
                                    <h2 className='occasionLabel'>
                                        {prop['label']}
                                    </h2>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default OccasionModal;