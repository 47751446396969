import React from 'react';
import { Modal } from 'antd';
import "./style.css";

const CenteredPopup = (props) => {

    return (
        <Modal
            className='CenteredPopup-maincontainer'
            open={props.visible}
            footer={[]}
        >
            <div className='CenteredPopupModal'>
                <div className='blurContainer'>
                    <h1 className='popup-title'>{props.title}</h1>
                    <div className='popup-content'>{props.content}</div>
                    <div className='popup-action-button-container'>{props.actions}</div>
                </div>
            </div>
        </Modal>
    );
};

export default CenteredPopup;