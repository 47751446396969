import React, { useEffect, useState } from "react";
import Tag from '../../../api/models/Tag';
import Post from "../../../api/models/Post"
import Card from "../../../Components/Card";
import NavBar from "../../../Components/NavBar";
import Banner from "../../../Components/Banner";
import Footer from "../../../Components/Footer";
import MetaTags from "../../../Components/MetaTags"
import Metatag from "../../../api/models/Metatag.js";
import SearchBar from '../../../Components/SearchBar'
import ButtonSmall from "../../../Components/ButtonSmall";
import bannerImage from "../../../assets/images/occasion/occasions/vow-renewal.jpg"

let page;
let lastPage;


const VowRenewal = ({ setProgress }) => {

    const [seo, setSeo] = useState();
    const [tags, setTags] = useState([]);
    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isVisible, setIsVisible] = useState(true);
    useEffect(() => {
        Metatag.metaslug('vowrenewal').then(result =>
            setSeo(result)
        );
    }, []);

    useEffect(() => {
        Tag.all(1).then(({ data }) => {
            let val = data?.map((item) => item).filter((items) => items.name === 'Vow Renewal')
            setTags(val[0]?.id)
        });
    }, [])

    useEffect(() => {
        if (tags?.length !== 0) {
            loadPage(true);
        }
    }, [tags]);

    function loadPage(first = false) {
        if (first) {
            setPosts([])
            page = 1;
            lastPage = 1;
        }
        if (page > lastPage) {
            return;
        }
        setLoading(true);
        Post.tagfilter(page, tags)
            .then(data => {
                page === 1
                    ? setPosts(data.data)
                    : setPosts(posts.concat(data.data));
                lastPage = data.meta.last_page;
                page = data.links.next == null ? Number(page) + 1 : data?.links?.next.split('?')[1].split('=')[1];
            })
            .catch(() => { })
            .finally(() => {
                setLoading(false);
            });
    }
    useEffect(() => {
        setProgress(10)
        setProgress(20)
        setProgress(60)
        setProgress(100)
    }, [setProgress])

    return (
        <>
            <MetaTags
                title={seo?.title != null ? seo?.title : "LOVU: Vow Renewal"}
                description={seo?.description != null ? seo?.description : "Enchanting Destinations for Unforgettable Vow Renewal Ceremonies"}
                imageScr={seo?.meta_image[0]?.original_url != undefined ? seo?.meta_image[0]?.original_url : bannerImage}
            />
            <div className="homepage occassions">
                <Banner
                    isProperties
                    imageSrc={bannerImage}
                    title="Vow Renewal"
                    heading="Enchanting Destinations for Unforgettable Vow Renewal Ceremonies"
                    widget={
                        <SearchBar
                            heightToHideFrom={520}
                            isVisible={isVisible}
                            setIsVisible={setIsVisible}
                        />
                    }
                />
                <div className="pageContent">
                    <div className="primarySection">
                        <h2 className="occassions-main-title">Enchanting Destinations for Unforgettable Vow Renewal Ceremonies</h2>
                        <h2 className="occassions-main-subtitle"> Explore the World's Most Romantic Vow Renewal Locations</h2>
                        <div className="occassions-main-content">
                            Vow renewal travel offers couples a magical opportunity to celebrate their enduring love
                            and commitment in some of the world’s most enchanting destinations. From the
                            sun-kissed beaches of Cancun, Mexico, to the lush landscapes of Costa Rica, and the
                            romantic shores of Aruba, the choices are as diverse as they are breathtaking. Imagine
                            reaffirming your love amidst the historic ruins of Cap Cana, Dominican Republic, or
                            under the cascading waterfalls of Negril, Jamaica. These destinations not only provide
                            stunning backdrops for your ceremony but also offer comprehensive vow renewal
                            packages that cater to every detail, ensuring a stress-free and memorable experience.
                        </div>
                    </div>

                    <h2 className="explore-description">Explore destinations and hotels that are perfect for your dream  vow renewal:</h2>
                    <div className="card-list-container">
                        <div className="card-list-wrap">
                            {posts.map((v, i) => <Card
                                key={i}
                                imageSrc={v?.featured_image?.original_url}
                                title={v?.title}
                                type={v?.type}
                                city={v?.city}
                                country={v?.country}
                                hasRatingPrice={v?.rating ? true : false}
                                starRating={v?.rating}
                                price={v?.price}
                                backgroundColor={v?.image_color ? v?.image_color : null}
                                cardStyle={v?.template_type}
                                id={v?.id}
                            />)}
                        </div>
                        {
                            (posts?.length === 0 && !loading) && <div style={{ color: 'black' }}>No Data Found</div>
                        }
                        {loading ? <div className="card-list-wrap" style={{ marginTop: -38 }}>
                            <div className="card skeleton-box" />
                            <div className="card skeleton-box" />
                            <div className="card skeleton-box" />
                            <div className="card skeleton-box" />
                        </div> : page > lastPage ? null : <ButtonSmall handleClick={() => loadPage()} className="button-instance-black-outline " buttonstyle="button-outline" text="LOAD MORE" />}
                    </div>
                </div>
                <Footer className="design-component-instance-node-3" isVisible={isVisible} />
                <NavBar navigationDark={true} />

            </div>
        </>
    );
};

export default VowRenewal;