import React from "react";

const AppStore = ({ className }) => {
  return (
    <svg
      className={`mobile-app-store-badge-2 ${className}`}
      fill="none"
      // height="41"
      viewBox="0 0 135 41"
      // width="135"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect className="rect" fill="black" height="39" rx="6.5" width="134" x="0.5" y="1.3252" />
      <rect className="rect" height="39" rx="6.5" stroke="#A6A6A6" width="134" x="0.5" y="1.3252" />
      <path
        className="path"
        d="M81.5259 20.0261V22.3171H80.0899V23.8195H81.5259V28.9245C81.5259 30.6677 82.3145 31.3649 84.2984 31.3649C84.647 31.3649 84.9791 31.3234 85.2696 31.2736V29.7878C85.0206 29.8127 84.8628 29.8293 84.5889 29.8293C83.7007 29.8293 83.3106 29.4142 83.3106 28.4679V23.8195H85.2696V22.3171H83.3106V20.0261H81.5259Z"
        fill="white"
      />
      <path
        className="path"
        d="M90.3234 31.4894C92.9631 31.4894 94.5817 29.7214 94.5817 26.7912C94.5817 23.8776 92.9548 22.1013 90.3234 22.1013C87.6838 22.1013 86.0568 23.8776 86.0568 26.7912C86.0568 29.7214 87.6755 31.4894 90.3234 31.4894ZM90.3234 29.904C88.7712 29.904 87.8996 28.7668 87.8996 26.7912C87.8996 24.8322 88.7712 23.6867 90.3234 23.6867C91.8674 23.6867 92.7473 24.8322 92.7473 26.7912C92.7473 28.7585 91.8674 29.904 90.3234 29.904Z"
        fill="white"
      />
      <path
        className="path"
        d="M95.9666 31.3151H97.7513V25.9777C97.7513 24.7077 98.7059 23.8527 100.059 23.8527C100.374 23.8527 100.906 23.9108 101.055 23.9606V22.2009C100.864 22.1511 100.524 22.1262 100.258 22.1262C99.0794 22.1262 98.075 22.7736 97.8177 23.6618H97.6849V22.2756H95.9666V31.3151Z"
        fill="white"
      />
      <path
        className="path"
        d="M105.486 23.6203C106.806 23.6203 107.669 24.5417 107.711 25.9611H103.145C103.245 24.55 104.166 23.6203 105.486 23.6203ZM107.703 28.8747C107.371 29.5803 106.632 29.9704 105.553 29.9704C104.125 29.9704 103.204 28.966 103.145 27.3805V27.2809H109.529V26.6584C109.529 23.8195 108.01 22.1013 105.495 22.1013C102.946 22.1013 101.328 23.9357 101.328 26.8244C101.328 29.7131 102.913 31.4894 105.503 31.4894C107.57 31.4894 109.014 30.4933 109.421 28.8747H107.703Z"
        fill="white"
      />
      <path
        className="path"
        d="M69.8223 27.977C69.96 30.1967 71.8098 31.6162 74.5629 31.6162C77.5053 31.6162 79.3464 30.1278 79.3464 27.7533C79.3464 25.8863 78.2968 24.8539 75.7502 24.2602L74.3822 23.9247C72.7647 23.5461 72.1109 23.0385 72.1109 22.1524C72.1109 21.0339 73.1261 20.3026 74.6489 20.3026C76.0943 20.3026 77.0923 21.0167 77.273 22.161H79.1486C79.0367 20.0703 77.1956 18.5991 74.6747 18.5991C71.9646 18.5991 70.1579 20.0703 70.1579 22.2814C70.1579 24.1054 71.1817 25.1894 73.4272 25.7142L75.0275 26.1014C76.6707 26.4885 77.3934 27.0564 77.3934 28.0028C77.3934 29.104 76.2578 29.9041 74.7091 29.9041C73.0487 29.9041 71.8958 29.1556 71.7323 27.977H69.8223Z"
        fill="white"
      />
      <path
        className="path"
        d="M51.335 22.1262C50.1065 22.1262 49.044 22.7404 48.4961 23.7697H48.3633V22.2756H46.6451V34.32H48.4297V29.9455H48.5708C49.044 30.9001 50.065 31.4645 51.3516 31.4645C53.6343 31.4645 55.087 29.6633 55.087 26.7912C55.087 23.9191 53.6343 22.1262 51.335 22.1262ZM50.8287 29.8625C49.3345 29.8625 48.3965 28.6838 48.3965 26.7995C48.3965 24.9069 49.3345 23.7282 50.837 23.7282C52.3477 23.7282 53.2525 24.882 53.2525 26.7912C53.2525 28.7087 52.3477 29.8625 50.8287 29.8625Z"
        fill="white"
      />
      <path
        className="path"
        d="M61.3318 22.1262C60.1033 22.1262 59.0408 22.7404 58.4929 23.7697H58.3601V22.2756H56.6419V34.32H58.4265V29.9455H58.5676C59.0408 30.9001 60.0618 31.4645 61.3484 31.4645C63.6311 31.4645 65.0838 29.6633 65.0838 26.7912C65.0838 23.9191 63.6311 22.1262 61.3318 22.1262ZM60.8255 29.8625C59.3313 29.8625 58.3933 28.6838 58.3933 26.7995C58.3933 24.9069 59.3313 23.7282 60.8338 23.7282C62.3445 23.7282 63.2493 24.882 63.2493 26.7912C63.2493 28.7087 62.3445 29.8625 60.8255 29.8625Z"
        fill="white"
      />
      <path
        className="path"
        d="M43.4431 31.3151H45.4907L41.0083 18.9002H38.9348L34.4524 31.3151H36.4312L37.5755 28.02H42.3074L43.4431 31.3151ZM39.8726 21.1544H40.0189L41.817 26.4025H38.0659L39.8726 21.1544Z"
        fill="white"
      />
      <path
        className="path"
        d="M35.6511 9.53613V15.5251H37.8135C39.5981 15.5251 40.6316 14.4253 40.6316 12.512C40.6316 10.6277 39.5898 9.53613 37.8135 9.53613H35.6511ZM36.5808 10.3828H37.7097C38.9507 10.3828 39.6853 11.1714 39.6853 12.5244C39.6853 13.8982 38.9631 14.6785 37.7097 14.6785H36.5808V10.3828Z"
        fill="white"
      />
      <path
        className="path"
        d="M43.7967 15.6123C45.1165 15.6123 45.9258 14.7283 45.9258 13.2632C45.9258 11.8064 45.1124 10.9182 43.7967 10.9182C42.4769 10.9182 41.6634 11.8064 41.6634 13.2632C41.6634 14.7283 42.4727 15.6123 43.7967 15.6123ZM43.7967 14.8196C43.0206 14.8196 42.5848 14.251 42.5848 13.2632C42.5848 12.2837 43.0206 11.7109 43.7967 11.7109C44.5687 11.7109 45.0086 12.2837 45.0086 13.2632C45.0086 14.2468 44.5687 14.8196 43.7967 14.8196Z"
        fill="white"
      />
      <path
        className="path"
        d="M52.818 11.0054H51.9256L51.1205 14.4543H51.0499L50.1202 11.0054H49.2652L48.3355 14.4543H48.2691L47.4598 11.0054H46.555L47.8001 15.5251H48.7174L49.6471 12.1965H49.7176L50.6515 15.5251H51.577L52.818 11.0054Z"
        fill="white"
      />
      <path
        className="path"
        d="M53.8456 15.5251H54.7379V12.8813C54.7379 12.1758 55.1571 11.7358 55.817 11.7358C56.477 11.7358 56.7924 12.0969 56.7924 12.8232V15.5251H57.6847V12.5991C57.6847 11.5242 57.1286 10.9182 56.12 10.9182C55.4394 10.9182 54.9911 11.2212 54.7711 11.7234H54.7047V11.0054H53.8456V15.5251Z"
        fill="white"
      />
      <path className="path" d="M59.09 15.5251H59.9824V9.24146H59.09V15.5251Z" fill="white" />
      <path
        className="path"
        d="M63.3384 15.6123C64.6582 15.6123 65.4675 14.7283 65.4675 13.2632C65.4675 11.8064 64.6541 10.9182 63.3384 10.9182C62.0186 10.9182 61.2051 11.8064 61.2051 13.2632C61.2051 14.7283 62.0144 15.6123 63.3384 15.6123ZM63.3384 14.8196C62.5623 14.8196 62.1265 14.251 62.1265 13.2632C62.1265 12.2837 62.5623 11.7109 63.3384 11.7109C64.1104 11.7109 64.5503 12.2837 64.5503 13.2632C64.5503 14.2468 64.1104 14.8196 63.3384 14.8196Z"
        fill="white"
      />
      <path
        className="path"
        d="M68.1263 14.8486C67.6407 14.8486 67.2879 14.6121 67.2879 14.2053C67.2879 13.8069 67.5701 13.5952 68.1927 13.5537L69.2967 13.4832V13.8608C69.2967 14.4211 68.7986 14.8486 68.1263 14.8486ZM67.898 15.5999C68.4915 15.5999 68.9854 15.3425 69.2552 14.8901H69.3257V15.5251H70.1849V12.4373C70.1849 11.4827 69.5457 10.9182 68.4126 10.9182C67.3875 10.9182 66.657 11.4163 66.5657 12.1924H67.429C67.5286 11.8728 67.8731 11.6902 68.3711 11.6902C68.9812 11.6902 69.2967 11.96 69.2967 12.4373V12.8274L68.0723 12.8979C66.9974 12.9644 66.3914 13.4333 66.3914 14.2468C66.3914 15.0728 67.0264 15.5999 67.898 15.5999Z"
        fill="white"
      />
      <path
        className="path"
        d="M73.213 15.5999C73.8355 15.5999 74.3626 15.3052 74.6324 14.8113H74.703V15.5251H75.558V9.24146H74.6656V11.7234H74.5992C74.3543 11.2253 73.8314 10.9307 73.213 10.9307C72.0716 10.9307 71.337 11.8354 71.337 13.2632C71.337 14.6951 72.0633 15.5999 73.213 15.5999ZM73.4662 11.7317C74.2132 11.7317 74.6822 12.3252 74.6822 13.2673C74.6822 14.2136 74.2174 14.7988 73.4662 14.7988C72.7108 14.7988 72.2584 14.2219 72.2584 13.2632C72.2584 12.3127 72.7149 11.7317 73.4662 11.7317Z"
        fill="white"
      />
      <path
        className="path"
        d="M81.3444 15.6123C82.6643 15.6123 83.4736 14.7283 83.4736 13.2632C83.4736 11.8064 82.6601 10.9182 81.3444 10.9182C80.0246 10.9182 79.2111 11.8064 79.2111 13.2632C79.2111 14.7283 80.0205 15.6123 81.3444 15.6123ZM81.3444 14.8196C80.5683 14.8196 80.1325 14.251 80.1325 13.2632C80.1325 12.2837 80.5683 11.7109 81.3444 11.7109C82.1164 11.7109 82.5563 12.2837 82.5563 13.2632C82.5563 14.2468 82.1164 14.8196 81.3444 14.8196Z"
        fill="white"
      />
      <path
        className="path"
        d="M84.6548 15.5251H85.5471V12.8813C85.5471 12.1758 85.9663 11.7358 86.6262 11.7358C87.2861 11.7358 87.6016 12.0969 87.6016 12.8232V15.5251H88.4939V12.5991C88.4939 11.5242 87.9377 10.9182 86.9292 10.9182C86.2485 10.9182 85.8003 11.2212 85.5803 11.7234H85.5139V11.0054H84.6548V15.5251Z"
        fill="white"
      />
      <path
        className="path"
        d="M92.6036 9.88062V11.0261H91.8856V11.7773H92.6036V14.3298C92.6036 15.2014 92.9979 15.55 93.9898 15.55C94.1642 15.55 94.3302 15.5293 94.4754 15.5044V14.7615C94.3509 14.7739 94.2721 14.7822 94.1351 14.7822C93.691 14.7822 93.4959 14.5747 93.4959 14.1016V11.7773H94.4754V11.0261H93.4959V9.88062H92.6036Z"
        fill="white"
      />
      <path
        className="path"
        d="M95.6732 15.5251H96.5656V12.8855C96.5656 12.2007 96.9723 11.74 97.7028 11.74C98.3336 11.74 98.6698 12.1052 98.6698 12.8274V15.5251H99.5622V12.6074C99.5622 11.5325 98.9687 10.9224 98.0058 10.9224C97.3251 10.9224 96.8478 11.2253 96.6278 11.7317H96.5573V9.24146H95.6732V15.5251Z"
        fill="white"
      />
      <path
        className="path"
        d="M102.781 11.6777C103.441 11.6777 103.873 12.1384 103.894 12.8481H101.611C101.661 12.1426 102.121 11.6777 102.781 11.6777ZM103.889 14.3049C103.723 14.6577 103.354 14.8528 102.814 14.8528C102.101 14.8528 101.64 14.3506 101.611 13.5579V13.5081H104.802V13.1968C104.802 11.7773 104.043 10.9182 102.785 10.9182C101.511 10.9182 100.702 11.8354 100.702 13.2798C100.702 14.7241 101.495 15.6123 102.789 15.6123C103.823 15.6123 104.545 15.1143 104.748 14.3049H103.889Z"
        fill="white"
      />
      <path
        className="path"
        d="M24.769 21.126C24.7907 19.445 25.6934 17.8544 27.1256 16.974C26.2221 15.6836 24.7088 14.8655 23.1344 14.8163C21.4552 14.64 19.8272 15.8211 18.9715 15.8211C18.0992 15.8211 16.7817 14.8338 15.363 14.863C13.5137 14.9227 11.7898 15.9741 10.8901 17.5908C8.95607 20.9393 10.3987 25.8603 12.2513 28.5669C13.1782 29.8923 14.2615 31.3727 15.6789 31.3202C17.066 31.2627 17.584 30.4357 19.2583 30.4357C20.9171 30.4357 21.4031 31.3202 22.8493 31.2868C24.3377 31.2627 25.2754 29.9556 26.1698 28.6177C26.8358 27.6733 27.3483 26.6296 27.6882 25.5252C25.9391 24.7854 24.771 23.0251 24.769 21.126Z"
        fill="white"
      />
      <path
        className="path"
        d="M22.0373 13.0363C22.8489 12.0621 23.2487 10.8099 23.1518 9.54565C21.912 9.67587 20.7668 10.2684 19.9443 11.2053C19.14 12.1206 18.7214 13.3507 18.8006 14.5667C20.0408 14.5794 21.2601 14.0029 22.0373 13.0363Z"
        fill="white"
      />
    </svg>
  );
};

export default AppStore