import React from "react";

const DestinationIcon = ({ className, fillcolor }) => {
    return (

        <svg className={className} width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path className={fillcolor} d="M2 4.5C2 5.59375 2.5625 6.5625 3.5 7.125C4.40625 7.65625 5.5625 7.65625 6.5 7.125C7.40625 6.5625 8 5.59375 8 4.5C8 3.4375 7.40625 2.46875 6.5 1.90625C5.5625 1.375 4.40625 1.375 3.5 1.90625C2.5625 2.46875 2 3.4375 2 4.5ZM5.75 8.9375V15.25C5.75 15.6875 5.40625 16 5 16C4.5625 16 4.25 15.6875 4.25 15.25V8.9375C2.09375 8.59375 0.5 6.75 0.5 4.5C0.5 2.03125 2.5 0 5 0C7.46875 0 9.5 2.03125 9.5 4.5C9.5 6.75 7.875 8.59375 5.75 8.9375Z" fill="url(#paint0_linear_6929_15829)" />
            <defs>
                <linearGradient id="paint0_linear_6929_15829" x1="-4.81987" y1="5.30029" x2="8.17233" y2="21.0214" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#393E45" />
                    <stop offset="1" stopColor="#0A0D10" />
                </linearGradient>
            </defs>
        </svg>

    );
};

export default DestinationIcon;