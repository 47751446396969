import React from "react";
import ButtonSmall from "../ButtonSmall";
import X from "../../assets/icons/social/Twitter.svg"
import Youtube from "../../assets/icons/social/Youtube.svg"
import Facebook from "../../assets/icons/social/Facebook.svg"
import Instagram from "../../assets/icons/social/Instagram.svg"
import "./style.css";
import { useNavigate } from "react-router-dom";
import { writeData } from "../../Helpers";

const PostFooter = ({
    data, buttontype
}) => {
    const buttons = buttontype?.buttons;
    const navigate = useNavigate();
    return (
        <div className='postfooter'>
            <div className='hotelDetailButton'>
                {data?.tel && (
                    <a href={`tel:${data?.tel}`} target="_blank">
                        <ButtonSmall className="button-instance-gold" buttonstyle="default" text={data?.tel} />
                    </a>
                )}
                {buttons?.properties && (
                    <ButtonSmall className="button-instance-gold" buttonstyle="default" text={buttons?.properties} handleClick={() => {
                        navigate(`/destinations/${data?.title?.replace(/\s+/g, '-').toLowerCase()}?filter=property`);
                    }
                    } />
                )}
                {buttons?.advisors && (
                    <ButtonSmall className="button-instance-gold" buttonstyle="default" text={buttons?.advisors} handleClick={() => {
                        navigate(`/destinations/${data?.title?.replace(/\s+/g, '-').toLowerCase()}?filter=advisor`);

                    }} />
                )}
                {(buttons?.website && data?.website) && (
                    <a href={data?.website} target="_blank">
                        <ButtonSmall className="button-instance-gold" buttonstyle="default" text={buttons?.website} />
                    </a>
                )}
                {data?.rewards && (
                    <a href={data?.rewards} target="_blank">
                        <ButtonSmall className="button-instance-gold" buttonstyle="default" text={buttons?.rewards} />
                    </a>
                )}
            </div>
            {(data?.facebook ||
                data?.instagram ||
                data?.twitter ||
                data?.youtube) && (

                    <div className='social-media-link'>
                        <h4 className='follow-text'>Follow {data.title} On</h4>
                        <div className='social-media-icons'>
                            {data.facebook && (
                                <a href={data?.facebook} target="_blank" style={{ padding: "10px" }} >
                                    <img className="social-media-image" alt="Image1" src={Facebook} />
                                </a>
                            )}
                            {data.instagram && (
                                <a href={data?.instagram} target="_blank" style={{ padding: "10px" }}>
                                    <img className="social-media-image" alt="Image1" src={Instagram} />
                                </a>
                            )}
                            {data.twitter && (
                                <a href={data?.twitter} target="_blank" style={{ padding: "10px" }} >
                                    <img className="social-media-image" alt="Image1" src={X} />
                                </a>
                            )}
                            {data.youtube && (
                                <a href={data?.youtube} target="_blank" style={{ padding: "10px" }}>
                                    <img className="social-media-image" alt="Image1" src={Youtube} />
                                </a>
                            )}
                        </div>
                    </div>
                )}
        </div>
    );
};

export default PostFooter;