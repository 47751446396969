import React from "react";

const SearchIcon = ({ className, fillColor }) => {
    return (
        <svg className={className ? className : ''} width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path className={fillColor ? fillColor : ''} d="M14.375 8.125C14.375 5.89844 13.1641 3.86719 11.25 2.73438C9.29688 1.60156 6.91406 1.60156 5 2.73438C3.04688 3.86719 1.875 5.89844 1.875 8.125C1.875 10.3906 3.04688 12.4219 5 13.5547C6.91406 14.6875 9.29688 14.6875 11.25 13.5547C13.1641 12.4219 14.375 10.3906 14.375 8.125ZM13.1641 14.5312C11.7578 15.625 10 16.25 8.125 16.25C3.63281 16.25 0 12.6172 0 8.125C0 3.67188 3.63281 0 8.125 0C12.5781 0 16.25 3.67188 16.25 8.125C16.25 10.0391 15.5859 11.7969 14.4922 13.2031L19.7266 18.3984C20.0781 18.7891 20.0781 19.375 19.7266 19.7266C19.3359 20.1172 18.75 20.1172 18.3984 19.7266L13.1641 14.5312Z" fill="white" />
        </svg>

    );
};

export default SearchIcon