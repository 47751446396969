import React, { useEffect, useState } from 'react';
import { Modal, Spin } from 'antd';

import "./style.css";

const Loader = ({ visible, onClose }) => {

    return (
        <Modal
            className='loadermodal-maincontainer'
            open={visible}
            maskClosable={false}
            onCancel={onClose}
            footer={[]}
        >
            <div className='loadermodal'>
                <Spin style={{ marginLeft: '8px' }} />
            </div>

        </Modal >
    );
};

export default Loader;