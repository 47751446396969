import React from "react";

const HotelIcon = ({ className }) => {
    return (
        <svg className={className} width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.65625 0H13.3438C13.6992 0 14 0.300781 14 0.65625C14 1.03906 13.6992 1.3125 13.3438 1.3125H13.125V12.6875H13.3438C13.6992 12.6875 14 12.9883 14 13.3438C14 13.7266 13.6992 14 13.3438 14H0.65625C0.273438 14 0 13.7266 0 13.3438C0 12.9883 0.273438 12.6875 0.65625 12.6875H0.875V1.3125H0.65625C0.273438 1.3125 0 1.03906 0 0.65625C0 0.300781 0.273438 0 0.65625 0ZM11.8125 1.3125H2.1875V12.6875H5.6875V10.5H4.8125C4.56641 10.5 4.34766 10.3086 4.40234 10.0898C4.59375 8.83203 5.6875 7.875 7 7.875C8.28516 7.875 9.37891 8.83203 9.57031 10.0898C9.625 10.3086 9.40625 10.5 9.1875 10.5H8.3125V12.6875H11.8125V1.3125ZM3.9375 2.625H4.8125C5.03125 2.625 5.25 2.84375 5.25 3.0625V3.9375C5.25 4.18359 5.03125 4.375 4.8125 4.375H3.9375C3.69141 4.375 3.5 4.18359 3.5 3.9375V3.0625C3.5 2.84375 3.69141 2.625 3.9375 2.625ZM6.125 3.0625C6.125 2.84375 6.31641 2.625 6.5625 2.625H7.4375C7.65625 2.625 7.875 2.84375 7.875 3.0625V3.9375C7.875 4.18359 7.65625 4.375 7.4375 4.375H6.5625C6.31641 4.375 6.125 4.18359 6.125 3.9375V3.0625ZM9.1875 2.625H10.0625C10.2812 2.625 10.5 2.84375 10.5 3.0625V3.9375C10.5 4.18359 10.2812 4.375 10.0625 4.375H9.1875C8.94141 4.375 8.75 4.18359 8.75 3.9375V3.0625C8.75 2.84375 8.94141 2.625 9.1875 2.625ZM3.5 5.6875C3.5 5.46875 3.69141 5.25 3.9375 5.25H4.8125C5.03125 5.25 5.25 5.46875 5.25 5.6875V6.5625C5.25 6.80859 5.03125 7 4.8125 7H3.9375C3.69141 7 3.5 6.80859 3.5 6.5625V5.6875ZM6.5625 5.25H7.4375C7.65625 5.25 7.875 5.46875 7.875 5.6875V6.5625C7.875 6.80859 7.65625 7 7.4375 7H6.5625C6.31641 7 6.125 6.80859 6.125 6.5625V5.6875C6.125 5.46875 6.31641 5.25 6.5625 5.25ZM8.75 5.6875C8.75 5.46875 8.94141 5.25 9.1875 5.25H10.0625C10.2812 5.25 10.5 5.46875 10.5 5.6875V6.5625C10.5 6.80859 10.2812 7 10.0625 7H9.1875C8.94141 7 8.75 6.80859 8.75 6.5625V5.6875Z" fill="#344054" />
        </svg>
    );
};

export default HotelIcon;