import React, { useEffect, useRef, useState } from 'react';
import { Checkbox, Modal } from 'antd';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ButtonSmall from '../../../Components/ButtonSmall';
import "./style.css";

const CoupleModal = ({ visible, onClose, progress = 60, reservation, setReservation, setIsBudgetVisible, setIsGuestVisible }) => {

    const [type, setType] = useState([]);
    const topRef = useRef(null);

    useEffect(() => {
        if (topRef.current) {
            topRef.current.scrollTop = 0;
        }
    }, [visible])

    const ProgressBar = ({ progress }) => {
        return (
            <div className="progress-bar">
                <div className="progress" style={{ width: `${progress}%` }}></div>
            </div>
        );
    };
    const goNext = () => {
        onClose();
        setIsBudgetVisible(true)
    }

    const includeItems = [
        {
            value: 'Adventures',
            label: 'Adventures',
        },
        {
            value: 'Ballers',
            label: 'Ballers',
        },
        {
            value: 'Beach bums',
            label: 'Beach bums',
        },
        {
            value: 'Budget conscious',
            label: 'Budget conscious',
        },
        {
            value: 'Culinary artists',
            label: 'Culinary artists',
        },
        {
            value: 'Explorers',
            label: 'Explorers',
        },
        {
            value: 'Fitness & Health',
            label: 'Fitness & Health',
        },
        {
            value: 'Posh',
            label: 'Posh',
        },
        {
            value: 'Thrill Seekers',
            label: 'Thrill Seekers',
        },
    ];

    const handleChecked = (e) => {

        if (type.length > 4) {
            return;
        }
        const selectedIncludes = type;
        if (selectedIncludes.includes(e)) {
            const updatedIncludes = selectedIncludes.filter(item => item !== e);
            setType(updatedIncludes);
        } else if (selectedIncludes.length < 3) {
            setType([...type, e]);
        }
    }

    const handleBack = () => {
        onClose();
        setIsGuestVisible(true);
    }

    useEffect(() => {
        setReservation({
            ...reservation,
            type: type.join(','),
        });
    }, [type]);

    return (
        <div>
            <Modal
                className='couple-maincontainer'
                open={visible}
                onCancel={onClose}
                maskClosable={false}
                footer={[]}
            >
                <div className='couple-modal'>
                    <ArrowBackIcon onClick={handleBack} className='back-arrow' />
                    <ProgressBar progress={progress} />
                    <div className='couple-modal-container' ref={topRef}>
                        <div className='couple-title'>What type of couple are you?( Select up to 3)</div>
                        {includeItems.map((prop, key) => {
                            return (
                                <div className='couple-modal-subcontainer' key={key}>
                                    <Checkbox checked={reservation?.type.includes(prop.label)} onChange={() => handleChecked(prop.label)}>
                                        <div className='couple-type-label'>{prop.label}</div>
                                    </Checkbox>
                                </div>
                            );
                        })}
                    </div>
                    <div className='next-btn-container'>
                        <ButtonSmall buttonstyle="default " className='destination-next-btn' disabled={reservation.type !== '' ? '' : 'disabled-destination-btn'} text="Next" handleClick={goNext} />
                    </div>

                </div>
            </Modal>
        </div>
    );
};

export default CoupleModal;