
import React from 'react';
import { Modal } from 'antd';
import Logo from '../../../assets/icons/welcomelogo';
import ButtonSmall from '../../../Components/ButtonSmall';
import useNotification from '../../../providers/NotificationProvider';
import "./style.css";

const WelcomeModal = ({ visible, onClose, setIsPopupVisible, setIsJoinNow }) => {

    const [notification, createNotification] = useNotification();

    const handleJoinbtn = () => {
        createNotification({
            title: 'Terms',
            content:
                'By joining I accept the Terms of Use. I have read and understood the Privacy Policy.',
            primary: {
                title: 'Accept & Continue',
                onPress: () => {
                    onClose();
                    setIsPopupVisible(true);
                    setIsJoinNow(true);
                }
            },
            secondary: {
                title: 'Decline',
            },
        })
    }

    const handleLogin = () => {
        onClose();
        setIsJoinNow(false);
        setIsPopupVisible(true);
    }

    const handleGuest = () => {
        onClose();
    }

    return (
        <Modal
            className='welcomemodal-maincontainer'
            open={visible}
            maskClosable={false}
            onCancel={onClose}
            footer={[]}
        >
            <div className='welcomemodal'>

                <Logo className="welcome-logo" />
                <div className='button-container'>
                    <div className='button-subcontainer'>
                        <ButtonSmall className="welcome-join-btn" buttonstyle="default" text="Join Now" handleClick={handleJoinbtn} />
                        <ButtonSmall className="welcome-login-btn" buttonstyle="default" text="Login" handleClick={handleLogin} />
                    </div>
                    <div onClick={handleGuest} className='welcome-guest'>continue as guest</div>
                </div>
            </div>
        </Modal >
    );
};

export default WelcomeModal;