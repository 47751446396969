import React from "react";

const LocationIcon = ({ className }) => {
    return (
        <svg className={className} width="11" height="14" viewBox="0 0 11 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.1875 5.26025C9.1875 3.1001 7.41016 1.32275 5.25 1.32275C3.0625 1.32275 1.3125 3.1001 1.3125 5.26025C1.3125 5.61572 1.42188 6.13525 1.72266 6.84619C1.99609 7.50244 2.40625 8.26807 2.87109 9.03369C3.66406 10.2915 4.56641 11.4946 5.25 12.3696C5.90625 11.4946 6.80859 10.2915 7.60156 9.03369C8.06641 8.26807 8.47656 7.50244 8.75 6.84619C9.05078 6.13525 9.1875 5.61572 9.1875 5.26025ZM10.5 5.26025C10.5 7.6665 7.30078 11.9048 5.87891 13.6821C5.55078 14.0923 4.92188 14.0923 4.59375 13.6821C3.19922 11.9048 0 7.6665 0 5.26025C0 2.36182 2.35156 0.0102539 5.25 0.0102539C8.14844 0.0102539 10.5 2.36182 10.5 5.26025ZM6.125 5.26025C6.125 4.79541 5.71484 4.38525 5.25 4.38525C4.75781 4.38525 4.375 4.79541 4.375 5.26025C4.375 5.75244 4.75781 6.13525 5.25 6.13525C5.71484 6.13525 6.125 5.75244 6.125 5.26025ZM3.0625 5.26025C3.0625 4.49463 3.47266 3.78369 4.15625 3.37354C4.8125 2.99072 5.66016 2.99072 6.34375 3.37354C7 3.78369 7.4375 4.49463 7.4375 5.26025C7.4375 6.05322 7 6.76416 6.34375 7.17432C5.66016 7.55713 4.8125 7.55713 4.15625 7.17432C3.47266 6.76416 3.0625 6.05322 3.0625 5.26025Z" fill="#344054" />
        </svg>

    );
};

export default LocationIcon;