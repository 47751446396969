import React from 'react';
import { Modal } from 'antd';
import { useNavigate } from 'react-router-dom';
import useStore from '../../providers/StoreProvider';
import ButtonSmall from '../../Components/ButtonSmall';
import BadgeVisible from '../../Components/BadgeVisible';
import "./style.css";

const RequestSuccessModal = ({ visible, onClose, data }) => {

    const navigate = useNavigate();
    const { store } = useStore();

    let title = data?.title;
    let anchorTitle = title?.replace(/\s+/g, '-').toLowerCase();

    const changeType = (type, title) => {
        if (type == "basic" || type == "property" || type == "brand" || type == "destination") {
            return `/${title}`
        } else if (type === "advisor") {
            return `/${'ta'}/${title}`
        } else {
            return `/${type}/${title}`;
        }
    }

    const handleReadMore = () => {
        onClose();
        navigate(changeType(data?.type, anchorTitle))
    }

    return (
        <div>
            <Modal
                className='requestsubmit-maincontainer'
                open={visible}
                onCancel={onClose}
                maskClosable={false}
                footer={[]}
            >
                <div className='requestsubmit-modal'>
                    <div className='requestsubmit-modal-container'>
                        <div className='request-submit-title'>
                            Thank you{' '}
                            {store.user.full_name != null
                                ? store.user.full_name
                                : null}
                            , I’ll be in touch soon!
                        </div>
                        <div className='detail-title'>{data?.title}</div>
                        {data?.type === 'advisor' && (
                            <>
                                <div className='advisor-excerpt'>{data?.excerpt}</div>
                                <BadgeVisible className="class-33" text="Travel Advisor" />
                            </>
                        )}
                        {data?.type === 'property' && (
                            <>
                                <div className='advisor-excerpt'>{data?.city}, {data?.country}</div>
                                <BadgeVisible className="class-33" text="Hotel" />
                            </>
                        )}
                        {data?.type === 'destination' && (
                            <BadgeVisible className="class-33" text="Destination" />
                        )}
                        <div className='success-description'>{data?.description}</div>
                        {data?.featured_image?.sizes.small &&

                            <img className='success-img' src={data?.featured_image?.sizes.small} alt="img" />
                        }

                    </div>
                    <div className='next-btn-container'>
                        <ButtonSmall buttonstyle="default" className='success-read-btn' handleClick={handleReadMore} text="Read More" />
                    </div>

                </div>
            </Modal >
        </div >
    );
};

export default RequestSuccessModal;