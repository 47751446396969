import React from "react";

const GalleryIcon = ({ className, fill }) => {
    return (
        <svg className={className} width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7 2.0625C6.75391 2.0625 6.5625 2.28125 6.5625 2.5V8.625C6.5625 8.87109 6.72656 9.0625 6.97266 9.0625L8.85938 6.49219C8.99609 6.32812 9.1875 6.21875 9.40625 6.21875C9.59766 6.21875 9.78906 6.32812 9.92578 6.49219L10.3906 7.12109L11.9219 4.76953C12.0312 4.57812 12.25 4.46875 12.4688 4.46875C12.6875 4.46875 12.8789 4.57812 13.0156 4.76953L15.8047 9.0625C16.0234 9.03516 16.1875 8.84375 16.1875 8.625V2.5C16.1875 2.28125 15.9688 2.0625 15.75 2.0625H7ZM5.25 2.5C5.25 1.54297 6.01562 0.75 7 0.75H15.75C16.707 0.75 17.5 1.54297 17.5 2.5V8.625C17.5 9.60938 16.707 10.375 15.75 10.375H7C6.01562 10.375 5.25 9.60938 5.25 8.625V2.5ZM3.71875 4.25H4.375V5.5625V7.96875V11.0312V11.25V13.4375H9.625V11.25H10.9375V13.4375H12.25C12.4688 13.4375 12.6875 13.2461 12.6875 13V11.25H14V13C14 13.9844 13.207 14.75 12.25 14.75H10.9375H10.2812H9.625H4.375H3.71875H3.0625H1.75C0.765625 14.75 0 13.9844 0 13V6C0 5.04297 0.765625 4.25 1.75 4.25H3.0625H3.71875ZM3.0625 5.5625H1.75C1.50391 5.5625 1.3125 5.78125 1.3125 6V7.3125H3.0625V5.5625ZM3.0625 13.4375V11.6875H1.3125V13C1.3125 13.2461 1.50391 13.4375 1.75 13.4375H3.0625ZM1.3125 10.375H3.0625V8.625H1.3125V10.375ZM8.3125 2.9375C8.77734 2.9375 9.1875 3.34766 9.1875 3.8125C9.1875 4.30469 8.77734 4.6875 8.3125 4.6875C7.82031 4.6875 7.4375 4.30469 7.4375 3.8125C7.4375 3.34766 7.82031 2.9375 8.3125 2.9375Z" fill={fill ? 'white' : '#3B3B4D'} />
        </svg>
    );
};

export default GalleryIcon