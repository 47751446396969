
import PropTypes from "prop-types";
import React from "react";
import { useReducer } from "react";
import Badge from "../Badge";
import ButtonsButton from "../ButtonsButton";
import "./style.css";

const FooterLink = ({
  badge,
  color,
  theme,
  stateProp,
  className,
  buttonsButtonText = "Overview",
  buttonsButtonTextPaddingClassName,
  buttonsButtonText1 = "Overview",
  buttonsButtonTextPaddingClassNameOverride,
}) => {
  const [state, dispatch] = useReducer(reducer, {
    badge: badge || false,
    color: color || "default",
    theme: theme || "light",
    state: stateProp || "default",
  });

  return (
    <div
      className={`footer-link ${className}`}
      onMouseLeave={() => {
        dispatch("mouse_leave");
      }}
      onMouseEnter={() => {
        dispatch("mouse_enter");
      }}
    >
      <ButtonsButton
        className="buttons-button-instance"
        hierarchy={state.color === "brand" ? "link-color" : "link-gray"}
        icon="default"
        iconLeading={false}
        iconTrailing={false}
        size="lg"
        stateProp={state.theme === "light" && state.state === "hover" ? "hover" : "default"}
        text={!state.badge ? buttonsButtonText : buttonsButtonText1}
        textPaddingClassName={
          !state.badge ? buttonsButtonTextPaddingClassName : buttonsButtonTextPaddingClassNameOverride
        }
      />
      {state.badge && (
        <Badge text="New" />
      )}
    </div>
  );
};

function reducer(state, action) {
  switch (action) {
    case "mouse_enter":
      return {
        ...state,
        state: "hover",
      };

    case "mouse_leave":
      return {
        ...state,
        state: "default",
      };
  }

  return state;
}

FooterLink.propTypes = {
  badge: PropTypes.bool,
  color: PropTypes.oneOf(["brand", "default"]),
  theme: PropTypes.oneOf(["dark", "light"]),
  stateProp: PropTypes.oneOf(["hover", "default"]),
  buttonsButtonText: PropTypes.string,
  buttonsButtonText1: PropTypes.string,
};

export default FooterLink;