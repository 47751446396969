
import React from "react";

const HorizontalLogo = ({ className }) => {
    return (
        <svg className={className} width="213" height="48" viewBox="0 0 213 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M41.4868 39.144V43.5699C41.4868 44.7627 40.5242 45.7254 39.3313 45.7254H2.15543C0.962619 45.7254 0 44.7627 0 43.5699V3.548C0 2.35519 0.962619 1.39258 2.15543 1.39258H7.97299C9.1658 1.39258 10.1284 2.35519 10.1284 3.548V35.869C10.1284 36.4863 10.6307 36.9885 11.248 36.9885H39.3313C40.5242 36.9885 41.4868 37.9616 41.4868 39.144Z" fill="white" />
            <path d="M44.1443 23.5635C44.1443 8.74754 55.0365 0.000244141 72.3323 0.000244141C89.6176 0.000244141 100.52 8.73708 100.52 23.5635C100.52 38.3795 89.628 47.1267 72.3323 47.1267C55.0365 47.1267 44.1443 38.3899 44.1443 23.5635ZM90.193 23.5635C90.193 14.0629 83.2873 8.74752 72.3323 8.74752C61.3773 8.74752 54.4715 14.0629 54.4715 23.5635C54.4715 33.0641 61.3145 38.3794 72.3323 38.3794C83.2873 38.3899 90.193 33.0641 90.193 23.5635Z" fill="white" />
            <path d="M113.317 2.60632L128.426 36.2143C128.844 37.0618 130.048 37.0618 130.456 36.2143L145.512 2.61678C145.868 1.87389 146.621 1.40305 147.448 1.40305H153.559C155.16 1.40305 156.206 3.09809 155.484 4.53155L135.3 44.5535C134.934 45.2755 134.191 45.7358 133.375 45.7358H125.329C124.513 45.7358 123.77 45.2755 123.404 44.5535L103.22 4.52109C102.498 3.08763 103.534 1.39258 105.145 1.39258H111.381C112.208 1.40304 112.961 1.86343 113.317 2.60632Z" fill="white" />
            <path d="M209.945 1.40234C211.138 1.40234 212.1 2.36496 212.1 3.55777V24.84C212.1 38.1388 203.301 47.1372 187.208 47.1372C171.116 47.1372 162.316 38.1388 162.316 24.84V3.55777C162.316 2.36496 163.279 1.40234 164.472 1.40234H170.3C171.492 1.40234 172.455 2.36496 172.455 3.55777V24.0762C172.455 32.9386 177.645 38.2644 187.208 38.2644C196.772 38.2644 201.961 32.949 201.961 24.0762V3.55777C201.961 2.36496 202.924 1.40234 204.117 1.40234H209.945V1.40234Z" fill="white" />
        </svg>


    );
};

export default HorizontalLogo
