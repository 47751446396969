import React from "react";

const Globe = ({ className }) => {
  return (
    <svg
      className={`globe-05 ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_3779_552)">
        <path
          className="path"
          d="M12.5 2.04819C11.7108 1.80032 10.871 1.66669 9.99996 1.66669C5.39759 1.66669 1.66663 5.39765 1.66663 10C1.66663 14.6024 5.39759 18.3334 9.99996 18.3334C14.6023 18.3334 18.3333 14.6024 18.3333 10C18.3333 8.57058 17.9734 7.2252 17.3393 6.04956M14.1666 4.79169H14.1708M8.75 18.2402L8.75009 16.4041C8.7501 16.3047 8.78568 16.2085 8.8504 16.133L10.9219 13.7162C11.0921 13.5176 11.0394 13.2131 10.8123 13.0833L8.43207 11.7231C8.3674 11.6862 8.31382 11.6326 8.27691 11.5679L6.72534 8.84888C6.64459 8.70737 6.48877 8.62591 6.32649 8.64036L1.72011 9.05064M17.5 5.00002C17.5 6.84097 15.8333 8.33335 14.1666 10C12.5 8.33335 10.8333 6.84097 10.8333 5.00002C10.8333 3.15907 12.3257 1.66669 14.1666 1.66669C16.0076 1.66669 17.5 3.15907 17.5 5.00002ZM14.375 4.79169C14.375 4.90675 14.2817 5.00002 14.1666 5.00002C14.0516 5.00002 13.9583 4.90675 13.9583 4.79169C13.9583 4.67663 14.0516 4.58335 14.1666 4.58335C14.2817 4.58335 14.375 4.67663 14.375 4.79169Z"
          stroke="#667085"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.66667"
        />
      </g>
      <defs className="defs">
        <clipPath className="clip-path" id="clip0_3779_552">
          <rect className="rect" fill="white" height="20" width="20" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Globe