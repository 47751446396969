import React, { useEffect, useRef, useState } from 'react';
import { message, Spin } from 'antd';
import NavBar from '../../Components/NavBar';
import Footer from '../../Components/Footer';
import Banner from '../../Components/Banner';
import BookingPayment from "./BookingPayment";
import PostModel from "../../api/models/Post";
import MetaTags from '../../Components/MetaTags';
import BackArrow from "../../assets/icons/BackArrow";
import ReactStars from "react-rating-stars-component";
import RightArrow from "../../assets/icons/RightArrow";
import ButtonSmall from '../../Components/ButtonSmall';
import { Country, State, City } from 'country-state-city';
import bannerImage from "../../assets/images/ZentiumSearchBanner.jpeg";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import "./style.css";


const ZentrumBooking = ({ setProgress }) => {

    const countryRef = useRef(null);
    const stateRef = useRef(null);
    const cityRef = useRef(null);

    const [isCountryVisible, setIsCountryVisible] = useState(false)
    const [isStateVisible, setStateVisible] = useState(false)
    const [isCityVisible, setCityVisible] = useState(false)

    const handleClickOutside = (event) => {
        if (countryRef.current && !countryRef.current.contains(event.target)) {
            const inputFieldClicked = event.target.closest('.suggestions-list');
            if (!inputFieldClicked) {
                setIsCountryVisible(false);
            }
        }
        if (stateRef.current && !stateRef.current.contains(event.target)) {
            const inputFieldClicked = event.target.closest('.suggestions-list');
            if (!inputFieldClicked) {
                setStateVisible(false);
            }
        }
        if (cityRef.current && !cityRef.current.contains(event.target)) {
            const inputFieldClicked = event.target.closest('.suggestions-list');
            if (!inputFieldClicked) {
                setCityVisible(false);
            }
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);


    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const hotelTitle = searchParams.get('filter');
    const tokenForNewHotel = searchParams.get('token');
    const ip = searchParams.get('ip');
    const startDate = searchParams.get('startDate');
    const endDate = searchParams.get('endDate');
    const recommendationId = searchParams.get('recommendationId');
    const correlationId = searchParams.get('correlationId');
    const LocationId = searchParams.get('LocationId');
    const occasion = searchParams.get('occasion');
    const people = searchParams.get('people');
    const locationTitle = searchParams.get('location');
    const encodedRooms = searchParams.get('rooms');
    const { HotelId } = useParams();


    const [hotelLoader, setHotelLoader] = useState(false);
    const [hotelContent, setHotelContent] = useState([]);
    const [names, setNames] = useState([{ title: '', firstName: '', lastName: '', type: '' }]);
    const [contactInfo, setContactInfo] = useState({ email: '', phone: '' });
    const [billingAddress, setBillingAddress] = useState({ addressLine1: '', addressLine2: '', country: '', state: '', city: '', zip: '' });
    const [bookingDetail, setBookingDetail] = useState();
    const [bookingDetailLoader, setBookingDetailLoader] = useState(false)
    const [finalBookedResult, setFinalBookedResult] = useState();
    const [finalBookLoader, setFinalBookLoader] = useState(false);
    const [errors, setErrors] = useState({});
    const [isBookingPayment, setIsBookingPayment] = useState(false);
    const [isPaymentSuccess, setIsPaymentSuccess] = useState(false);
    const [bookingId, setBookingId] = useState();
    const [success, setSuccess] = useState(false);
    const [failed, setFailed] = useState(false);



    let bookingPeopleCount = 0;
    let totalRates = 0;
    let taxAmount = 0;
    let Total = 0;

    useEffect(() => {
        setProgress(10)
        setProgress(20)
        setProgress(60)
        setProgress(100)
    }, [setProgress])


    useEffect(() => {
        getHotelContent();
        getBillingContent();
    }, [HotelId, ip])

    let tokendata = localStorage.getItem('data');
    let rawuser = localStorage.getItem('user');
    const user = JSON.parse(rawuser);
    var currentdate = new Date().getTime();
    var expir = new Date(user?.subscription_expires_at).getTime();

    const getHotelContent = async () => {
        if (ip) {
            setHotelLoader(true)
            var channelId = tokendata === null ? process.env.REACT_APP_PUBLIC_ZENTRUM_CHANNEL_ID : tokendata !== null && (user?.subscription_sku == null || currentdate > expir) ? process.env.REACT_APP_NON_PAID_ZENTRUM_CHANNEL_ID : process.env.REACT_APP_MEMBER_PAID_ZENTRUM_CHANNEL_ID;
            await PostModel.fetchhotelcontent(HotelId, ip, correlationId, channelId)
                .then((res) => {
                    setHotelContent(res.hotels);
                    setHotelLoader(false)
                }
                )
                .catch(() => { });
        }
    }

    const getBillingContent = async () => {
        if (ip) {
            setBookingDetailLoader(true);
            await PostModel.priceByRecommendation(HotelId, tokenForNewHotel, ip, recommendationId, correlationId)
                .then((res) => {
                    setBookingDetail(res?.hotel)
                    setBookingDetailLoader(false);
                }
                )
                .catch(() => { });
        }
    }

    const handleBackToRoomSelection = () => {
        navigate(`/hotelroom/${HotelId}?filter=${hotelTitle}&token=${tokenForNewHotel}&ip=${ip}&currentpage=${'room'}&startDate=${startDate}&endDate=${endDate}&correlationId=${correlationId}&LocationId=${LocationId}&occasion=${occasion}&people=${people}&location=${locationTitle}&rooms=${encodedRooms}`);
    }

    const handleChange = (index, key, value) => {
        const newNames = [...names];
        newNames[index][key] = value;
        setNames(newNames);
    };

    const handleContactInfoChange = (e) => {
        setContactInfo({ ...contactInfo, [e.target.name]: e.target.value });
    };

    const [countrySuggestions, setCountrySuggestions] = useState([]);
    const [countryCode, setCountryCode] = useState();
    const [stateSuggestions, setStateSuggestions] = useState([]);
    const [stateCode, setStateCode] = useState();
    const [citySuggestions, setCitySuggestions] = useState([]);




    const handleBillingAddressChange = (e) => {
        const { name, value } = e.target;
        setBillingAddress((prev) => ({ ...prev, [name]: value }));
        if (name === 'country') {
            fetchCountrySuggestions(value);
        } else if (name === 'state' && billingAddress.country) {
            fetchStateSuggestions(value);
        } else if (name === 'city' && billingAddress.state) {
            fetchCitySuggestions(value, billingAddress.country, billingAddress.state);
        }
    };

    useEffect(() => {
        setCountrySuggestions(Country.getAllCountries());
    }, [])

    useEffect(() => {
        setStateSuggestions(State.getStatesOfCountry(countryCode))
    }, [countryCode])

    useEffect(() => {
        setCitySuggestions(City.getCitiesOfState(countryCode, stateCode))
    }, [stateCode])




    const fetchCountrySuggestions = async (term) => {
        const count = Country.getAllCountries();
        const filtcount = count.filter((cou) => cou.name.toLowerCase().includes(term.toLowerCase()))
        setCountrySuggestions(filtcount);
        setIsCountryVisible(true)
    };



    const fetchStateSuggestions = async (term) => {
        const state = State.getStatesOfCountry(countryCode);
        const filterState = state.filter((states) => states.name.toLowerCase().includes(term.toLowerCase()))
        setStateSuggestions(filterState);
        setStateVisible(true);
    };

    const fetchCitySuggestions = async (term) => {
        const city = City.getCitiesOfState(countryCode, stateCode);
        const filterCity = city.filter((citys) => citys.name.toLowerCase().includes(term.toLowerCase()))
        setCitySuggestions(filterCity);
        setCityVisible(true);
    };

    const handleSuggestionClick = (name, value, code) => {
        setBillingAddress((prev) => ({ ...prev, [name]: value }));
        if (name === 'country') {
            setCountryCode(code);
            setCountrySuggestions([]);
            setIsCountryVisible(false)
            setStateVisible(false);
            setCityVisible(false);

        } else if (name === 'state') {
            setStateSuggestions([]);
            setStateCode(code);
            setStateVisible(false);
            setIsCountryVisible(false)
            setCityVisible(false);
        } else {
            setCitySuggestions([]);
            setCityVisible(false);
            setIsCountryVisible(false)
            setStateVisible(false);
        }
    };


    const validateFields = () => {
        const newErrors = {};
        names.forEach((name, index) => {
            if (!name.firstName) {
                newErrors[`firstName${index}`] = '* First name is required';
            }
            if (!name.lastName) {
                newErrors[`lastName${index}`] = '* Last name is required';
            }
        });

        if (!contactInfo.email) {
            newErrors.email = '* Email is required';
        }
        if (!contactInfo.phone) {
            newErrors.phone = '* Phone number is required';
        }
        if (!billingAddress.addressLine1) {
            newErrors.addressLine1 = '* Billing address line 1 is required';
        }
        if (!billingAddress.country) {
            newErrors.country = '* country is required';
        }
        if (!billingAddress.city) {
            newErrors.city = '* City is required';
        }
        if (!billingAddress.state) {
            newErrors.state = '* State is required';
        }
        if (!billingAddress.zip) {
            newErrors.zip = '* Postal code is required';
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleBookingHandler = async () => {

        if (!validateFields()) {
            return;
        }
        let bookingid = 'Lovutravel-hotel-booking-' + Date.now();
        setBookingId(bookingid)

        setFinalBookLoader(true);
        setIsBookingPayment(true);
    };



    const formattedStartDate = new Date(startDate).toLocaleDateString('en-US', {
        day: 'numeric',
        month: 'long',
        timeZone: 'UTC'
    });

    const formattedEndDate = new Date(endDate).toLocaleDateString('en-US', {
        day: 'numeric',
        month: 'long',
        timeZone: 'UTC'
    });

    function formatWithCommas(x) {
        return "$" + x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    const timeDifference = new Date(endDate).getTime() - new Date(startDate).getTime();
    const daysDifference = timeDifference / (1000 * 3600 * 24);

    const bookingDiscount = bookingDetail?.discounts;
    let TotalDiscount;
    const handleTotalDiscount = (x) => {
        return tokendata === null ? bookingDiscount?.length >= 0 ? Number(0) : Number(bookingDiscount[0]?.amount) : tokendata !== null && (user?.subscription_sku == null || currentdate > expir) ? bookingDiscount?.length >= 0 ? Math.round(((10 / 100) * x)) : (Number(bookingDiscount[0]?.amount) + Math.round(((10 / 100) * x))) : bookingDiscount?.length >= 0 ? Math.round(((20 / 100) * x)) : (Number(bookingDiscount[0]?.amount + Math.round(((20 / 100) * x))))
    }
    if (bookingDetail && bookingDetail?.rates && bookingDetail?.rates?.length > 0) {
        totalRates = tokendata === null ? bookingDetail?.rates[0]?.totalRate : tokendata !== null && (user?.subscription_sku == null || currentdate > expir) ? Math.round(((10 / 100) * bookingDetail?.rates[0]?.totalRate) + bookingDetail?.rates[0]?.totalRate) : Math.round(((20 / 100) * bookingDetail?.rates[0]?.totalRate) + bookingDetail?.rates[0]?.totalRate);
        TotalDiscount = handleTotalDiscount(bookingDetail?.rates[0]?.totalRate);
        if (bookingDetail?.rates[0]?.taxes && bookingDetail?.rates[0]?.taxes?.length > 0) {
            taxAmount = bookingDetail?.rates[0]?.taxes[0]?.amount;
        }
        bookingDetail?.rates?.forEach(rate => {
            if (rate?.occupancies && rate?.occupancies?.length > 0) {
                rate?.occupancies?.forEach(occupancy => {
                    const numOfAdults = parseInt(occupancy?.numOfAdults) || 0;
                    const numOfChildren = parseInt(occupancy?.numOfChildren) || 0;
                    bookingPeopleCount += numOfAdults + numOfChildren;
                });
            }
        });
    }

    if (totalRates && bookingDiscount) {
        Total = totalRates - TotalDiscount;
    }

    useEffect(() => {
        if (bookingPeopleCount > 0) {
            const totalCounts = JSON.parse(encodedRooms).reduce((totals, traveller) => {
                totals.adults += traveller.adults;
                totals.children += traveller.children;
                return totals;
            }, { adults: 0, children: 0 });

            let totalAdults = totalCounts.adults || 0;
            let totalChildren = totalCounts.children || 0;
            const newNames = Array.from({ length: bookingPeopleCount }, (_, index) => {
                let types = '';
                if (totalAdults > 0) {
                    types = 'adult';
                    totalAdults--;
                } else if (totalChildren > 0) {
                    types = 'child';
                    totalChildren--;
                }
                return {
                    title: 'Mr',
                    firstName: '',
                    lastName: '',
                    type: types,
                }
            });
            setNames(newNames);
        }

    }, [bookingPeopleCount])

    useEffect(() => {
        const updatedGuestName = names?.map(({ firstName, lastName, type, title }) => ({
            firstName, lastName, type,
            title, email: contactInfo?.email
        }));

        const rateid = bookingDetail?.rates[0]?.id;
        const roomid = bookingDetail?.rooms[0]?.id;
        const dummydata = {
            "rateIds": [
                rateid
            ],
            "specialRequests": [
                `occasion: ${occasion}`,
            ],
            "bookingRefId": bookingId,
            "roomsAllocations": [
                {
                    "roomId": roomid,
                    "rateId": rateid,
                    "guests": updatedGuestName
                }
            ],
            "billingContact": {
                "title": names[0]?.title,
                "firstName": names[0]?.firstName,
                "lastName": names[0]?.lastName,
                "contact": {
                    "phone": contactInfo?.phone,
                    "address": {
                        "line1": billingAddress?.addressLine1,
                        "line2": billingAddress?.addressLine2,
                        "city": {
                            "name": billingAddress?.city,
                            "code": ""
                        },
                        "state": {
                            "name": "",
                            "code": billingAddress?.state
                        },
                        "country": {
                            "name": billingAddress?.country,
                            "code": "US"
                        },
                        "postalCode": billingAddress?.zip
                    },
                    "email": contactInfo?.email
                }
            },

        }
        const hotelDetail = {
            hotelname: hotelTitle,
            startdate: startDate,
            enddate: endDate,
            email: contactInfo?.email,
        }
        const handleHotelBook = async () => {
            await PostModel.priceByRecommendation(HotelId, tokenForNewHotel, ip, recommendationId, correlationId)
                .then(async (res) => {
                    if (res) {
                        if (dummydata) {
                            await PostModel.booking(HotelId, tokenForNewHotel, ip, correlationId, dummydata, hotelDetail)
                                .then((res) => {
                                    if (res) {
                                        if (res?.bookingStatus == 'Confirmed') {
                                            setIsBookingPayment(true)
                                            setSuccess(true);
                                        } else {
                                            if (res.Error.message && typeof res.Error.message === "string") {
                                                message.error(res.Error.message)
                                            }
                                            setIsBookingPayment(true);
                                            setFailed(true);
                                            setFinalBookLoader(false);
                                        }
                                        setFinalBookedResult(res);
                                        setFinalBookLoader(false);
                                    } else {
                                    }
                                }
                                )
                                .catch((error) => { console.log(error, 'error from zentrum booking') });
                        }
                    }
                }
                )
                .catch((error) => { console.log(error, 'error from zentrum pricebyrecomendations') });
        }
        if (isPaymentSuccess) {
            handleHotelBook();
        }

    }, [isPaymentSuccess])


    return (
        <>
            <MetaTags
                title={`LOVU: Booking Rooms`}
                imageScr={bannerImage}
            />
            <div className="zentrumroom">
                <NavBar navigationDark={true} />
                <Banner
                    isSearch={true}
                    imageSrc={bannerImage}
                    widget={
                        <div className='widget'>
                            <div className='zentrumroom-widget'>
                                <div className='zentruroom-wiget-subcontainer'>
                                    {
                                        hotelLoader ?
                                            <div className='seacrh-title skeleton-box seacrh-title-box '></div>
                                            :
                                            <div className='seacrh-title'>{hotelTitle}</div>
                                    }
                                    {
                                        hotelLoader ?
                                            <div className="zentrum-room-rating-container">
                                                <div className="review-rating-span skeleton-box review-rating-span-box" ></div>
                                                <div className="review-count skeleton-box review-count-box"></div>
                                                <div className='zentrum-star skeleton-box'></div>
                                            </div>
                                            :
                                            <div className="zentrum-room-rating-container">
                                                {
                                                    hotelContent[0]?.reviews && hotelContent[0]?.reviews?.length > 0 && hotelContent[0]?.reviews[0]?.rating && hotelContent[0]?.reviews[0]?.rating != -1 &&
                                                    <div className="review-rating-span">{hotelContent[0]?.reviews[0]?.rating}</div>
                                                }
                                                {
                                                    hotelContent[0]?.reviews && hotelContent[0]?.reviews.length > 0 && hotelContent[0]?.reviews[0]?.count && hotelContent[0]?.reviews[0]?.count != 0 &&

                                                    <div className="review-count">{hotelContent[0]?.reviews[0]?.count} Reviews</div>
                                                }
                                                {
                                                    hotelContent[0]?.starRating &&
                                                    <ReactStars
                                                        count={Number(5)}
                                                        edit={false}
                                                        value={Number(hotelContent[0]?.starRating)}
                                                        isHalf={true}
                                                        size={22}
                                                        color="#fff"
                                                        activeColor="#ffd700"
                                                    />
                                                }
                                            </div>
                                    }
                                </div>
                                {hotelLoader ?
                                    <div className='seacrh-address skeleton-box seacrh-address-box'></div>
                                    :
                                    <div className='seacrh-address'>{hotelContent[0]?.contact?.address?.line1},{hotelContent[0]?.contact?.address?.city?.name},{hotelContent[0]?.contact?.address?.country?.name}</div>

                                }
                            </div>
                        </div>
                    }
                />

                <div className="pageContent">
                    <div className='zentrumroom-subcontainer'>
                        <div className='zentrumroom-widget widget-mobile'>
                            <div className='seacrh-title'>{hotelTitle}</div>
                            <div className='seacrh-address'>{hotelContent[0]?.contact?.address?.line1},{hotelContent[0]?.contact?.address?.city?.name},{hotelContent[0]?.contact?.address?.country?.name}</div>

                            <div className="zentrum-room-rating-container">
                                {
                                    hotelContent[0]?.reviews && hotelContent[0]?.reviews.length > 0 && hotelContent[0]?.reviews[0]?.rating && hotelContent[0]?.reviews[0]?.rating != -1 &&
                                    <div className="review-rating-span">{hotelContent[0]?.reviews[0]?.rating}</div>
                                }
                                {
                                    hotelContent[0]?.reviews && hotelContent[0]?.reviews.length > 0 && hotelContent[0]?.reviews[0]?.count && hotelContent[0]?.reviews[0]?.count != 0 &&

                                    <div className="review-count">{hotelContent[0]?.reviews[0]?.count} Reviews</div>
                                }
                                {
                                    hotelContent[0]?.starRating &&
                                    <ReactStars
                                        count={Number(5)}
                                        edit={false}
                                        value={Number(hotelContent[0]?.starRating)}
                                        isHalf={true}
                                        size={22}
                                        color="#fff"
                                        activeColor="#ffd700"
                                    />
                                }
                            </div>
                        </div>
                        <div onClick={handleBackToRoomSelection} className='zentrum-back-to-search'>
                            <BackArrow className='zentrum-back-arrow' />
                            <div className='zentrum-back-content'>Back To Room/Rate Selection</div>
                        </div>
                        <div className='zentrum-billing-section'>
                            <div className='zentrum-billing-section-container'>
                                <h2 className='zentrum-billing-section-title'>Guest Details</h2>
                                <div className='zentrum-guest-container'>
                                    {
                                        bookingDetailLoader ?
                                            <div className='zentrum-guest-room-title skeleton-box zentrum-guest-room-title-box'></div>
                                            :
                                            <h3 className='zentrum-guest-room-title'>Room : {bookingDetail?.rooms[0]?.name}</h3>
                                    }
                                    {names.map((name, index) => (
                                        <div className='zentrum-guest-list-container' key={index}>
                                            <div className='zentrum-guest-list-first-name'>
                                                <div className='zentrum-guest-input-heading'>
                                                    <h3 className='zentrum-guest-room-title'>Guest {index + 1}: </h3>
                                                    <label>First Name:*</label>
                                                </div>
                                                <input
                                                    type="text"
                                                    value={name.firstName}
                                                    onChange={(e) => handleChange(index, 'firstName', e.target.value)}
                                                />
                                                {errors[`firstName${index}`] && <p className="zentrumbook-error">{errors[`firstName${index}`]}</p>}
                                            </div>
                                            <div className='zentrum-guest-list-first-name'>
                                                <div className='zentrum-guest-input-heading'>
                                                    <label>Last Name:*</label>
                                                </div>
                                                <input
                                                    type="text"
                                                    value={name.lastName}
                                                    onChange={(e) => handleChange(index, 'lastName', e.target.value)}
                                                />
                                                {errors[`lastName${index}`] && <p className="zentrumbook-error">{errors[`lastName${index}`]}</p>}
                                            </div>

                                        </div>
                                    ))}
                                </div>
                                <h2 className='zentrum-billing-section-title'>Contact Information</h2>
                                <div className='zentrum-guest-container'>
                                    <div className='zentrum-guest-list-container'>

                                        <div className='zentrum-guest-list-email-phone'>
                                            <div className='zentrum-guest-input-heading'>
                                                <label>Email:*</label>
                                            </div>
                                            <input
                                                type="text"
                                                name="email"
                                                value={contactInfo.email}
                                                onChange={handleContactInfoChange}
                                            />
                                            {errors[`email`] && <p className="zentrumbook-error">{errors[`email`]}</p>}
                                        </div>
                                        <div className='zentrum-guest-list-email-phone'>
                                            <div className='zentrum-guest-input-heading'>
                                                <label>Phone:</label>
                                            </div>
                                            <input
                                                type="text"
                                                name="phone"
                                                value={contactInfo.phone}
                                                onChange={handleContactInfoChange}
                                            />
                                            {errors[`phone`] && <p className="zentrumbook-error">{errors[`phone`]}</p>}
                                        </div>
                                    </div>
                                </div>
                                <h2 className='zentrum-billing-section-title'>Billing Address</h2>
                                <div className='zentrum-guest-container billing-margin'>
                                    <div className='zentrum-billing-container'>

                                        <div className='zentrum-billing-address'>
                                            <div className='zentrum-guest-input-heading'>
                                                <label>Address Line 1:*</label>
                                            </div>
                                            <input
                                                type="text"
                                                name="addressLine1"
                                                value={billingAddress.addressLine1}
                                                onChange={handleBillingAddressChange}
                                            />
                                            {errors[`addressLine1`] && <p className="zentrumbook-error">{errors[`addressLine1`]}</p>}
                                        </div>
                                        <div className='zentrum-billing-address'>
                                            <div className='zentrum-guest-input-heading'>
                                                <label>Address Line 2:</label>
                                            </div>
                                            <input
                                                type="text"
                                                name="addressLine2"
                                                value={billingAddress.addressLine2}
                                                onChange={handleBillingAddressChange}
                                            />
                                        </div>
                                        <div className='zentrum-billing-country-container'>
                                            <div className='zentrum-billing-country'>
                                                <div className='zentrum-guest-input-heading'>
                                                    <label>Country:*</label>
                                                </div>
                                                <input
                                                    type="text"
                                                    name="country"
                                                    value={billingAddress.country}
                                                    onChange={handleBillingAddressChange}
                                                    onFocus={() => setIsCountryVisible(true)}
                                                    autoComplete='off'
                                                />
                                                {errors[`country`] && <p className="zentrumbook-error">{errors[`country`]}</p>}
                                                {countrySuggestions.length > 0 && isCountryVisible && (
                                                    <ul ref={countryRef} className="suggestions-list">
                                                        {countrySuggestions.map((suggestion, index) => (
                                                            <li
                                                                key={index}
                                                                className="suggested-item-list"
                                                                onClick={() => handleSuggestionClick('country', suggestion.name, suggestion.isoCode)}
                                                            >
                                                                {suggestion?.name}
                                                            </li>
                                                        ))}
                                                    </ul>
                                                )}
                                            </div>
                                            <div className='zentrum-billing-country'>
                                                <div className='zentrum-guest-input-heading'>
                                                    <label>State:*</label>
                                                </div>
                                                <input
                                                    type="text"
                                                    name="state"
                                                    value={billingAddress.state}
                                                    onFocus={() => setStateVisible(true)}
                                                    onChange={handleBillingAddressChange}
                                                    autoComplete='off'
                                                />
                                                {errors[`state`] && <p className="zentrumbook-error">{errors[`state`]}</p>}
                                                {stateSuggestions.length > 0 && isStateVisible && (
                                                    <ul ref={stateRef} className="suggestions-list">
                                                        {stateSuggestions.map((suggestion, index) => (
                                                            <li
                                                                key={index}
                                                                className="suggested-item-list"
                                                                onClick={() => handleSuggestionClick('state', suggestion.name, suggestion.isoCode)}
                                                            >
                                                                {suggestion.name}
                                                            </li>
                                                        ))}
                                                    </ul>
                                                )}
                                            </div>
                                            <div className='zentrum-billing-country'>
                                                <div className='zentrum-guest-input-heading'>
                                                    <label>City:*</label>
                                                </div>
                                                <input
                                                    type="text"
                                                    name="city"
                                                    value={billingAddress.city}
                                                    onFocus={() => setCityVisible(true)}
                                                    onChange={handleBillingAddressChange}
                                                    autoComplete='off'
                                                />
                                                {errors[`city`] && <p className="zentrumbook-error">{errors[`city`]}</p>}
                                                {citySuggestions.length > 0 && isCityVisible && (
                                                    <ul ref={cityRef} className="suggestions-list">
                                                        {citySuggestions.map((suggestion, index) => (
                                                            <li
                                                                key={index}
                                                                className="suggested-item-list"
                                                                onClick={() => handleSuggestionClick('city', suggestion.name)}
                                                            >
                                                                {suggestion.name}
                                                            </li>
                                                        ))}
                                                    </ul>
                                                )}
                                            </div>
                                            <div className='zentrum-billing-country'>
                                                <div className='zentrum-guest-input-heading'>
                                                    <label>Zip:*</label>
                                                </div>
                                                <input
                                                    type="text"
                                                    name="zip"
                                                    value={billingAddress.zip}
                                                    onChange={handleBillingAddressChange}
                                                    autoComplete='off'
                                                />
                                                {errors[`zip`] && <p className="zentrumbook-error">{errors[`zip`]}</p>}
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className='zentrum-room-policy-text'>By continuing to pay, I understand and agree with the <a>privacy policy</a> of LOVU.</div>
                                <div className='zentrum-room-booknow-btn'>
                                    {finalBookLoader ?
                                        <Spin style={{ marginLeft: '8px' }} />
                                        :
                                        <ButtonSmall className="zentrum-book-btn" buttonstyle="default" text="book now" handleClick={() => handleBookingHandler()} />
                                    }
                                </div>
                            </div>
                            <div className='zentrum-billing-detail-container'>
                                <div className='zentrum-billing-detail-subcontainer'>
                                    <h2 className='zentrum-billing-detail-title'>Your booking details</h2>
                                    <div className='zentrum-check-in-out-container'>
                                        <div className='zentrum-check-in'>
                                            <div className='zentrum-check-in-title'>Check-In</div>
                                            <div className='zentrum-check-in-date'>{formattedStartDate}</div>
                                        </div>
                                        <RightArrow />
                                        <div className='zentrum-check-out'>
                                            <div className='zentrum-check-in-title'>Check-Out</div>
                                            <div className='zentrum-check-in-date'>{formattedEndDate}</div>
                                        </div>
                                    </div>
                                    <div className='zentrum-total-stay'>Total length of stay: <span>{daysDifference} nights</span> </div>
                                    <div className='zentrum-divider'></div>
                                    {
                                        bookingDetailLoader ?
                                            <div className='zentrum-total-stay skeleton-box zentrum-total-stay-box '></div>
                                            :
                                            bookingDetail !== undefined &&
                                            <div className='zentrum-total-stay'>You selected: <span>{bookingDetail?.rates[0]?.occupancies?.length} rooms for {bookingPeopleCount} people</span> </div>
                                    }
                                </div>
                                <div className='zentrum-billing-price-subcontainer'>
                                    <h2 className='zentrum-billing-detail-title'>Your price summary</h2>
                                    {
                                        bookingDetailLoader ?
                                            <div className='zentrum-rom-original-price'>
                                                <div className='zentrum-rom-original-price-box skeleton-box'></div> <span className='zentrum-rom-original-price-span-box skeleton-box'></span>
                                            </div>
                                            :
                                            bookingDetail !== undefined &&
                                            <div className='zentrum-rom-original-price'>
                                                <div>Original price:</div> <span>{formatWithCommas(Math.round(totalRates))}</span>
                                            </div>
                                    }
                                    {
                                        tokendata !== null && (
                                            bookingDetailLoader ?
                                                <div className='zentrum-rom-original-price'>
                                                    <div className='zentrum-rom-original-price-box skeleton-box'></div> <span className='zentrum-rom-original-price-span-box skeleton-box'></span>
                                                </div>
                                                :
                                                bookingDetail !== undefined &&
                                                <div className='zentrum-rom-original-price'>
                                                    <div>Discount:</div> <span>{formatWithCommas(Math.round(TotalDiscount))}</span>
                                                </div>
                                        )
                                    }
                                    {
                                        bookingDetailLoader ?
                                            <div className='zentrum-rom-original-price'>
                                                <div className='zentrum-rom-original-price-box skeleton-box'></div> <span className='zentrum-rom-original-price-span-box skeleton-box'></span>
                                            </div>
                                            :
                                            bookingDetail !== undefined &&
                                            <div className='zentrum-rom-original-price zentrum-room-total'>
                                                <div>Total:</div>
                                                <span>{formatWithCommas(Math.round(Total, 2))}</span>
                                            </div>
                                    }

                                    {
                                        bookingDetailLoader ?
                                            <div className='zentrum-rom-original-price skeleton-box zentrum-tax-box'> </div>
                                            :
                                            taxAmount !== 0
                                            &&
                                            <div className='zentrum-rom-original-price'>Includes {formatWithCommas(taxAmount)} in taxes/fees </div>
                                    }
                                    {/* <div className='zentrum-rom-original-price zentrum-local-tax'>
                                                <div>10% local tax</div>
                                                <span>$246.18</span>
                                            </div>
                                            <div className='zentrum-rom-original-price zentrum-local-tax'>
                                                <div>Resort fee</div>
                                                <span >$194</span>
                                            </div> */}
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <Footer className="design-component-instance-node-3" correlationId={correlationId} />
                <BookingPayment
                    visible={isBookingPayment}
                    total={Total}
                    onClose={() => setIsBookingPayment(false)}
                    bookingId={bookingId}
                    guestEmail={contactInfo?.email}
                    setIsBookingPayment={setIsBookingPayment}
                    setIsPaymentSuccess={setIsPaymentSuccess}
                    setFinalBookLoader={setFinalBookLoader}
                    success={success}
                    failed={failed}
                />
            </div>
        </>
    )
}

export default ZentrumBooking;