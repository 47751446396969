import axios from 'axios';
import BaseModel from './BaseModel';
import { writeData, removeData } from '../../Helpers';

export default class User extends BaseModel {
    static modelName = 'user';
    modelName = 'user';

    setAttributes(attributes) {
        if (attributes.hasOwnProperty('birthday')) {
            attributes.birthday =
                attributes.birthday === null
                    ? null
                    : new Date(attributes.birthday);
        }
        if (attributes.hasOwnProperty('wedding_date')) {
            attributes.wedding_date =
                attributes.wedding_date === null
                    ? null
                    : new Date(attributes.wedding_date);
        }
        if (attributes.hasOwnProperty('anniversary_date')) {
            attributes.anniversary_date =
                attributes.anniversary_date === null
                    ? null
                    : new Date(attributes.anniversary_date);
        }
        super.setAttributes(attributes);
    }

    static async authenticated(cancelToken = null) {
        return axios
            .get(`/${this.modelName}`)
            .then(({ data }) => new this(data.data));
    }

    async update(attributes, cancelToken = null) {
        return axios
            .post(`/${this.modelName}`, attributes)
            .then(({ data }) => this.setAttributes(data.data));
    }

    async delete(cancelToken = null) {
        return axios.delete(`/${this.modelName}`);
    }

    static async login(data) {
        return axios
            .post('/login', { ...data, device_name: 'Test Phone' })
            .then(({ data }) => {
                writeData('data', data);
                axios.defaults.headers.common.Authorization = `Bearer ${data.token}`;
                return data;
            });
    }

    static async logout() {
        return axios.get('/logout').then(removeData);
    }

    static async register(data) {
        return axios
            .post('/register', {
                ...data,
                name: 'Test User',
                device_name: 'Test Phone',
            })
            .then(({ data }) => {
                writeData('data', data);
                axios.defaults.headers.common.Authorization = `Bearer ${data.token}`;
                return data;
            });
    }

    static async forgotPassword(data) {
        return axios.post('/forgot-password', data);
    }

    static async resetPassword(data) {
        data = { ...data, device_name: 'IPhone 14' };
        return axios.post('/reset-password', data).then(({ data }) => {
            writeData('data', data);
            axios.defaults.headers.common.Authorization = `Bearer ${data.token}`;
            return data;
        });
    }

    async like(id) {
        return axios.post(`/user/like/${id}`);
    }

    async dislike(id) {
        return axios.delete(`/user/like/${id}`);
    }

    async premiumNotification() {
        return axios.post('/user/premium-notification');
    }
}
