import React from 'react';
import { Modal, Spin } from 'antd';
import RedCheck from '../../../assets/icons/RedCheck';
import ButtonSmall from '../../../Components/ButtonSmall';
import useNotification from '../../../providers/NotificationProvider';
import "./style.css";

const Authenticate = ({ visible, onClose, setIsPopupVisible, setIsJoinNow }) => {

    const [notification, createNotification] = useNotification();

    const UpgradePerkList = [
        'Unique Travel Deals',
        'Curated Private Offers and Rates',
        'Exclusive Perks',
        'Access to Special Offers',
    ]

    const handleJoinbtn = () => {
        createNotification({
            title: 'Terms',
            content:
                'By joining I accept the Terms of Use. I have read and understood the Privacy Policy.',
            primary: {
                title: 'Accept & Continue',
                onPress: () => {
                    onClose();
                    setIsPopupVisible(true);
                    setIsJoinNow(true);
                }
            },
            secondary: {
                title: 'Decline',
            },
        })
    }

    const handleLogin = () => {
        onClose();
        setIsJoinNow(false);
        setIsPopupVisible(true);
    }


    return (
        <div>
            <Modal
                className='authenticate-maincontainer'
                open={visible}
                maskClosable={false}
                onCancel={onClose}
                footer={[]}
            >
                <div className='authenticate'>

                    <div className='authenticate-greet-small'>In order to get access to this functionality you will need to create an account or login with your credentials.</div>
                    <div className='authenticate-greet'>Having an account on LOVU will give you access to the following perks:</div>
                    <div className='listcontainer'>
                        {
                            UpgradePerkList?.map((item, index) => {
                                return (
                                    <div key={index} className='listitem'>
                                        <RedCheck className='red-check' />
                                        <div className='listitem-title'>{item}</div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className='authenticate-btns'>
                        <ButtonSmall className="authenticate-join" buttonstyle="default" text="Join Now" handleClick={handleJoinbtn} />
                        <ButtonSmall className="authenticate-join" buttonstyle="default" text="Login" handleClick={handleLogin} />
                    </div>

                </div>

            </Modal>
        </div>
    );
};

export default Authenticate;