import { useState } from "react";
import axios from "axios";
import { PaymentElement } from "@stripe/react-stripe-js";
import { useStripe, useElements } from "@stripe/react-stripe-js";


export default function CheckoutForm({ subscriptionid, setSuccess, setStripePromise }) {
    const stripe = useStripe();
    const elements = useElements();

    const [message, setMessage] = useState(null);
    const [isProcessing, setIsProcessing] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }
        setIsProcessing(true);
        const response = await stripe.confirmPayment({
            elements,
            confirmParams: {
                // Make sure to change this to your payment completion page
            },
            redirect: 'if_required'
        });

        if (response.error && response.error.type === "card_error" || response.error && response.error.type === "validation_error") {
            setMessage(response.error.message);
        } else if (response.paymentIntent.id) {
            await axios.post(`/payments/verify/stripe`, {
                subscription_id: subscriptionid, payment_id: response?.paymentIntent.id
            }).then(result => {
                var currentdate = new Date().getTime();
                var targetDate = new Date(result?.data?.subscription_expires_at)
                if (result?.data?.subscription_expires_at) {
                    if (currentdate < targetDate) {
                        setStripePromise('');
                        setSuccess(true);
                    } else {
                        setMessage('Payment verification failed please try again after sometimes!..')
                    }
                }
            });
        }
        setIsProcessing(false);
    };

    return (
        <form id="payment-form" onSubmit={handleSubmit}>
            <PaymentElement id="payment-element" />
            <button disabled={isProcessing || !stripe || !elements} id="submit">
                <span id="button-text">
                    {isProcessing ? "Processing ... " : "Pay now"}
                </span>
            </button>
            {message && <div id="payment-message">{message}</div>}
        </form>
    );
}
