import React from "react";

const FaceWink = ({ className }) => {
  return (
    <svg
      className={`face-wink-2 ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_3779_625)">
        <path
          className="path"
          d="M6.66675 11.6667C6.66675 11.6667 7.91675 13.3333 10.0001 13.3333C12.0834 13.3333 13.3334 11.6667 13.3334 11.6667M12.5001 7.5H12.5084M6.66675 7.5H8.33342M18.3334 10C18.3334 14.6024 14.6025 18.3333 10.0001 18.3333C5.39771 18.3333 1.66675 14.6024 1.66675 10C1.66675 5.39763 5.39771 1.66667 10.0001 1.66667C14.6025 1.66667 18.3334 5.39763 18.3334 10ZM12.9167 7.5C12.9167 7.73012 12.7302 7.91667 12.5001 7.91667C12.27 7.91667 12.0834 7.73012 12.0834 7.5C12.0834 7.26988 12.27 7.08333 12.5001 7.08333C12.7302 7.08333 12.9167 7.26988 12.9167 7.5Z"
          stroke="#667085"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.66667"
        />
      </g>
      <defs className="defs">
        <clipPath className="clip-path" id="clip0_3779_625">
          <rect className="rect" fill="white" height="20" width="20" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default FaceWink;