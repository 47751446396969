import React, { useEffect, useState } from "react";
import { message } from "antd";
import Post from "../../api/models/Post"
import Card from "../../Components/Card";
import Footer from "../../Components/Footer";
import NavBar from "../../Components/NavBar";
import Banner from "../../Components/Banner";
import MetaTags from "../../Components/MetaTags"
import Category from "../../api/models/Category"
import { TAGS, removeData } from "../../Helpers";
import ButtonTag from "../../Components/ButtonTag";
import useStore from "../../providers/StoreProvider";
import ButtonSmall from "../../Components/ButtonSmall";
import Metatag from "../../api/models/Metatag.js";
import bannerImage from "../../assets/images/ZentiumSearchBanner.jpeg"
import "./style.css";

let page;
let lastPage;


const FavoriteList = ({ setProgress }) => {

    const [seo, setSeo] = useState();
    const { store, setStore } = useStore();
    const [posts, setPosts] = useState([]);
    const [categorie, SetCategorie] = useState();
    const [loading, setLoading] = useState(false);
    const [randomSeed, setRandomSeed] = useState(parseInt(Math.random() * 10000));
    const rawdestination = localStorage.getItem('destination');
    const destination = JSON.parse(rawdestination);
    const [params, setParams] = useState(destination !== null ? destination : {});


    useEffect(() => {
        setProgress(10)
        setProgress(20)
        setProgress(60)
        setProgress(100)
    }, [setProgress])

    useEffect(() => {
        Metatag.metaslug('favorites').then(result =>
            setSeo(result)
        );
    }, []);

    useEffect(() => {
        if (destination) {
            setParams(destination);
            removeData('destination')
        }
    }, [destination])


    useEffect(() => {
        Category.all().then(({ data }) => {
            const filteredCategories = data.filter(
                category => category.is_published && category.navigation,
            );
            SetCategorie(filteredCategories);
            setStore({ ...store, categories: filteredCategories });
        }).catch((e) => message.error(e.message));
    }, []);

    useEffect(() => {
        loadPage(true, false);
    }, [store.posts, store.user.posts]);


    function loadPage(first = false, refresh = false) {
        if (refresh) {
            Category.all()
                .then(({ data }) => {
                    const filteredCategories = data.filter(
                        category => category.is_published && category.navigation,
                    );

                    setStore({ ...store, categories: filteredCategories });

                })
                .catch((e) => message.error(e.message));

            setRandomSeed(parseInt(Math.random() * 1000000));
        }
        if (first) {
            setPosts([]);
            page = 1;
            lastPage = 1;
        }
        if (page > lastPage) {
            return;
        }
        setLoading(true);
        Post.setFilter('tags', []);
        Post.setFilter('categories', null);
        if (store.posts === 'all') {
            Post.setFilter('include', store.user.posts);
        } else {
            if (Object.keys(params).length === 0) {
                Post.setFilter('types', [
                    'property',
                    'basic',
                    'advisor',
                    'brand',
                    'destination',
                    'collection',
                    'worldiaitenararies',
                ])
                    .when(store.posts === 'me', model => {

                        model.setFilter(
                            'tags',
                            store?.token ? store?.user?.tags : [''],
                        );
                        model.setFilter('include', store.user.posts);
                    }
                    )
                    .when(store.posts === 'favorites', model =>
                        model.setFilter('include', store.user.posts),
                    )
                    .when(typeof store.posts === 'number', model => {

                        model.setFilter('categories', store.posts);
                        model.setFilter('include', store.user.posts);
                    }
                    )
                    .when(
                        ['advisor', 'offer', 'worldiaitenararies'].includes(
                            store.posts,
                        ),
                        model => {
                            model.setFilter('types', store.posts);

                            if (store.posts === 'offer' && !store?.token) {
                                model.setFilter('tags', ['']);
                            }

                            model.setFilter('include', store.user.posts);
                        }
                    );
            } else {
                Post.when(params, model =>
                    model
                        .setFilter('types', params.types)
                        .setFilter('parent', params.parent),
                )
                    .when(store.posts === 'favorites', model =>
                        model.setFilter('include', store.user.posts),
                    )
                    .when(typeof store.posts === 'number', model => {

                        model.setFilter('categories', store.posts);
                        model.setFilter('include', store.user.posts);
                    }
                    )
                    .when(
                        ['advisor', 'offer'].includes(store.posts),
                        model => {
                            model.setFilter('types', store.posts);

                            if (store.posts === 'offer' && !store?.token) {
                                model.setFilter('tags', ['']);
                            }

                            model.setFilter('include', store.user.posts);
                        }
                    );
            }
        }

        Post.setFilter('seed', randomSeed)
            .all(page)
            .then(data => {
                if (store?.user?.posts?.length > 0) {
                    page === 1
                        ? setPosts(data.data)
                        : setPosts(posts.concat(data.data));
                    lastPage = data.meta.last_page;
                    page = data.links.next == null ? page + 1 : data?.links?.next.split('?')[1].split('=')[1];
                } else {
                    setPosts([])
                    page = lastPage + 1;
                }
            })
            .catch(() => { })
            .finally(() => {
                setLoading(false);
            });
    }

    let text =
        "😢 We're busy curating awesome hotels and marketplace, check back soon!";

    if (!store?.token) {
        text = `😢 ${TAGS?.find(e => e.value === store?.posts)?.name
            } only available for registered users.`;
    }

    return (
        <>
            <MetaTags
                title={seo?.title != null ? seo?.title : "LOVU: My Favorites"}
                description={seo?.description != null ? seo?.description : "Discover handpicked romantic destinations with LOVU. Connect with expert Romance Travel Advisors to plan the perfect honeymoon, anniversary, or getaway."}
                imageScr={seo?.meta_image[0]?.original_url != undefined ? seo?.meta_image[0]?.original_url : bannerImage}
            />
            <div className={`favorite`}>
                <NavBar navigationDark={true} />
                {/* <Banner
                    isSearch={true}
                    imageSrc={bannerImage}
                /> */}
                <div className="pageContent">

                    <div className="title-wrap">
                        <h2 className="favorite-title">My Favorites</h2>
                    </div>
                    <div className="navigation">
                        <ButtonTag
                            data={TAGS.filter(
                                e =>
                                    e.value !== 'favorites' || store?.user?.posts?.length,).concat(
                                        categorie?.map(e => ({
                                            name: e?.name,
                                            value: e?.id,
                                        })),
                                    )}
                            selected={store.posts}
                            handleClick={e => setStore({ ...store, posts: e })}
                        />
                    </div>
                    <div className="card-list-container">
                        <div className="card-list-wrap">
                            {posts.map((v, i) => <Card
                                key={i}
                                imageSrc={v?.featured_image?.original_url}
                                title={v?.title}
                                type={v?.type}
                                city={v?.city}
                                country={v?.country}
                                hasRatingPrice={v?.rating ? true : false}
                                starRating={v?.rating}
                                price={v?.price}
                                backgroundColor={v?.image_color ? v?.image_color : null}
                                cardStyle={v?.template_type}
                                id={v?.id}
                                length={v?.length}
                                url={v?.website}
                            />)}
                        </div>
                        {
                            (posts?.length === 0 && !loading) && <div style={{ color: 'black' }}>{text}</div>
                        }
                        {loading ? <div className="card-list-wrap" style={{ marginTop: -38 }}>
                            <div className="card skeleton-box" />
                            <div className="card skeleton-box" />
                            <div className="card skeleton-box" />
                            <div className="card skeleton-box" />
                        </div> : page > lastPage ? null : <ButtonSmall handleClick={() => loadPage()} className="button-instance-black-outline " buttonstyle="button-outline" text="LOAD MORE" />}
                    </div>
                </div>
                <Footer className="design-component-instance-node-3" />
            </div>
        </>
    );
};

export default FavoriteList;