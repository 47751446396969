import React from "react";

const Reservation = ({ className }) => {
    return (
        <svg className={className} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.75 0H15.25C15.6562 0 16 0.34375 16 0.75C16 1.1875 15.6562 1.5 15.25 1.5H15V14.5H15.25C15.6562 14.5 16 14.8438 16 15.25C16 15.6875 15.6562 16 15.25 16H0.75C0.3125 16 0 15.6875 0 15.25C0 14.8438 0.3125 14.5 0.75 14.5H1V1.5H0.75C0.3125 1.5 0 1.1875 0 0.75C0 0.34375 0.3125 0 0.75 0ZM13.5 1.5H2.5V14.5H6.5V12H5.5C5.21875 12 4.96875 11.7812 5.03125 11.5312C5.25 10.0938 6.5 9 8 9C9.46875 9 10.7188 10.0938 10.9375 11.5312C11 11.7812 10.75 12 10.5 12H9.5V14.5H13.5V1.5ZM4.5 3H5.5C5.75 3 6 3.25 6 3.5V4.5C6 4.78125 5.75 5 5.5 5H4.5C4.21875 5 4 4.78125 4 4.5V3.5C4 3.25 4.21875 3 4.5 3ZM7 3.5C7 3.25 7.21875 3 7.5 3H8.5C8.75 3 9 3.25 9 3.5V4.5C9 4.78125 8.75 5 8.5 5H7.5C7.21875 5 7 4.78125 7 4.5V3.5ZM10.5 3H11.5C11.75 3 12 3.25 12 3.5V4.5C12 4.78125 11.75 5 11.5 5H10.5C10.2188 5 10 4.78125 10 4.5V3.5C10 3.25 10.2188 3 10.5 3ZM4 6.5C4 6.25 4.21875 6 4.5 6H5.5C5.75 6 6 6.25 6 6.5V7.5C6 7.78125 5.75 8 5.5 8H4.5C4.21875 8 4 7.78125 4 7.5V6.5ZM7.5 6H8.5C8.75 6 9 6.25 9 6.5V7.5C9 7.78125 8.75 8 8.5 8H7.5C7.21875 8 7 7.78125 7 7.5V6.5C7 6.25 7.21875 6 7.5 6ZM10 6.5C10 6.25 10.2188 6 10.5 6H11.5C11.75 6 12 6.25 12 6.5V7.5C12 7.78125 11.75 8 11.5 8H10.5C10.2188 8 10 7.78125 10 7.5V6.5Z" fill="url(#paint0_linear_6550_14177)" />
            <defs>
                <linearGradient id="paint0_linear_6550_14177" x1="-4.81987" y1="5.30029" x2="8.17233" y2="21.0214" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#393E45" />
                    <stop offset="1" stopColor="#0A0D10" />
                </linearGradient>
            </defs>
        </svg>


    );
};

export default Reservation;