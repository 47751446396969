import axios from 'axios';
import BaseModel from "./BaseModel";

export default class Post extends BaseModel {
    static modelName = 'posts';
    modelName = 'posts';


    static async search(searchString, type = null, page = null, cancelToken = null) {
        return axios.get(`${this.modelName}/websearch`, {
            params: {
                query: searchString,
                type: type,
                page: page,
                // per_page: 2
            },
        })
            .then(({ data }) => data);
    }
    static async websearch(searchString, type = null) {
        return axios.get(`${this.modelName}/search`, {
            params: {
                query: searchString,
                type: type
            },
        })
            .then(({ data }) => data);
    }
    static async hotelsearch(searchString, type = null, page = null) {
        return axios.get(`${this.modelName}/hotelsearch`, {
            params: {
                query: searchString,
            },
        })
            .then((data) => data);
    }

}
