import React from "react";

const MenuBar = ({ className, fillColor }) => {
    return (
        <svg className={className ? className : ''} width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path className={fillColor ? fillColor : ''} d="M0 1.4375C0 0.929688 0.390625 0.5 0.9375 0.5H16.5625C17.0703 0.5 17.5 0.929688 17.5 1.4375C17.5 1.98438 17.0703 2.375 16.5625 2.375H0.9375C0.390625 2.375 0 1.98438 0 1.4375ZM0 7.6875C0 7.17969 0.390625 6.75 0.9375 6.75H16.5625C17.0703 6.75 17.5 7.17969 17.5 7.6875C17.5 8.23438 17.0703 8.625 16.5625 8.625H0.9375C0.390625 8.625 0 8.23438 0 7.6875ZM17.5 13.9375C17.5 14.4844 17.0703 14.875 16.5625 14.875H0.9375C0.390625 14.875 0 14.4844 0 13.9375C0 13.4297 0.390625 13 0.9375 13H16.5625C17.0703 13 17.5 13.4297 17.5 13.9375Z" fill="white" />
        </svg>
    );
};

export default MenuBar