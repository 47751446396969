import axios from 'axios';


export const shuffle = array => {
    return array
        .map(a => [Math.random(), a])
        .sort((a, b) => a[0] - b[0])
        .map(a => a[1]);
};


export async function writeData(key = 'data', data) {
    localStorage.setItem(key, JSON.stringify(data));
    return data;
}

export async function readData(key = 'data') {
    let data = localStorage.getItem(key);
    return await JSON.parse(data);
}

export async function removeData(key = 'data') {
    localStorage.removeItem(key);
}

export function dateToMonthString(date) {
    const monthNames = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ];
    return `${monthNames[date.getMonth()]} ${date.getFullYear()}`;
}

export const TAGS = [
    {
        name: 'Show All',
        value: 'all',
    },
    {
        name: 'Curated for me',
        value: 'me',
    },
    {
        name: 'My Favorites',
        value: 'favorites',
    },
    {
        name: 'Itineraries',
        value: 'worldiaitenararies',
    },
    // {
    //     name: 'Travel Advisors',
    //     value: 'advisor',
    // },
    {
        name: 'Marketplace',
        value: 'offer',
    },
];

export const DESTINATIONTAGS = [
    {
        name: 'Perfect For Wedding',
        value: 'all',
    },
    {
        name: 'Honeymoon Destinations',
        value: 'me',
    },
    {
        name: 'Adventurous Vow Renewals',
        value: 'advisor',
    },
    {
        name: 'Bring Back Spark',
        value: 'offer',
    },
];

export const DealsFilterTags = [
    {
        name: 'Show All',
        value: 'all',
    },
    {
        name: 'New Offers',
        value: 'new',
    },
];
export const DealspinArchieveFilterTags = [
    {
        name: 'Pin',
        value: 'pin',
    },
    {
        name: 'Archive',
        value: 'archive',
    },
];

export const POST_TYPES = {
    basic: null,
    property: 'Spotlight',
    advisor: 'Travel Advisor',
    brand: 'Brand',
    collection: 'Inspire Me',
    destination: 'Destination',
    worldiaitenararies: 'Itinerary',
    offer: 'Marketplace'
};

export const getUserDisplayName = user => {
    if (user?.firstname || user?.lastname) {
        return [user?.firstname, user?.lastname].join(' ');
    }
    return user?.email;
};


// export const openWebLink = async url => {
//     var weburl = url;

//     if (url) {
//         if (!url.startsWith('https://') && !url.startsWith('http://')) {
//             weburl = 'https://' + url;
//         }
//     }
//     await InAppBrowser.open(weburl, {
//         // iOS Properties
//         dismissButtonStyle: 'close',
//         preferredBarTintColor: '#ffffff',
//         preferredControlTintColor: '#000000',
//         readerMode: false,
//         animated: true,
//         modalPresentationStyle: 'fullScreen',
//         modalTransitionStyle: 'coverVertical',
//         modalEnabled: true,
//         enableBarCollapsing: false,
//         // Android Properties
//         showTitle: true,
//         toolbarColor: '#ffffff',
//         secondaryToolbarColor: 'black',
//         navigationBarColor: 'black',
//         navigationBarDividerColor: 'white',
//         enableUrlBarHiding: true,
//         enableDefaultShare: true,
//         forceCloseOnRedirection: false,
//         // Specify full animation resource identifier(package:anim/name)
//         // or only resource name(in case of animation bundled with app).
//         animations: {
//             startEnter: 'slide_in_right',
//             startExit: 'slide_out_left',
//             endEnter: 'slide_in_left',
//             endExit: 'slide_out_right',
//         },
//         headers: {
//             'my-custom-header': 'my custom header value',
//         },
//     });
// };

export const capitalize = str => {
    return str.charAt(0).toUpperCase() + str.slice(1);
};
