import React, { useEffect, useState } from "react";
import Banner from "../../../Components/Banner";
import Footer from "../../../Components/Footer";
import NavBar from "../../../Components/NavBar";
import MetaTags from "../../../Components/MetaTags";
import bannerImage from "../../../assets/images/ZentiumSearchBanner.jpeg"

const LovuTravelExpert = ({ setProgress }) => {

    useEffect(() => {
        setProgress(10)
        setProgress(20)
        setProgress(60)
        setProgress(100)
    }, [setProgress])

    return (
        <>
            <MetaTags
                title="LOVU: Call LOVU Travel Experts"

            />
            <div className="homepage privacy">
                <Banner
                    isSearch={true}
                    imageSrc={bannerImage}
                />
                <div className="pageContent">
                    <div className="primarySection">
                        <h2 className="privacy-main-subtitle">Call LOVU Travel Experts<a href="tel:1-786-471-6177" >  1-786-471-6177 </a></h2>
                        <div className="privacy-sub-container">
                            <div className="privacy-content">
                                Calling our expert travel advisor is like unlocking a hidden treasure trove of possibilities. With the help of a seasoned travel advisor from Lovu, you can craft your itinerary that is uniquely yours, blending iconic landmarks and gems tucked away in the quiet corners of your destination. Their deep knowledge will help enrich your experience, ensuring each moment is memorable and truly magical.
                            </div>
                        </div>
                        <div className="privacy-sub-container">
                            <div className="privacy-content">
                                Our expert travel advisors offer a level of service that elevates your journey from ordinary to extraordinary, making you feel like a privileged traveler.
                            </div>
                        </div>
                        <div className="privacy-sub-container">
                            <div className="privacy-content">
                                If you are seeking depth and authenticity, conversing with our travel advisor is not just a step, but the first thrilling leap towards creating an unforgettable adventure.
                            </div>
                        </div>
                        <div className="privacy-sub-container">
                            <div className="privacy-content">
                                Call our expert today!<a href="tel:1-786-471-6177" className="lovu-contact-phone"> 1-786-471-6177</a>
                            </div>
                        </div>
                    </div>
                </div>

                <Footer className="design-component-instance-node-3" />
                <NavBar navigationDark={true} />

            </div>
        </>
    );
};

export default LovuTravelExpert;