import React from 'react'
import parse from 'html-react-parser';
import UnorderList from '../UnorderList';
import "./style.css";


const ArticleContent = (props) => {
    const { data } = props;
    const htmlcontent = Array.isArray(parse(data)) ? parse(data) : [parse(data)];
    return (

        <div className='content-card'>
            {htmlcontent?.map((item, index) => {
                switch (item?.type) {
                    case 'h1':
                        return (
                            <h1 className='article-content-title-h1' key={index}>{item?.props?.children}</h1>

                        );
                    case 'h2':
                        return (
                            <h2 className='article-content-title-h2' key={index}>{item?.props?.children}</h2>

                        );
                    case 'h3':
                        return (
                            <h3 className='article-content-title-h3' key={index}>{item?.props?.children}</h3>

                        );
                    case 'p':
                        return (
                            <p className='para' key={index}>{item?.props?.children}</p>
                        );
                    case 'ul':
                        return <UnorderList key={index} data={item?.props?.children} />
                    default:
                        return <p className='para' key={index}>{item}</p>
                }
            })}

        </div>

    )
}

export default ArticleContent