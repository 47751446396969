import React, { useEffect, useRef } from "react";
import FooterLink from "../FooterLink";
import { Link } from "react-router-dom";
import LogoDark from "../../assets/icons/LogoDark";
import AppStore from "../../assets/icons/AppStore";
import X from "../../assets/icons/SocialMedia/X.svg";
import GoogleStore from "../../assets/icons/GoogleStore";
import Tiktok from "../../assets/icons/SocialMedia/tiktok.svg";
import Pinterest from "../../assets/icons/SocialMedia/pin.svg";
import Youtube from "../../assets/icons/SocialMedia/youtube.svg";
import LinkedIn from "../../assets/icons/SocialMedia/linkedin.svg";
import Facebook from "../../assets/icons/SocialMedia/Facebook.svg";
import Instagram from "../../assets/icons/SocialMedia/instagram.svg";
import "./style.css";

const Footer = ({ className, correlationId, setIsFooterVisible, isVisible = true }) => {

  const footerRef = useRef(null);

  const checkVisibility = () => {
    if (footerRef.current) {
      const rect = footerRef.current.getBoundingClientRect();
      const windowHeight = window.innerHeight || document.documentElement.clientHeight;

      // Check if the footer is partially visible
      const isPartiallyInViewport =
        rect.top < windowHeight &&
        rect.bottom > 0;

      setIsFooterVisible(isPartiallyInViewport);
    }
  };

  useEffect(() => {
    if (setIsFooterVisible) {
      checkVisibility(); // Initial check
      window.addEventListener('scroll', checkVisibility);
      window.addEventListener('resize', checkVisibility);

      return () => {
        window.removeEventListener('scroll', checkVisibility);
        window.removeEventListener('resize', checkVisibility);
      };
    }
  }, []);


  return (
    <div className={`footer ${className}`} ref={footerRef} style={{ paddingBottom: isVisible ? '50px' : '90px' }}>
      <div className="container">
        <div className="content-2">
          <div className="links">
            <div className="footer-links-column">
              <div className="heading">LOVU</div>
              <div className="footer-links">
                <Link to="/destinations">
                  <FooterLink
                    badge={false}
                    buttonsButtonText="Destinations"
                    buttonsButtonTextPaddingClassName="footer-link-2"
                    className="footer-link-instance"
                    color="default"
                    stateProp="default"
                    theme="light"
                  />
                </Link>
                <Link to="/hotel">
                  <FooterLink
                    badge={false}
                    buttonsButtonText="Hotels"
                    buttonsButtonTextPaddingClassName="footer-link-2"
                    className="footer-link-instance"
                    color="default"
                    stateProp="default"
                    theme="light"
                  />
                </Link>
                <Link to="/collection">

                  <FooterLink
                    badge={false}
                    buttonsButtonText="Collections"
                    buttonsButtonTextPaddingClassName="footer-link-2"
                    className="footer-link-instance"
                    color="default"
                    stateProp="default"
                    theme="light"
                  />
                </Link>
                <Link to="/ta">

                  <FooterLink
                    badge={false}
                    buttonsButtonText="Travel Advisors"
                    buttonsButtonTextPaddingClassName="footer-link-2"
                    className="footer-link-instance"
                    color="default"
                    stateProp="default"
                    theme="light"
                  />
                </Link>
              </div>
            </div>
            <div className="footer-links-column">
              <div className="heading">Occassions</div>
              <div className="footer-links">
                <Link to="/occassions/destinationwedding">
                  <FooterLink
                    badge={false}
                    buttonsButtonText="Wedding"
                    buttonsButtonTextPaddingClassName="footer-link-2"
                    className="footer-link-instance"
                    color="default"
                    stateProp="default"
                    theme="light"
                  />
                </Link>
                <Link to="/occassions/honeymoon">
                  <FooterLink
                    badge={false}
                    buttonsButtonText="Honeymoon"
                    buttonsButtonTextPaddingClassName="footer-link-2"
                    className="footer-link-instance"
                    color="default"
                    stateProp="default"
                    theme="light"
                  />
                </Link>
                <Link to="/occassions/anniversary">
                  <FooterLink
                    badge={false}
                    buttonsButtonText="Anniversary"
                    buttonsButtonTextPaddingClassName="footer-link-2"
                    className="footer-link-instance"
                    color="default"
                    stateProp="default"
                    theme="light"
                  />
                </Link>
                <Link to="/occassions/vowrenewal">
                  <FooterLink
                    badge
                    buttonsButtonText1="Vow Renewal"
                    buttonsButtonTextPaddingClassNameOverride="footer-link-2"
                    className="footer-link-instance"
                    color="default"
                    stateProp="default"
                    theme="light"
                  />
                </Link>
                <Link to="/occassions/proposal">
                  <FooterLink
                    badge={false}
                    buttonsButtonText="Proposal"
                    buttonsButtonTextPaddingClassName="footer-link-2"
                    className="footer-link-instance"
                    color="default"
                    stateProp="default"
                    theme="light"
                  />
                </Link>
                <Link to="/occassions/babymoon">
                  <FooterLink
                    badge={false}
                    buttonsButtonText="Babymoon"
                    buttonsButtonTextPaddingClassName="footer-link-2"
                    className="footer-link-instance"
                    color="default"
                    stateProp="default"
                    theme="light"
                  />
                </Link>
              </div>
            </div>
            <div className="footer-links-column">
              <div className="heading">Platform</div>
              <div className="footer-links">
                <a href="https://lovutravel.com/articles">
                  <FooterLink
                    badge={false}
                    buttonsButtonText="Blog"
                    buttonsButtonTextPaddingClassName="footer-link-2"
                    className="footer-link-instance"
                    color="default"
                    stateProp="default"
                    theme="light"
                  />
                </a>
                <a href="https://lovutravel.com/about" >
                  <FooterLink
                    badge={false}
                    buttonsButtonText="About Us"
                    buttonsButtonTextPaddingClassName="footer-link-2"
                    className="footer-link-instance"
                    color="default"
                    stateProp="default"
                    theme="light"
                  />
                </a>
                <a href="https://lovutravel.com//press" >
                  <FooterLink
                    badge={false}
                    buttonsButtonText="In The Press"
                    buttonsButtonTextPaddingClassName="footer-link-2"
                    className="footer-link-instance"
                    color="default"
                    stateProp="default"
                    theme="light"
                  />
                </a>
                <Link to="/faq" >
                  <FooterLink
                    badge={false}
                    buttonsButtonText="FAQ"
                    buttonsButtonTextPaddingClassName="footer-link-2"
                    className="footer-link-instance"
                    color="default"
                    stateProp="default"
                    theme="light"
                  />
                </Link>

              </div>
            </div>
            <div className="footer-links-column">
              <div className="heading">Partnerships</div>
              <div className="footer-links">
                <a href="https://lovutravel.com/for-hotels">
                  <FooterLink
                    badge={false}
                    buttonsButtonText="List Your Hotel"
                    buttonsButtonTextPaddingClassName="footer-link-2"
                    className="footer-link-instance"
                    color="default"
                    stateProp="default"
                    theme="light"
                  />
                </a>
                <a href="https://lovutravel.com/for-destinations">
                  <FooterLink
                    badge={false}
                    buttonsButtonText="For Destinations"
                    buttonsButtonTextPaddingClassName="footer-link-2"
                    className="footer-link-instance"
                    color="default"
                    stateProp="default"
                    theme="light"
                  />
                </a>
                <a href="https://lovutravel.com/for-travel-advisors">
                  <FooterLink
                    badge={false}
                    buttonsButtonText="For Travel Advisors"
                    buttonsButtonTextPaddingClassName="footer-link-2"
                    className="footer-link-instance"
                    color="default"
                    stateProp="default"
                    theme="light"
                  />
                </a>
              </div>
            </div>
            <div className="footer-links-column">
              <div className="heading">Legal</div>
              <div className="footer-links">
                <Link to="/terms-and-conditions">
                  <FooterLink
                    badge={false}
                    buttonsButtonText="Terms"
                    buttonsButtonTextPaddingClassName="footer-link-2"
                    className="footer-link-instance"
                    color="default"
                    stateProp="default"
                    theme="light"
                  />
                </Link>
                <Link to="/privacy-policy">
                  <FooterLink
                    badge={false}
                    buttonsButtonText="Privacy"
                    buttonsButtonTextPaddingClassName="footer-link-2"
                    className="footer-link-instance"
                    color="default"
                    stateProp="default"
                    theme="light"
                  />
                </Link>
                <Link to="/contacts">
                  <FooterLink
                    badge={false}
                    buttonsButtonText="Contact"
                    buttonsButtonTextPaddingClassName="footer-link-2"
                    className="footer-link-instance"
                    color="default"
                    stateProp="default"
                    theme="light"
                  />
                </Link>
              </div>
            </div>
          </div>
          <div className="mobile-links">
            <Link to="/terms-and-conditions">
              <FooterLink
                badge={false}
                buttonsButtonText="Terms"
                buttonsButtonTextPaddingClassName="footer-link-2"
                className="footer-link-instance"
                color="default"
                stateProp="default"
                theme="light"
              />
            </Link>
            <Link to="/privacy-policy">
              <FooterLink
                badge={false}
                buttonsButtonText="Privacy"
                buttonsButtonTextPaddingClassName="footer-link-2"
                className="footer-link-instance"
                color="default"
                stateProp="default"
                theme="light"
              />
            </Link>
            <FooterLink
              badge={false}
              buttonsButtonText="Cookies"
              buttonsButtonTextPaddingClassName="footer-link-2"
              className="footer-link-instance"
              color="default"
              stateProp="default"
              theme="light"
            />
            <Link to="/contacts">
              <FooterLink
                badge={false}
                buttonsButtonText="Contact"
                buttonsButtonTextPaddingClassName="footer-link-2"
                className="footer-link-instance"
                color="default"
                stateProp="default"
                theme="light"
              />
            </Link>
          </div>
          <div className="div-2">
            <div className="heading">Personalized Offer App</div>
            <div className="div-2">
              <a href="https://apps.apple.com/ee/app/lovu-trips/id1668732997" target="_blank"><AppStore className="mobile-app-store" /></a>
              <a href="https://play.google.com/store/apps/details?id=com.lovuapp" target="_blank"><GoogleStore className="mobile-app-store-badge" /></a>
            </div>
          </div>
        </div>

      </div>
      {
        correlationId &&
        <div className="display-correlationid">{correlationId ? correlationId : ''}</div>
      }
      <div className="social-media-container">
        <a href="https://www.facebook.com/LOVUTravelApp/" target="_blank"><img src={Facebook} alt='no-icon-found' className='social-icon' /></a>
        <a href="https://x.com/LOVUtravelapp" target="_blank"><img src={X} alt='no-icon-found' className='social-icon' /></a>
        <a href="https://www.instagram.com/lovu.app/" target="_blank"><img src={Instagram} alt='no-icon-found' className='social-icon' /></a>
        <a href="https://www.pinterest.com/lovuapp/" target="_blank"><img src={Pinterest} alt='no-icon-found' className='social-icon' /></a>
        <a href="https://www.youtube.com/channel/UCdgdQf0Jeb1iTLSwVMF99sQ" target="_blank"><img src={Youtube} alt='no-icon-found' className='social-icon' /></a>
        <a href="https://www.tiktok.com/@lovu.travel" target="_blank"><img src={Tiktok} alt='no-icon-found' className='social-icon' /></a>
        <a href="https://www.linkedin.com/company/lovu-travel/" target="_blank"><img src={LinkedIn} alt='no-icon-found' className='social-icon' /></a>
      </div>
      <div className="content-wrapper">
        <div className="content-3">
          <Link to="/"><LogoDark className="logo-2" /></Link>
          <div className="social-media-icon-container">
            <a href="https://www.facebook.com/LOVUTravelApp/" target="_blank"><img src={Facebook} alt='no-icon-found' className='social-icon' /></a>
            <a href="https://x.com/LOVUtravelapp" target="_blank"><img src={X} alt='no-icon-found' className='social-icon' /></a>
            <a href="https://www.instagram.com/lovu.app/" target="_blank"><img src={Instagram} alt='no-icon-found' className='social-icon' /></a>
            <a href="https://www.pinterest.com/lovuapp/" target="_blank"><img src={Pinterest} alt='no-icon-found' className='social-icon' /></a>
            <a href="https://www.youtube.com/channel/UCdgdQf0Jeb1iTLSwVMF99sQ" target="_blank"><img src={Youtube} alt='no-icon-found' className='social-icon' /></a>
            <a href="https://www.tiktok.com/@lovu.travel" target="_blank"><img src={Tiktok} alt='no-icon-found' className='social-icon' /></a>
            <a href="https://www.linkedin.com/company/lovu-travel/" target="_blank"><img src={LinkedIn} alt='no-icon-found' className='social-icon' /></a>
          </div>
          <div className="footer-text">© 2024 LOVU</div>
        </div>
      </div>
    </div>
  );
};

export default Footer;