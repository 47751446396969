import React from "react";

const Stars = ({ className }) => {
  return (
    <svg
      className={`stars-03 ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_3779_572)">
        <path
          className="path"
          d="M15.4166 6.66669V2.50002M4.58329 17.5V13.3334M13.3333 4.58335H17.5M2.49996 15.4167H6.66663M5.41663 1.66669L4.76291 2.97412C4.54167 3.4166 4.43105 3.63784 4.28327 3.82956C4.15213 3.99968 3.99962 4.15219 3.8295 4.28333C3.63778 4.43111 3.41654 4.54173 2.97406 4.76297L1.66663 5.41669L2.97406 6.0704C3.41654 6.29164 3.63778 6.40226 3.8295 6.55005C3.99962 6.68118 4.15213 6.83369 4.28327 7.00381C4.43105 7.19553 4.54167 7.41677 4.76291 7.85926L5.41663 9.16669L6.07034 7.85926C6.29158 7.41677 6.4022 7.19553 6.54999 7.00382C6.68112 6.83369 6.83363 6.68118 7.00375 6.55005C7.19547 6.40226 7.41671 6.29164 7.8592 6.0704L9.16663 5.41669L7.8592 4.76297C7.41671 4.54173 7.19547 4.43111 7.00375 4.28333C6.83363 4.15219 6.68112 3.99968 6.54999 3.82956C6.4022 3.63784 6.29158 3.4166 6.07034 2.97412L5.41663 1.66669ZM14.1666 10L13.374 11.5852C13.1528 12.0277 13.0422 12.249 12.8944 12.4407C12.7632 12.6108 12.6107 12.7633 12.4406 12.8944C12.2489 13.0422 12.0277 13.1528 11.5852 13.3741L9.99996 14.1667L11.5852 14.9593C12.0277 15.1805 12.2489 15.2912 12.4406 15.4389C12.6107 15.5701 12.7632 15.7226 12.8944 15.8927C13.0422 16.0844 13.1528 16.3057 13.374 16.7481L14.1666 18.3334L14.9592 16.7481C15.1805 16.3057 15.2911 16.0844 15.4389 15.8927C15.57 15.7226 15.7225 15.5701 15.8926 15.4389C16.0844 15.2912 16.3056 15.1805 16.7481 14.9593L18.3333 14.1667L16.7481 13.3741C16.3056 13.1528 16.0844 13.0422 15.8926 12.8944C15.7225 12.7633 15.57 12.6108 15.4389 12.4407C15.2911 12.249 15.1805 12.0277 14.9592 11.5852L14.1666 10Z"
          stroke="#667085"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.66667"
        />
      </g>
      <defs className="defs">
        <clipPath className="clip-path" id="clip0_3779_572">
          <rect className="rect" fill="white" height="20" width="20" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Stars