import React, { useEffect, useState } from 'react';
import Home from '../Pages/Home';
import Hotel from '../Pages/Hotel';
import Offer from '../Pages/Offer';
import { notification } from 'antd';
import useAuth from './AuthProvider';
import useStore from './StoreProvider';
import Article from '../Pages/Article';
import Profile from '../Pages/Profile';
import FAQ from '../Pages/Platform/FAQ';
import Terms from '../Pages/Legal/Terms';
import Footer from '../Components/Footer';
import Privacy from '../Pages/Legal/Privacy';
import Collection from '../Pages/Collection';
import Occassions from '../Pages/Occassions';
import { messaging } from '../firebaseConfig';
import Reservation from '../Pages/Reservation';
import LoadingBar from 'react-top-loading-bar';
import FavoriteList from '../Pages/FavoriteList';
import ZentrumRooms from '../Pages/ZentrumRooms';
import ScrollToTop from '../Layouts/ScrollToTop';
import ZentrumSearch from '../Pages/ZentrumSearch';
import CupidDesign from '../Components/CupidDesign';
import ZentrumBooking from '../Pages/ZentrumBooking';
import Special from '../Pages/LovuCollection/Special';
import { getToken, onMessage } from "firebase/messaging";
import HotelsList from '../Pages/LovuCollection/HotelsList';
import DestinationWishList from '../Pages/DestinationWishList';
import Babymoon from '../Pages/OccassionsCollections/Babymoon';
import Proposals from '../Pages/OccassionsCollections/Proposals';
import Honeymoon from '../Pages/OccassionsCollections/Honeymoon';
import VowRenewal from '../Pages/OccassionsCollections/VowRenewal';
import Anniversary from '../Pages/OccassionsCollections/Anniversary';
import CollectionsList from '../Pages/LovuCollection/CollectionsList';
import DestinationsList from '../Pages/LovuCollection/DestinationsList';
import TravelAdvisorList from '../Pages/LovuCollection/TravelAdvisorList';
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom"
import DestinationsWedding from '../Pages/OccassionsCollections/DestinationsWedding';
import LovuTravelExpert from '../Pages/Legal/LovuTravelExpert';
import Contact from '../Pages/Legal/Contact';
import ItinerarieList from '../Pages/LovuCollection/ItinerarieList';

const NavigationProvider = () => {

    const { store, setStore } = useStore();

    const [progress, setProgress] = useState(0);
    const { authenticate } = useAuth();

    let data = localStorage.getItem('data');


    async function pushNotificationSetup() {
        const permission = await Notification.requestPermission();

        if (permission === "granted") {
            const token = await getToken(messaging, {
                vapidKey: "BPaRaONLDU7BiOZegGoAFUyE8tcVVSJE2kkgCjzzMFfkxXra-k-gM1byZcls5EbTYUNHJxpUHIsi3sAupjmc--I",
            });
            if (token) {
                store.user.update({ fcm_token: token })
            }
        } else if (permission === "denied") {
        }
    }

    useEffect(() => {
        authenticate().finally(() => {
        });
    }, []);

    useEffect(() => {
        if (store.token) {
            pushNotificationSetup();
        }
    }, [store.token]);

    useEffect(() => {
        if (data) {
            const unsubscribe = onMessage(messaging, (payload) => {
                notification.open({
                    description: payload.notification.body,
                    message: payload.notification.title,
                    onClick: () => {
                        console.log('Notification Clicked!');
                    },
                });
            });

            return () => {
                unsubscribe();
            };
        }
    }, []);


    return (

        <Router>
            <LoadingBar
                color='rgb(226 16 95)'
                progress={progress}
                onLoaderFinished={() => setProgress(0)}
            />
            <Routes>
                <Route path="/" element={<Home setProgress={setProgress} />} ></Route>
                <Route path="/article/:id" element={<Article setProgress={setProgress} />} ></Route>
                <Route path="/:id" element={<Hotel type={'basic'} setProgress={setProgress} />}></Route>
                <Route path="/ta/:id" element={<Hotel type={'advisor'} setProgress={setProgress} />}></Route>
                <Route path="/collection" element={<CollectionsList setProgress={setProgress} />}></Route>
                <Route path="/collection/:id" element={<Collection type={'collection'} setProgress={setProgress} />}></Route>
                <Route path="/destinations" element={<DestinationsList setProgress={setProgress} />}></Route>
                <Route path="/destinations/:id" element={<DestinationWishList setProgress={setProgress} />}></Route>
                <Route path="/hotel" element={<HotelsList setProgress={setProgress} />}></Route>
                <Route path="/ta" element={<TravelAdvisorList setProgress={setProgress} />}></Route>
                <Route path="/itineraries" element={<ItinerarieList setProgress={setProgress} />}></Route>
                <Route path="/marketplace" element={<Special setProgress={setProgress} />}></Route>
                <Route path="/offer" element={data !== null ? <Offer setProgress={setProgress} /> : <Navigate to={'/'} />}></Route>
                <Route path="/offer/:id" element={data !== null ? <Offer setProgress={setProgress} /> : <Navigate to={'/'} />}></Route>
                <Route path="/profile" element={data !== null ? <Profile setProgress={setProgress} /> : <Navigate to={'/'} />}></Route>
                <Route path="/occassions" element={<Occassions setProgress={setProgress} />}></Route>
                <Route path="occassions/destinationwedding" element={<DestinationsWedding setProgress={setProgress} />}></Route>
                <Route path="occassions/honeymoon" element={<Honeymoon setProgress={setProgress} />}></Route>
                <Route path="occassions/anniversary" element={<Anniversary setProgress={setProgress} />}></Route>
                <Route path="occassions/babymoon" element={<Babymoon setProgress={setProgress} />}></Route>
                <Route path="occassions/vowrenewal" element={<VowRenewal setProgress={setProgress} />}></Route>
                <Route path="occassions/proposal" element={<Proposals setProgress={setProgress} />}></Route>
                <Route path="/privacy-policy" element={<Privacy setProgress={setProgress} />}></Route>
                <Route path="/terms-and-conditions" element={<Terms setProgress={setProgress} />}></Route>
                <Route path="/call-an-expert" element={<LovuTravelExpert setProgress={setProgress} />}></Route>
                <Route path="/contacts" element={<Contact setProgress={setProgress} />}></Route>
                <Route path="/favorite" element={<FavoriteList setProgress={setProgress} />}></Route>
                <Route path="/faq" element={<FAQ setProgress={setProgress} />}></Route>
                <Route path="/header" element={<CupidDesign />}></Route>
                <Route path="/footer" element={<Footer />}></Route>
                <Route path="/search/:LocationId" element={<ZentrumSearch setProgress={setProgress} />}></Route>
                <Route path="/hotelroom/:HotelId" element={<ZentrumRooms setProgress={setProgress} />}></Route>
                <Route path="/hotelbooking/:HotelId" element={<ZentrumBooking setProgress={setProgress} />}></Route>
                <Route path="/reservations" element={data !== null ? <Reservation setProgress={setProgress} /> : <Navigate to={'/'} />}></Route>
                <Route path="/reservations/:id" element={data !== null ? <Reservation setProgress={setProgress} /> : <Navigate to={'/'} />}></Route>

            </Routes>
            <ScrollToTop />
        </Router>
    );
};

export default NavigationProvider