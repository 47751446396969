import React, { useEffect, useState } from "react";
import Tag from '../../../api/models/Tag';
import Post from "../../../api/models/Post"
import Card from "../../../Components/Card";
import NavBar from "../../../Components/NavBar";
import Banner from "../../../Components/Banner";
import Footer from "../../../Components/Footer";
import MetaTags from "../../../Components/MetaTags"
import Metatag from "../../../api/models/Metatag.js";
import SearchBar from '../../../Components/SearchBar'
import ButtonSmall from "../../../Components/ButtonSmall";
import bannerImage from "../../../assets/images/occasion/occasions/anniversaries.jpg"

let page;
let lastPage;


const Anniversary = ({ setProgress }) => {

    const [seo, setSeo] = useState();
    const [tags, setTags] = useState([]);
    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isVisible, setIsVisible] = useState(true);

    useEffect(() => {
        Metatag.metaslug('anniversary').then(result =>
            setSeo(result)
        );
    }, []);

    useEffect(() => {
        Tag.all(1).then(({ data }) => {
            let val = data?.map((item) => item).filter((items) => items.name === 'anniversary')
            setTags(val[0]?.id)
        });
    }, [])

    useEffect(() => {
        if (tags?.length !== 0) {
            loadPage(true);
        }
    }, [tags]);

    function loadPage(first = false) {
        if (first) {
            setPosts([])
            page = 1;
            lastPage = 1;
        }
        if (page > lastPage) {
            return;
        }
        setLoading(true);
        Post.tagfilter(page, tags)
            .then(data => {
                page === 1
                    ? setPosts(data.data)
                    : setPosts(posts.concat(data.data));
                lastPage = data.meta.last_page;
                page = data.links.next == null ? page + 1 : data?.links?.next.split('?')[1].split('=')[1];
            })
            .catch(() => { })
            .finally(() => {
                setLoading(false);
            });
    }

    useEffect(() => {
        setProgress(10)
        setProgress(20)
        setProgress(60)
        setProgress(100)
    }, [setProgress])

    return (
        <>
            <MetaTags
                title={seo?.title != null ? seo?.title : "LOVU: Romantic Anniversary Getaways"}
                description={seo?.description != null ? seo?.description : "Celebrate Love and Togetherness"}
                imageScr={seo?.meta_image[0]?.original_url != undefined ? seo?.meta_image[0]?.original_url : bannerImage}
            />
            <div className="homepage occassions">
                <Banner
                    isProperties
                    imageSrc={bannerImage}
                    title="Romantic Anniversary Getaways"
                    heading="Celebrate Love and Togetherness"
                    widget={
                        <SearchBar
                            heightToHideFrom={520}
                            isVisible={isVisible}
                            setIsVisible={setIsVisible}
                        />
                    }
                />
                <div className="pageContent">
                    <div className="primarySection">
                        <h2 className="occassions-main-title">Romantic Anniversary Getaways: Celebrate Love and Togetherness</h2>
                        <h2 className="occassions-main-subtitle">The Ultimate Guide to Memorable Anniversary Destinations</h2>
                        <div className="occassions-main-content">
                            Celebrating an anniversary is a pivotal milestone for couples, offering an exquisite
                            opportunity to rekindle romance and create everlasting memories. Whether it's a first
                            anniversary or a golden jubilee, choosing the perfect getaway is paramount. Envision
                            walking hand-in-hand along the serene beaches of the Maldives, toasting to love in a
                            cozy vineyard in Tuscany, or exploring the enchanting streets of Paris under the
                            luminescent glow of the Eiffel Tower. These destinations not only promise unparalleled
                            romantic experiences but also cater to every couple's desire for adventure, relaxation,
                            and luxury.
                        </div>
                    </div>

                    <h2 className="explore-description">Explore destinations and hotels that are perfect for your dream anniversary:</h2>
                    <div className="card-list-container">
                        <div className="card-list-wrap">
                            {posts.map((v, i) => <Card
                                key={i}
                                imageSrc={v?.featured_image?.original_url}
                                title={v?.title}
                                type={v?.type}
                                city={v?.city}
                                country={v?.country}
                                hasRatingPrice={v?.rating ? true : false}
                                starRating={v?.rating}
                                price={v?.price}
                                backgroundColor={v?.image_color ? v?.image_color : null}
                                cardStyle={v?.template_type}
                                id={v?.id}
                            />)}
                        </div>
                        {
                            (posts?.length === 0 && !loading) && <div style={{ color: 'black' }}>No Data Found</div>
                        }
                        {loading ? <div className="card-list-wrap" style={{ marginTop: -38 }}>
                            <div className="card skeleton-box" />
                            <div className="card skeleton-box" />
                            <div className="card skeleton-box" />
                            <div className="card skeleton-box" />
                        </div> : page > lastPage ? null : <ButtonSmall handleClick={() => loadPage()} className="button-instance-black-outline " buttonstyle="button-outline" text="LOAD MORE" />}
                    </div>
                </div>
                <Footer className="design-component-instance-node-3" isVisible={isVisible} />
                <NavBar navigationDark={true} />

            </div>
        </>
    );
};

export default Anniversary;