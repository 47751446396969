import React from "react";
import Select from "react-dropdown-select";
import Globe from "../../assets/icons/Globe";
import "./styles.css";

const Dropdown = ({
  className = "input-field-3",
  text = "Email",
  field,
  options,
  placeholder,
  isVisible,
  onChange,
  value,
  icon = <Globe className="instance-node" />,
}) => {


  return (
    <div className={`input-field type-icon-leading ${className}`}>
      <div className="input-with-labels">
        <div className={`label design-component-instance-node`}>{<>{isVisible && text}</>}</div>
        {
          isVisible ?
            <div className='drop'>
              {icon}
            </div>
            :
            <div className='drop-sticky'>
              {icon}
            </div>
        }
        <Select
          options={options}
          onChange={onChange}
          values={value}
          placeholder={placeholder}
          className="select-drop-down"
          dropdownPosition="auto"
        />
      </div>
    </div>
  );
};

export default Dropdown;