import React, { useEffect, useState } from 'react';
import { Modal } from 'antd';
import ButtonSmall from '../../../Components/ButtonSmall';
import Intro1 from "../../../../src/assets/images/intro/1.png"
import Intro2 from "../../../../src/assets/images/intro/2.png"
import Intro3 from "../../../../src/assets/images/intro/3.png"
import Intro4 from "../../../../src/assets/images/intro/4.png"
import Intro5 from "../../../../src/assets/images/intro/5.png"
import Intro6 from "../../../../src/assets/images/intro/6.png"
import Intro7 from "../../../../src/assets/images/intro/7.png"
import Intro8 from "../../../../src/assets/images/intro/8.png"
import Intro9 from "../../../../src/assets/images/intro/9.png"
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import "./style.css";

const Intro = ({ visible, onClose, setOnBoardingShow }) => {

    const [pageIndex, setPageIndex] = useState(1);
    const handleLeft = () => {
        if (pageIndex === 0) {
            setPageIndex(0)
        } else {
            setPageIndex(pageIndex - 1)
        }
    }

    const handleRight = () => {
        if (pageIndex >= 2) {
            setPageIndex(2);
        } else {
            setPageIndex(pageIndex + 1)
        }
    }

    const handleOnboard = () => {
        onClose();
        setOnBoardingShow(true);
    }

    useEffect(() => {
        setPageIndex(0)
    }, [visible])

    return (
        <Modal
            className='intromodal-maincontainer'
            open={visible}
            maskClosable={false}
            onCancel={onClose}
            footer={[]}
        >
            <div className='intromodal'>
                <div className='left-container'>
                    <KeyboardArrowLeftIcon onClick={handleLeft} className='left-arrow' />
                </div>
                <div className='intro-maincontainer'>
                    {
                        pageIndex === 0 &&
                        <div className='into-subcontainer1'>
                            <img src={Intro1} alt='nointro found' className='first-into-image' />
                            <img src={Intro2} alt='nointro found' className='second-into-image' />
                            <img src={Intro3} alt='nointro found' className='third-into-image' />
                            <div className='intro-content'>
                                <div className='into-title'> Find Romantic Hotspots!</div>
                                <div className='into-description'>Browse thousands of best romantic destinations.</div>
                            </div>
                        </div>
                    }
                    {
                        pageIndex === 1 &&
                        <div className='into-subcontainer1'>
                            <img src={Intro4} alt='nointro found' className='first-into-image' />
                            <img src={Intro5} alt='nointro found' className='second-into-image' />
                            <img src={Intro6} alt='nointro found' className='third-into-image' />
                            <div className='intro-content'>
                                <div className='into-title'>Get Private Offers!</div>
                                <div className='into-description'>Travel deals tailored to your preferences.</div>
                            </div>
                        </div>
                    }
                    {
                        pageIndex === 2 &&
                        <div className='into-subcontainer1'>
                            <img src={Intro7} alt='nointro found' className='first-into-image' />
                            <img src={Intro8} alt='nointro found' className='second-into-image' />
                            <img src={Intro9} alt='nointro found' className='third-into-image' />
                            <div className='intro-content'>
                                <div className='into-title'> Bon Voyage!</div>
                                <div className='into-description'>Book your experience and pack your bags!</div>
                            </div>
                        </div>
                    }
                </div>
                <div className='div'>
                    <div className='introcounterContainer'>
                        <ul className="intropagination-counter">
                            {[0, 1, 2].map((_, index) => (
                                <li
                                    key={index}
                                    className={index === pageIndex ? "active" : ""}
                                />
                            ))}
                        </ul>
                    </div>
                    <ButtonSmall buttonstyle="default" className='intro-btn' text="GET STARTED" handleClick={handleOnboard} />
                </div>
                <div className='right-container'>
                    <KeyboardArrowRightIcon onClick={handleRight} className='right-arrow' />
                </div>
            </div>

        </Modal >
    );
};

export default Intro;