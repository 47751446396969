import React, { useEffect, useState } from "react";
import { Skeleton as Sk } from "antd";
import BadgeVisible from "../BadgeVisible";
import { POST_TYPES } from "../../Helpers";
import ReactStars from "react-rating-stars-component";
import SimpleImageSlider from "react-simple-image-slider";
import "./style.css";

const Banner = ({
  title,
  heading,
  imageSrc,
  actionButton,
  widget,
  hasRatingPrice,
  starRating,
  price,
  city,
  country,
  type,
  description,
  isHotelBanner,
  isSearch,
  templatetype,
  isProperties,
  isHotelType,
  isWidgetVisible,
  setIsWidgetVisible,
  isWidgetVisible1,
  bannerLoader,
  setBannerLoader
}) => {

  const [activeIndex, setActiveIndex] = useState(1);
  const [showNav, setShowNav] = useState(true);

  const handleSlideChange = (idx, event) => {
    setActiveIndex(idx);
  };

  useEffect(() => {
    if (imageSrc?.length === 1) {
      setShowNav(false);
      return;
    }
  }, [imageSrc]);

  useEffect(() => {
    if (setBannerLoader) {
      const intervalId = setInterval(() => {
        setBannerLoader(false)
      }, 2000);
      return () => {
        clearInterval(intervalId);
      };
    }

  }, [])


  return (

    imageSrc ? (isSearch ? <>
      <div className="hero">
        <div className="issearch-background" style={{
          backgroundImage: `url(${imageSrc})`
        }}></div>

        <div className="banner-content">
          {widget}
        </div>
      </div>
    </> : <>
      {isProperties ? (<div className="hero">
        <div style={{
          backgroundImage: `url(${imageSrc})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          width: '100%',
          height: '100%'
        }}>
          <div className="banner-content">
            <div className="message-content">
              <div className="message-wrap">
                <div className="message">
                  <div className="text-title">{title}</div>
                  <h1 className="text-heading">{heading}</h1>
                </div>

                <div className="button-wrap">
                  {actionButton}
                </div>
              </div>
            </div>
            {widget}
          </div>
        </div>

      </div>) : (
        <div className="hero">
          <div className="sliderimage-wrap">
            {bannerLoader ?
              <div className="slider-banner-loader"></div>
              :
              imageSrc &&
              <SimpleImageSlider
                style={{ position: 'absolute' }}
                width={'100%'}
                height={'100%'}
                autoPlay={true}
                autoPlayDelay={6.0}
                images={imageSrc}
                showBullets={true}
                showNavs={showNav}
                navSize={{ width: "10px" }}
                onStartSlide={(idx) => handleSlideChange(idx)}
              />
            }
          </div>
          <div className="banner-content">
            <div className="message-content">
              <div className="message-wrap">
                {isHotelBanner ? (<>
                  <div className="message">
                    {(!isWidgetVisible || isWidgetVisible1) &&
                      <>
                        <h1 className="text-heading">{title || <Sk />}</h1>
                        <div className="location">
                          {city}{country && ','} {country && country}
                        </div>
                        {type &&
                          <div className="frame-of-badges">
                            {
                              isHotelType ? <BadgeVisible
                                className={"class-33"}
                                text={type}
                              />
                                : type !== 'basic' && <BadgeVisible
                                  className={`${type === "brand" ? "class-34" : "class-33"}`}
                                  text={type === "property" ? templatetype : POST_TYPES[type]}
                                />}
                          </div>
                        }
                      </>
                    }
                  </div>
                  {(!isWidgetVisible || isWidgetVisible1) &&
                    <>
                      {description && <div className="description">{description}</div>}
                      {hasRatingPrice && (
                        <div className="rating-price">
                          <div className="star-rating">
                            <ReactStars
                              count={5}
                              edit={false}
                              value={starRating}
                              isHalf={true}
                              size={24}
                              color="#fff"
                              activeColor="#ffd700"
                            />
                          </div>
                          {price && <div className="price-from">from ${price}</div>}
                        </div>
                      )}
                      {
                        type == 'offer' && (price && <div className="price-from">from ${price}</div>)
                      }
                    </>
                  }

                </>) : (
                  !bannerLoader && imageSrc?.map((value, index) => {
                    return (
                      index === activeIndex - 1 ?
                        (
                          <div key={index} style={{ display: 'flex', flexDirection: 'column', gap: '40px' }}>
                            <div className="message">
                              <div className="text-title">{value.title}</div>
                              {
                                value?.anchor ?
                                  <a href={value?.anchor}><h1 className="text-heading">{value.heading}</h1></a>
                                  :
                                  <h1 className="text-heading">{value.heading}</h1>
                              }
                            </div>
                            <a href={value?.anchor} key={index} > <div className="button-wrap">
                              {value?.actionButton}
                            </div>
                            </a>
                          </div>
                        ) : null
                    )
                  })
                )}
                {(isHotelBanner && (!isWidgetVisible || isWidgetVisible1)) &&
                  <div className="button-wrap">
                    {actionButton}
                  </div>

                }
              </div>
            </div>

            {isHotelBanner ? (isWidgetVisible || isWidgetVisible1)
              &&
              widget
              :
              widget
            }
          </div>
        </div>)}
    </>) : null
  );
};

export default Banner;