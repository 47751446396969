import React from "react";

const LikeOutline = ({ className }) => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M17.2552 2.94276C16.4086 1.96898 15.2601 1.42187 14.0625 1.42188C12.8649 1.42188 11.7164 1.96898 10.8698 2.94276L9.99978 3.94295L9.12978 2.94276C7.36649 0.915587 4.50763 0.915588 2.74434 2.94276C0.981052 4.96992 0.981052 8.25661 2.74434 10.2838L3.61434 11.284L9.99978 18.625L16.3852 11.284L17.2552 10.2838C18.1022 9.31046 18.5781 7.99008 18.5781 6.61327C18.5781 5.23646 18.1022 3.91608 17.2552 2.94276Z" stroke="white" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    );
};

export default LikeOutline