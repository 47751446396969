import PropTypes from "prop-types";
import React from "react";
import "./style.css";

const ButtonTag = ({ className, handleClick, data, selected }) => {
  return (
    <>
      {
        data?.map((v, i) => (
          <div
            key={i}
            onClick={() => handleClick(v?.value)}
            className={`button-tag ${selected == v?.value ? "active" : "inactive"} ${className}`}
          >
            <div className="show-all">{v?.name}</div>
          </div>))
      }</>
  );
};

ButtonTag.propTypes = {
  status: PropTypes.oneOf(["active-hover", "inactive", "active", "inactive-hover"]),
  text: PropTypes.string,
};

export default ButtonTag;