import React from "react";
import "./style.css";

const ButtonSmall = ({ buttonstyle, className, textStyle, text = "GET INSPIRED. GET OFFERS. GO.", learnmore, handleClick, disabled }) => {
  return (
    <div onClick={handleClick} className={`button-small small-${buttonstyle ? buttonstyle : ''} ${className ? className : ''} ${learnmore ? learnmore : ''} ${disabled ? disabled : ''}`}>
      <p className={`button-text ${textStyle ? textStyle : ''}`}>{text}</p>
    </div>
  );
};

export default ButtonSmall;