import React, { useState, useEffect, useRef } from "react";
import Badge from "../Badge";
import { Input } from "antd";
import Wave from 'react-wavify'
import Search from "../../Pages/Search";
import ButtonSmall from "../ButtonSmall";
import Post from "../../api/models/Post";
import Cancel from "../../assets/icons/Cancel";
import Stars from "../../assets/icons/stars.png";
import MenuBar from "../../assets/icons/MenuBar";
import { messaging } from "../../firebaseConfig";
import useAuth from '../../providers/AuthProvider';
import ButtonTag from '../../Components/ButtonTag';
import LogoDark from "../../assets/icons/LogoDark";
import Favorite from "../../assets/icons/Favorite";
import Notification from "../../Pages/Notification";
import LogoLight from "../../assets/icons/LogoLight";
import PopUpModal from "../../Pages/Auth/PopUpModal";
import useStore from "../../providers/StoreProvider";
import LogoutIcon from "../../assets/icons/LogoutIcon";
import SearchIcon from "../../assets/icons/SearchIcon";
import AdvisorIcon from "../../assets/icons/AdvisorIcon";
import Reservation from "../../assets/icons/Reservation";
import UserProfile from "../../assets/icons/UserProfile";
import X from "../../assets/images/icons/cancel-icon.png";
import OccasionIcon from "../../assets/icons/OccasionIcon";
import ExploreArrow from "../../assets/icons/ExploreArrow";
import { Link, useLocation, useNavigate } from "react-router-dom";
import CollectionIcon from "../../assets/icons/CollectionIcon";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import DestinationIcon from "../../assets/icons/DestinationIcon";
import NotificationIcon from "../../assets/icons/NotificationIcon";
import useNotification from "../../providers/NotificationProvider";
import UserPlaceholder from "../../assets/images/icons/profile.png";
import { dateToMonthString, getUserDisplayName } from "../../Helpers";
import "./style.css";
import ItinearariesIcon from "../../assets/icons/ItinearariesIcon";


const NavBar = ({ navigationDark }) => {

  const { store } = useStore();
  const navigate = useNavigate();
  const { logout } = useAuth();
  const location = useLocation();
  const searchContainerRef = useRef(null);
  const [notification, createNotification] = useNotification();

  let data = localStorage.getItem('data');
  let user = localStorage.getItem('user');
  let userValues = JSON.parse(user);


  const [result, setResult] = useState();
  const [type, setType] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [isJoinNow, setIsJoinNow] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [resultTypes, setResultTypes] = useState([]);
  const [searchDelay, setSearchDelay] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const [isNotificationVisible, setIsNotificationVisible] = useState(false);
  const [isSearchContainerVisible, setIsSearchContainerVisible] = useState(false);



  const activeHome = location.pathname === "/" ? 'nav-active-link' : '';
  const activeTripdesign = location.pathname.match(/^\/trips.lovu.travel/) ? 'nav-active-link' : '';
  const activeExplore = location.pathname.match(/^\/destinations/) || location.pathname.match(/^\/collection/) || location.pathname.match(/^\/occassions/) || location.pathname.match(/^\/ta/) || location.pathname.match(/^\/itineraries/) ? 'nav-active-link' : '';
  const activeDestination = location.pathname.match(/^\/destinations/) ? 'nav-active-link' : '';
  const activeCollection = location.pathname.match(/^\/collection/) ? 'nav-active-link' : '';
  const activeOccasion = location.pathname.match(/^\/occassions/) ? 'nav-active-link' : '';
  const activeAdviosr = location.pathname.match(/^\/ta/) ? 'nav-active-link' : '';
  const activeSpecial = location.pathname.match(/^\/marketplace/) ? 'nav-active-link' : '';
  const activeItineraries = location.pathname.match(/^\/itineraries/) ? 'nav-active-link' : '';

  const handleUrl = async () => {
    if (data) {
      await Post.itinearary()
        .then((res) => {
          if (res) {
            let fullurl = 'https://trips.lovu.travel/' + '?' + res?.query;
            window.location.href = fullurl;
          }
        }
        )
        .catch(() => { });
    } else {
      window.location.href = 'https://trips.lovu.travel/';

    }
  }

  const handleSearchInputChange = (e) => {
    setSearchValue(e.target.value);
    if (e.target.value) {
      setIsSearchContainerVisible(true);
    } else {
      setIsSearchContainerVisible(false);
    }
  };

  const handleSearchInputClick = () => {
    if (searchValue) {
      setIsSearchContainerVisible(true);
    }
  };

  const handleSearchContainerMouseEnter = () => {
    setIsSearchContainerVisible(true);
  };

  const handleSearchContainerMouseLeave = () => {
    if (!searchValue) {
      setIsSearchContainerVisible(false);
    }
  };

  const handleClickOutsideOfSearchContainer = (event) => {
    if (searchContainerRef.current && !searchContainerRef.current.contains(event.target)) {
      setIsSearchContainerVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutsideOfSearchContainer);
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideOfSearchContainer);
    };
  }, []);

  const changeType = (type, title, url) => {
    var hyphenless = title.split('-').join('_');
    let anchorTitle = hyphenless?.replace(/\s+/g, '-').toLowerCase();
    if (type == "basic" || type == "property" || type == "brand" || type == "destination" || type == 'offer') {
      return `/${anchorTitle}`
    } else if (type === "advisor") {
      return `/${'ta'}/${anchorTitle}`
    } else if (type === "worldiaitenararies") {
      return `${url}`
    } else {
      return `/${type}/${anchorTitle}`;
    }
  }

  function visualizeText(text, searchString) {
    if (searchString === '') {
      return text;
    }
    const matches = text.match(new RegExp(searchString, 'gi'));
    return text.split(new RegExp(searchString, 'gi')).map((e, i, a) => (
      <span className='highlight-query-content' key={i}>
        {e}
        {i < a.length - 1 && (
          <span className='highlight-query' key={i}>
            {matches[i]}
          </span>
        )}
      </span>
    ));
  }

  useEffect(() => {
    setType(null);
    setResultTypes([...new Set(result?.map(e => e.type))]);
  }, [result]);

  useEffect(() => {
    setLoading(true);
    if (searchDelay) {
      clearTimeout(searchDelay);
    }
    let timeout = setTimeout(search, 500);
    setSearchDelay(timeout);
    return () => searchDelay && clearTimeout(searchDelay);
  }, [searchValue]);

  const search = () => {
    if (searchValue === '') {
      setResult();
      return;
    }
    setLoading(true);
    Post.search(searchValue, null)
      .then(result => result.data)
      .then(setResult)
      .then(() => setLoading(false));
  };

  const handleJoinIn = () => {
    setIsModalVisible(true)
    setIsJoinNow(true)
    setIsActive(false)
  }

  const handleLogin = () => {
    setIsModalVisible(true)
    setIsJoinNow(false)
    setIsActive(false)
  }

  const clearFCMToken = async () => {
    try {
      await messaging.deleteToken();
      console.log('FCM token deleted.');
    } catch (error) {
      console.error('Error deleting FCM token:', error);
    }
  };

  const clearCacheData = () => {
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
  };

  const handleLogout = () => {
    createNotification({
      title: 'Warning',
      content:
        'Are you sure you want to  logout?',
      primary: {
        title: 'Logout',
        onPress: () => {
          logout();
          clearCacheData();
          localStorage.clear();
          clearFCMToken().then(() => navigate('/'))
        }
      },
      secondary: {
        title: 'Cancel',
      },
    })
  }


  return (
    <div className="nav-bar-container">
      <div className="nav-bar">
        <Link to="/">{navigationDark ? <LogoDark className="lovu-header-logo" /> : <LogoLight className="lovu-header-logo" />}</Link>
        <div className="menu-list-container">
          <Link to="/" >
            <ButtonSmall
              className="button-small-2"
              buttonstyle="button-transparent"
              textStyle={activeHome}
              text="Home"
            />
          </Link>
          <ButtonSmall
            className="button-small-2"
            buttonstyle="button-transparent"
            text="TRIP DESIGNER"
            textStyle={activeTripdesign}
            handleClick={handleUrl}
          />
          <div className="explore-container">
            <ButtonSmall
              className="button-small-2"
              buttonstyle="button-transparent"
              text="Explore"
              textStyle={activeExplore}
            />
            <ExploreArrow fillcolor={activeExplore} className="explore-arrow" />
            <div className="explore-main-container">
              <Link to="/destinations">
                <div className="explore-sub-container">
                  <DestinationIcon fillcolor={activeDestination} className="explore-sub-icon" />
                  <ButtonSmall
                    className="button-small-2"
                    buttonstyle="button-transparent"
                    text="Destinations"
                    textStyle={`explore-text ${activeDestination}`}
                  />
                </div>
              </Link>
              <Link to="/collection">
                <div className="explore-sub-container">
                  <CollectionIcon fillcolor={activeCollection} className="explore-sub-icon" />
                  <ButtonSmall
                    className="button-small-2"
                    buttonstyle="button-transparent"
                    text="Collections"
                    textStyle={`explore-text ${activeCollection}`}
                  />
                </div>
              </Link>
              <Link to="/occassions">
                <div className="explore-sub-container">
                  <OccasionIcon fillcolor={activeOccasion} className="explore-sub-icon" />
                  <ButtonSmall
                    className="button-small-2"
                    buttonstyle="button-transparent"
                    text="Occassions"
                    textStyle={`explore-text ${activeOccasion}`}
                  />
                </div>
              </Link>
              <Link to="/ta">
                <div className="explore-sub-container">
                  <AdvisorIcon fillcolor={activeAdviosr} className="explore-sub-icon" />
                  <ButtonSmall
                    className="button-small-2"
                    buttonstyle="button-transparent"
                    text="Travel Advisors"
                    textStyle={`explore-text ${activeAdviosr}`}
                  />
                </div>
              </Link>
              <Link to="/itineraries">
                <div className="explore-sub-container">
                  <ItinearariesIcon fillcolor={activeItineraries} className="explore-sub-icon" />
                  <ButtonSmall
                    className="button-small-2"
                    buttonstyle="button-transparent"
                    text="Itineraries"
                    textStyle={`explore-text ${activeItineraries}`}
                  />
                </div>
              </Link>
            </div>
          </div>
          <Link to="/marketplace">
            <ButtonSmall
              className="button-small-2"
              buttonstyle="button-transparent"
              text="Marketplace"
              textStyle={activeSpecial}
            />
          </Link>
        </div>
        <div className="login-profile-maincontainer">
          <div className="login-search-container">
            <Input
              value={searchValue}
              onChange={handleSearchInputChange}
              onClick={handleSearchInputClick}
              onFocus={() => searchValue != '' && setIsSearchContainerVisible(true)}
              suffix={
                searchValue == "" ?
                  <span><SearchIcon fillColor={'nav-search-fill'} className="nav-search-icon" /></span> :
                  <img onClick={() => setSearchValue('')} src={X} alt="cancel" className="nav-search-cancel-icon" />
              }

              className="nav-search-input"
            />
            {
              searchValue !== '' && isSearchContainerVisible &&
              <div
                ref={searchContainerRef}
                className="login-search-subcontainer"
                onMouseEnter={handleSearchContainerMouseEnter}
                onMouseLeave={handleSearchContainerMouseLeave}

              >
                <div className="login-searchresult-maincontainer">

                  <div className='login-searchresult-filteroption-container'>
                    {searchValue !== '' && (loading) ?
                      <div className='loding-skeleton-tag'>
                        <SkeletonTheme highlightColor="#ebddcc" width={130} height={45}>
                          <Skeleton borderRadius={'6px 6px 0 0'} />
                        </SkeletonTheme>
                        <SkeletonTheme highlightColor="#ebddcc" width={110} height={45}>
                          <Skeleton borderRadius={'6px 6px 0 0'} />
                        </SkeletonTheme>
                        <SkeletonTheme highlightColor="#ebddcc" width={150} height={45}>
                          <Skeleton borderRadius={'6px 6px 0 0'} />
                        </SkeletonTheme>
                      </div>
                      :
                      result?.length > 0
                      &&
                      <div className="login-searchresult-filteroption-subcontainer">
                        <ButtonTag
                          data={[
                            { name: 'Show all', value: null },
                            { name: 'Hotels', value: 'property' },
                            { name: 'Collections', value: 'collection' },
                            { name: 'Brands', value: 'brand' },
                            { name: 'Destinations', value: 'destination' },
                            { name: 'Travel Advisors', value: 'advisor' },
                            { name: 'Iteneraries', value: 'worldiaitenararies' },
                            { name: 'Marketplace', value: 'offer' },
                          ].filter(
                            e =>
                              resultTypes.length > 0 &&
                              (!e.value || resultTypes.includes(e.value)),
                          )}
                          selected={type}
                          handleClick={setType}
                        />
                      </div>
                    }
                  </div>
                  <div className="login-searchresult-subcontainer">
                    {searchValue !== '' && (!result?.length || loading) ? (
                      <div>
                        {loading ?
                          <div className='login-searchresult-skeleton'>
                            <SkeletonTheme highlightColor="#ebddcc" width={300} height={20}>
                              <Skeleton width={380} style={{ marginTop: '10px', display: 'flex' }} />
                              <Skeleton style={{ marginBottom: '15px', display: 'flex' }} />
                            </SkeletonTheme>
                            <SkeletonTheme highlightColor="#ebddcc" width={300} height={20}>
                              <Skeleton width={380} style={{ display: 'flex' }} />
                              <Skeleton style={{ marginBottom: '15px', display: 'flex' }} />
                            </SkeletonTheme>
                            <SkeletonTheme highlightColor="#ebddcc" width={300} height={20}>
                              <Skeleton width={380} style={{ display: 'flex' }} />
                              <Skeleton style={{ marginBottom: '15px', display: 'flex' }} />
                            </SkeletonTheme>
                            <SkeletonTheme highlightColor="#ebddcc" width={300} height={20}>
                              <Skeleton width={380} style={{ display: 'flex' }} />
                              <Skeleton style={{ marginBottom: '15px', display: 'flex' }} />
                            </SkeletonTheme>
                            <SkeletonTheme highlightColor="#ebddcc" width={300} height={20}>
                              <Skeleton width={380} style={{ display: 'flex' }} />
                              <Skeleton style={{ marginBottom: '15px', display: 'flex' }} />
                            </SkeletonTheme>
                            <SkeletonTheme highlightColor="#ebddcc" width={300} height={20}>
                              <Skeleton width={380} style={{ display: 'flex' }} />
                              <Skeleton style={{ marginBottom: '15px', display: 'flex' }} />
                            </SkeletonTheme>
                            <SkeletonTheme highlightColor="#ebddcc" width={300} height={20}>
                              <Skeleton width={380} style={{ display: 'flex' }} />
                              <Skeleton style={{ marginBottom: '15px', display: 'flex' }} />
                            </SkeletonTheme>
                          </div>
                          :
                          <div className='login-searchresult-no-result'>No results</div>
                        }
                      </div>
                    ) :

                      (result?.filter(
                        e =>
                        (!type ||
                          (type === 'property'
                            ? ['property', 'basic'].includes(e.type)
                            : e.type === type))
                      )
                        .map((hit, index, array) => (
                          <>
                            <Link to={changeType(hit?.type, hit?.title, hit?.website)} onClick={() => setSearchValue('')} className='result-content' key={index}>
                              <div className="search-result-text-container">
                                <div className='search-result-title'>
                                  {visualizeText(
                                    hit?.title,
                                    searchValue,
                                  )}
                                </div>
                                <div className='search-result-city'>
                                  {visualizeText(
                                    [hit?.city, hit?.country]
                                      .filter(
                                        e => e && e?.length,
                                      )
                                      .join(',  '),
                                    searchValue,
                                  )}

                                </div>
                              </div></Link>
                            {index !== array.length - 1 && <div className="search-result-text-container-hr" />}
                          </>
                        ))
                      )
                    }
                  </div>
                </div>
              </div>
            }
          </div>
          {data == null ?
            <>
              <div className="login-maincotainer">
                <ButtonSmall
                  textStyle="join-btn"
                  buttonstyle="button-outline"
                  text="JOIN NOW"
                  handleClick={handleJoinIn}
                />
                <ButtonSmall
                  textStyle="join-btn"
                  className="button-small-2"
                  buttonstyle="button-transparent"
                  text="LOG-IN"
                  handleClick={handleLogin}
                />
              </div>
              <div className="login-handburger-menu">
                <div onClick={() => setIsSearchVisible(true)} className="nav-bar-icons login-handburger-search-icon"><SearchIcon fillColor='dark-theme' /></div>
                <div onClick={() => setIsActive(!isActive)} className="nav-bar-icons"><MenuBar fillColor='dark-theme' /> </div>
              </div>
            </>
            :
            <div className="logout-profile-maincontainer">
              <div onClick={() => setIsSearchVisible(true)} className="nav-bar-icons profile-search-icon"><SearchIcon fillColor='dark-theme' /></div>
              <div className="profile-placeholder-container">
                {
                  userValues?.media?.length > 0
                    ?
                    userValues?.media[0]?.sizes?.small
                      ?
                      <img className="nav-bar-icons profile-img" src={userValues?.media[0]?.sizes?.small} alt="profile-img" />
                      :
                      <UserProfile className='nav-bar-icons profile-placeholder-icon' />
                    :
                    <UserProfile className='nav-bar-icons profile-placeholder-icon' />
                }
                <div className="profile-placeholder-subcontainer">
                  <div className="logout-container">
                    <div className="logout-username-container">
                      {
                        userValues?.media?.length > 0 ?
                          userValues?.media[0]?.sizes?.small ?
                            <img className="profile-img" src={userValues?.media[0]?.sizes?.small} alt="profile-img" />
                            :
                            <img className="profile-img" src={UserPlaceholder} alt="profile-img" /> :
                          <img className="profile-img" src={UserPlaceholder} alt="profile-img" />
                      }
                      <div className="logout-username">{getUserDisplayName(userValues)?.slice(0, 15)}</div>
                    </div>
                    <Link to="/offer">
                      <div className="logout-offer-container">
                        <img className="offer-icon" src={Stars} alt="stars-img" />
                        <div className="logout-container-text-name">My offers</div>
                        {store?.user?.unread_deals_count > 0 ? (
                          <Badge
                            text={`${store?.user?.unread_deals_count} new offer`}
                          />
                        ) : (
                          <></>
                        )}
                      </div>
                    </Link>
                    <Link to="/profile">
                      <div className="logout-myprofile-container">
                        <UserProfile />
                        <div className="logout-container-text-name">My Profile</div>
                      </div>
                    </Link>
                    <Link to="/favorite">
                      <div className="logout-myprofile-container">
                        <Favorite className='favorite-icon' />
                        <div className="logout-container-text-name">My Favorites</div>
                      </div>
                    </Link>
                    <Link to="/reservations">
                      <div className="logout-myprofile-container">
                        <Reservation className='favorite-icon' />
                        <div className="logout-container-text-name">My Reservations</div>
                      </div>
                    </Link>
                    <hr className="bottom-line" />
                    <div className="logout-logout-container" onClick={handleLogout}>
                      <div className='logout-icon'><LogoutIcon fillColor='dark-theme' /> </div>
                      <div className="logout-container-text-name">Log out</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="nav-bar-icons notification-container" onClick={() => setIsNotificationVisible(true)} >
                <NotificationIcon className='notification-icon' fillColor='dark-theme' />
                {store?.user?.unread_notifications_count > 0 ? <div className="notification-badge-container"> {store?.user?.unread_notifications_count}</div> : <></>}
              </div>
              <div onClick={() => setIsActive(!isActive)} className="nav-bar-icons profile-handburger-menu"> <MenuBar fillColor='dark-theme' /></div>
            </div>
          }
        </div>
      </div>
      <>
        <div style={{ height: data !== null ? '590px' : '520px' }} className={isActive ? "res-nav-visible" : "res-nav-visible-none"}>

          <div className={`res-nav-nar-header`}>
            <Link to="/"><div onClick={() => setIsActive(!isActive)}>{<LogoLight className="lovu-header-logo" />}</div></Link>
            <div onClick={() => setIsActive(!isActive)} className="nav-bar-icons "><Cancel /></div>
          </div>

          <div className="res-navbar-menucontainer">
            <Link to="/"> <ButtonSmall className="button-small-2" buttonstyle="button-transparent" text="Home" handleClick={() => setIsActive(!isActive)} /> </Link>
            <ButtonSmall className="button-small-2" buttonstyle="button-transparent" text="TRIP DESIGNER" handleClick={handleUrl} />
            <Link to="/destinations"><ButtonSmall className="button-small-2" buttonstyle="button-transparent" text="DESTINATIONS" /> </Link>
            <Link to="/collection"><ButtonSmall className="button-small-2" buttonstyle="button-transparent" text="COLLECTIONS" /> </Link>
            <Link to="/offer"><ButtonSmall className="button-small-2" buttonstyle="button-transparent" text="My Offer" /> </Link>
            <Link to="/reservations"><ButtonSmall className="button-small-2" buttonstyle="button-transparent" text="My Reservations" /> </Link>
            <Link to="/occassions"><ButtonSmall className="button-small-2" buttonstyle="button-transparent" text="OCCASSIONS" /> </Link>
            <Link to="/marketplace"> <ButtonSmall className="button-small-2" buttonstyle="button-transparent" text="Marketplace" /></Link>
            {store.token !== null && <ButtonSmall className="button-small-2" buttonstyle="button-transparent" text="logout" handleClick={handleLogout} />}
            {!data ?
              <div className="res-navbar-login-btn-container">
                <ButtonSmall buttonstyle="button-outline res-login-btn" text="JOIN NOW" handleClick={handleJoinIn} />
                <ButtonSmall buttonstyle="button-outline res-login-btn" text="LOG-IN" handleClick={handleLogin} />
              </div>
              :
              <div style={{ width: '112vw', marginLeft: '-20%', position: 'absolute', bottom: '0' }}>
                <Wave paused={true} fill="url(#gradient)" mask="url(#paint0_linear_4973_4527)" options={{ height: 70, speed: 0.09, points: 2, amplitude: -950 }}>
                  <defs>
                    <linearGradient id="gradient"  >
                      <stop offset="10%" stopColor="#23BAFF" />
                      <stop offset="90%" stopColor="#5183F0" />
                    </linearGradient>
                  </defs>
                </Wave>
              </div>
            }
          </div>
          {data !== null &&
            <div className="res-profile-container">
              <Link to="/profile">
                <div className="res-profile-img-name-container">
                  {
                    userValues?.media?.length > 0 ?
                      userValues?.media[0]?.sizes?.small ?
                        <img className="profile-img-mobile" src={userValues?.media[0]?.sizes?.small} alt="profile-img" />
                        :
                        <img className="profile-img-mobile" src={UserPlaceholder} alt="profile-img" /> :
                      <img className="profile-img-mobile" src={UserPlaceholder} alt="profile-img" />
                  }
                  <div className="res-name-member-container">
                    <div className="logout-username">{getUserDisplayName(userValues)}</div>
                    <div className="user-memebr-since"> Member since{' '}{dateToMonthString(new Date(userValues?.created_at,),)}</div>
                  </div>
                </div>
              </Link>
              <div onClick={handleLogout} className="res-logout">{navigationDark ? <LogoutIcon fillColor={'dark-theme'} /> : <LogoutIcon />}</div>
            </div>}
        </div>
      </>
      <PopUpModal
        visible={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        isJoinNow={isJoinNow}
        setIsJoinNow={setIsJoinNow}
        setIsModalVisible={setIsModalVisible}
      />
      <Search
        visible={isSearchVisible}
        onClose={() => setIsSearchVisible(false)}
      />
      <Notification
        visible={isNotificationVisible}
        onClose={() => setIsNotificationVisible(false)}
      />
    </div >
  );
};

export default NavBar;