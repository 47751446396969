import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import Switch from "react-switch";
import Tag from '../../api/models/Tag';
import debounce from 'lodash/debounce';
import { DatePicker, Input } from "antd";
import Select from 'react-dropdown-select';
import Banner from "../../Components/Banner";
import Footer from "../../Components/Footer";
import NavBar from "../../Components/NavBar";
import AddIcon from '@mui/icons-material/Add';
import Skeleton from "react-loading-skeleton";
import MetaTags from "../../Components/MetaTags";
import ResetPassword from "../Auth/ResetPassword";
import { getUserDisplayName } from "../../Helpers";
import useAuth from "../../providers/AuthProvider";
import useStore from "../../providers/StoreProvider";
import Metatag from "../../api/models/Metatag.js";
import lovuLogo from "../../assets/LOVU_full_logo.svg"
import ButtonSmall from "../../Components/ButtonSmall";
import AppleIcon from '../../assets/icons/social/apple.svg';
import GoogleIcon from '../../assets/icons/social/google.svg';
import CameraEnhanceIcon from '@mui/icons-material/CameraEnhance';
import useNotification from "../../providers/NotificationProvider";
import Occasionpreference from '../../api/models/Occasionpreference';
import bannerImage from "../../assets/images/ZentiumSearchBanner.jpeg"
import FacebookIcon from '../../assets/icons/button-icons/facebook.svg';
import FileDownloadDoneIcon from '@mui/icons-material/FileDownloadDone';
import "./style.css";


const Profile = ({ setProgress }) => {

    const [notification, createNotification] = useNotification();
    const { logout } = useAuth();
    const [seo, setSeo] = useState();
    const [tags, setTags] = useState([]);
    const [occasions, setOccasions] = useState([]);
    const [updateForm, setUpdateForm] = useState({});
    const [savedState, setSavedState] = useState({ state: false, timeout: false, });
    const [state, setState] = useState({
        dateEditing: false,
        weddingDateEditing: false,
        anniversaryDateEditing: false,
        genderEditing: false,
        pronounEditing: false,
    });
    const [occasionPreference, setOccasionPreference] = useState('');
    const [selectOccasion, setSelectOccasion] = useState([]);
    const [destinationPreference, setDestinationPreference] = useState('');
    const [selectDestination, setSelectDestination] = useState([]);
    const [travelPreference, setTravelPreference] = useState('');
    const [selectTravel, setSelectTravel] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [switchState, setSwitchState] = useState(false);
    const { store, setStore } = useStore();
    const user = store.user;
    const getTime = () => new Date().getTime();
    const dateRef = useRef(null);
    const genderRef = useRef(null);
    const pronounRef = useRef(null);

    const genderOption = [
        {
            value: 'male',
            label: 'Male',
        },
        {
            value: 'female',
            label: 'Female',
        },
        {
            value: 'other',
            label: 'Other',
        }]

    const pronounOption = [
        {
            value: 'She/Her',
            label: 'She/Her',
        },
        {
            value: 'He/Him',
            label: 'He/Him',
        },
        {
            value: 'They/Them',
            label: 'They/Them',
        },
        {
            value: 'Ze/Hir',
            label: 'Ze/Hir',
        },
        {
            value: 'Ze/Zir',
            label: 'Ze/Zir',
        },
        {
            value: 'Xe/Xem',
            label: 'Xe/Xem',
        },
    ]

    useEffect(() => {
        setProgress(10)
        setProgress(20)
        setProgress(60)
        setProgress(100)
    }, [setProgress])

    useEffect(() => {
        Metatag.metaslug('profile').then(result =>
            setSeo(result)
        );
    }, []);

    useEffect(() => {
        if (Object.keys(updateForm).length === 0) {
            return;
        }
        setStore({ ...store, user: user.copy(updateForm) });
        user.update(updateForm).then(() => {
            setStore({ ...store, user: user.copy() });
            setSavedState({ ...savedState, state: getTime() });
        });
        setUpdateForm({});
    }, [updateForm, savedState, store, user, setStore]);

    useEffect(() => {
        Tag.all(1).then(({ data }) => setTags(data));
        Occasionpreference.all(1).then(({ data }) => setOccasions(data));
    }, []);

    useEffect(() => {
        if (savedState.state && savedState.timeout) {
            clearTimeout(savedState.timeout);
        }
        let timeout = setTimeout(
            () => setSavedState({ ...savedState, state: false }),
            3000,
        );
        setSavedState({ ...savedState, timeout: timeout });
        return () => clearTimeout(savedState.timeout);
    }, [savedState.state]);

    const filteredOccasionPreference = occasions.filter((item) =>
        item.name.toLowerCase().includes(occasionPreference.toLowerCase())
    );

    const filteredDestinationPreference = tags.filter(tag => tag.destination).map((e, i) => e).filter((item) =>
        item.name.toLowerCase().includes(destinationPreference.toLowerCase()))

    const filteredTravelPreference = tags.filter(tag => !tag.destination).map((e, i) => e).filter((item) =>
        item.name.toLowerCase().includes(travelPreference.toLowerCase()))

    const handleSwitchChange = () => {
        setSwitchState(!switchState);
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = () => {
                setUpdateForm({ featured_image: reader.result });
            };
            reader.readAsDataURL(file);
        }
    };

    const handleOccasionPress = (value) => {
        const updatedTags = user.occasionpreferences.slice();
        const tagIndex = updatedTags.indexOf(value);
        if (tagIndex !== -1) {
            updatedTags.splice(tagIndex, 1);
        } else {
            updatedTags.push(value);
        }
        setUpdateForm({
            occasionpreferences: updatedTags,
        });
        setSelectOccasion([...selectOccasion, value]);
        setOccasionPreference('');
    };

    const handleTagPress = (occasionid) => {
        user.occasionpreferences.splice(
            user.occasionpreferences.indexOf(
                occasionid,
            ),
            1,
        );
        setUpdateForm({
            occasionpreferences: user.occasionpreferences,
        });
    };

    const removeTagPress = (tagid) => {
        user.tags.splice(
            user.tags.indexOf(
                tagid,
            ),
            1,
        );
        setUpdateForm({
            tags: user.tags,
        });
    };

    const handleOccasionTagPress = (value) => {
        const updatedTags = user.tags.slice();
        const tagIndex = updatedTags.indexOf(value);
        if (tagIndex !== -1) {
            updatedTags.splice(tagIndex, 1);
        } else {
            updatedTags.push(value);
        }
        setUpdateForm({
            tags: updatedTags,
        });

        setSelectDestination([...selectDestination, value]);
        setDestinationPreference('');
    };

    const handleTravelTagPress = (value) => {
        const updatedTags = user.tags.slice();
        const tagIndex = updatedTags.indexOf(value);
        if (tagIndex !== -1) {
            updatedTags.splice(tagIndex, 1);
        } else {
            updatedTags.push(value);
        }
        setUpdateForm({
            tags: updatedTags,
        });

        setSelectTravel([...selectTravel, value]);
        setTravelPreference('');
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleOutsideClick);
        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
        };
    });
    const handleOutsideClick = (e) => {
        if (dateRef.current && !dateRef.current.contains(e.target)) {
            setState({ ...state, dateEditing: false, })
        }
        if (genderRef.current && !genderRef.current.contains(e.target)) {
            setState({ ...state, genderEditing: false, })
        }
        if (pronounRef.current && !pronounRef.current.contains(e.target)) {
            setState({ ...state, pronounEditing: false, })
        }
    };

    const delayedUpdateForm = debounce((field) => {
        if (field === 'firstname') {
            setUpdateForm({
                firstname: user.firstname,
            })
        }
        if (field === 'lastname') {
            setUpdateForm({
                lastname: user.lastname,
            })
        }
        if (field === 'email') {
            setUpdateForm({
                email: user.email,
            })
        }
        if (field === 'phone') {
            setUpdateForm({
                phone: user.phone,
            })
        }
    }, 500);


    const handleBlur = (field) => {
        delayedUpdateForm(field);
    };


    return (
        <>
            <MetaTags
                title={seo?.title != null ? seo?.title : "LOVU: Profile"}
                description={seo?.description != null ? seo?.description : "Discover handpicked romantic destinations with LOVU. Connect with expert Romance Travel Advisors to plan the perfect honeymoon, anniversary, or getaway."}
                imageScr={seo?.meta_image[0]?.original_url != undefined ? seo?.meta_image[0]?.original_url : lovuLogo}
            />
            <div className="profile">
                <NavBar navigationDark={true} />
                {/* <Banner
                    isSearch={true}
                    imageSrc={bannerImage}
                /> */}
                <div className="pageContent">
                    <div className="sub-content">
                        {savedState.state && (
                            <div className="autoSaveContainer">
                                <FileDownloadDoneIcon style={{ color: "#5cb85c" }} />
                                <h1 className="autoSaveContainerText">
                                    Changes saved!
                                </h1>
                            </div>
                        )}
                        <h1 className='profile-title'>Profile & Site Preferences</h1>
                        <div className="profile-image-container">
                            <div>
                                <label htmlFor="imagePickerInput" >
                                    <span className="user-profile">
                                        {(!user?.featured_image && (!user?.media || user?.media?.length === 0)) ? (
                                            <AddIcon color="#252525" />
                                        ) : (
                                            <img
                                                src={typeof user?.featured_image === 'string'
                                                    ? user?.featured_image
                                                    : (user?.media && user.media.length > 0 && user?.media[0]?.sizes?.small)
                                                }
                                                alt='No profile found'
                                                className="user-profile"
                                            />

                                        )}
                                        <div className="hover-camera-container">
                                            <CameraEnhanceIcon className="hover-camera-icon" />
                                        </div>
                                    </span>
                                </label>
                                <input
                                    type="file"
                                    accept="image/*"
                                    onChange={handleImageChange}
                                    id="imagePickerInput"
                                    style={{ display: "none" }}
                                />

                            </div>

                            <div className="user-name"><u>{getUserDisplayName(user) ? getUserDisplayName(user) : <Skeleton width={150} />}</u></div>
                        </div>

                        <div className="profile-section">
                            <h1 className="userInfo-text">User Info</h1>
                            {
                                (user.firstname || user.lastname || user.email) ?
                                    <div className="userInfo-container">
                                        <div className="userInfoRow">
                                            <h2 className="userInfoRowTitle">First Name:</h2>
                                            <div className="userInfoChangable">
                                                <Input className="user-input-field"
                                                    type="text" placeholder="Tap to edit"

                                                    onChange={(e) => setStore({
                                                        ...store,
                                                        user: user.copy({
                                                            firstname: e.target.value,
                                                        }),
                                                    })}
                                                    onBlur={() => handleBlur('firstname')}
                                                    onPressEnter={() =>
                                                        setUpdateForm({
                                                            firstname: user.firstname,
                                                        })
                                                    }
                                                    value={user?.firstname} />
                                            </div>
                                        </div>
                                        <div className="userInfoRow">
                                            <h2 className="userInfoRowTitle">Last Name:</h2>
                                            <div className="userInfoChangable">
                                                <Input className="user-input-field"
                                                    type="text" placeholder="Tap to edit"

                                                    onChange={(e) => setStore({
                                                        ...store,
                                                        user: user.copy({
                                                            lastname: e.target.value,
                                                        }),
                                                    })}
                                                    onBlur={() => handleBlur('lastname')}
                                                    onPressEnter={() =>
                                                        setUpdateForm({
                                                            lastname: user.lastname,
                                                        })
                                                    }
                                                    value={user?.lastname} />
                                            </div>
                                        </div>
                                        <div className="userInfoRow">
                                            <h2 className="userInfoRowTitle" ref={dateRef}>Birthday:</h2>
                                            <div className="userInfoChangable date-picker" >
                                                {state.dateEditing ? (
                                                    <DatePicker
                                                        open={state.dateEditing}
                                                        format={"DD-MMMM-YYYY"}
                                                        onChange={(date, dateString) => {
                                                            setUpdateForm({ birthday: dateString });
                                                            setState({
                                                                ...state,
                                                                dateEditing: false,

                                                            })
                                                        }}
                                                    />
                                                ) : (
                                                    <h2 className="tap-to-edit-text" onClick={() => setState({
                                                        ...state,
                                                        dateEditing: true,
                                                        pronounEditing: false,
                                                        genderEditing: false
                                                    })}>
                                                        {user?.birthday ? moment(user.birthday).format("DD MMMM YYYY") : "Tap to edit"}
                                                    </h2>
                                                )}
                                            </div>
                                        </div>
                                        <div className="userInfoRow">
                                            <h2 className="userInfoRowTitle">Gender:</h2>
                                            <div className="userInfoChangable" ref={genderRef}>
                                                {state.genderEditing ? (
                                                    <Select
                                                        className="gender-drop-down"
                                                        options={genderOption || []}
                                                        keepOpen={state.genderEditing}
                                                        value={user?.gender || ''}
                                                        separator={false}
                                                        onChange={(valu) => {
                                                            setUpdateForm({ gender: valu[0]?.value });
                                                            setState({
                                                                ...state,
                                                                genderEditing: false,
                                                            });
                                                        }}
                                                        placeholder={user?.gender || ''}
                                                    />
                                                ) : (
                                                    <h2 className="tap-to-edit-text" onClick={() => setState({
                                                        ...state,
                                                        genderEditing: true,
                                                        pronounEditing: false
                                                    })}>
                                                        {user?.gender ? user.gender : "Tap to edit"}
                                                    </h2>
                                                )}

                                            </div>
                                        </div>
                                        <div className="userInfoRow">
                                            <h2 className="userInfoRowTitle">Pronoun:</h2>
                                            <div className="userInfoChangable" ref={pronounRef}>
                                                {state.pronounEditing ? (
                                                    <Select
                                                        className="gender-drop-down"
                                                        options={pronounOption || []}
                                                        separator={false}
                                                        dropdownHandle={false}
                                                        keepOpen={state.pronounEditing || ''}
                                                        value={user?.pronoun}
                                                        onChange={(valu) => {
                                                            setUpdateForm({ pronoun: valu[0].value });
                                                            setState({
                                                                ...state,
                                                                pronounEditing: false,
                                                            })
                                                        }}
                                                        placeholder={user?.pronoun ? user?.pronoun : 'select'}
                                                    />

                                                ) : (
                                                    <h2 className="tap-to-edit-text" onClick={() => setState({
                                                        ...state,
                                                        pronounEditing: true,
                                                        genderEditing: false,
                                                    })}>
                                                        {user.pronoun ? user.pronoun : "Tap to edit"}
                                                    </h2>
                                                )}
                                            </div>
                                        </div>

                                        <div className="userInfoRow">
                                            <h2 className="userInfoRowTitle">Email:</h2>
                                            <div className="userInfoChangable">
                                                <Input className="user-input-field"
                                                    type="text" placeholder="Tap to edit"

                                                    onChange={(e) => setStore({
                                                        ...store,
                                                        user: user.copy({
                                                            email: e.target.value,
                                                        }),
                                                    })}
                                                    onBlur={() => handleBlur('email')}
                                                    onPressEnter={() =>
                                                        setUpdateForm({
                                                            email: user.email,
                                                        })
                                                    }
                                                    value={user?.email} />
                                            </div>
                                        </div>
                                        <div className="userInfoRow">
                                            <h2 className="userInfoRowTitle">Phone:</h2>
                                            <div className="userInfoChangable">
                                                <Input className="user-input-field"
                                                    placeholder="Tap to edit"
                                                    type="tel"
                                                    onChange={e => {
                                                        const phoneNumber = e.target.value;
                                                        if (!/^\d{0,15}$/.test(phoneNumber)) {
                                                            if (!/^\d*$/.test(phoneNumber)) {
                                                                alert("Please enter numbers only.");
                                                            } else {
                                                                alert("Please enter a valid phone number with maximum 15 digits.");
                                                            }
                                                            return;
                                                        }
                                                        setStore({
                                                            ...store,
                                                            user: user.copy({
                                                                phone: phoneNumber,
                                                            }),
                                                        });
                                                    }}
                                                    onBlur={() => handleBlur('phone')}
                                                    onPressEnter={() =>
                                                        setUpdateForm({
                                                            phone: user.phone,
                                                        })
                                                    }
                                                    value={user.phone}
                                                />
                                            </div>
                                        </div>
                                        <div className="userInfoRow">
                                            <h2 className="userInfoRowTitle">Password:</h2>
                                            <div className="userInfoChangable">
                                                <h2 className="tap-to-edit-text" onClick={() => setIsModalVisible(true)}> Tap to set </h2>
                                            </div>
                                        </div>
                                        <div className="userInfoRow" >
                                            {
                                                user?.social_accounts?.length > 0 &&
                                                <h2 className="userInfoRowTitle"
                                                    style={{ marginTop: user?.social_accounts?.length > 2 && -40, marginBottom: user?.social_accounts?.length > 2 && 40 }}>Social Login:</h2>
                                            }

                                            <div className="userInfoChangable" >
                                                {user.social_accounts
                                                    ? [
                                                        'facebook',
                                                        'google',
                                                        'apple',
                                                    ]
                                                        .filter(provider =>
                                                            Boolean(
                                                                user.social_accounts.find(
                                                                    el =>
                                                                        el.provider_name ===
                                                                        provider,
                                                                ),
                                                            ),
                                                        )
                                                        .map(provider => {
                                                            let ProviderIcon = provider === "google" ? GoogleIcon : provider === "facebook" ? FacebookIcon : AppleIcon;
                                                            return <>
                                                                <div
                                                                    style={{ width: "50%" }}
                                                                    className={`social-button-container-${provider}`}
                                                                >
                                                                    <h2 className='button-link-text'>{Boolean(
                                                                        user.social_accounts.find(el => el.provider_name === provider)
                                                                    ) ? "Linked With" : "Link With"}</h2>

                                                                    <img className="social-button-icon" alt="Image1" src={ProviderIcon} />
                                                                </div>
                                                            </>
                                                        })
                                                    : null}
                                            </div>
                                        </div>

                                    </div>
                                    :
                                    <div className="skeleton-container">
                                        <div className="userInfoRow">
                                            <div className="userInfoRowTitle">
                                                <Skeleton width={140} />
                                            </div>
                                            <div className="userInfoRowTitle">
                                                <Skeleton />
                                            </div>
                                        </div>
                                        <div className="userInfoRow">
                                            <div className="userInfoRowTitle">
                                                <Skeleton width={140} />
                                            </div>
                                            <div className="userInfoRowTitle">
                                                <Skeleton />
                                            </div>
                                        </div>
                                        <div className="userInfoRow">
                                            <div className="userInfoRowTitle">
                                                <Skeleton width={140} />
                                            </div>
                                            <div className="userInfoRowTitle">
                                                <Skeleton />
                                            </div>
                                        </div>
                                        <div className="userInfoRow">
                                            <div className="userInfoRowTitle">
                                                <Skeleton width={140} />
                                            </div>
                                            <div className="userInfoRowTitle">
                                                <Skeleton />
                                            </div>
                                        </div>
                                        <div className="userInfoRow">
                                            <div className="userInfoRowTitle">
                                                <Skeleton width={140} />
                                            </div>
                                            <div className="userInfoRowTitle">
                                                <Skeleton />
                                            </div>
                                        </div>
                                        <div className="userInfoRow">
                                            <div className="userInfoRowTitle">
                                                <Skeleton width={140} />
                                            </div>
                                            <div className="userInfoRowTitle">
                                                <Skeleton />
                                            </div>
                                        </div>
                                    </div>
                            }
                        </div>
                        <div className="preferenceSectionContainer">
                            <div className="preferenceSection">
                                <div className="userInfo-text">Occasion Preferences</div>
                                <div className="OccasionPreferenceSection">
                                    <div
                                        className="suggestionTagContentContainer">
                                        {occasions
                                            ?.filter(tag => (user?.occasionpreferences?.indexOf(tag?.id) > -1))
                                            ?.map((tag, i) => (
                                                <div
                                                    key={i}
                                                    onClick={() => handleTagPress(tag?.id)}>
                                                    <h2 className="suggestion" id={(user?.occasionpreferences?.includes(tag?.id)) ? "selectedTag" : null}>{tag?.name.toUpperCase()} X</h2>
                                                </div>
                                            ))}

                                        <Input className="user-input-field"
                                            type="text" placeholder="Type an occasion"
                                            onChange={(e) => setOccasionPreference(e.target.value)}
                                            value={occasionPreference} />
                                        {occasionPreference?.length > 0 && (
                                            filteredOccasionPreference?.map((occasionvalue) => (
                                                <div key={occasionvalue} onClick={() => handleOccasionPress(occasionvalue?.id)
                                                } >
                                                    <h2 className="suggestion" id={(user?.occasionpreferences?.includes(occasionvalue?.id)) ? "selectedTag" : null}>{occasionvalue.name.toUpperCase()}</h2>
                                                </div>
                                            ))
                                        )}

                                    </div>
                                </div>
                            </div>
                            <div className="preferenceSection">
                                <div className="userInfo-text">Preferred destinations</div>
                                <div className="OccasionPreferenceSection">
                                    <div
                                        className="suggestionTagContentContainer">
                                        {tags
                                            ?.filter(tag => tag?.destination && (user?.tags?.indexOf(tag?.id) > -1))
                                            ?.map((tag, i) => (
                                                <div
                                                    key={i}
                                                    onClick={() => removeTagPress(tag?.id)}>
                                                    <h2 className="suggestion" id={(user?.tags?.includes(tag?.id)) ? "selectedTag" : null}>{tag?.name.toUpperCase()} X</h2>
                                                </div>
                                            ))}
                                        <Input className="user-input-field"
                                            type="text" placeholder="Type an destination"
                                            onChange={(e) => setDestinationPreference(e.target.value)}
                                            value={destinationPreference} />
                                        {destinationPreference.length > 0 && (
                                            filteredDestinationPreference
                                                ?.map((suggestion) => (
                                                    <div
                                                        key={suggestion.id}
                                                        onClick={() => handleOccasionTagPress(suggestion?.id)}>
                                                        <h2 className="suggestion" id={(user?.tags?.includes(suggestion?.id)) ? "selectedTag" : null}>{suggestion?.name.toUpperCase()}</h2>
                                                    </div>
                                                ))
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="preferenceSection">
                                <div className="userInfo-text">Travel Preferences</div>
                                <div className="OccasionPreferenceSection">
                                    <div
                                        className="suggestionTagContentContainer">
                                        {tags
                                            ?.filter(tag => !tag?.destination && (user?.tags?.indexOf(tag?.id) > -1))
                                            ?.map((tag, i) => (
                                                <div
                                                    key={i}
                                                    onClick={() => removeTagPress(tag?.id)}>
                                                    <h2 className="suggestion" id={(user?.tags?.includes(tag?.id)) ? "selectedTag" : null}>{tag?.name.toUpperCase()}  X</h2>
                                                </div>
                                            ))}
                                        <Input className="user-input-field"
                                            type="text" placeholder="Type an Travel Preference"
                                            onChange={(e) => setTravelPreference(e.target.value)}
                                            value={travelPreference} />
                                        {travelPreference.length > 0 && (
                                            filteredTravelPreference
                                                ?.map((suggestion) => (
                                                    <div
                                                        key={suggestion.id}
                                                        onClick={() => handleTravelTagPress(suggestion?.id)}>
                                                        <h2 className="suggestion" id={(user?.tags?.includes(suggestion?.id)) ? "selectedTag" : null}>{suggestion?.name.toUpperCase()}</h2>
                                                    </div>
                                                ))
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="LastSection">
                            <div className="NotificationContainer">
                                <h1 className="userInfo-text">
                                    Notification Preferences
                                </h1>
                                <div className="switchContainer">

                                    <label className="switchContainerLabel">
                                        <Switch onChange={() => setUpdateForm({ mobile_notifications: !user.email_notifications })}
                                            checked={user.email_notifications}
                                            onHandleColor="#5A7BEF"
                                            onColor="#E9E9E9"
                                            offColor="#E9E9E9"
                                            checkedIcon={false}
                                            uncheckedIcon={false}
                                        />
                                        <span className="switchButtonText">Email notifications</span>
                                    </label>
                                    <label className="switchContainerLabel">
                                        <Switch onChange={() =>
                                            setUpdateForm({
                                                mobile_notifications:
                                                    !user.mobile_notifications,
                                            })}
                                            checked={user.mobile_notifications}
                                            onHandleColor="#5A7BEF"
                                            onColor="#E9E9E9"
                                            offColor="#E9E9E9"
                                            checkedIcon={false}
                                            uncheckedIcon={false}
                                        />
                                        <span className="switchButtonText">Mobile notifications</span>
                                    </label>
                                    <label className="switchContainerLabel">
                                        <Switch onChange={handleSwitchChange}
                                            checked={switchState}
                                            onHandleColor="#5A7BEF"
                                            onColor="#E9E9E9"
                                            offColor="#E9E9E9"
                                            checkedIcon={false}
                                            uncheckedIcon={false}
                                        />
                                        <span className="switchButtonText">SMS</span>
                                    </label>

                                </div>
                            </div>
                            <div className="OtherPreferenceContainer">
                                <h1 className="userInfo-text">
                                    Other Preferences
                                </h1>
                                <div className="switchContainer">
                                    <label className="switchContainerLabel">
                                        <Switch onChange={() =>
                                            setUpdateForm({
                                                contact: !user.contact,
                                            })}
                                            checked={user.contact}
                                            onHandleColor="#5A7BEF"
                                            onColor="#E9E9E9"
                                            offColor="#E9E9E9"
                                            checkedIcon={false}
                                            uncheckedIcon={false}
                                        />
                                        <span className="switchButtonText">Allow to be contacted by Travel Advisors that specialize in the type of travel I’m interested in</span>
                                    </label>
                                    <label className="switchContainerLabel">
                                        <Switch onChange={() =>
                                            setUpdateForm({
                                                newsletter: !user.newsletter,
                                            })}
                                            checked={user.newsletter}
                                            onHandleColor="#5A7BEF"
                                            onColor="#E9E9E9"
                                            offColor="#E9E9E9"
                                            checkedIcon={false}
                                            uncheckedIcon={false}
                                        />
                                        <span className="switchButtonText">Sign me up for LOVU newsletter, so I can receive product updates and the best deals.</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="delete-button">
                            <ButtonSmall className="" buttonstyle="default" text="delete account" handleClick={() =>
                                createNotification({
                                    title: 'Delete Account',
                                    content:
                                        'Are you sure? All your user information will be deleted. This can’t be undone.',
                                    primary: {
                                        title: 'Delete',
                                        onPress: () =>
                                            user.delete().then(logout),
                                    },
                                    secondary: {
                                        title: 'Cancel',
                                    },
                                })
                            } />
                        </div>
                    </div>
                </div>
                <Footer className="design-component-instance-node-3" />
                <ResetPassword
                    isPasswordChange={true}
                    visible={isModalVisible}
                    onClose={() => setIsModalVisible(false)}
                    setIsModalVisible={setIsModalVisible}
                    onSaveClick={(state) => setUpdateForm(state)}
                />

            </div>
        </>);
};

export default Profile;