import React, { useEffect, useRef } from 'react';
import Plus from "../../../assets/icons/Plus";
import { Checkbox, Modal, Input } from 'antd';
import Minus from "../../../assets/icons/Minus";
import Dollar from "../../../assets/icons/Dollar";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ButtonSmall from '../../../Components/ButtonSmall';
import "./style.css";

const BudgetModal = ({ visible, onClose, progress = 80, reservation, setReservation, setIsSubmitVisible, setIsGuestVisible, setIsCoupleVisible }) => {

    const topRef = useRef(null);

    useEffect(() => {
        if (topRef.current) {
            topRef.current.scrollTop = 0;
        }
    }, [visible])

    const ProgressBar = ({ progress }) => {
        return (
            <div className="progress-bar">
                <div className="progress" style={{ width: `${progress}%` }}></div>
            </div>
        );
    };

    const includeItems = [
        {
            value: 'Everything',
            label: 'Everything',
            description:
                'Includes venue and most vendors like decorations, floral, audio & video equipment, photography, entertainment, etc.',
        },
        {
            value: 'Medium',
            label: 'Medium',
            description:
                'Includes venue, catering, beverages and essential rentals.',
        },
        {
            value: 'Minimal',
            label: 'Minimal',
            description: 'Includes venue and essential rental only.',
        },
    ];

    const goNext = () => {
        onClose();
        setIsSubmitVisible(true)
    }

    const handleBack = () => {
        onClose();
        if (reservation.occasion === 'Vow Renewal') {
            setIsGuestVisible(true);
        } else {
            setIsCoupleVisible(true);
        }
    }

    function formatNumberWithCommas(number) {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
    const handleIncrement = () => {
        const incrementedValue = parseInt(reservation?.budget) + 100;
        setReservation({
            ...reservation,
            budget: incrementedValue !== '' ? parseInt(formatNumberWithCommas(incrementedValue).replace(/,/g, ''), 10) || 0 : 0,
        })
    };

    const handleDecrement = () => {
        const decrementedValue =
            parseInt(reservation?.budget) - 100;
        const newValue = Math.max(decrementedValue, 0);
        setReservation({
            ...reservation,
            budget: newValue !== '' ? parseInt(formatNumberWithCommas(newValue).replace(/,/g, ''), 10) || 0 : 0,
        })
    };

    const handleChecked = (e) => {
        setReservation({
            ...reservation,
            includes: e,
        })
    }

    return (
        <div>
            <Modal
                className='budget-maincontainer'
                open={visible}
                onCancel={onClose}
                maskClosable={false}
                footer={[]}
            >
                <div className='budget-modal'>
                    <ArrowBackIcon onClick={handleBack} className='back-arrow' />
                    <ProgressBar progress={progress} />
                    <div className='budget-modal-container' ref={topRef}>
                        <div className='guest-modal-subcontainer'>
                            <div className='guest-title'>Our budget is</div>
                            <div className='num-input-container'>

                                <div onClick={handleDecrement}>
                                    <Minus className='minus-icon' />
                                </div>

                                <Input className="guest-input budget"
                                    type="text"
                                    prefix={<span><Dollar className='dollar-icon' /></span>}
                                    onChange={e => {
                                        const values = e.target.value.replace(/,/g, '');
                                        if (!/^\d*$/.test(values))
                                            return;

                                        setReservation({
                                            ...reservation,
                                            budget: values,
                                        })
                                    }
                                    }
                                    value={formatNumberWithCommas(reservation.budget.toString())}
                                />
                                <div onClick={handleIncrement}>
                                    <Plus className='minus-icon' />
                                </div>

                            </div>
                        </div>
                        {reservation.occasion === 'Wedding' ||
                            reservation.occasion === 'Vow Renewal' ?
                            <>
                                <div className='guest-title'>and it includes...</div>
                                {includeItems.map((prop, key) => {
                                    return (
                                        <div className='budget-includes'>
                                            <Checkbox
                                                key={key}
                                                checked={reservation?.includes?.includes(prop.label)}
                                                onChange={() => handleChecked(prop.label)
                                                }
                                            >
                                                <div className='budget-label'>{prop.label}</div>
                                            </Checkbox>
                                            <div className='budget-description'>
                                                {prop.description}
                                            </div>
                                        </div>
                                    );
                                })}

                            </>
                            : null
                        }
                        <div className='budget-airfare'>
                            <div className='dot' >{`\u2022`}</div>
                            {reservation.occasion === 'Wedding' ? (
                                <div className='budget-airfare-title'>
                                    Budget does not include airfare
                                </div>
                            ) : (
                                <div className='budget-airfare-title'>
                                    Budget include airfare and accommodation
                                </div>
                            )}
                        </div>

                    </div>
                    <div className='next-btn-container'>
                        <ButtonSmall buttonstyle="default" className='destination-next-btn ' text="Next" handleClick={goNext} />
                    </div>

                </div>
            </Modal>
        </div>
    );
};

export default BudgetModal;