import React from "react";

const LogoutIcon = ({ claseName, fillColor }) => {
    return (
        <svg className={claseName} width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path className={fillColor} d="M13.75 4.74219V6.8125C13.75 7.35938 13.3203 7.75 12.8125 7.75H8.125V10.25H12.8125C13.3203 10.25 13.75 10.6797 13.75 11.1875V13.2969L18.0469 9L13.75 4.74219ZM20 9C20 9.46875 19.8047 9.89844 19.4922 10.2109L14.9609 14.7422C14.6094 15.0938 14.1406 15.25 13.6719 15.25C12.6562 15.25 11.875 14.4688 11.875 13.4531V12.125H8.125C7.07031 12.125 6.25 11.3047 6.25 10.25V7.75C6.25 6.73438 7.07031 5.875 8.125 5.875H11.875V4.58594C11.875 3.57031 12.6562 2.75 13.6719 2.75C14.1406 2.75 14.6094 2.94531 14.9609 3.29688L19.4922 7.82812C19.8047 8.14062 20 8.57031 20 9ZM6.5625 2.125H3.4375C2.53906 2.125 1.875 2.82812 1.875 3.6875V14.3125C1.875 15.2109 2.53906 15.875 3.4375 15.875H6.5625C7.07031 15.875 7.5 16.3047 7.5 16.8125C7.5 17.3594 7.07031 17.75 6.5625 17.75H3.4375C1.52344 17.75 0 16.2266 0 14.3125V3.6875C0 1.8125 1.52344 0.25 3.4375 0.25H6.5625C7.07031 0.25 7.5 0.679688 7.5 1.1875C7.5 1.73438 7.07031 2.125 6.5625 2.125Z" fill="white" />
        </svg>

    );
};

export default LogoutIcon;