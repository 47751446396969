import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { Modal } from 'antd';
import ButtonSmall from '../../Components/ButtonSmall';
import useStore from '../../providers/StoreProvider';
import UserPlaceholder from "../../assets/images/icons/profile.png";
import "./style.css";

const AdvisorList = ({ post, visible, onClose, setIsdestinationVisible, setDestinationPost, setDestinationAdvisor, reservation, setReservation, occasionpreference }) => {

    const { store } = useStore();
    const user = store.user;
    const topRef = useRef(null);
    const [reservedOccasion, setReservedOccasion] = useState('');

    useEffect(() => {
        if (user?.occasionpreferences?.length > 0) {
            let occasion = occasionpreference?.filter(tag => (user?.occasionpreferences?.indexOf(tag?.id) > -1));
            setReservedOccasion(occasion[0]?.name)
        }
        if (topRef.current) {
            topRef.current.scrollTop = 0;
        }
        let arrival = moment(new Date()).format("MM/DD/YYYY");
        let depature = moment(new Date()).add(6, 'days').format("MM/DD/YYYY");
        setReservation({
            ...reservation,
            occasion: reservedOccasion,
            arrival_date: arrival,
            departure_date: depature,
            city: '',
            flights: true,
            flexible_dates: true,
            persons: 2,
            noofchildrens: 1,
            rooms: 1,
            nights: 1,
            budget: 10000,
            type: '',
            message: '',
            desire_destination1: '',
            desire_destination2: '',
            guestsOwnRoom: true,
            includes: 'Everything'
        })
    }, [visible, user])

    const handleHotelDirectly = () => {
        onClose();
        setIsdestinationVisible(true);
    }
    const handleGetAnOffer = (item) => {
        onClose();
        setDestinationPost(post);
        setDestinationAdvisor(item);
        setIsdestinationVisible(true);
    }


    return (
        <div>
            <Modal
                className='advisor-list-maincontainer'
                open={visible}
                maskClosable={false}
                onCancel={onClose}
                footer={[]}
            >
                <div className='advisor-list' ref={topRef}>
                    <div className='advisor-list-maintitle'>{post?.title}</div>
                    {
                        post?.type !== 'basic' &&
                        post?.type !== 'destination' &&
                        post?.direct_requests_enabled &&
                        (<ButtonSmall buttonstyle="default" className='direct-hotel-addition-style' textStyle={'direct-hotel-text'} text="Request from Hotel Directly" handleClick={handleHotelDirectly} />)
                    }
                    {
                        (post?.type === 'basic' || post?.type === 'property') &&
                        (<div className='advisor-list-subtitle'>
                            {
                                post?.type !== 'basic' &&
                                post?.type !== 'destination' &&
                                post?.direct_requests_enabled &&
                                <div style={{ display: 'inline', fontWeight: '700' }}>or {' '}</div>
                            }
                            get private offer to include special rates, amenities, and upgrades at this hotel with our travel advisors
                        </div>)
                    }
                    {
                        post?.type === 'destination' &&
                        (<div className='advisor-list-subtitle'>
                            Get private quotes from romancetravel advisors specializing in{' '}{post?.title}
                        </div>)
                    }
                    <div className='advisor-list-main-container'>
                        {
                            post?.related?.filter(e => e.type === 'advisor').map((item, index) =>
                                <div className='advisor-list-container' key={index}>
                                    <div className='advisor-list-subcontainer'>
                                        <a href={`/${item?.type}/${item?.id}`}>
                                            {
                                                item?.featured_image?.sizes?.thumbnail ?
                                                    <img onClick={onClose} className='advisor-list-img' src={item?.featured_image?.sizes?.thumbnail} alt='advisor-list-img' />
                                                    :
                                                    <img className="advisor-list-img" src={UserPlaceholder} alt="advisor-list-img" />

                                            }

                                        </a>
                                        <div className='advisor-list-text-container'>
                                            <div className='advisor-list-title'>{item.title}</div>
                                            <div className='advisor-list-description'>{item.description}</div>
                                        </div>
                                    </div>
                                    <ButtonSmall buttonstyle="default" className='offer-addition-style' textStyle={'offer-text'} text="Get An Offer" handleClick={() => handleGetAnOffer(item)} />
                                </div>
                            )
                        }
                    </div>

                </div>
            </Modal>
        </div>
    );
};

export default AdvisorList;