import React, { useCallback, useEffect, useRef, useState } from "react";
import moment from "moment";
import { Input, Spin } from "antd";
import Deal from '../../api/models/Deal';
import Banner from "../../Components/Banner";
import Footer from "../../Components/Footer";
import NavBar from "../../Components/NavBar";
import DealModel from '../../api/models/Deal';
import Skeleton from "react-loading-skeleton";
import SendIcon from '@mui/icons-material/Send';
import MetaTags from "../../Components/MetaTags";
import ButtonTag from "../../Components/ButtonTag";
import { LoadingOutlined } from '@ant-design/icons';
import Metatag from "../../api/models/Metatag.js";
import Content from '../../Components/Content/index';
import { signInWithCustomToken } from "firebase/auth";
import useStore from "../../providers/StoreProvider";
import lovuLogo from "../../assets/LOVU_full_logo.svg"
import { db, auth } from "../../../src/firebaseConfig";
import ButtonSmall from "../../Components/ButtonSmall";
import Pin from "../../assets/icons/deal-list/pin.svg"
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Profile from "../../assets/images/icons/profile.png"
import PinTop from "../../assets/icons/deal-list/pintop.svg"
import Archieve from "../../assets/icons/deal-list/archieve.svg"
import bannerImage from "../../assets/images/ZentiumSearchBanner.jpeg"
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { DealsFilterTags, DealspinArchieveFilterTags } from "../../Helpers";
import { addDoc, collection, onSnapshot, orderBy, query, } from "firebase/firestore";
import "./style.css";


const Offer = ({ setProgress }) => {

    const params = useParams();
    let chatWindowRef = useRef(null);
    const { store, setStore } = useStore();
    const [seo, setSeo] = useState();
    const [data, setData] = useState([]);
    const [dealData, setDealData] = useState();
    const [loader, setLoader] = useState(false);
    const [temporaryData, setTemporaryData] = useState([]);
    const [dealLoader, setdealLoader] = useState(false);
    const [firebaseToken, setFirebaseToken] = useState();
    const [recievedMessages, setRecievedMessages] = useState([]);
    const [chatMessage, setChatMessage] = useState("");
    const [messageLoader, setMessageLoader] = useState(true);
    const [occasionFilters, setoccasionFilters] = useState({});
    const [currentFilter, setcurrentFilter] = useState('all');
    const [isMobile, setIsMobile] = useState(false);
    const [height, setHeight] = useState(null);
    const [isSticky, setIsSticky] = useState(false);
    const [noOfferText, setNoOfferText] = useState(false);
    const navigate = useNavigate();
    const user = store.user;
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const filter = searchParams.get('filter');
    let container = useRef(null);
    let scrollRef = useRef(null);


    useEffect(() => {
        Metatag.metaslug('offer').then(result =>
            setSeo(result)
        );
    }, []);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        setProgress(10)
        setProgress(20)
        setProgress(60)
        setProgress(100)
    }, [])

    function loadPage(first) {
        if (user?.email) {
            Deal.all(1).then(data => {
                const id = data?.data?.filter(function (item) { return !item.is_archive }).sort((a, b) => (a.is_pin < b.is_pin ? 1 : -1))[0]?.id;
                const type = 'all';
                if (id != undefined) {
                    if (window.innerWidth <= 768) {
                    } else {
                        setLoader(true)
                        if (params?.id == undefined && filter == undefined) {
                            navigate(`/offer/${id}?filter=${type}`);
                        }
                        if (data.data.length > 0 && dealData == undefined) {
                            setDealData({ id: id });
                        }
                    }
                } else {
                    setNoOfferText(true)
                }
                setData(data.data);
                getFilteredData(data.data);
                var filters = [];
                data.data.map((item, index) => {
                    filters.indexOf(item.request.occasion) === -1 &&
                        filters.push(item.request.occasion);
                });
                var cleanFilter = [];
                for (var i = 0; i < filters.length; ++i) {
                    var filterItem = {};
                    filterItem['name'] = filters[i] + ' Offers';
                    filterItem['value'] = filters[i];
                    cleanFilter.push(filterItem);
                }
                setoccasionFilters(cleanFilter);
                setLoader(false)
                if (first && id) {
                    setStore({ ...store, posts: 'all' });
                    navigate(`/offer/${id}?filter=${type}`)
                }
            })
        }
    }

    useEffect(() => {
        loadPage();
        setStore({ ...store, posts: currentFilter });
    }, [user, store.posts]);

    useEffect(() => {
        setcurrentFilter(filter == undefined ? 'all' : filter)
    }, [filter]);

    function getFilteredData(data) {
        if (currentFilter === 'all') {
            var tmpdata = data
                .filter(item => !item.is_archive)
                .sort((a, b) => (a.is_pin < b.is_pin ? 1 : -1));
            setTemporaryData(tmpdata);
        } else if (currentFilter === 'new') {
            var tmpdata = data
                .filter(item => !item.read_at)
                .sort((a, b) => (a.is_pin < b.is_pin ? 1 : -1));
            if (tmpdata.length > 0) {
                setTemporaryData(tmpdata);
            } else {
                setMessageLoader(false);
            }
        } else if (currentFilter === 'pin') {
            var tmpdata = data.filter(item => item.is_pin);
            setTemporaryData(tmpdata);
        } else if (currentFilter === 'archive') {
            var tmpdata = data
                .filter(item => item.is_archive)
                .sort((a, b) => (a.is_pin < b.is_pin ? 1 : -1));
            setTemporaryData(tmpdata);
        } else {
            var tmpdata = data
                .filter(item => item.request.occasion === currentFilter && !item.is_archive)
                .sort((a, b) => (a.is_pin < b.is_pin ? 1 : -1))
                .map(item => ({
                    ...item,
                    new_message: temporaryData.find(td => td.id === item.id)?.new_message || item.new_message
                }));
            setTemporaryData(tmpdata);
        }
    }

    const handlePinChange = async (index, value) => {
        if (temporaryData[index].is_pin) {
            const res = await DealModel.unpin(temporaryData[index].id);
            temporaryData[index].is_pin = res?.data?.data?.is_pin;
            setTemporaryData([...temporaryData]);
            loadPage();
        } else {
            const res = await DealModel.pin(temporaryData[index].id);
            temporaryData[index].is_pin = res?.data?.data?.is_pin;
            setTemporaryData([...temporaryData]);
            loadPage();
        }
    };

    const handleArchieveChange = async (index, value) => {
        if (temporaryData[index].is_archive) {
            const res = await DealModel.unarchive(temporaryData[index].id);
            temporaryData[index].is_archive = res?.data?.data?.is_archive;
            setTemporaryData([...temporaryData]);
            loadPage(true);
        } else {

            const res = await DealModel.archive(temporaryData[index].id);
            temporaryData[index].is_archive = res?.data?.data?.is_archive;
            setTemporaryData([...temporaryData]);
            loadPage(true);
        }
    };

    function formatNumberWithCommas(number) {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    useEffect(() => {
        setdealLoader(true)
        if (user?.email) {
            if (params?.id && params?.id !== ':id') {
                const dealId = params.id;
                fetchData(dealId);
            } else if (dealData && dealData.id) {
                if (!isMobile) {
                    const dealId = dealData.id;
                    fetchData(dealId);
                }
            } else {
                setdealLoader(false);
            }
            async function fetchData(dealId) {
                try {
                    const data = await DealModel.find(dealId);
                    const tagTypeArchieve = [data].filter(item => item.is_archive);
                    const tagTypePin = [data].filter(item => item.pin);
                    if (tagTypeArchieve.length > 0) {
                        setcurrentFilter("archive")
                        setStore({ ...store, posts: 'archive' });
                    } else if (tagTypePin.length > 0) {
                        setcurrentFilter("pin")
                        setStore({ ...store, posts: 'pin' });
                    }
                    setDealData(data);
                    if (data?.fireBaseToken) {
                        setFirebaseToken(data.fireBaseToken);
                    }
                } catch (error) {
                    console.error('Error fetching deal data:', error);
                } finally {
                    setdealLoader(false);
                }
            }
        }
    }, [params?.id, user]);

    const handleGetAllMessages = async () => {
        const unsubscribe = onSnapshot(
            query(
                collection(
                    db,
                    process.env.REACT_APP_CHAT_ID,
                    `request-${dealData?.request?.id}`,
                    "users",
                    `${dealData?.traveladvisor_uid}`,
                    "chatUsers",
                    `${dealData?.user_uid}`,
                    "messages"
                ),
                orderBy("timestamp")
            ),
            (snapshot) => {
                const mappedData = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    message: doc.data(),
                }));
                console.log(dealData.status == 'rejected', 'dealData.status ');

                const lastValue = mappedData[mappedData.length - 1];
                const alreadyReloaded = localStorage.getItem('pageReloaded');
                if (lastValue?.message?.message.includes(`OfferProvided-${dealData?.request?.id}`) && !alreadyReloaded && (dealData.status == 'rejected' || dealData.status == null)) {
                    localStorage.setItem('pageReloaded', 'true');
                    window.location.reload();
                    console.log(lastValue?.message?.message, 'last received');
                } else {
                    localStorage.removeItem('pageReloaded');
                }

                setRecievedMessages(
                    snapshot.docs.map((doc) => ({
                        id: doc.id,
                        message: doc.data(),
                    }))
                );
                setMessageLoader(false)
            },
            (error) => {
                console.error("Error fetching messages:", error);
            }
        );
        if (!recievedMessages.length) {
            setMessageLoader(false);
        }
        return unsubscribe;

    }

    useEffect(() => {
        if (firebaseToken) {
            signInWithCustomToken(auth, firebaseToken)
                .then((userCredential) => {
                    if (userCredential) {
                        handleGetAllMessages()
                    }
                })
        }

    }, [firebaseToken, dealData?.user_uid, dealData?.traveladvisor_uid])

    const handleBooked = async () => {

        if (!dealData?.booking_url) {
            await DealModel.booked(dealData.id).then((res) => {
                try {
                } catch (error) {
                    console.log(JSON.stringify(error))
                }
            })
        } else {
            window.open(dealData?.booking_url, "_blank")
        }
    };
    const handleDecline = async () => {
        await DealModel.reject(dealData.id).then((res) => {
            try {
                DealModel.find(dealData.id).then(data => {
                    setDealData(data);
                });
            } catch (error) {
                console.log(JSON.stringify(error))
            }
        });

    };
    const handleLateResponse = async () => {
        await DealModel.lateresponse(dealData?.id).then(() => {
        });
    };
    const handleNewMessageUpdate = async () => {
        await DealModel.newmessageupdate(dealData?.id).then(() => {
        });
    };

    const handleKeyPress = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            onSend();
            setChatMessage("");
        }
    };

    const onSend = async () => {
        if (chatMessage !== '' && chatMessage !== null) {
            try {
                if (dealData?.traveladvisor_uid && dealData?.user_uid) {
                    const usermsg = {
                        messageUserId: dealData?.user_uid,
                        message: chatMessage,
                        timestamp: new Date(),
                    };
                    setRecievedMessages(previousMessages => [
                        ...previousMessages,
                        usermsg,
                    ]);
                    // if we use local  and stage db collection is deals
                    // if we use live db collection is lovudeals
                    await addDoc(
                        collection(
                            db,
                            process.env.REACT_APP_CHAT_ID,
                            `request-${dealData?.request?.id}`,
                            "users",
                            `${dealData?.traveladvisor_uid}`,
                            "chatUsers",
                            `${dealData?.user_uid}`,
                            "messages"
                        ), usermsg
                    );

                    await addDoc(
                        collection(
                            db,
                            process.env.REACT_APP_CHAT_ID,
                            `request-${dealData?.request?.id}`,
                            "users",
                            `${dealData?.user_uid}`,
                            "chatUsers",
                            `${dealData?.traveladvisor_uid}`,
                            "messages"
                        ), usermsg
                    );
                    setChatMessage("");
                }
            } catch (error) {
                console.log(error);
            }
            const currentDate = new Date();
            const lastMessageTime = new Date(
                recievedMessages[recievedMessages.length - 1].message.timestamp['seconds'] * 1000 + recievedMessages[recievedMessages.length - 1].message.timestamp['nanoseconds'] / 1000000,
            );
            var timeDiff = (currentDate - lastMessageTime);
            var minsDiff = Math.round(((timeDiff % 86400000) % 3600000) / 60000);
            if (minsDiff > 15) {
                handleLateResponse();
            } else {
                handleNewMessageUpdate();
            }
            handleGetAllMessages();
        }
        setChatMessage("");
    };

    useEffect(() => {
        setHeight(container?.current?.clientHeight);
    }, [container]);

    useEffect(() => {
        if (isMobile) {
            scrollToBottom();
        }
        const handleScroll = () => {
            const containerHeight = container?.current?.clientHeight;
            const chatWindowScrollTop = chatWindowRef?.current?.clientHeight;
            if (chatWindowScrollTop > containerHeight) {
                chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
                setIsSticky(false);
            } else if (chatWindowScrollTop <= containerHeight) {
                setIsSticky(true);
            }
        }
        handleScroll();

    }, [recievedMessages, height, container, chatWindowRef, dealData, temporaryData, chatMessage]);

    const scrollToBottom = () => {
        if (!messageLoader) {
            if (chatWindowRef.current) {
                chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
            }
        }
    };

    const handleDealSelected = useCallback((item, index) => {
        try {
            if (!temporaryData || index < 0 || index >= temporaryData.length) {
                console.error('Invalid temporaryData or index');
                return;
            }
            setMessageLoader(true);
            const updatedData = [...temporaryData];
            updatedData[index] = {
                ...updatedData[index],
                new_message: false,
                read_at: new Date(),
            };

            setTemporaryData(updatedData);

            setStore(prevStore => ({
                ...prevStore,
                user: {
                    ...prevStore.user,
                    unread_deals_count: prevStore.user.unread_deals_count - 1
                }
            }));

            navigate(`/offer/${item?.id}?filter=${currentFilter}`);


        } catch (error) {
            console.error('Error handling deal selection: ', error);
        }


    }, [temporaryData, setTemporaryData, setStore, setDealData]);

    const skeletonNumber = [0, 1, 2, 3]
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

    return (
        <>
            <MetaTags
                title={seo?.title != null ? seo?.title : "LOVU: Offer"}
                description={seo?.description != null ? seo?.description : "Discover handpicked romantic destinations with LOVU. Connect with expert Romance Travel Advisors to plan the perfect honeymoon, anniversary, or getaway."}
                imageScr={seo?.meta_image[0]?.original_url != undefined ? seo?.meta_image[0]?.original_url : lovuLogo}
            />
            <div className="offer">
                <NavBar navigationDark={true} />
                {/* <Banner
                    isSearch={true}
                    imageSrc={bannerImage}
                /> */}
                <div className="pageContent">
                    {(data.length > 0 && !loader) ?
                        <div className="sub-content" ref={scrollRef}>
                            <h1 className='offer-title'>My Offer Requests</h1>
                            {
                                (filter == null || !isMobile) &&

                                <div className="navigation">
                                    {
                                        Object.keys(occasionFilters).length === 0 ?
                                            <>
                                                {
                                                    skeletonNumber.map((v) => <Skeleton height={50} width={180} borderRadius={'6px 6px 0 0'} />)
                                                }

                                            </>
                                            :
                                            <ButtonTag
                                                data={DealsFilterTags.concat(
                                                    occasionFilters,
                                                ).concat(DealspinArchieveFilterTags)}
                                                selected={store.posts}
                                                handleClick={e => {
                                                    setStore({ ...store, posts: e });
                                                    setcurrentFilter(e);
                                                    setTemporaryData([]);
                                                    let tmpdata = data;
                                                    if (e === 'all') {
                                                        tmpdata = tmpdata.filter(item => !item.is_archive);
                                                        setDealData(tmpdata[0])
                                                        if (tmpdata.length === 0) {
                                                            if (!isMobile) {
                                                                navigate(`/offer/?filter=${e}`)
                                                            }
                                                        } else {
                                                            setDealData(tmpdata[0])
                                                            if (!isMobile) {
                                                                navigate(`/offer/${tmpdata[0]?.id}?filter=${e}`)
                                                            }
                                                        }
                                                    } else if (e === 'new') {
                                                        tmpdata = tmpdata.filter(item => !item.read_at);
                                                        setDealData(tmpdata[0])
                                                        if (tmpdata.length === 0) {
                                                            if (!isMobile) {
                                                                navigate(`/offer/?filter=${e}`)
                                                            }
                                                        } else {
                                                            setDealData(tmpdata[0])
                                                            if (!isMobile) {
                                                                navigate(`/offer/${tmpdata[0]?.id}?filter=${e}`)
                                                            }
                                                        }
                                                    } else if (e === 'pin') {
                                                        tmpdata = tmpdata.filter(item => item.is_pin);
                                                        setDealData(tmpdata[0])
                                                        if (tmpdata.length === 0) {
                                                            if (!isMobile) {
                                                                navigate(`/offer/?filter=${e}`)
                                                            }
                                                        } else {
                                                            setDealData(tmpdata[0])
                                                            if (!isMobile) {
                                                                navigate(`/offer/${tmpdata[0]?.id}?filter=${e}`)
                                                            }
                                                        }
                                                    } else if (e === 'archive') {
                                                        tmpdata = tmpdata.filter(item => item.is_archive);
                                                        setDealData(tmpdata[0])
                                                        if (tmpdata.length === 0) {
                                                            if (!isMobile) {
                                                                navigate(`/offer/?filter=${e}`)
                                                            }
                                                        } else {
                                                            setDealData(tmpdata[0])
                                                            if (!isMobile) {
                                                                navigate(`/offer/${tmpdata[0]?.id}?filter=${e}`)
                                                            }
                                                        }
                                                    } else if (occasionFilters.some((filter) => filter.value === e)) {
                                                        tmpdata = tmpdata.filter((item) => item.request.occasion === e && !item.is_archive);
                                                        setDealData(tmpdata[0])
                                                        if (tmpdata.length === 0) {
                                                            if (!isMobile) {
                                                                navigate(`/offer/?filter=${e}`)
                                                            }
                                                        } else {
                                                            setDealData(tmpdata[0])
                                                            if (!isMobile) {
                                                                navigate(`/offer/${tmpdata[0]?.id}?filter=${e}`)
                                                            }
                                                        }
                                                    }

                                                    tmpdata.sort((a, b) => a.is_pin < b.is_pin ? 1 : -1);
                                                    setTemporaryData(tmpdata);
                                                }}
                                            />
                                    }
                                </div>
                            }
                            {
                                (params?.id && isMobile) &&
                                <div className="back-page-container" onClick={() => navigate('/offer')}>
                                    <ArrowBackIcon />
                                    <div className="back-arrow-text">Offers Page</div>
                                </div>
                            }
                            <div className="offer-main-container" ref={scrollRef}>
                                {
                                    temporaryData.length <= 0 && <div
                                        className="no-offer-found">
                                        No Offers Found
                                    </div>
                                }
                                {
                                    (filter == null || !isMobile) &&
                                    <>

                                        <div className="offer-dealist-main-container">
                                            {
                                                temporaryData.map((item, index) => {

                                                    return (
                                                        <>
                                                            <div ref={container} onClick={() => handleDealSelected(item, index)} key={index}>
                                                                <div className={`offer-dealist-container-${params?.id && dealData?.id == item?.id ? 'selected' : ""}`}
                                                                    style={{ opacity: item?.new_message ? 1 : "" }}
                                                                >

                                                                    <div className="deal-card">
                                                                        <div className="deal-content">
                                                                            <span className="offerType">
                                                                                {item.request.occasion}
                                                                            </span>{' '}
                                                                            offer from{' '}
                                                                            <span className="offer-author">
                                                                                {item.post.title}
                                                                            </span>
                                                                        </div>
                                                                        {item.is_pin ?
                                                                            <img src={Pin} alt='No images1 found' className="pintop-icon"
                                                                                onClick={() => handlePinChange(index, 'pin')} />
                                                                            :
                                                                            <img src={PinTop} alt='No images1 found' className="pintop-icon" onClick={() => handlePinChange(index)} />
                                                                        }
                                                                        {item.is_archive ?
                                                                            <div class="unarchieve-tooltip" onClick={() => handleArchieveChange(index)} > <img src={Archieve} alt='No images1 found' className="unarchive-icon" /><span class="tooltiptext">Unarchieve</span></div>
                                                                            :
                                                                            <div class="tooltip" onClick={() => handleArchieveChange(index)}> <img src={Archieve} alt='No images1 found' className="archive-icon" /><span class="tooltiptext">archieve</span></div>
                                                                        }
                                                                    </div>
                                                                    <div className="deal-time">
                                                                        <h1 className="deal-time">{moment(
                                                                            item?.created_at,
                                                                        ).format(
                                                                            'MM/DD/YYYY [at] h:mmA',
                                                                        )}</h1>
                                                                    </div>

                                                                    <div className="Tag-container">
                                                                        {!item.read_at && (
                                                                            <h1 className="tagText">New Offer</h1>
                                                                        )}
                                                                        {(item?.new_message) ? (
                                                                            <h1 className="tagText">New Message</h1>
                                                                        ) : null}

                                                                    </div>



                                                                </div>

                                                            </div>
                                                        </>
                                                    )
                                                })


                                            }
                                        </div>
                                    </>
                                }

                                {
                                    (filter != null || !isMobile) &&
                                    <div className={"offer-deal-main-container"}
                                    >
                                        <>
                                            {
                                                dealLoader ?
                                                    <div className="offer-deal-container">
                                                        <div className="offerTitleCard">
                                                            <div className="offerLandingImage" > <Skeleton height={35} width={35} borderRadius={35} /></div>
                                                            <h1 className="offerTitleHeading"> <Skeleton height={15} width={150} /></h1>
                                                        </div>
                                                        <div className="offerMainHeadingwrap">
                                                            <h1 className="offerMainHeading">
                                                                <Skeleton height={15} width={150} />
                                                            </h1>
                                                        </div>
                                                        <h1 className="offerDate">
                                                            <Skeleton height={15} width={150} />
                                                        </h1>
                                                        <div><Skeleton height={5} className="line" /></div>
                                                        <div className="MainTextView">

                                                            <h2 className="subHeading">
                                                                <Skeleton height={12} />
                                                            </h2>
                                                        </div>
                                                        <div className="MainTextView">

                                                            <h2 className="subHeading">
                                                                <Skeleton height={12} />
                                                            </h2>
                                                        </div>
                                                        <div className="MainTextView">

                                                            <h2 className="subHeading">
                                                                <Skeleton height={12} />
                                                            </h2>
                                                        </div>
                                                        <div className="MainTextView">

                                                            <h2 className="subHeading">
                                                                <Skeleton height={12} />
                                                            </h2>
                                                        </div>
                                                        <div className="MainTextView">

                                                            <h2 className="subHeading">
                                                                <Skeleton height={15} />
                                                            </h2>
                                                        </div>
                                                    </div>
                                                    :
                                                    temporaryData.length > 0 ?
                                                        <>
                                                            <div className={`deal-content-scrollable-${isSticky ? 'sticky' : ''}`}
                                                                ref={chatWindowRef}
                                                            >

                                                                <div className="offer-deal-container">
                                                                    <div className="offerTitleCard">
                                                                        {
                                                                            dealData?.post ?
                                                                                dealData?.post?.media?.featured_image?.original_url ?
                                                                                    <img src={dealData?.post?.media?.featured_image?.original_url} alt='No images1 found' className="offerLandingImage" />
                                                                                    :
                                                                                    <img src={Profile} alt='No images1 found' className="offerLandingImage" />
                                                                                :
                                                                                <div className="offerLandingImage" > <Skeleton height={35} width={35} borderRadius={35} /></div>
                                                                        }
                                                                        <h1 className="offerTitleHeading">{dealData?.post?.title}</h1>
                                                                    </div>
                                                                    <div className="offerMainHeadingwrap">
                                                                        {
                                                                            dealData?.request?.occasion ?
                                                                                <>
                                                                                    <h1 className="offerMainHeading">
                                                                                        {dealData?.request?.occasion}
                                                                                    </h1>
                                                                                    <h2 className="offerText"> Offer</h2>
                                                                                </>
                                                                                :
                                                                                null
                                                                        }
                                                                    </div>
                                                                    {
                                                                        dealData?.created_at ?
                                                                            <h1 className="offerDate">
                                                                                {moment(dealData?.created_at).format(
                                                                                    'MM/DD/YYYY [at] h:mmA',
                                                                                )}
                                                                            </h1>
                                                                            :
                                                                            null
                                                                    }
                                                                    {dealData?.request?.occasion ?
                                                                        <div className="closeModalLine" />
                                                                        : null
                                                                    }
                                                                    {dealData?.request?.arrival_date != null ? (
                                                                        dealData?.request?.departure_date != null ? (
                                                                            <div className="MainTextView">
                                                                                <h1 className="textHeading">
                                                                                    Travel Dates:
                                                                                </h1>
                                                                                <h2 className="subHeading">
                                                                                    {moment(dealData?.request?.arrival_date).format(
                                                                                        'MM/DD/YYYY',
                                                                                    )}{' '}
                                                                                    -{' '}
                                                                                    {moment(
                                                                                        dealData?.request?.departure_date,
                                                                                    ).format('MM/DD/YYYY')}
                                                                                </h2>
                                                                            </div>
                                                                        ) : null
                                                                    ) : null}
                                                                    {dealData?.request?.flexible_dates != null ? (
                                                                        <div className="MainTextView">
                                                                            <h1 className="textHeading">
                                                                                Flexible Dates:
                                                                            </h1>
                                                                            <h2 className="subHeading">
                                                                                {dealData?.request?.flexible_dates == 1
                                                                                    ? 'YES'
                                                                                    : 'NO'}
                                                                            </h2>
                                                                        </div>
                                                                    ) : null}
                                                                    {dealData?.request?.persons != null ? (
                                                                        <div className="MainTextView">
                                                                            <h1 className="textHeading">
                                                                                Guests:
                                                                            </h1>
                                                                            <h2 className="subHeading">
                                                                                {dealData?.request?.persons}
                                                                            </h2>
                                                                        </div>
                                                                    ) : null}

                                                                    {dealData?.request?.persons != null ? (
                                                                        <div className="MainTextView">
                                                                            <h1 className="textHeading">
                                                                                Leaving From:
                                                                            </h1>
                                                                            <h2 className="subHeading">
                                                                                {dealData?.request?.city}
                                                                            </h2>
                                                                        </div>
                                                                    ) : null}

                                                                    {dealData?.request?.occasion !== 'Wedding' &&
                                                                        dealData?.request?.occasion !== 'Vow Renewal' ? (
                                                                        <div className="MainTextView">
                                                                            {
                                                                                dealData?.request?.rooms ?
                                                                                    <><h1 className="textHeading">
                                                                                        # of rooms
                                                                                    </h1>
                                                                                        <h2 className="subHeading">
                                                                                            {dealData?.request?.rooms}
                                                                                        </h2></>
                                                                                    :
                                                                                    null
                                                                            }

                                                                        </div>
                                                                    ) : null}

                                                                    {dealData?.request?.noofchildrens != null ? (
                                                                        dealData?.request?.occasion === 'Wedding' ||
                                                                            dealData?.request?.occasion === 'Vow Renewal' ? (
                                                                            <div className="MainTextView">
                                                                                <h1 className="textHeading">
                                                                                    Childrens
                                                                                </h1>
                                                                                <h2 className="subHeading">
                                                                                    {dealData?.request?.noofchildrens}
                                                                                </h2>
                                                                            </div>
                                                                        ) : null
                                                                    ) : null}

                                                                    {dealData?.request?.nights != null ? (
                                                                        dealData?.request?.occasion === 'Wedding' ||
                                                                            dealData?.request?.occasion === 'Vow Renewal' ? (
                                                                            <div className="MainTextView">
                                                                                <h1 className="textHeading">
                                                                                    Nights
                                                                                </h1>
                                                                                <h2 className="subHeading">
                                                                                    {dealData?.request?.nights}
                                                                                </h2>
                                                                            </div>) : null
                                                                    ) : null}

                                                                    {dealData?.request?.guestsOwnRoom != null ? (
                                                                        <div className="MainTextView">
                                                                            <h1 className="textHeading">
                                                                                Guests Pay for own rooms?
                                                                            </h1>
                                                                            <h2 className="subHeading">
                                                                                {dealData?.request?.guestsOwnRoom == 1
                                                                                    ? 'YES'
                                                                                    : 'NO'}
                                                                            </h2>
                                                                        </div>
                                                                    ) : null}

                                                                    {dealData?.request?.budget != null ? (
                                                                        <div className="MainTextView">
                                                                            <h1 className="textHeading">
                                                                                Our Budget:
                                                                            </h1>
                                                                            {dealData?.request?.budget?.indexOf('$') !== -1 ? (
                                                                                <h2 className="subHeading">
                                                                                    {dealData.request.budget}
                                                                                </h2>
                                                                            ) : (
                                                                                <h2 className="subHeading">
                                                                                    $
                                                                                    {formatNumberWithCommas(
                                                                                        dealData.request.budget,
                                                                                    )}
                                                                                </h2>
                                                                            )}
                                                                        </div>
                                                                    ) : null}

                                                                    {dealData?.request?.includes !== null &&
                                                                        (dealData?.request?.occasion === 'Wedding' ||
                                                                            dealData?.request?.occasion === 'Vow Renewal') ? (
                                                                        <div className="MainTextView">
                                                                            <h1 className="textHeading">
                                                                                Budget includes:
                                                                            </h1>
                                                                            {dealData?.request?.includes?.includes('Everything') ? (
                                                                                <h2 className="subHeadingTwo">
                                                                                    <span className="subHeadingBudget">
                                                                                        Everything
                                                                                    </span>
                                                                                    - Includes venue and most vendors like
                                                                                    decorations, floral, audio & video
                                                                                    equipment, photography, entertainment, etc.
                                                                                </h2>
                                                                            ) : null}
                                                                            {dealData?.request?.includes?.includes('Medium') ? (
                                                                                <h2 className="subHeadingTwo">
                                                                                    <span className="subHeadingBudget">
                                                                                        Medium{' '}
                                                                                    </span>{' '}
                                                                                    - Includes venue, catering, beverages and
                                                                                    essential rentals.
                                                                                </h2>
                                                                            ) : null}
                                                                            {dealData?.request?.includes?.includes('Minimal') ? (
                                                                                <h2 className="subHeadingTwo">
                                                                                    <span className="subHeadingBudget">
                                                                                        Minimal{' '}
                                                                                    </span>{' '}
                                                                                    - Includes venue and essential rental only.
                                                                                </h2>
                                                                            ) : null}
                                                                        </div>
                                                                    ) : null}

                                                                    {dealData?.request?.message != null ? (
                                                                        <div className="MainTextView">
                                                                            <h1 className="textHeading">
                                                                                Additional Details:
                                                                            </h1>
                                                                            <h2 className="subHeading">
                                                                                {dealData?.request?.message}
                                                                            </h2>
                                                                        </div>
                                                                    ) : null}

                                                                </div>

                                                                <div className="ChatContainer" >
                                                                    {messageLoader ? (
                                                                        <Spin indicator={antIcon} />
                                                                    ) :
                                                                        <>
                                                                            {recievedMessages?.map((item, index) => {
                                                                                return item?.message?.message?.startsWith(`OfferProvided-${dealData?.request?.id}`) ?
                                                                                    item?.message?.message.split('-').pop() == dealData?.no_of_offer ? (
                                                                                        <>
                                                                                            {dealData?.is_provided != null && dealData.status != 'rejected' ? (
                                                                                                <div className="bookOfferContainer">
                                                                                                    <div className="bookOfferCard">
                                                                                                        <h1 className="CardTitle"> {dealData?.heading_1}</h1>
                                                                                                        {dealData?.text_1 != null ? (
                                                                                                            <div className="smallText">
                                                                                                                Enjoy $5,000 off of your wedding package at applicable hotels in Cancun, Mexico!
                                                                                                                <Content
                                                                                                                    data={dealData?.text_1}
                                                                                                                />
                                                                                                            </div>
                                                                                                        ) : null}
                                                                                                        <h1 className="mediumText">  {dealData?.heading_2}</h1>
                                                                                                        {dealData?.text_2 != null ? (
                                                                                                            <div className="smallText">
                                                                                                                <Content
                                                                                                                    data={dealData?.text_2}
                                                                                                                />
                                                                                                            </div>
                                                                                                        ) : null}

                                                                                                        {dealData?.booking_text != null ? (
                                                                                                            <h1 className="mediumText"> How To Book This Offer</h1>
                                                                                                        ) : null}

                                                                                                        {dealData?.booking_text != null ? (
                                                                                                            <div className="smallText">
                                                                                                                <Content
                                                                                                                    data={dealData?.booking_text}
                                                                                                                />
                                                                                                            </div>
                                                                                                        ) : null}

                                                                                                        {dealData?.terms != null ? (
                                                                                                            <h1 className="mediumText">Terms and Condition</h1>
                                                                                                        ) : null}
                                                                                                        {dealData?.terms != null ? (
                                                                                                            <div className="smallText">
                                                                                                                <Content
                                                                                                                    data={dealData?.terms}
                                                                                                                />
                                                                                                            </div>
                                                                                                        ) : null}
                                                                                                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                                                                                            <div className="offer-book-button" >

                                                                                                                {dealData?.booking_url != null ? (
                                                                                                                    <div className="basePadding">
                                                                                                                        <ButtonSmall textStyle="search-btn" buttonstyle="default" text="Book This Offer" handleClick={handleBooked} />
                                                                                                                    </div>
                                                                                                                ) : <div className="basePadding">
                                                                                                                    <ButtonSmall textStyle="search-btn" buttonstyle="default" text={dealData?.status == 'booked' ? "offer Accepted" : "Accept Offer"} handleClick={handleBooked} />

                                                                                                                </div>}
                                                                                                                {
                                                                                                                    dealData?.status == 'booked' ? null :
                                                                                                                        <div
                                                                                                                            className="declineOfferContainer" onClick={handleDecline}>
                                                                                                                            <h1 className="declineTextCard">
                                                                                                                                DECLINED OFFER
                                                                                                                            </h1>
                                                                                                                        </div>
                                                                                                                }
                                                                                                            </div>
                                                                                                        </div>

                                                                                                    </div>
                                                                                                </div>)
                                                                                                :
                                                                                                <div
                                                                                                    className="OfferDeclinedText" >
                                                                                                    OFFER DECLINED
                                                                                                </div>
                                                                                            }
                                                                                        </>)
                                                                                        :
                                                                                        <div
                                                                                            className="OfferDeclinedText">
                                                                                            OFFER DECLINED
                                                                                        </div>
                                                                                    :
                                                                                    <>
                                                                                        <div className="messageContainer">
                                                                                            {item.message.messageUserId == dealData?.traveladvisor_uid ?
                                                                                                <div className="sendMessage">
                                                                                                    {
                                                                                                        dealData?.post?.media?.featured_image?.original_url ?
                                                                                                            <img src={dealData?.post?.media?.featured_image?.original_url} alt='No images1 found' className="offerLandingImage" />

                                                                                                            :
                                                                                                            <img src={Profile} alt='No images1 found' className="offerLandingImage" />

                                                                                                    }
                                                                                                    <h1 className="senderMessageText">{item.message.message}</h1>
                                                                                                </div>
                                                                                                :
                                                                                                <div className="receivedMessage">
                                                                                                    <h1 className="recieverMessageText">{item.message.message}</h1>
                                                                                                </div>

                                                                                            }
                                                                                        </div>
                                                                                    </>
                                                                            })}
                                                                        </>
                                                                    }

                                                                </div>
                                                            </div>
                                                        </>
                                                        : null
                                            }
                                            {
                                                temporaryData.length > 0 &&
                                                <div className="ChatInputContainer">
                                                    <Input className="chat-input-field"
                                                        type="text" placeholder="Ask questions about your offer"
                                                        suffix={<span onClick={onSend}><SendIcon className="send-icon" /></span>}
                                                        onChange={(e) => setChatMessage(e.target.value)}
                                                        onPressEnter={handleKeyPress}
                                                        onClick={() => onSend()}
                                                        value={chatMessage} />
                                                </div>
                                            }

                                        </>
                                    </div>
                                }

                            </div>
                        </div>
                        :
                        !noOfferText ?
                            <div className="sub-content">
                                <h1 className='offer-title'>My Offer Requests</h1>
                                <div className="navigation">
                                    <>
                                        {
                                            skeletonNumber.map((v) => <Skeleton height={44} width={140} borderRadius={'6px 6px 0 0'} />)
                                        }
                                    </>

                                </div>
                                <div className="offer-main-container">
                                    <div className="offer-dealist-main-container" style={{ marginTop: 20 }}>

                                        <>
                                            {
                                                skeletonNumber.map((v, i) => {
                                                    return <div className="offer-dealist-container" key={i}>
                                                        <div className="deal-card">
                                                            <div className="deal-content">
                                                                <span>

                                                                    <Skeleton height={10} className="offerTypeSkeleton" />
                                                                </span>

                                                            </div>
                                                            <div className="pin-icon">

                                                                <Skeleton height={35} width={35} borderRadius={35} style={{ marginRight: 10 }} />
                                                            </div>
                                                            <div className="archive-icon">

                                                                <Skeleton height={35} width={35} borderRadius={35} />
                                                            </div>

                                                        </div>
                                                        <div className="deal-time">
                                                            <h1 className="deal-time"> <Skeleton height={10} width={200} /></h1>
                                                        </div>

                                                        <div className="Tag-container">
                                                            <Skeleton height={10} width={200} />

                                                        </div>
                                                    </div>
                                                })
                                            }


                                        </>

                                    </div>
                                    {
                                        !isMobile && (
                                            <div className="offer-deal-main-container" >
                                                <div className="offer-deal-container">
                                                    <div className="offerTitleCard">
                                                        <div className="offerLandingImage" > <Skeleton height={35} width={35} borderRadius={35} /></div>
                                                        <h1 className="offerTitleHeading"> <Skeleton height={15} width={150} /></h1>
                                                    </div>
                                                    <div className="offerMainHeadingwrap">
                                                        <h1 className="offerMainHeading">
                                                            <Skeleton height={15} width={150} />
                                                        </h1>
                                                    </div>
                                                    <h1 className="offerDate">
                                                        <Skeleton height={15} width={150} />
                                                    </h1>
                                                    <div><Skeleton height={5} width={650} /></div>
                                                    <div className="MainTextView">
                                                        <h1 className="textHeading">
                                                            <Skeleton height={15} width={200} style={{ marginTop: 10 }} />
                                                        </h1>
                                                        <h2 className="subHeading">
                                                            <Skeleton height={15} width={300} />
                                                        </h2>
                                                    </div>
                                                    <div className="MainTextView">
                                                        <h1 className="textHeading">
                                                            <Skeleton height={15} width={200} style={{ marginTop: 10 }} />
                                                        </h1>
                                                        <h2 className="subHeading">
                                                            <Skeleton height={15} width={300} />
                                                        </h2>
                                                    </div>
                                                    <div className="MainTextView">
                                                        <h1 className="textHeading">
                                                            <Skeleton height={15} width={200} style={{ marginTop: 10 }} />
                                                        </h1>
                                                        <h2 className="subHeading">
                                                            <Skeleton height={15} width={300} />
                                                        </h2>
                                                    </div>
                                                    <div className="MainTextView">
                                                        <h1 className="textHeading">
                                                            <Skeleton height={15} width={200} style={{ marginTop: 10 }} />
                                                        </h1>
                                                        <h2 className="subHeading">
                                                            <Skeleton height={15} width={300} />
                                                        </h2>
                                                    </div>
                                                    <div className="MainTextView">
                                                        <h1 className="textHeading">
                                                            <Skeleton height={15} width={200} style={{ marginTop: 10 }} />
                                                        </h1>
                                                        <h2 className="subHeading">
                                                            <Skeleton height={15} width={300} />
                                                        </h2>
                                                    </div>
                                                </div>

                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                            :

                            <div
                                className="no-offer">
                                No Offers Found
                            </div>
                    }
                </div>
                <Footer className="design-component-instance-node-3" />
            </div >
        </>
    );
};

export default Offer;