import { message } from 'antd';
import axios from 'axios';

export default class BaseModel {
    attributes = [];

    static filters = {};



    constructor(data = {}) {
        this.setAttributes(data);
        BaseModel.filters = {};
    }

    setAttributes(attributes) {
        this.attributes = attributes;
        Object.assign(this, attributes);
    }

    get getAttributes() {
        return this.attributes;
    }

    static async all(page = 1, cancelToken = null) {
        return axios
            .get(`/${this.modelName}`, {
                params: {
                    page: page,
                    ...this.filters,
                },
            })
            .then(({ data }) => ({
                ...data,
                data: data.data.map(i => new this(i)),
            })).catch((e) => message.error(e.message))
    }

    static async hotelfilter(page = 1, cancelToken = null, types) {
        return axios
            .get(`/${this.modelName}`, {
                params: {
                    page: page,
                    per_page: 200,
                    types: 'property'
                },
            })
            .then(({ data }) => ({
                ...data,
                data: data.data.map(i => new this(i)),
            })).catch((e) => message.error(e.message))
    }

    static async alls(page = 1, types) {
        return axios
            .get(`/${this.modelName}`, {
                params: {
                    page: page,
                    types: types
                },
            })
            .then(({ data }) => ({
                ...data,
                data: data.data.map(i => new this(i)),
            }));
    }
    static async tagfilter(page = 1, tags) {
        return axios
            .get(`/${this.modelName}`, {
                params: {
                    page: page,
                    tags: tags
                },
            })
            .then(({ data }) => ({
                ...data,
                data: data.data.map(i => new this(i)),
            }));
    }

    static async find(id, cancelToken = null) {
        let { data } = await axios.get(`/${this.modelName}/${id}`);

        return new this(data.data);
    }

    static async showbyname(id, cancelToken = null) {
        let { data } = await axios.get(`/${this.modelName}/showbyname/${id}`);
        return new this(data.data);
    }
    static async showarticles(id) {
        let { data } = await axios.get(`/${this.modelName}/showbyid/${id}`);
        return new this(data);
    }
    static async showarticlebyslug(slug) {
        let { data } = await axios.get(`/${this.modelName}/showbyslug/${slug}`);
        return new this(data);
    }
    static async metaslug(slug) {
        let { data } = await axios.get(`/${this.modelName}/showbyslug/${slug}`);
        return new this(data);
    }
    static async displayHotel(ip, requestData, corelationId) {
        const { data } = await axios.post(`/${this.modelName}/displayhotels/${ip}/${corelationId}`, { requestData });
        return new this(data);
    }
    static async availabilityresult(ip, requestdatafortoken, corelationId) {
        let { data } = await axios.post(`/${this.modelName}/availabilityresult/${ip}/${corelationId}`, { requestdatafortoken });
        return new this(data);
    }

    static async fetchHotelDatabynextresultkey(token, nextresultkey, ip, corelationId) {
        let { data } = await axios.post(`/${this.modelName}/fetchHotelDatabynextresultkey/${token}/${nextresultkey}/${ip}/${corelationId}`);
        return new this(data);
    }

    static async fetchhotelcontent(id, ip, correlationId, channelId) {
        let { data } = await axios.post(`/${this.modelName}/fetchhotelcontent/${id}/${ip}/${correlationId}/${channelId}`);
        return new this(data);
    }
    static async roomsAndRate(id, token, ipaddress, correlationId) {
        let { data } = await axios.post(`/${this.modelName}/roomsandrate/${id}/${token}/${ipaddress}/${correlationId}`);
        return new this(data);
    }

    static async priceByRecommendation(id, token, ipaddress, recommendationId, correlationId) {
        let { data } = await axios.post(`/${this.modelName}/priceByRecommendation/${id}/${token}/${ipaddress}/${recommendationId}/${correlationId}`);
        return new this(data);
    }
    static async getCorrelationId() {
        let { data } = await axios.post(`/${this.modelName}/getCorrelationId`);
        return data;
    }

    static async booking(id, token, ipaddress, correlationId, body, hoteldata) {
        let requestbodydata = {
            body,
            hoteldata
        }
        let tokendata = localStorage.getItem('data');
        let { data } = await axios.post(`/${this.modelName}/${tokendata == null ? 'unauthbooking' : 'booking'}/${id}/${token}/${ipaddress}/${correlationId}`, { requestbodydata });
        return new this(data);
    }
    static async cancellation(ipaddress, correlationId, cancellationid) {
        let { data } = await axios.post(`/${this.modelName}/cancellation/${ipaddress}/${correlationId}/${cancellationid}`);
        return new this(data);
    }
    static async getreservation(userid) {
        let { data } = await axios.post(`/${this.modelName}/getreservation/${userid}`);
        return new this(data);
    }
    static async getbookingdetail(ipaddress, correlationId, body) {
        let { data } = await axios.post(`/${this.modelName}/getbookingdetail/${ipaddress}/${correlationId}`, { body });
        return new this(data);
    }
    static async itinearary() {
        let { data } = await axios.post(`/${this.modelName}/itineararys`);
        return new this(data);
    }

    async update(attributes) {
        let { data } = await axios.patch(
            `/${this.modelName}/${this.id}`,
            attributes,
        );
        this.setAttributes(data.data);
    }

    async delete() {
        await axios.delete(`/${this.modelName}/${this.id}`);
    }

    static async delete(id) {
        await axios.delete(`/${this.modelName}/${id}`);
    }

    static async like(id) {
        await axios.post(`/${this.modelName}/${id}/likes`);
    }
    static async pin(id) {
        return await axios.post(`/${this.modelName}/pin/${id}`);
    }

    static async unpin(id) {
        return await axios.post(`/${this.modelName}/unpin/${id}`);
    }
    static async archive(id) {
        return await axios.post(`/${this.modelName}/archive/${id}`);
    }

    static async unarchive(id) {
        return await axios.post(`/${this.modelName}/unarchive/${id}`);
    }

    static async lateresponse(id) {
        return await axios.post(`/${this.modelName}/lateresponse/${id}`);
    }
    static async newmessageupdate(id) {
        return await axios.post(`/${this.modelName}/newmessageupdate/${id}`);
    }
    static async reject(id) {
        return await axios.post(`/${this.modelName}/reject/${id}`);
    }
    static async booked(id) {
        return await axios.post(`/${this.modelName}/booked/${id}`);
    }
    static async dislike(id) {
        await axios.delete(`/${this.modelName}/${id}/likes`);
    }

    copy(data = null) {
        if (data) {
            this.setAttributes(data);
        }
        return Object.assign(Object.create(Object.getPrototypeOf(this)), this);
    }

    static setFilter(key, value) {
        this.filters[key] = value;
        return this;
    }

    static when(condition, callback) {
        if (condition) {
            return callback(this) ?? this;
        }
        return this;
    }
}
