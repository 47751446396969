
import React from "react";

const WelcomeLogo = ({ className }) => {
    return (
        <svg className={className} width="213" height="291" viewBox="0 0 213 291" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M106.878 212.084C126.398 212.084 142.222 207.732 142.222 202.363C142.222 196.995 126.398 192.643 106.878 192.643C87.3571 192.643 71.5327 196.995 71.5327 202.363C71.5327 207.732 87.3571 212.084 106.878 212.084Z" fill="#EEEEEE" />
            <path fillRule="evenodd" clipRule="evenodd" d="M106.888 35.2128C94.8134 11.7332 79.4011 0.00394428 60.6509 0.00394428C25.7246 0.00394428 0.853485 32.9527 0.853485 69.5845C0.28847 84.6935 9.97744 120.007 51.4328 150.675C79.0767 171.12 97.4606 188.06 106.574 201.484C114.767 192.454 133.548 175.525 162.919 150.675C206.979 113.405 212.159 86.4827 212.912 69.5845C211.751 30.9961 188.041 0.401548 155.176 0.00394428C135.264 -0.247174 119.161 11.4926 106.888 35.2128Z" fill="url(#paint0_radial)" />
            <path fillRule="evenodd" clipRule="evenodd" d="M43.9829 35.3486C61.3937 35.3486 77.413 41.1767 90.1572 50.9389C91.2349 51.7655 90.6699 53.471 89.3097 53.5442L78.8255 53.9C49.0785 54.9777 34.7962 56.1182 35.9785 57.3215C37.2969 58.6607 57.8781 60.2407 97.7221 62.0822C100.537 62.2183 103.184 63.5576 104.837 65.7967C108.75 71.0806 111.963 76.8982 114.348 83.1029C114.924 84.5991 113.877 86.2209 112.256 86.3465L108.688 86.6081C97.6175 87.4661 92.3231 88.3764 92.7939 89.3181C93.3171 90.3644 100.913 91.5991 115.593 93.0325C116.671 93.1162 117.581 93.88 117.791 94.9264C118.732 99.593 119.245 104.417 119.245 109.345C119.245 109.92 119.235 110.506 119.224 111.071C119.193 112.295 118.241 113.31 116.995 113.415L114.327 113.645C107.181 114.294 103.54 114.963 103.404 115.654C103.257 116.428 107.516 117.171 116.169 117.883C117.581 117.998 118.575 119.274 118.366 120.655C116.776 130.815 113.082 140.295 107.746 148.655C80.395 155.32 60.4416 155.833 49.4761 149.408C9.00425 119.055 0.298831 84.0237 0.863847 69.1135C0.863847 61.3393 1.99388 53.7326 4.10746 46.5757C15.6589 39.4712 29.3343 35.3486 43.9829 35.3486Z" fill="url(#paint1_linear)" />
            <path fillRule="evenodd" clipRule="evenodd" d="M122.468 70.8402C154.831 72.0644 143.447 122.34 108.646 127.248C151.723 127.248 165.723 79.9537 145.498 61.8523C136.74 61.8732 128.39 65.1273 122.468 70.8402Z" fill="#B21C68" />
            <path fillRule="evenodd" clipRule="evenodd" d="M182.433 8.24902C201.622 20.8782 212.127 43.3218 212.923 70.0136L212.86 71.1436C212.326 80.8116 210.077 93.7861 200.157 109.617C203.056 95.3451 200.701 83.6681 192.509 74.6488C207.932 55.8463 201.706 23.358 183.458 9.28488L182.433 8.24902Z" fill="#971356" />
            <path fillRule="evenodd" clipRule="evenodd" d="M126.82 60.22C151.828 32.2726 203.38 47.9257 210.872 84.7981C207.251 100.744 196.129 122.665 164.3 149.859L156.871 156.168C131.194 178.036 114.149 193.145 106.585 201.484C97.764 188.478 80.4473 172.386 54.2578 152.809L50.8573 150.445C24.1969 131.548 11.7457 110.496 5.44678 93.7338C34.3253 106.363 65.6418 126.693 100.464 126.693C153.795 126.693 174.512 58.2424 126.82 60.22Z" fill="url(#paint2_radial)" />
            <path fillRule="evenodd" clipRule="evenodd" d="M126.82 60.2202C146.24 44.1487 176.27 51.1067 193.136 68.2664C203.014 78.3112 206.948 86.3051 206.027 97.7205L205.661 99.7922C199.226 113.468 188.669 128.775 165.576 148.781L163.913 150.058C202.794 99.29 180.957 48.3653 126.82 60.2202Z" fill="url(#paint3_linear)" />
            <path fillRule="evenodd" clipRule="evenodd" d="M126.82 60.2199C146.627 36.4265 180.675 43.9287 199.697 62.6788C205.975 68.8731 209.281 76.6891 210.872 84.7981C208.538 94.937 204.238 104.741 195.763 116.627C211.018 77.432 175.108 49.4114 126.82 60.2199Z" fill="url(#paint4_linear)" />
            <path fillRule="evenodd" clipRule="evenodd" d="M165.2 114.21C177.923 121.273 178.132 133.337 171.614 143.392C170.107 144.753 168.537 146.165 166.926 147.557C168.789 141.676 168.307 130.313 165.587 115.204L165.2 114.21Z" fill="url(#paint5_linear)" />
            <path fillRule="evenodd" clipRule="evenodd" d="M165.2 114.211C174.815 111.469 185.017 114.054 193.482 119.641C187.8 127.248 180.863 134.991 171.624 143.393C177.975 133.505 177.62 121.148 165.2 114.211Z" fill="#D8EEF8" />
            <path d="M42.3088 283.359V287.785C42.3088 288.978 41.3462 289.941 40.1534 289.941H2.97745C1.78464 289.941 0.822021 288.978 0.822021 287.785V247.763C0.822021 246.571 1.78464 245.608 2.97745 245.608H8.79502C9.98783 245.608 10.9504 246.571 10.9504 247.763V280.084C10.9504 280.702 11.4527 281.204 12.07 281.204H40.1534C41.3462 281.204 42.3088 282.177 42.3088 283.359Z" fill="#1A1A1A" />
            <path d="M44.9663 267.78C44.9663 252.964 55.8586 244.216 73.1543 244.216C90.4396 244.216 101.342 252.953 101.342 267.78C101.342 282.596 90.45 291.343 73.1543 291.343C55.8586 291.343 44.9663 282.606 44.9663 267.78ZM91.0151 267.78C91.0151 258.279 84.1093 252.964 73.1543 252.964C62.1993 252.964 55.2935 258.279 55.2935 267.78C55.2935 277.28 62.1365 282.595 73.1543 282.595C84.1093 282.606 91.0151 277.28 91.0151 267.78Z" fill="#1A1A1A" />
            <path d="M114.139 246.822L129.248 280.43C129.666 281.277 130.87 281.277 131.278 280.43L146.334 246.832C146.69 246.089 147.444 245.618 148.27 245.618H154.381C155.982 245.618 157.028 247.313 156.306 248.747L136.122 288.769C135.756 289.491 135.013 289.951 134.197 289.951H126.151C125.335 289.951 124.592 289.491 124.226 288.769L104.042 248.736C103.32 247.303 104.356 245.608 105.967 245.608H112.203C113.03 245.618 113.783 246.079 114.139 246.822Z" fill="#1A1A1A" />
            <path d="M210.767 245.618C211.96 245.618 212.922 246.581 212.922 247.774V269.056C212.922 282.355 204.123 291.353 188.03 291.353C171.938 291.353 163.138 282.355 163.138 269.056V247.774C163.138 246.581 164.101 245.618 165.294 245.618H171.122C172.314 245.618 173.277 246.581 173.277 247.774V268.292C173.277 277.154 178.467 282.48 188.03 282.48C197.594 282.48 202.783 277.165 202.783 268.292V247.774C202.783 246.581 203.746 245.618 204.939 245.618H210.767Z" fill="#1A1A1A" />
            <defs>
                <radialGradient id="paint0_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(52.0469 51.1639) rotate(29.6179) scale(231.99)">
                    <stop stopColor="#FF0874" />
                    <stop offset="1" stopColor="#952725" />
                </radialGradient>
                <linearGradient id="paint1_linear" x1="58.1074" y1="45.3449" x2="62.7997" y2="153.874" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#F7C772" />
                    <stop offset="1" stopColor="#FF880F" />
                </linearGradient>
                <radialGradient id="paint2_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(184.668 91.996) rotate(158.035) scale(159.958 163.695)">
                    <stop stopColor="#85D0EF" />
                    <stop offset="0.4376" stopColor="#3D98BF" />
                    <stop offset="1" stopColor="#3054A5" />
                </radialGradient>
                <linearGradient id="paint3_linear" x1="242.447" y1="-16.2832" x2="128.865" y2="78.1477" gradientUnits="userSpaceOnUse">
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="white" stopOpacity="0" />
                </linearGradient>
                <linearGradient id="paint4_linear" x1="300.556" y1="28.9072" x2="158.466" y2="109.36" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#77C5E5" />
                    <stop offset="1" stopColor="white" />
                </linearGradient>
                <linearGradient id="paint5_linear" x1="172.022" y1="91.9358" x2="155.956" y2="125.524" gradientUnits="userSpaceOnUse">
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="white" stopOpacity="0" />
                </linearGradient>
            </defs>
        </svg>

    );
};

export default WelcomeLogo;