const FacilitiesItem = [
    { value: "Bar/Lounge", label: "Bar/Lounge" },
    { value: "Beach", label: "Beach" },
    { value: "Beauty Shop", label: "Beauty Shop" },
    { value: "Casino", label: "Casino" },
    { value: "Concierge", label: "Concierge" },
    { value: "Continental breakfast", label: "Continental breakfast" },
    { value: "Disco / Club", label: "Disco / Club" },
    { value: "Entertainment", label: "Entertainment" },
    { value: "Fitness facility", label: "Fitness facility" },
    { value: "Fitness Programme", label: "Fitness Programme" },
    { value: "High Speed Internet", label: "High Speed Internet" },
    { value: "Hotel bar", label: "Hotel bar" },
    { value: "Hotel restaurant", label: "Hotel restaurant" },
    { value: "Jacuzzi", label: "Jacuzzi" },
    { value: "Karaoke", label: "Karaoke" },
    { value: "Lounge", label: "Lounge" },
    { value: "Meeting / Banquet Facilities", label: "Meeting / Banquet Facilities" },
    { value: "Multilingual Staff", label: "Multilingual Staff" },
    { value: "Night Club", label: "Night Club" },
    { value: "Number Of Pool", label: "Number Of Pool" },
    { value: "On-Site Beach", label: "On-Site Beach" },
    { value: "On-Site Fitness Centre", label: "On-Site Fitness Centre" },
    { value: "Restaurants", label: "Restaurants" },
    { value: "Sauna", label: "Sauna" },
    { value: "Spa", label: "Spa" },
    { value: "Special Spa Packages", label: "Special Spa Packages" },
    { value: "Steam Room", label: "Steam Room" },
    { value: "Tours", label: "Tours" },
    { value: "Wi-Fi", label: "Wi-Fi" },
    { value: "Aerobic", label: "Aerobic" },
    { value: "Aerobic Equipment", label: "Aerobic Equipment" },
    { value: "Archery Centre", label: "Archery Centre" },
    { value: "Bike Rental", label: "Bike Rental" },
    { value: "Billiards", label: "Billiards" },
    { value: "Canoe", label: "Canoe" },
    { value: "Darts", label: "Darts" },
    { value: "Fishing", label: "Fishing" },
    { value: "Golf", label: "Golf" },
    { value: "Golf course", label: "Golf course" },
    { value: "Horse riding", label: "Horse riding" },
    { value: "Jogging Track", label: "Jogging Track" },
    { value: "Parasol", label: "Parasol" },
    { value: "Scuba Diving", label: "Scuba Diving" },
    { value: "Skiing", label: "Skiing" },
    { value: "Squash", label: "Squash" },
    { value: "Squash Court", label: "Squash Court" },
    { value: "WaterSports", label: "WaterSports" },
    { value: "Wind Surf", label: "Wind Surf" }
];

export default FacilitiesItem;