import React from "react";

const Favorite = ({ className }) => {
    return (
        <svg className={className} width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.03125 13.6562L6.96875 13.5625L1.5 8.5C0.53125 7.59375 0 6.34375 0 5.03125V4.9375C0 2.75 1.5625 0.84375 3.71875 0.4375C4.9375 0.1875 6.1875 0.46875 7.21875 1.1875C7.5 1.375 7.75 1.625 8 1.875C8.125 1.75 8.25 1.59375 8.40625 1.46875C8.53125 1.375 8.65625 1.28125 8.78125 1.1875C9.78125 0.46875 11.0312 0.1875 12.25 0.4375C14.4375 0.84375 16 2.75 16 4.9375V5.03125C16 6.34375 15.4375 7.59375 14.4688 8.5L9 13.5625L8.9375 13.6562C8.6875 13.875 8.34375 14.0312 8 14.0312C7.625 14.0312 7.3125 13.875 7.03125 13.6562ZM7.46875 3.53125C7.4375 3.53125 7.4375 3.53125 7.4375 3.5L6.875 2.875C6.15625 2.0625 5.0625 1.71875 4 1.90625C2.53125 2.1875 1.5 3.46875 1.5 4.9375V5.03125C1.5 5.9375 1.84375 6.78125 2.5 7.375L8 12.4688L13.4688 7.375C14.125 6.78125 14.5 5.9375 14.5 5.03125V4.9375C14.5 3.46875 13.4375 2.1875 11.9688 1.90625C10.9062 1.71875 9.8125 2.0625 9.09375 2.875L8.53125 3.5C8.53125 3.53125 8.53125 3.53125 8.5 3.5625C8.375 3.6875 8.1875 3.78125 8 3.78125C7.78125 3.78125 7.59375 3.6875 7.46875 3.5625V3.53125Z" fill="url(#paint0_linear_6248_11560)" />
            <defs>
                <linearGradient id="paint0_linear_6248_11560" x1="-4.81987" y1="4.30029" x2="8.17233" y2="20.0214" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#393E45" />
                    <stop offset="1" stopColor="#0A0D10" />
                </linearGradient>
            </defs>
        </svg>
    );
};

export default Favorite