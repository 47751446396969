import React, { useEffect, useState } from "react";
import axios from "axios";
import Post from "../../api/models/Post";
import Slider from '@mui/material/Slider';
import Select from 'react-dropdown-select';
import Banner from "../../Components/Banner";
import Footer from "../../Components/Footer";
import NavBar from "../../Components/NavBar";
import PostModel from "../../api/models/Post";
import { Checkbox, Input, message } from 'antd';
import MetaTags from "../../Components/MetaTags";
import SearchBar from "../../Components/SearchBar";
import SearchIcon from '@mui/icons-material/Search';
import Metatag from "../../api/models/Metatag.js";
import Searchcard from "../../Components/Searchcard";
import ReactStars from "react-rating-stars-component";
import ButtonSmall from "../../Components/ButtonSmall";
import Filter from "../../assets/images/icons/filter.png";
import { useLocation, useParams } from "react-router-dom";
import X from "../../assets/images/icons/cancel-icon.png";
// import OccasionVisible from "../../Components/OccasionVisible";
import bannerImage from "../../assets/images/ZentiumSearchBanner.jpeg"
import FacilitiesItem from "../../../src/Layouts/FacilitiesList"
import "./style.css";


const ZentrumSearch = ({ setProgress }) => {

    const location = useLocation();
    var minLabel;
    var maxLabel;

    const searchParams = new URLSearchParams(location.search);
    const planToGo = searchParams.get('location');
    const occasion = searchParams.get('occasion');
    const startDate = searchParams.get('startdate');
    const endDate = searchParams.get('enddate');
    const people = searchParams.get('people');
    const rooms = searchParams.get('rooms');
    const searchtype = searchParams.get('searchtype');
    const coordinateslat = searchParams.get('coordinateslat');
    const coordinateslong = searchParams.get('coordinateslong');
    const encodedRooms = JSON.parse(rooms);
    const { LocationId } = useParams();
    const FilteredArray = encodedRooms?.map(({ adults, ages }) => ({ numOfAdults: adults, childAges: ages?.length > 0 ? ages?.flatMap(ageGroup => ageGroup.length > 0 ? ageGroup?.map(age => age.value) : []) : [] }));

    const [seo, setSeo] = useState();
    const [hotelList, setHotelList] = useState();
    const [hotelRateList, setHotelRateList] = useState();
    const [tempHotelList, setTempHotelList] = useState();
    const [price, setPrice] = useState([10, 100]);
    const [MinValue, setMinValue] = useState();
    const [MaxValue, setMaxValue] = useState();
    const [marks, setMarks] = useState([{ value: 10, label: 10 }, { value: 100, label: 100 },])
    const [loader, setLoader] = useState(false);
    const [selectedOption, setSelectedOption] = useState([{ value: "Relevent", label: "Relevent" }])
    const [selectFacilities, setSelectFacilities] = useState()
    // const [occasionValue, setOccasionValue] = useState();
    const [searchTerm, setSearchTerm] = useState('');
    const [isMobile, setIsMobile] = useState(false);
    const [isFilterOpen, setIsFilterOpen] = useState(false)
    const [filterByFourStar, setFiterByFourStar] = useState(false);
    const [filterByFiveStar, setFiterByFiveStar] = useState(false);
    const [ip, setIP] = useState("");
    const [itemsToShow, setItemsToShow] = useState(0);
    const [hasMore, setHasMore] = useState(false);
    const [nextResultKey, setNextResultKey] = useState();
    const [tokenForNewHotel, setTokenForNewHotel] = useState();
    const [hotelContentList, setHotelContentList] = useState();
    const [loadMoreFunc, setLoadMoreFunc] = useState(false);
    const [currentStatus, setCurrentStatus] = useState();
    const [correlationId, setCorrelationId] = useState();
    const [flagValue1, setFlagValue1] = useState(false);
    const [flagValue2, setFlagValue2] = useState(false);
    const [isVisible, setIsVisible] = useState(true);
    const [requestTokenData, setRequestTokenData] = useState();
    const [lovuHotelContent, setLovuHotelContent] = useState([]);
    const [tempLovuHotelContent, setTempLovuHotelContent] = useState([]);
    const [hasRateChange, setHasRateChange] = useState(false)
    const timeDifference = new Date(endDate).getTime() - new Date(startDate).getTime();
    const daysDifference = timeDifference / (1000 * 3600 * 24);

    let tokendata = localStorage.getItem('data');
    let rawuser = localStorage.getItem('user');
    const searchDetails = localStorage.getItem('searchLocationDetail');
    const searchDetail = JSON.parse(searchDetails);
    const searchLocationDetail = {
        id: searchDetail?.id,
        name: searchDetail?.name,
        fullName: searchDetail?.fullName,
        type: searchDetail?.type,
        state: searchDetail?.state,
        country: searchDetail?.country,
        coordinates: searchDetail?.coordinates
    };
    const user = JSON.parse(rawuser);
    var currentdate = new Date().getTime();
    var expir = new Date(user?.subscription_expires_at).getTime();

    // const occasions = [
    //     'Wedding',
    //     'Honeymoon',
    //     'Anniversary',
    //     'Proposal',
    //     'Vow Renewal',
    //     'Babymoon',
    //     'Romantic Getaway',
    //     'Celebrating Love',
    // ];


    const sortOption = [
        {
            value: "Relevent",
            label: "Relevent"
        },
        {
            value: "Price high to low",
            label: "Price : high to low"
        },
        {
            value: "Price low to high",
            label: "Price : low to high"
        },
        {
            value: "Rating high to low",
            label: "Rating : high to low"
        },
        {
            value: "Rating low to high",
            label: "Rating : low to high"
        },
    ]

    useEffect(() => {
        Metatag.metaslug('zentrum_listing').then(result =>
            setSeo(result)
        );
        if (searchtype?.toLowerCase() == 'hotel') {
            setSearchTerm(planToGo.split(',')[0]);
        }

    }, []);



    useEffect(() => {
        getBoundries();
        setLoader(true);
        setHasMore(false);
    }, [LocationId, startDate, endDate, rooms, ip, correlationId])

    useEffect(() => {
        getIpAddress();
        getCorrelationId();
    }, [])

    useEffect(() => {
        loadLovuHotelContent();
    }, [ip, LocationId, startDate, endDate, rooms, ip, correlationId]);



    useEffect(() => {
        if (hotelList?.length > 0 && hotelRateList?.length > 0) {
            setLoader(false);
            handleMergedHotelDatas();
        } else if (!hotelList && !hotelRateList) {
            setHotelRateList([]);
            setTempHotelList([]);
            setHotelList([]);
        } else if (!hotelList && hotelRateList?.length >= 0) {
            setTempHotelList([])
        }
    }, [hotelRateList, flagValue1, flagValue2])

    useEffect(() => {
        setItemsToShow(10)
        if (flagValue1 && flagValue2) {

            if (filterByFiveStar && !filterByFourStar) {
                if (searchTerm) {
                    setHasMore(false)
                    const zentrumHotel = hotelList?.filter(item => {
                        if (item?.name?.toLowerCase().includes(searchTerm.toLowerCase())) {
                            return true
                        }
                    })

                    const lovuHotel = lovuHotelContent?.filter(item => {
                        if (item?.title?.toLowerCase().includes(searchTerm.toLowerCase())) {
                            return true
                        }
                    })
                    const filteredHotels = filterHotelsByRoomRate(zentrumHotel, price, 5);
                    const lovuHotels = lovuHotelFilterByRate(lovuHotel, price, 5)
                    handleOtherFilters(filteredHotels)
                    handleOtherFilterInLovu(lovuHotels)
                } else {
                    setHasMore(true);
                    const filteredHotels = filterHotelsByRoomRate(hotelList, price, 5);
                    const lovuHotels = lovuHotelFilterByRate(lovuHotelContent, price, 5)
                    handleOtherFilters(filteredHotels)
                    handleOtherFilterInLovu(lovuHotels)
                }
            }
            if (!filterByFiveStar && filterByFourStar) {
                if (searchTerm) {
                    setHasMore(false)
                    const zentrumHotel = hotelList?.filter(item => {
                        if (item?.name?.toLowerCase().includes(searchTerm.toLowerCase())) {
                            return true
                        }
                    })

                    const lovuHotel = lovuHotelContent?.filter(item => {
                        if (item?.title?.toLowerCase().includes(searchTerm.toLowerCase())) {
                            return true
                        }
                    })
                    const filteredHotels = filterHotelsByRoomRate(zentrumHotel, price, 4);
                    const lovuHotels = lovuHotelFilterByRate(lovuHotel, price, 4)
                    handleOtherFilters(filteredHotels)
                    handleOtherFilterInLovu(lovuHotels)
                } else {
                    setHasMore(true);
                    const filteredHotels = filterHotelsByRoomRate(hotelList, price, 4);
                    const lovuHotels = lovuHotelFilterByRate(lovuHotelContent, price, 4)
                    handleOtherFilters(filteredHotels)
                    handleOtherFilterInLovu(lovuHotels)
                }
            }
            if (filterByFiveStar && filterByFourStar) {
                if (searchTerm) {
                    setHasMore(false)
                    const zentrumHotel = hotelList?.filter(item => {
                        if (item?.name?.toLowerCase().includes(searchTerm.toLowerCase())) {
                            return true
                        }
                    })

                    const lovuHotel = lovuHotelContent?.filter(item => {
                        if (item?.title?.toLowerCase().includes(searchTerm.toLowerCase())) {
                            return true
                        }
                    })
                    const filteredHotels = filterHotelsByRoomRate(zentrumHotel, price, [4, 5]);
                    const lovuHotels = lovuHotelFilterByRate(lovuHotel, price, [4, 5])
                    handleOtherFilters(filteredHotels)
                    handleOtherFilterInLovu(lovuHotels)
                } else {
                    setHasMore(true);
                    const filteredHotels = filterHotelsByRoomRate(hotelList, price, [4, 5]);
                    const lovuHotels = lovuHotelFilterByRate(lovuHotelContent, price, [4, 5])
                    handleOtherFilters(filteredHotels)
                    handleOtherFilterInLovu(lovuHotels)
                }

            }
            if (!filterByFiveStar && !filterByFourStar) {
                if (searchTerm) {
                    setHasMore(false)
                    const zentrumHotel = hotelList?.filter(item => {
                        if (item?.name?.toLowerCase().includes(searchTerm.toLowerCase())) {
                            return true
                        }
                    })

                    const lovuHotel = lovuHotelContent?.filter(item => {
                        if (item?.title?.toLowerCase().includes(searchTerm.toLowerCase())) {
                            return true
                        }
                    })
                    const filteredHotels = filterHotelsByRoomRate(zentrumHotel, price);
                    const lovuHotels = lovuHotelFilterByRate(lovuHotel, price)
                    handleOtherFilters(filteredHotels);
                    handleOtherFilterInLovu(lovuHotels)
                } else {
                    setHasMore(true);
                    const filteredHotels = filterHotelsByRoomRate(hotelList, price);
                    const lovuHotels = lovuHotelFilterByRate(lovuHotelContent, price)
                    // setTempHotelList(filteredHotels);
                    handleOtherFilters(filteredHotels);
                    handleOtherFilterInLovu(lovuHotels)
                }

            }
        }
    }, [filterByFourStar, filterByFiveStar, price, selectedOption, selectFacilities, searchTerm])

    useEffect(() => {
        setProgress(10)
        setProgress(20)
        setProgress(60)
        setProgress(100)
    }, [setProgress])

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 1140);
        };
        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };

    }, []);



    const getIpAddress = async () => {
        const res = await axios.get('https://api64.ipify.org?format=json', { headers: { Authorization: null } });
        setIP(res.data.ip);
    }

    const getCorrelationId = () => {
        PostModel.getCorrelationId()
            .then((res) => {
                setCorrelationId(res);
            })
            .catch((error) => { console.error('Error config:', error) });
    }

    const getBoundries = async () => {
        var channelId = tokendata === null ? process.env.REACT_APP_PUBLIC_ZENTRUM_CHANNEL_ID : tokendata !== null && (user?.subscription_sku == null || currentdate > expir) ? process.env.REACT_APP_NON_PAID_ZENTRUM_CHANNEL_ID : process.env.REACT_APP_MEMBER_PAID_ZENTRUM_CHANNEL_ID;
        if (ip && correlationId) {
            if (LocationId) {
                if (coordinateslat && coordinateslong) {
                    const requestData = {
                        "channelId": channelId,
                        "culture": "en-us",
                        "circularRegion": {
                            "centerLat": coordinateslat,
                            "centerLong": coordinateslong,
                            "radiusInKM": 100
                        },
                        "filterBy": {
                            "ratings": {
                                "max": 5,
                                "min": 4
                            }
                        },
                        "contentFields": [
                            "basic",
                            "reviews",
                            // "facilities",
                            "descriptions",
                        ]
                    }
                    const requestDatafortoken = {
                        "channelId": channelId,
                        "currency": "USD",
                        "culture": "en-US",
                        "checkIn": startDate,
                        "checkOut": endDate,
                        "occupancies": FilteredArray,
                        "circularRegion": {
                            "centerLat": coordinateslat,
                            "centerLong": coordinateslong,
                            "radiusInKM": 100
                        },
                        "searchLocationDetails": searchLocationDetail,
                        "nationality": "US",
                        "countryOfResidence": "US"
                    }
                    getHotelDatas(requestData, requestDatafortoken);
                }
                const baseurl = `https://autosuggest.travel.zentrumhub.com/api/locations/locationcontent/locationv2/${LocationId}?isGoogleAutoCompleteEnabled=true`;
                await axios.get(baseurl, { headers: { accountId: process.env.REACT_APP_ZENTRUM_ACCOUNT_ID, apiKey: process.env.REACT_APP_GOOGLE_API_KEY, Authorization: null } })
                    .then((res) => {
                        if (res.data) {
                            const shape = res.data.shape;
                            if (shape === "Polygon") {
                                const coordinates = res.data.boundaries[0];
                                const requestData = {
                                    "channelId": channelId,
                                    "culture": "en-us",
                                    "polygonalRegion": {
                                        "coordinates": coordinates
                                    },
                                    "filterBy": {
                                        "ratings": {
                                            "max": 5,
                                            "min": 4
                                        }
                                    },
                                    "contentFields": [
                                        "basic",
                                        "reviews",
                                        "facilities",
                                        "descriptions",
                                        // "images",
                                    ]
                                }
                                const requestDatafortoken = {
                                    "channelId": channelId,
                                    "currency": "USD",
                                    "culture": "en-US",
                                    "checkIn": startDate,
                                    "checkOut": endDate,
                                    "occupancies": FilteredArray,
                                    "polygonalRegion": {
                                        "coordinates": coordinates
                                    },
                                    "searchLocationDetails": searchLocationDetail,
                                    "nationality": "US",
                                    "countryOfResidence": "US"
                                }
                                getHotelDatas(requestData, requestDatafortoken);
                            } else if (shape === "MultiPolygon") {
                                const multipolygonalboundry = res.data.boundaries;
                                const allBoundaries = [];
                                for (const boundry of multipolygonalboundry) {
                                    allBoundaries.push(boundry);
                                }
                                const coordinates = allBoundaries.map(boundary => {
                                    return { coordinates: boundary };
                                });
                                const requestData = {
                                    "channelId": channelId,
                                    "culture": "en-us",
                                    "multiPolygonalRegion": {
                                        "polygons": coordinates
                                    },
                                    "filterBy": {
                                        "ratings": {
                                            "max": 5,
                                            "min": 4
                                        }
                                    },
                                    "searchLocationDetails": searchLocationDetail,
                                    // "contentFields": [
                                    //     "facilities",
                                    //     "basic",
                                    //     "reviews",
                                    //     "masterfacilities",
                                    //     "descriptions",
                                    //     "images",
                                    // ]
                                }
                                const requestDatafortoken = {
                                    "channelId": channelId,
                                    "currency": "USD",
                                    "culture": "en-US",
                                    "checkIn": startDate,
                                    "checkOut": endDate,
                                    "occupancies": FilteredArray,
                                    "multiPolygonalRegion": {
                                        "polygons": coordinates
                                    },
                                    "searchLocationDetails": searchLocationDetail,
                                    "nationality": "US",
                                    "countryOfResidence": "US"
                                }
                                getHotelDatas(requestData, requestDatafortoken);
                            } else {
                                const boundry = res.data.coordinates;
                                const requestData = {
                                    "channelId": channelId,
                                    "culture": "en-us",
                                    "circularRegion": {
                                        "centerLat": boundry.lat,
                                        "centerLong": boundry.long,
                                        "radiusInKM": 100
                                    },
                                    "filterBy": {
                                        "ratings": {
                                            "max": 5,
                                            "min": 4
                                        }
                                    },
                                    "contentFields": [
                                        "basic",
                                        "reviews",
                                        // "facilities",
                                        "descriptions",
                                    ]
                                }
                                const requestDatafortoken = {
                                    "channelId": channelId,
                                    "currency": "USD",
                                    "culture": "en-US",
                                    "checkIn": startDate,
                                    "checkOut": endDate,
                                    "occupancies": FilteredArray,
                                    "circularRegion": {
                                        "centerLat": boundry.lat,
                                        "centerLong": boundry.long,
                                        "radiusInKM": 100
                                    },
                                    "searchLocationDetails": searchLocationDetail,
                                    "nationality": "US",
                                    "countryOfResidence": "US"
                                }
                                getHotelDatas(requestData, requestDatafortoken);
                            }
                        }
                    });
            }
        }
    }

    const getHotelDatas = async (req, reqtoken) => {
        if (req && reqtoken) {
            const newRequestData = JSON.stringify(req);
            const newRequestTokenData = JSON.stringify(reqtoken);
            setRequestTokenData(newRequestTokenData);
            if (newRequestData) {
                PostModel.displayHotel(ip, newRequestData, correlationId)
                    .then((res) => {
                        setFlagValue1(true);
                        setTempHotelList(res.hotels);
                        setHotelList(res.hotels);
                        setHotelContentList(res.hotels);
                    })
                    .catch((error) => { console.log(JSON.stringify(error)) });
            }
            if (newRequestTokenData) {
                getAvailabilityResult(ip, newRequestTokenData);
            }
        }
    }

    const getAvailabilityResult = (ip, newRequestTokenData) => {
        setLoader(true);
        PostModel.availabilityresult(ip, newRequestTokenData, correlationId)
            .then((res) => {
                const Stringvalues = typeof res.attributes === 'string';
                if (Stringvalues) {
                    message.error("A system error occurred. Try again after some time.");
                    if (tempLovuHotelContent?.length == 0 || tempLovuHotelContent == []) {
                        setTempHotelList([]);
                    }
                    setLoader(false);
                }
                if (res.matchedHotelData.nextResultsKey) {
                    setNextResultKey(res.matchedHotelData.nextResultsKey);
                    setTokenForNewHotel(res.matchedHotelData.token);
                }
                if (res.matchedHotelData.status === "InProgress") {
                    setCurrentStatus(res.matchedHotelData.status);
                } else {
                    setCurrentStatus(res.matchedHotelData.status);
                    setLoadMoreFunc(true);
                    setLoader(false);
                }
                setFlagValue2(true);
                setHotelRateList(res.matchedHotelData.hotels);
                setTempHotelList(res.matchedHotelData.hotels);
            })
            .catch((error) => { console.error('Error config:', error) });
    }

    const loadLovuHotelContent = () => {
        Post.hotelsearch(planToGo, null)
            .then(result => {
                setLovuHotelContent(result?.data?.data);
                setTempLovuHotelContent(result?.data?.data);
            }
            );
    }

    const handleMergedHotelDatas = () => {
        if (hotelList && hotelRateList) {
            if (flagValue1 && flagValue2) {
                if (hotelRateList.length <= 2 && nextResultKey && currentStatus != 'Completed') {
                    getAvailabilityResult(ip, requestTokenData);
                } else {
                    const merged = hotelList?.map(hotel => {
                        const matchingRoomRate = hotelRateList?.filter(rate => rate.id == hotel?.id);
                        const roomRate = matchingRoomRate?.length > 0 ? matchingRoomRate[0]?.rate?.totalRate : null;
                        return { ...hotel, roomRate: roomRate };
                    });
                    if (merged) {
                        const NOImageFilter = merged?.filter((img) => img?.heroImage != null);
                        const DisplayHotels = NOImageFilter.sort((a, b) => {
                            if (a.roomRate === null && b.roomRate !== null) {
                                return 1;
                            }
                            if (a.roomRate !== null && b.roomRate === null) {
                                return -1;
                            }
                            return 0;
                        });
                        // const DisplayHotels = NOImageFilter?.filter((rate) => rate?.roomRate != null);
                        if (DisplayHotels?.length > 0) {
                            setLoader(false)
                            setTempHotelList(DisplayHotels);
                            setHotelList(DisplayHotels);
                            // if (DisplayHotels?.length >= 10) {
                            // }
                            setHasMore(true);
                            const MapValues = DisplayHotels?.map((v) => v?.roomRate);
                            const LovuMapValues = lovuHotelContent?.map((v) => v?.price);
                            minLabel = Math.round(Math.min(...MapValues)) < Math.round(Math.min(...LovuMapValues)) ? Math.round(Math.min(...MapValues)) : Math.round(Math.min(...LovuMapValues));
                            maxLabel = Math.round(Math.max(...MapValues)) > Math.round(Math.max(...LovuMapValues)) ? Math.round(Math.max(...MapValues)) : Math.round(Math.max(...LovuMapValues));
                            setMinValue(minLabel);
                            setMaxValue(maxLabel);
                            setMarks([{ value: minLabel, label: numberWithCommas(minLabel) }, { value: maxLabel, label: numberWithCommas(maxLabel) },]);
                            setPrice([minLabel, maxLabel]);
                        } else {
                            setTempHotelList([]);
                            setHasMore(false);
                        }
                    } else {
                        message.info("No Hotel Found.")
                        setLoader(false)
                    }
                }


            }
        }
    }





    const loadMoreItems = () => {
        setLoader(true);
        if (itemsToShow < tempHotelList.length || currentStatus === "InProgress") {
            if (tempHotelList.length - itemsToShow <= 30) {
                if (!loadMoreFunc) {
                    PostModel.fetchHotelDatabynextresultkey(tokenForNewHotel, nextResultKey, ip, correlationId)
                        .then((lastresponse) => {
                            if (lastresponse?.status !== "Completed") {
                                setNextResultKey(lastresponse?.nextResultKey);
                                setCurrentStatus(lastresponse.status)
                            }
                            if (lastresponse.status === "Completed") {
                                setLoadMoreFunc(true);
                                setCurrentStatus(lastresponse.status);
                            }
                            const merged = hotelContentList?.map(hotel => {
                                const matchingRoomRate = lastresponse?.hotels?.filter(rate => rate.id == hotel?.id);
                                const roomRate = matchingRoomRate?.length > 0 ? matchingRoomRate[0]?.rate?.totalRate : null;
                                return { ...hotel, roomRate: roomRate };
                            });
                            const NOImageFilter = merged?.filter((img) => img?.heroImage != null);
                            const DisplayHotels = NOImageFilter?.filter((rate) => rate?.roomRate != null);
                            const tempHotelIds = tempHotelList.map(hotel => hotel.id);
                            const filteredHotels = DisplayHotels.filter(hotel => !tempHotelIds.includes(hotel.id));

                            setHotelList([...tempHotelList, ...filteredHotels]);
                            setTempHotelList([...tempHotelList, ...filteredHotels]);
                            setLoader(false);
                        })
                }
            }
            setItemsToShow(prev => prev + 30);
            setLoader(false);
        } else {
            setHasMore(false);
            setLoader(false);
        }
    };


    //hotel list

    const handleFilterIcon = () => {
        setIsFilterOpen(true)
    }

    const handleFilterClose = () => {
        setIsFilterOpen(false);
    }

    const handleChange = (event, newValue) => {
        if (newValue[0] == MinValue && newValue[1] == MaxValue) {
            setHasRateChange(false)
        } else {
            setHasRateChange(true);
        }
        setPrice(newValue);
    };

    const handleStarRateChange = (e) => {
        setFiterByFourStar(e.target.checked)
    };

    const handleCheckboxChange = (e) => {
        setFiterByFiveStar(e.target.checked)
    };


    const lovuHotelFilterByRate = (hotels, priceRange, rating) => {
        const lovuHotels = hotels?.filter(hotel => {
            const ratingValue = hotel?.rating;

            const roomRate = hotel.price;
            const isPriceInRange = hasRateChange ? roomRate != null && (roomRate) >= priceRange[0] && (roomRate) <= priceRange[1] : (roomRate) >= priceRange[0] && (roomRate) <= priceRange[1];
            if (rating !== undefined) {
                const isRatingMatched = Array.isArray(rating) ? (ratingValue == rating[0] || ratingValue == rating[1]) : (ratingValue == rating) || rating == undefined;

                return isRatingMatched;
            } else {
                return isPriceInRange
            }
        });
        return lovuHotels;
    }

    const filterHotelsByRoomRate = (hotels, priceRange, rating) => {
        const filteredHotels = hotels?.filter(hotel => {
            const ratingValue = hotel?.starRating;
            const roomRate = hotel.roomRate;
            const isPriceInRange = roomRate >= priceRange[0] && roomRate <= priceRange[1];
            if (rating !== undefined) {
                const isRatingMatched = Array.isArray(rating) ? (ratingValue == rating[0] || ratingValue == rating[1]) : (ratingValue == rating) || rating == undefined;
                return isRatingMatched;
            } else {
                return isPriceInRange
            }
        });
        return filteredHotels;
    }

    const handleFacilities = (hotels) => {
        let sortedHotels = [...hotels];
        if (selectFacilities && selectFacilities.length > 0) {
            const selectedFacilitiesNames = selectFacilities.map(facility => facility.value);
            const filteredHotels = sortedHotels?.filter(hotel => {
                return hotel?.facilities?.some(facility => selectedFacilitiesNames?.includes(facility.name));
            });
            return filteredHotels
        } else {
            return hotels
        }
    }

    const handleLovuSort = (hotels) => {
        if (selectedOption !== undefined) {
            let sortedHotels = [...hotels];

            if (selectedOption[0].value === 'Price high to low') {
                sortedHotels = sortedHotels.filter(hotel => hotel.price !== null).sort((a, b) => b.price - a.price);
            }
            if (selectedOption[0].value === 'Price low to high') {
                sortedHotels = sortedHotels.filter(hotel => hotel.price !== null).sort((a, b) => a.price - b.price);

            }
            if (selectedOption[0].value === 'Rating high to low') {
                sortedHotels = sortedHotels.filter(hotel => hotel.rating !== null).sort((a, b) => b.rating - a.rating);
            }
            if (selectedOption[0].value === 'Rating low to high') {
                sortedHotels = sortedHotels.filter(hotel => hotel.rating !== null).sort((a, b) => a.rating - b.rating);
            }
            if (selectedOption[0].value === 'Relevent') {
                if (selectFacilities || selectFacilities == undefined || selectFacilities?.length == 0) {
                    if (!selectFacilities || selectFacilities.length == 0) {
                        setTempLovuHotelContent(sortedHotels);
                    } else {
                        setTempLovuHotelContent([]);
                    }
                } else {
                    setTempLovuHotelContent(sortedHotels);

                }

            } else {
                if (selectFacilities || selectFacilities == undefined || selectFacilities?.length == 0) {
                    if (!selectFacilities || selectFacilities.length == 0) {
                        setTempLovuHotelContent(sortedHotels);
                    } else {
                        setTempLovuHotelContent([]);
                    }
                } else {
                    setTempLovuHotelContent(sortedHotels);

                }
            }
        }
    };


    const handleSort = (hotels) => {
        if (selectedOption !== undefined) {
            let sortedHotels = [...hotels];
            if (selectedOption[0].value === 'Price high to low') {
                sortedHotels.sort((a, b) => b.roomRate - a.roomRate);
            }
            if (selectedOption[0].value === 'Price low to high') {
                sortedHotels.sort((a, b) => a.roomRate - b.roomRate);
            }
            if (selectedOption[0].value === 'Rating high to low') {
                sortedHotels.sort((a, b) => b.starRating - a.starRating);
            }
            if (selectedOption[0].value === 'Rating low to high') {
                sortedHotels.sort((a, b) => a.starRating - b.starRating);
            }
            if (selectedOption[0].value === 'Relevent') {
                if (selectFacilities !== undefined) {
                    const facilitie = handleFacilities(sortedHotels);
                    setTempHotelList(facilitie);
                } else {
                    setTempHotelList(sortedHotels);
                }
            } else {
                if (selectFacilities !== undefined) {
                    const facilitie = handleFacilities(sortedHotels);
                    setTempHotelList(facilitie);
                } else {
                    setTempHotelList(sortedHotels);
                }
            }
        }
    };


    const handleOtherFilters = (hotels) => {
        if (selectedOption !== undefined) {
            handleSort(hotels);
        } else {
            if (selectFacilities !== undefined) {
                const facilitie = handleFacilities(hotels);
                setTempHotelList(facilitie);
            } else {
                setTempHotelList(hotels);
            }
        }
    }

    const handleOtherFilterInLovu = (hotels) => {
        if (selectedOption !== undefined) {
            handleLovuSort(hotels);
        } else {

            if (selectFacilities || selectFacilities == undefined || selectFacilities?.length == 0) {
                if (!selectFacilities || selectFacilities.length == 0) {
                    setTempLovuHotelContent(hotels);
                } else {
                    setTempLovuHotelContent([]);
                }
            }

        }
    }

    function numberWithCommas(x) {
        return "$" + x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    const handleRoomRate = (RoomRate) => {
        if (RoomRate) {
            const rate = Math.round(RoomRate);
            return rate.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
    }

    const handleTenPercent = (x) => {
        let tenPercent = Math.round(((10 / 100) * x) + x);

        return tenPercent.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    const handleTwentyPercent = (x) => {
        let twentyPercent = Math.round(((20 / 100) * x) + x);

        return twentyPercent.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }


    const handleZentrumSearchImage = (image) => {

        if (image?.length > 0) {
            const result = image?.map((value, index) => {
                return value?.links[0];
            });
            return result;
        } else {
            return image;
        }
    }


    // ?.filter(item => {
    //     if (!searchTerm) return true
    //     if (item?.name?.toLowerCase().includes(searchTerm.toLowerCase())) {
    //         return true
    //     }
    // })

    // .filter(item => {
    //     if (!searchTerm) return true
    //     if (item?.title?.toLowerCase().includes(searchTerm.toLowerCase())) {
    //         return true
    //     }
    // })

    return (
        <>
            <MetaTags
                title={seo?.title != null ? seo?.title : `LOVU: Booking Search`}
                description={seo?.description != null ? seo?.description : "Discover handpicked romantic destinations with LOVU. Connect with expert Romance Travel Advisors to plan the perfect honeymoon, anniversary, or getaway."}
                imageScr={seo?.meta_image[0]?.original_url != undefined ? seo?.meta_image[0]?.original_url : bannerImage}

            />
            <div className="search">
                <Banner
                    isSearch={true}
                    imageSrc={bannerImage}
                    widget={
                        <SearchBar
                            isupdatebtn={true}
                            iszentrumsearch={true}
                            heightToHideFrom={140}
                            LocationId={LocationId}
                            name={planToGo}
                            startDate={startDate}
                            endDate={endDate}
                            NoofPeople={people}
                            roomArray={encodedRooms}
                            occasion={occasion}
                            isVisible={isVisible}
                            setIsVisible={setIsVisible}
                            setSearchTerm={setSearchTerm}
                            searchTerm={searchTerm}
                        />}
                />
                <div className="pageContent">
                    <div className="sub-content">
                        {
                            (tempHotelList?.length !== 0 && tempHotelList != []) &&
                            <div onClick={handleFilterIcon} className={isMobile ? "filtericon-container" : "display-none"}>
                                <img src={Filter} alt="filter" className="filter-icon" />
                            </div>
                        }

                        {!loader
                            ?
                            <>
                                {
                                    isFilterOpen && isMobile ? (
                                        <div className={"search-filter"}>
                                            <div onClick={handleFilterClose} className="close-cancel"  >
                                                <img src={X} alt="cancel" className="search-cancel-icon" />
                                            </div>
                                            <div className="search-sort">
                                                <div className="sort-title">Sort by:</div>
                                                <div style={{ width: '100%' }}>
                                                    <Select
                                                        values={selectedOption}
                                                        searchable={false}
                                                        onChange={setSelectedOption}
                                                        options={sortOption}
                                                        placeholder='Relevance'
                                                        className="zentium-drop-down"

                                                    />
                                                </div>
                                            </div>
                                            <div className="search-sort">
                                                <div className="sort-title">Filter by hotel name:</div>
                                                <Input
                                                    value={searchTerm}
                                                    onChange={(e) => setSearchTerm(e.target.value)}
                                                    suffix={<span><SearchIcon className="input-icon" /></span>}
                                                />
                                            </div>
                                            {/* <div className="search-sort">
                            <div className="sort-title">Good for:</div>
                            <div className="occasion-visibible">
                                {occasions.map((item, index) =>

                                    <OccasionVisible key={index} handleClick={() => setOccasionValue(item)} text={item} value={item} occasionValue={occasionValue} />
                                )}
                            </div>
                        </div> */}
                                            <div className="search-sort">
                                                <div className="sort-title">Features:</div>
                                                <div style={{ width: '100%' }}>
                                                    <Select
                                                        value={selectFacilities}
                                                        multi={true}
                                                        onChange={setSelectFacilities}
                                                        searchable={false}
                                                        options={FacilitiesItem}
                                                        placeholder=''
                                                        className="zentium-drop-down-multi"
                                                    />
                                                </div>
                                            </div>
                                            <div className="search-sort">
                                                <div className="sort-title">Price range:</div>
                                                <Slider
                                                    getAriaLabel={() => 'Temperature range'}
                                                    value={price}
                                                    onChange={handleChange}
                                                    valueLabelFormat={(value) => numberWithCommas(value)}
                                                    valueLabelDisplay="auto"
                                                    min={MinValue}
                                                    max={MaxValue}
                                                    marks={marks}
                                                />
                                            </div>
                                            <div className="search-sort">
                                                <div className="sort-title">Star rating:</div>
                                                <Checkbox checked={filterByFiveStar} onChange={(e) => handleCheckboxChange(e, 5)}>
                                                    <ReactStars
                                                        count={5}
                                                        edit={false}
                                                        value={5}
                                                        isHalf={true}
                                                        size={23}
                                                        color="rgba(205, 205, 205, 1)"
                                                        activeColor="#ffd700"
                                                    />
                                                </Checkbox>
                                                <Checkbox checked={filterByFourStar} onChange={(e) => handleStarRateChange(e, 4)}>
                                                    <ReactStars
                                                        count={5}
                                                        edit={false}
                                                        value={4}
                                                        isHalf={true}
                                                        size={23}
                                                        color="rgba(205, 205, 205, 1)"
                                                        activeColor="#ffd700"
                                                    />
                                                </Checkbox>
                                            </div>
                                        </div>
                                    )
                                        : !isMobile ?
                                            <div className={"search-filter"}>
                                                <div className="search-sort">
                                                    <div className="sort-title">Sort by:</div>
                                                    <div style={{ width: '100%' }}>
                                                        <Select
                                                            values={selectedOption}
                                                            searchable={false}
                                                            onChange={setSelectedOption}
                                                            options={sortOption}
                                                            placeholder='Relevance'
                                                            className="zentium-drop-down"

                                                        />
                                                    </div>
                                                </div>
                                                <div className="search-sort">
                                                    <div className="sort-title">Filter by hotel name:</div>
                                                    <Input
                                                        value={searchTerm}
                                                        onChange={(e) => setSearchTerm(e.target.value)}
                                                        suffix={<span><SearchIcon className="input-icon" /></span>}
                                                    />
                                                </div>
                                                {/* <div className="search-sort">
                    <div className="sort-title">Good for:</div>
                    <div className="occasion-visibible">
                        {occasions.map((item, index) =>

                            <OccasionVisible key={index} handleClick={() => setOccasionValue(item)} text={item} value={item} occasionValue={occasionValue} />
                        )}
                    </div>
                </div> */}
                                                <div className="search-sort">
                                                    <div className="sort-title">Features:</div>
                                                    <div style={{ width: '100%' }}>
                                                        <Select
                                                            value={selectFacilities}
                                                            multi={true}
                                                            onChange={setSelectFacilities}
                                                            searchable={false}
                                                            options={FacilitiesItem}
                                                            placeholder=''
                                                            className="zentium-drop-down-multi"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="search-sort">
                                                    <div className="sort-title">Price range:</div>
                                                    <Slider
                                                        getAriaLabel={() => 'Temperature range'}
                                                        value={price}
                                                        onChange={handleChange}
                                                        valueLabelFormat={(value) => numberWithCommas(value)}
                                                        valueLabelDisplay="auto"
                                                        min={MinValue}
                                                        max={MaxValue}
                                                        marks={marks}
                                                    />
                                                </div>
                                                <div className="search-sort">
                                                    <div className="sort-title">Star rating:</div>
                                                    <Checkbox checked={filterByFiveStar} onChange={(e) => handleCheckboxChange(e, 5)}>
                                                        <ReactStars
                                                            count={5}
                                                            edit={false}
                                                            value={5}
                                                            isHalf={true}
                                                            size={23}
                                                            color="rgba(205, 205, 205, 1)"
                                                            activeColor="#ffd700"
                                                        />
                                                    </Checkbox>
                                                    <Checkbox checked={filterByFourStar} onChange={(e) => handleStarRateChange(e, 4)}>
                                                        <ReactStars
                                                            count={5}
                                                            edit={false}
                                                            value={4}
                                                            isHalf={true}
                                                            size={23}
                                                            color="rgba(205, 205, 205, 1)"
                                                            activeColor="#ffd700"
                                                        />
                                                    </Checkbox>
                                                </div>
                                            </div>
                                            : null
                                }

                            </>

                            :
                            <div className={isMobile ? "display-none" : "search-filter"}>
                                {[...Array(5)].map((_, index) => (
                                    <div className="search-sort" key={index}>
                                        <div className="sort-title skeleton-box title-box"></div>
                                        <div className="zentium-drop-down skeleton-box input-box" style={{ width: '100%' }}></div>
                                    </div>
                                ))}
                            </div>
                        }
                        <div className="search-content" >
                            <div className="search-content-sub">
                                {
                                    tempLovuHotelContent?.length > 0 && tempLovuHotelContent?.map((value, index) =>
                                        <Searchcard
                                            key={index}
                                            HotelId={value.id}
                                            imageSrc={value?.featured_image?.original_url}
                                            title={value?.title}
                                            type={value?.type}
                                            city={value?.city}
                                            country={value?.country}
                                            description={value?.description}
                                            hasRatingPrice={value?.rating && true}
                                            starRating={value?.rating}
                                            price={value?.price}
                                            backgroundColor="rgb(104, 125, 101)"
                                            cardStyle={value?.type}
                                            lovuhotel={true}
                                            Value={value}
                                        />
                                    )}
                                {(!loader)
                                    ?
                                    (

                                        <>
                                            <>

                                                {/* <div className="search-card-container search-card-container-box">
                                                <div className="search-card-img skeleton-box" />
                                                <div className="search-card-subcontainer">
                                                    <div>
                                                        <div className="search-tag-star">
                                                            <div className="search-badge"></div>
                                                            <div className="search-star">
                                                                <div className="review-rating">
                                                                    <span className="review-rating-span"></span>
                                                                    <div className="review-count"></div>
                                                                </div>
                                                                <div className="search-star-rating"></div>
                                                            </div>
                                                        </div>
                                                        <div className="search-card-content">
                                                            <div className="search-title"></div>
                                                            <div className="search-address"></div>
                                                            <div className="search-address"></div>
                                                        </div>
                                                    </div>
                                                    <div className="mobile-free-cancel">
                                                        <div className="free-cancel"></div>
                                                        <div className="search-star-rating"></div>
                                                        <div className="mobile-search-price"></div>
                                                        <div className="mobile-available-button">
                                                            <div></div>
                                                            <div></div>
                                                        </div>
                                                    </div>
                                                    <div className="hotel-room-container">
                                                        <div className="search-price-container"></div>
                                                        <div className="free-cancel-container">
                                                            <div className="Free-Cancellation"></div>
                                                            <div className="hotel-price">
                                                                <div className="learn-more"></div>
                                                                <div className="available-rooms"></div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div> */}

                                            </>

                                            {tempHotelList?.length > 0 && tempHotelList?.slice(0, itemsToShow).map((item, index) => {
                                                return <Searchcard
                                                    key={index}
                                                    HotelId={item.id}
                                                    imageSrc={item?.heroImage}
                                                    images={handleZentrumSearchImage(item?.images)}
                                                    tokenForNewHotel={tokenForNewHotel}
                                                    nextResultKey={nextResultKey !== undefined ? nextResultKey : ''}
                                                    title={item?.name}
                                                    type={item?.type}
                                                    city={item?.contact?.address?.country?.city}
                                                    country={item?.contact?.address?.country?.name}
                                                    description={item?.descriptions?.length > 0 && item?.descriptions[0]?.text}
                                                    hasRatingPrice={item?.rating && true}
                                                    starRating={item?.starRating}
                                                    price={handleRoomRate(item?.roomRate)}
                                                    ratings={item?.reviews && item?.reviews.length > 0 && item?.reviews[0]?.rating}
                                                    count={item?.reviews && item?.reviews.length > 0 && item?.reviews[0]?.count}
                                                    backgroundColor="rgb(104, 125, 101)"
                                                    cardStyle={item?.template_type}
                                                    ip={ip}
                                                    startDate={startDate}
                                                    endDate={endDate}
                                                    correlationId={correlationId}
                                                    LocationId={LocationId}
                                                    occasion={occasion}
                                                    people={people}
                                                    location={planToGo}
                                                    encodedRooms={rooms}
                                                    noofnights={daysDifference}
                                                    tenpercent={handleTenPercent(item?.roomRate)}
                                                    twentypercent={handleTwentyPercent(item?.roomRate)}
                                                />
                                            }
                                            )}
                                        </>
                                    )
                                    :
                                    <>
                                        {[...Array(5)].map((_, index) => (
                                            <div key={index} className="search-card-container skeleton-box skeleton-box-height">
                                            </div>
                                        ))}
                                    </>
                                }


                                {
                                    (!loader && (tempHotelList?.length == 0 || tempHotelList == []) && (tempLovuHotelContent?.length == 0 || tempLovuHotelContent == []) && !searchTerm) &&
                                    <div className="no-hotel-found">No Hotel Found</div>
                                }
                                {
                                    (!loader && (tempHotelList?.length == 0 || tempHotelList == []) && (tempLovuHotelContent?.length == 0 || tempLovuHotelContent == []) && searchTerm) &&
                                    <div className="no-hotel-found">No hotels found matching your search.</div>
                                }
                                <div style={{ marginBottom: '20px' }}>
                                    {
                                        loader ?
                                            <>
                                                {[...Array(5)].map((_, index) => (
                                                    <div key={index} className="search-card-container skeleton-box skeleton-box-height">

                                                    </div>
                                                ))}
                                            </>
                                            :
                                            (hasMore && currentStatus != "Completed") && <ButtonSmall handleClick={() => loadMoreItems()} className="button-instance-black-outline" buttonstyle="button-outline" text="LOAD MORE" />}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer className="design-component-instance-node-3" correlationId={correlationId} isVisible={isVisible} />
                <NavBar navigationDark={true} />


            </div>
        </>
    );
};

export default ZentrumSearch;