import React from "react";

const AdvisorIcon = ({ className, fillcolor }) => {
    return (

        <svg className={className} width="14" height="17" viewBox="0 0 14 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path className={fillcolor} d="M9.5 5C9.5 4.125 9 3.3125 8.25 2.84375C7.46875 2.40625 6.5 2.40625 5.75 2.84375C4.96875 3.3125 4.5 4.125 4.5 5C4.5 5.90625 4.96875 6.71875 5.75 7.1875C6.5 7.625 7.46875 7.625 8.25 7.1875C9 6.71875 9.5 5.90625 9.5 5ZM3 5C3 3.59375 3.75 2.28125 5 1.5625C6.21875 0.84375 7.75 0.84375 9 1.5625C10.2188 2.28125 11 3.59375 11 5C11 6.4375 10.2188 7.75 9 8.46875C7.75 9.1875 6.21875 9.1875 5 8.46875C3.75 7.75 3 6.4375 3 5ZM1.53125 15.5H12.4375C12.1562 13.5312 10.4688 12 8.40625 12H5.5625C3.5 12 1.8125 13.5312 1.53125 15.5ZM0 16.0938C0 13 2.46875 10.5 5.5625 10.5H8.40625C11.5 10.5 14 13 14 16.0938C14 16.5938 13.5625 17 13.0625 17H0.90625C0.40625 17 0 16.5938 0 16.0938Z" fill="url(#paint0_linear_6929_15847)" />
            <defs>
                <linearGradient id="paint0_linear_6929_15847" x1="-4.81987" y1="6.30029" x2="8.17233" y2="22.0214" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#393E45" />
                    <stop offset="1" stopColor="#0A0D10" />
                </linearGradient>
            </defs>
        </svg>


    );
};

export default AdvisorIcon;