import React, { useState, createContext, useContext } from 'react';
import User from '../api/models/User';

const StoreContext = createContext();

const INITIAL_STATE = {
    token: null,
    user: new User(),
    posts: 'all',
    categories: [],
};

export function StoreProvider(props) {
    const [store, setStore] = useState(INITIAL_STATE);

    const setInitialStore = () => setStore(INITIAL_STATE);

    return (
        <StoreContext.Provider value={{ store, setStore, setInitialStore }}>
            {props.children}
        </StoreContext.Provider>
    );
}

export default function useStore() {
    return useContext(StoreContext);
}
