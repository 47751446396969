import React, { useEffect, useState } from "react";
import Post from "../../../api/models/Post"
import Card from "../../../Components/Card";
import Banner from "../../../Components/Banner";
import Footer from "../../../Components/Footer";
import NavBar from "../../../Components/NavBar";
import MetaTags from "../../../Components/MetaTags";
import Metatag from "../../../api/models/Metatag.js";
import SearchBar from "../../../Components/SearchBar";
import ButtonSmall from "../../../Components/ButtonSmall";
import bannerImage from "../../../assets/images/CollectionListBanner.jpeg"

let page;
let lastPage;

const CollectionsList = ({ setProgress }) => {

    const [seo, setSeo] = useState();
    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isVisible, setIsVisible] = useState(true);

    useEffect(() => {
        setProgress(10)
        setProgress(20)
        setProgress(60)
        setProgress(100)
    }, [setProgress])

    useEffect(() => {
        Metatag.metaslug('collection').then(result =>
            setSeo(result)
        );
        collectionloadPage(true);
    }, []);

    function collectionloadPage(first = false, refresh = false) {
        if (first) {
            setPosts([])
            page = 1;
            lastPage = 1;
        }
        if (page > lastPage) {
            return;
        }
        setLoading(true);
        let types = 'collection';
        Post.alls(page, types)
            .then(data => {
                page === 1
                    ? setPosts(data.data)
                    : setPosts(posts.concat(data.data));
                lastPage = data.meta.last_page;
                page = data.links.next == null ? page + 1 : data?.links?.next.split('?')[1].split('=')[1];
            })
            .catch(() => { })
            .finally(() => {
                setLoading(false);
            });

    }


    return (
        <>
            <MetaTags
                title={seo?.title != null ? seo?.title : "LOVU: Curated Collections for Every Love Story"}
                description={seo?.description != null ? seo?.description : "Explore Unique Travel Destinations and Boutique Hotels"}
                imageScr={seo?.meta_image[0]?.original_url != undefined ? seo?.meta_image[0]?.original_url : bannerImage}
            />
            <div className="homepage collection">
                <Banner
                    isProperties
                    imageSrc={bannerImage}
                    title="Curated Collections for Every Love Story"
                    heading="Explore Unique Travel Destinations and Boutique Hotels"
                    widget={
                        <SearchBar heightToHideFrom={520}
                            isVisible={isVisible}
                            setIsVisible={setIsVisible}
                        />

                    }
                />
                <div className="pageContent">
                    <div className="card-list-container">
                        <div className="card-list-wrap">
                            {posts.map((v, i) => <Card
                                key={i}
                                imageSrc={v?.featured_image?.original_url}
                                title={v?.title}
                                type={v?.type}
                                city={v?.city}
                                country={v?.country}
                                hasRatingPrice={v?.rating && true}
                                starRating={v?.rating}
                                price={v?.price}
                                backgroundColor={v?.image_color ? v?.image_color : null}
                                cardStyle={v?.template_type}
                                id={v?.id}
                                isBadgeVisible={false}
                            />)}
                        </div>
                        {loading ? <div className="card-list-wrap" style={{ marginTop: -38 }}>
                            <div className="card skeleton-box" />
                            <div className="card skeleton-box" />
                            <div className="card skeleton-box" />
                            <div className="card skeleton-box" />
                        </div> :
                            (page > lastPage ? null : <ButtonSmall handleClick={() => collectionloadPage()} className="button-instance-black-outline " buttonstyle="button-outline" text="LOAD MORE" />)}
                    </div>
                </div>
                <Footer className="design-component-instance-node-3" isVisible={isVisible} />
                <NavBar navigationDark={true} />

            </div>
        </>
    );
};

export default CollectionsList;