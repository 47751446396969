import React, { useEffect, useState } from 'react';
import { Input } from 'antd';
import Modal from 'react-modal';
import Post from "../../api/models/Post"
import ButtonTag from '../../Components/ButtonTag';
import Arrow from "../../assets/images/icons/arrow.png"
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import "./style.css";

function visualizeText(text, searchString) {
    if (searchString === '') {
        return text;
    }
    const matches = text.match(new RegExp(searchString, 'gi'));
    return text.split(new RegExp(searchString, 'gi')).map((e, i, a) => (
        <span className='highlight-query-content' key={i}>
            {e}
            {i < a.length - 1 && (
                <span className='highlight-query' key={i}>
                    {matches[i]}
                </span>
            )}
        </span>
    ));
}

const Search = ({ visible, onClose }) => {

    const [searchValue, setSearchValue] = useState('');
    const [result, setResult] = useState();
    const [type, setType] = useState(null);
    const [loading, setLoading] = useState(false);
    const [searchDelay, setSearchDelay] = useState(null);
    const [resultTypes, setResultTypes] = useState([]);

    useEffect(() => {
        setType(null);
        setResultTypes([...new Set(result?.map(e => e.type))]);
    }, [result]);

    useEffect(() => {
        setLoading(true);
        if (searchDelay) {
            clearTimeout(searchDelay);
        }
        let timeout = setTimeout(search, 500);
        setSearchDelay(timeout);
        return () => searchDelay && clearTimeout(searchDelay);
    }, [searchValue]);

    const search = () => {
        if (searchValue === '') {
            setResult();
            return;
        }
        setLoading(true);
        Post.search(searchValue, null)
            .then(result => result.data)
            .then(setResult)
            .then(() => setLoading(false));
    };

    const handlePopupClose = () => {
        setSearchValue('');
        onClose();
    }

    const handleSearchNavigation = (option) => {
        setSearchValue('');
        onClose();
    }

    const changeType = (type, title, url) => {
        var hyphenless = title.split('-').join('_');
        let anchorTitle = hyphenless?.replace(/\s+/g, '-').toLowerCase();
        if (type == "basic" || type == "property" || type == "brand" || type == "destination" || type == 'offer') {
            return `/${anchorTitle}`
        } else if (type === "advisor") {
            return `/${'ta'}/${anchorTitle}`
        } else if (type === "worldiaitenararies") {
            return `${url}`
        } else {
            return `/${type}/${anchorTitle}`;
        }
    }

    return (
        <div>
            <Modal
                isOpen={visible}
                onRequestClose={onClose}
                contentLabel="Example Modal"
                ariaHideApp={false}
            >
                <div className="search-result-container">
                    <img onClick={handlePopupClose} className="arrow-img" src={Arrow} alt="arrow" />
                    <Input className="search-input-style" autoFocus={true} value={searchValue} onChange={(e) => setSearchValue(e.target.value)} placeholder="What are you looking for?" />
                </div>
                <div className='filteroption'>
                    {searchValue !== '' && (loading) ?
                        <div className='loding-skeleton-tag'>
                            <SkeletonTheme highlightColor="#ebddcc" width={130} height={45}>
                                <Skeleton borderRadius={'6px 6px 0 0'} />
                            </SkeletonTheme>
                            <SkeletonTheme highlightColor="#ebddcc" width={110} height={45}>
                                <Skeleton borderRadius={'6px 6px 0 0'} />
                            </SkeletonTheme>
                            <SkeletonTheme highlightColor="#ebddcc" width={150} height={45}>
                                <Skeleton borderRadius={'6px 6px 0 0'} />
                            </SkeletonTheme>
                        </div>
                        :
                        searchValue !== '' &&
                        <div className='filterOption-navigation'>
                            <ButtonTag
                                data={[
                                    { name: 'Show all', value: null },
                                    { name: 'Hotels', value: 'property' },
                                    { name: 'Collections', value: 'collection' },
                                    { name: 'Brands', value: 'brand' },
                                    { name: 'Destinations', value: 'destination' },
                                    { name: 'Travel Advisors', value: 'advisor' },
                                    { name: 'Iteneraries', value: 'worldiaitenararies' },
                                ].filter(
                                    e =>
                                        resultTypes.length > 0 &&
                                        (!e.value || resultTypes.includes(e.value)),
                                )}
                                selected={type}
                                handleClick={setType}
                            />
                        </div>
                    }
                </div>
                <div className="search-result-main">
                    {searchValue !== '' && (!result?.length || loading) ? (
                        <div>
                            {loading ?
                                <div className='loding-skeleton'>
                                    <SkeletonTheme highlightColor="#ebddcc" width={300} height={20}>
                                        <Skeleton width={380} style={{ marginBottom: '8px', marginTop: '10px' }} />
                                        <Skeleton style={{ marginBottom: '25px' }} />
                                    </SkeletonTheme>
                                    <SkeletonTheme highlightColor="#ebddcc" width={300} height={20}>
                                        <Skeleton width={380} style={{ marginBottom: '8px' }} />
                                        <Skeleton style={{ marginBottom: '25px' }} />
                                    </SkeletonTheme>
                                    <SkeletonTheme highlightColor="#ebddcc" width={300} height={20}>
                                        <Skeleton width={380} style={{ marginBottom: '8px' }} />
                                        <Skeleton style={{ marginBottom: '25px' }} />
                                    </SkeletonTheme>
                                    <SkeletonTheme highlightColor="#ebddcc" width={300} height={20}>
                                        <Skeleton width={380} style={{ marginBottom: '8px' }} />
                                        <Skeleton style={{ marginBottom: '25px' }} />
                                    </SkeletonTheme>
                                    <SkeletonTheme highlightColor="#ebddcc" width={300} height={20}>
                                        <Skeleton width={380} style={{ marginBottom: '8px' }} />
                                        <Skeleton style={{ marginBottom: '25px' }} />
                                    </SkeletonTheme>
                                    <SkeletonTheme highlightColor="#ebddcc" width={300} height={20}>
                                        <Skeleton width={380} style={{ marginBottom: '8px' }} />
                                        <Skeleton style={{ marginBottom: '25px' }} />
                                    </SkeletonTheme>
                                    <SkeletonTheme highlightColor="#ebddcc" width={300} height={20}>
                                        <Skeleton width={380} style={{ marginBottom: '8px' }} />
                                        <Skeleton style={{ marginBottom: '25px' }} />
                                    </SkeletonTheme>
                                </div>
                                :
                                <div className='loading'>No results</div>
                            }
                        </div>
                    ) :

                        (result?.filter(
                            e =>
                            (!type ||
                                (type === 'property'
                                    ? ['property', 'basic'].includes(e.type)
                                    : e.type === type))
                        )
                            .map((hit, index, array) => (
                                <>
                                    <a href={changeType(hit?.type, hit?.title, hit?.website)} onClick={handleSearchNavigation} className='result-content' key={index}>
                                        <div style={{ marginBottom: 20 }}>
                                            <div className='search-result-title'>
                                                {visualizeText(
                                                    hit?.title,
                                                    searchValue,
                                                )}
                                            </div>
                                            <div className='search-result-city'>
                                                {visualizeText(
                                                    [hit?.city, hit?.country]
                                                        .filter(
                                                            e => e && e?.length,
                                                        )
                                                        .join(',  '),
                                                    searchValue,
                                                )}

                                            </div>
                                        </div></a>
                                    {index !== array.length - 1 && <div className="search-result-text-container-hr" />}

                                </>
                            ))
                        )
                    }
                </div>

            </Modal>
        </div>
    );
};

export default Search;