import React, { useCallback, useEffect, useRef, useState } from 'react'
import moment from 'moment';
import { Skeleton as Sk } from "antd";
import AdvisorList from '../AdvisorList';
import GuestModal from '../Requests/Guests';
import PopUpModal from '../Auth/PopUpModal';
import BudgetModal from '../Requests/Budget';
import Banner from '../../Components/Banner';
import Footer from '../../Components/Footer'
import NavBar from '../../Components/NavBar'
import Skeleton from 'react-loading-skeleton'
import PostModel from "../../api/models/Post"
import CoupleModal from '../Requests/Couples';
import Gallery from '../../Components/Gallery'
import Authenticate from '../Auth/Authenticate';
import OccasionModal from '../Requests/Occasion';
import WelcomeModal from '../Auth/WelcomeScreen';
import MetaTags from '../../Components/MetaTags';
import 'react-loading-skeleton/dist/skeleton.css'
import SearchBar from '../../Components/SearchBar';
import SubmitModal from '../Requests/SubmitRequest';
import RequestSuccessModal from '../RequestSuccess';
import PostFooter from '../../Components/PostFooter'
import useStore from '../../providers/StoreProvider';
import DestinationModal from '../Requests/Destination';
import ButtonSmall from '../../Components/ButtonSmall';
import LikeFilled from "../../assets/icons/LikeFilled";
import LikeOutline from "../../assets/icons/LikeOutline";
import ContentBlock from '../../Components/ContentBlock'
import { useNavigate, useParams } from 'react-router-dom';
import ImageTextCard from '../../Components/ImageTextCard';
import useNotification from '../../providers/NotificationProvider';
import Occasionpreference from '../../api/models/Occasionpreference';
import bannerImage from "../../assets/images/ZentiumSearchBanner.jpeg"
import "./style.css";
import axios from 'axios';


const Hotel = ({ setProgress, type }) => {
    const params = useParams();
    const { store, setStore } = useStore();
    const user = store.user;
    let token = localStorage.getItem('data');
    const navigate = useNavigate();

    const [notification, createNotification] = useNotification();
    const [Value, setValue] = useState();
    const [isVisible, setIsVisible] = useState(true);
    const [isMobile, setIsMobile] = useState(false);
    const [occasionpreference, setOccasionpreference] = useState([]);
    const [isGaleryVisible, setIsGaleryVisible] = useState(false);
    const [currentImage, setCurrentImage] = useState(0);
    const [bannerMedia, setBannerMedia] = useState([]);
    const [isOccasionVisible, setIsOccasionVisible] = useState(false);
    const [isDestinationVisible, setIsdestinationVisible] = useState(false);
    const [isGuestVisible, setIsGuestVisible] = useState(false);
    const [isCoupleVisible, setIsCoupleVisible] = useState(false);
    const [isBudgetVisible, setIsBudgetVisible] = useState(false);
    const [isSubmitVisible, setIsSubmitVisible] = useState(false);
    const [isRequestSubmitVisible, setIsRequestSubmitVisible] = useState(false)
    const [isAdvisorList, setIsAdvisorList] = useState(false);
    const [destinationPost, setDestinationPost] = useState();
    const [destinationAdvisor, setDestinationAdvisor] = useState(null);
    const [isPopupVisible, setIsPopupVisible] = useState(false);
    const [isJoinNow, setIsJoinNow] = useState(false);
    const [isWelcomeVisible, setIsWelcomeVisible] = useState(false);
    const [isAuthenticate, setIsAuthenticate] = useState(false);
    let arrival = moment(new Date()).format("MM/DD/YYYY");
    let depature = moment(new Date()).add(6, 'days').format("MM/DD/YYYY");
    const [reservation, setReservation] = useState({
        occasion: '',
        arrival_date: arrival,
        departure_date: depature,
        city: '',
        flights: true,
        flexible_dates: true,
        persons: 2,
        noofchildrens: 1,
        rooms: 1,
        nights: 1,
        budget: 10000,
        type: '',
        message: '',
        desire_destination1: '',
        desire_destination2: '',
        guestsOwnRoom: true,
        includes: 'Everything',
        property_id: destinationAdvisor ? destinationPost?.id : null,
    });
    const [isWidgetVisible, setIsWidgetVisible] = useState(false);
    const [isWidgetVisible1, setIsWidgetVisible1] = useState(false);
    const [resize, setResize] = useState(true)
    const [imageFixed, setImageFixed] = useState(true);
    const [directHotelDetail, setDirectHotelDetail] = useState();
    const [isdirectHotelDetail, setisdirectHotelDetail] = useState(false);
    const [isFooterVisible, setIsFooterVisible] = useState(false);


    var currentdate = new Date().getTime();
    var expir = new Date(store?.user?.subscription_expires_at).getTime();

    const getType = data => {
        return {
            property: {
                button: 'Request a private offer',
                onclick: () => {
                    if (
                        store?.user?.requests_count >= 3 &&
                        (!store?.user?.subscription_expires_at || currentdate > expir)
                    ) {
                        createNotification({
                            title: 'Premium required!',
                            content:
                                'You’ve sent three offer requests already. To send more you need to upgrade premium account.',
                            primary: {
                                title: 'OK',
                            },
                        });
                        return;
                    } else {

                        if (!token) {
                            setIsWelcomeVisible(true)
                        } else {
                            setIsAdvisorList(true)
                        }
                    }
                },
                buttons: {
                    website: 'Visit Hotel’s website',
                    rewards: 'Rewards membership',
                },
            },
            advisor: {
                button: 'Request a private offer',
                onclick: () => {
                    if (
                        store?.user?.requests_count >= 3 &&
                        (!store?.user?.subscription_expires_at || currentdate > expir)
                    ) {
                        createNotification({
                            title: 'Premium required!',
                            content:
                                'You’ve sent three offer requests already. To send more you need to upgrade premium account.',
                            primary: {
                                title: 'OK',
                            },
                        });
                        return;
                    } else {
                        if (!token) {
                            setIsWelcomeVisible(true)
                        } else {
                            setIsdestinationVisible(true)
                        }
                    }
                },

                buttons: {
                    website: 'Visit my website',
                },
            },
            brand: {
                button: null,
                buttons: {
                    properties: `${data?.title} Hotels`,
                    advisors: 'Travel Specialists',
                    website: 'Visit Website',
                    rewards: 'Join Membership Rewards',
                },
            },
            destination: {
                button: 'Request a private offer',
                onclick: () => {
                    if (
                        store?.user?.requests_count >= 3 &&
                        (!store?.user?.subscription_expires_at || currentdate > expir)
                    ) {
                        createNotification({
                            title: 'Premium required!',
                            content:
                                'You’ve sent three offer requests already. To send more you need to upgrade premium account.',
                            primary: {
                                title: 'OK',
                            },
                        });
                        return;
                    } else {
                        if (!token) {
                            setIsWelcomeVisible(true)
                        } else {
                            setIsAdvisorList(true)
                        }
                    }
                },
                buttons: {
                    properties: 'Where to Stay',
                    advisors: 'Travel Specialists',
                    website: 'Visit Website',
                },
            },
            collection: {
                button: null,
                buttons: {},
            },
            basic: {
                button: 'Request a private offer',
                onclick: () => {
                    if (
                        store?.user?.requests_count >= 3 &&
                        (!store?.user?.subscription_expires_at || currentdate > expir)
                    ) {
                        createNotification({
                            title: 'Premium required!',
                            content:
                                'You’ve sent three offer requests already. To send more you need to upgrade premium account.',
                            primary: {
                                title: 'OK',
                            },
                        });
                        return;
                    } else {
                        if (!token) {
                            setIsWelcomeVisible(true)
                        } else {
                            setIsAdvisorList(true)
                        }
                    }
                },
                buttons: {
                    website: 'Visit Hotel’s website',
                },
            },
            offer: {
                button: data?.website ? 'Reserve this offer' : null,
                buttons: {},
                onclick: () => {
                    window.location.href = data?.website;
                },
            },
        }[data?.type];
    };

    const buttontype = getType(Value);

    const openImageViewer = useCallback((index) => {
        setCurrentImage(index);
        setIsGaleryVisible(true);
    }, []);

    const closeImageViewer = () => {
        setCurrentImage(0);
        setIsGaleryVisible(false);
    };

    const closeModal = () => {
        setIsPopupVisible(false);
    };

    useEffect(() => {
        setProgress(10)
        setProgress(20)
        setProgress(60)
        setProgress(100)
    }, [setProgress])

    useEffect(() => {
        Occasionpreference.all(1).then(({ data }) => setOccasionpreference(data));
    }, []);

    useEffect(() => {
        PostModel.showbyname(params?.id)
            .then(setValue)
            .catch(() => { });
    }, [params.id]);

    const [liked, setLiked] = useState(false)




    function likePost(like = true) {
        if (!store?.token) return setIsAuthenticate(true);
        updateUserLikes(Value?.id, like);
        if (!store?.user) return;
        if (like) {
            store.user
                .like(Value?.id)
                .catch(() => updateUserLikes(Value?.id, !like));
        } else {
            store.user
                .dislike(Value?.id)
                .catch(() => updateUserLikes(Value?.id, !like));
        }
    }

    function updateUserLikes(id, like = true) {
        if (!store?.user) return;
        like
            ? store.user.posts.push(id)
            : store.user.posts.splice(store.user.posts.indexOf(id), 1);
        setStore({ ...store });
    }

    useEffect(() => {
        if (Value?.id) {
            setLiked(store?.user?.posts?.includes(Value?.id))
        }
    }, [Value, store])



    useEffect(() => {
        setDestinationPost(Value);
        setReservation({
            ...reservation,
            occasion: user?.occasion,
        })
    }, [Value, user]);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
            if (window.innerWidth <= 768) {
                setResize(false)
                setIsWidgetVisible1(true)

            } else {
                setResize(true)
                setIsWidgetVisible1(false)

            }
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const fetchBannerMedia = () => {
            const result = Value?.featured_media.map((value, index) => {
                if (value && value.original_url) {
                    return { url: value.original_url };
                }
                return null;
            });
            setBannerMedia(result);
        };

        fetchBannerMedia();
    }, [Value?.featured_media]);

    const imgref = useRef(null);
    const contentref = useRef(null);

    useEffect(() => {
        const handleScroll = () => {
            const contentDiv = contentref.current;

            if (!contentDiv) return;

            const contentHeight = contentref.current.scrollHeight;
            const scrollPosition = window.scrollY;
            const windowHeight = window.innerHeight;

            if (contentHeight <= (scrollPosition + 700)) {
                setImageFixed(false);
            } else {
                setImageFixed(true)
            }

        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        let directHotelBook = async () => {
            // let dest = Value?.title + ',' + Value?.city + ',' + Value?.country;
            let dest = Value?.title;
            const suggestList = await axios.get(`https://autosuggest.us.prod.zentrumhub.com/api/locations/locationcontent/autosuggestv2?term=${dest}&isGoogleAutoCompleteEnabled=true`, { headers: { accountId: process.env.REACT_APP_ZENTRUM_ACCOUNT_ID, apiKey: process.env.REACT_APP_GOOGLE_API_KEY, Authorization: null } });

            let hotelList = suggestList.data?.locationSuggestions.find((list) => {
                return list.type === 'Hotel';
            });
            setDirectHotelDetail(hotelList);
            setisdirectHotelDetail(false);
        }
        if (Value?.title != undefined) {
            setisdirectHotelDetail(true);
            directHotelBook();
        }
    }, [Value])


    return (
        <>
            <MetaTags
                title={Value?.meta_title == null ? `LOVU: ${Value?.title}` : Value?.meta_title}
                description={Value?.meta_description == null ? Value?.description : Value?.meta_description}
                imageScr={Value?.meta_image?.length == 0 ? bannerImage : Value?.meta_image[0]?.original_url}
            />
            <div className={`hotelpage ${Value?.type == 'advisor' || Value?.type == 'offer' || Value?.type == 'destination' ? `hotelpage-hero` : ``}`}>
                <NavBar navigationDark={true} />
                <div ref={imgref} className='hotelpage-main-container'>
                    <div
                        style={resize ? { position: isFooterVisible ? 'absolute' : 'fixed', bottom: isFooterVisible ? '0px' : 'unset', top: isFooterVisible ? 'unset' : '75px', height: isFooterVisible ? '100vh' : '90%' } : {}}
                        className='hotelpage-sub-container-one'>
                        {Value ? (<Banner
                            isSearch={false}
                            bannerMedia={bannerMedia}
                            isHotelBanner={true}
                            imageSrc={bannerMedia}
                            title={Value?.title}
                            type={Value?.type}
                            city={Value?.city}
                            country={Value?.country}
                            hasRatingPrice={Value?.rating && true}
                            starRating={Value?.rating}
                            price={Value?.price}
                            backgroundColor="rgb(104, 125, 101)"
                            templatetype={Value?.template_type}
                            description={Value?.description}
                            isWidgetVisible={isWidgetVisible}
                            isWidgetVisible1={isWidgetVisible1}
                            setIsWidgetVisible={setIsWidgetVisible}
                            actionButton={<>
                                {buttontype?.button !== null &&
                                    <div className='banner-actionbutton'>
                                        <div onClick={() => likePost(!liked)}>
                                            {
                                                liked ?
                                                    <LikeFilled />
                                                    :
                                                    <LikeOutline />
                                            }
                                        </div>
                                        {(!isWidgetVisible1 && Value.type != 'advisor' && Value?.type != 'offer' && Value?.type != 'destination') && <ButtonSmall buttonstyle="default" text={"Book Direct"} className={'book-direct'} handleClick={() => setIsWidgetVisible(!isWidgetVisible)} />}
                                        {(!isWidgetVisible1 && Value?.type == 'destination') && <a href='https://trips.lovu.travel/project/new'><ButtonSmall buttonstyle="default" text={"Book Your Itinerary"} className={'book-direct'} /></a>}
                                        {
                                            Value?.type == 'offer' ?
                                                <ButtonSmall buttonstyle="default" className={'book-direct'} text={buttontype?.button} handleClick={buttontype?.onclick} />
                                                :
                                                <ButtonSmall buttonstyle="button-outline" className={'book-direct'} text={buttontype?.button} handleClick={buttontype?.onclick} />
                                        }
                                    </div>
                                }
                            </>}
                            widget={
                                isdirectHotelDetail ? null :
                                (Value.type != 'advisor' && Value?.type != 'offer' && Value?.type != 'destination') &&
                                <SearchBar
                                    setIsWidgetVisible={setIsWidgetVisible}
                                    setDateAndLocation={true}
                                    directHotelDetail={directHotelDetail}
                                    isVisible={isVisible}
                                    setIsVisible={setIsVisible}
                                />
                            }
                        />) : <div className='hero skeleton-box1'>
                            <div className='banner-content' ><Sk /> </div>
                        </div>}
                    </div>
                    <div ref={contentref} className='hotelpage-sub-container-two'>
                        <div className="pageContent">
                            {Value?.type === 'basic' || Value?.type === 'advisor' || Value?.type === 'offer' || Value?.type === 'collection' ? (<div>
                                <div className='advisor-content'>
                                    {
                                        Value?.content ?
                                            <ContentBlock data={Value} />
                                            :
                                            <>
                                                <Sk title={2} paragraph={{ rows: 8 }} className='skeleton-content' />
                                                <Sk paragraph={{ rows: 8 }} />
                                            </>
                                    }
                                </div>
                                {Value?.type !== 'basic' && Value?.type !== 'offer' &&
                                    Value?.website !== null && (
                                        <PostFooter
                                            data={Value}
                                        />
                                    )}
                            </div>) : (

                                Value?.content[1]?.type === 'GalleryBlock' && Value?.content[1]?.media?.length === 0
                                    ?
                                    (
                                        <>
                                            <div className='advisor-content'>
                                                {
                                                    Value?.content ?
                                                        <ContentBlock data={Value} />
                                                        :
                                                        <>
                                                            <Sk title={2} paragraph={{ rows: 8 }} className='skeleton-content' />
                                                            <Sk paragraph={{ rows: 8 }} />
                                                        </>
                                                }

                                            </div>
                                            {Value?.type !== 'offer' &&
                                                Value?.website !== null && (
                                                    <PostFooter
                                                        data={Value}
                                                        buttontype={buttontype}
                                                    />
                                                )}
                                        </>
                                    )
                                    :

                                    (<div>
                                        <div className='content'>
                                            <div className='main-content'>
                                                {
                                                    <ContentBlock data={Value} />
                                                }
                                            </div>
                                            {Value?.content ? Value?.content.map((content, index) => {
                                                if (content.type === "GalleryBlock") {
                                                    return <div key={index} className={isMobile ? 'image-card-none' : 'image-card'}>
                                                        <div className='main-flex-row'>
                                                            <div className={`flex-row leftColumn`}>
                                                                {
                                                                    content.media
                                                                        .filter((el, filterIndex) => [0, 1, 2].includes(filterIndex))
                                                                        .map((el, index) => {
                                                                            const completed = [0].includes(index) ? "bigSquare" : [1].includes(index) ? "squareImage" : "squareImage1";
                                                                            return <img onClick={() => openImageViewer(el?.id)} key={index} src={el?.sizes.small} alt='No images found' className={completed} />
                                                                        }
                                                                        )

                                                                }
                                                            </div>
                                                            <div className={`flex-row rightColumn`}>
                                                                {
                                                                    content.media
                                                                        .filter((el, filterIndex) => [3, 4].includes(filterIndex))
                                                                        .map((el, index) => {
                                                                            const completed = [0].includes(index) ? "squareImage2" : "portraitBigImage";
                                                                            return <img onClick={() => openImageViewer(el?.id)} key={index} src={el?.sizes.small} alt='No images found' className={completed} />
                                                                        }
                                                                        )
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className={`secondRow`}>
                                                            {
                                                                content.media
                                                                    .filter((el, filterIndex) => [5, 6, 7, 8, 9, 10, 11, 12, 13, 14].includes(filterIndex))
                                                                    .map((el, index) => {
                                                                        const completed =
                                                                            ([0].includes(index) &&
                                                                                "secondRowImage1") ||
                                                                            ([1].includes(index) &&
                                                                                "secondRowImage2") ||
                                                                            ([2, 3, 4].includes(index) &&
                                                                                "secondRowImage3") ||
                                                                            ([5].includes(index) &&
                                                                                "secondRowImage4") ||
                                                                            ([6].includes(index) &&
                                                                                "secondRowImage5") ||
                                                                            ([7, 8, 9].includes(index) &&
                                                                                "secondRowImage3");
                                                                        return <img onClick={() => openImageViewer(el?.id)} key={index} src={el?.sizes.small} alt='No images found' className={completed} />
                                                                    }
                                                                    )

                                                            }
                                                        </div>
                                                        <Gallery
                                                            data={content.media}
                                                            currentIndex={currentImage}
                                                            isGaleryVisible={isGaleryVisible}
                                                            closeImageViewer={closeImageViewer}
                                                        />
                                                    </div>
                                                }
                                            })
                                                : <div className={isMobile ? 'image-card-none' : 'image-card'}>
                                                    <div className={`secondRow`}>
                                                        <Skeleton square={true} style={{ margin: "2px" }} height={120} width={160} />
                                                        <Skeleton square={true} style={{ margin: "2px" }} height={120} width={310} />
                                                        <Skeleton square={true} style={{ margin: "2px" }} height={120} width={310} />
                                                        <Skeleton square={true} style={{ margin: "2px" }} height={120} width={160} />
                                                        <Skeleton square={true} style={{ margin: "2px" }} height={120} width={160} />
                                                        <Skeleton square={true} style={{ margin: "2px" }} height={120} width={310} />
                                                        <Skeleton square={true} style={{ margin: "2px" }} height={200} width={310} />
                                                        <Skeleton square={true} style={{ margin: "2px" }} height={200} width={160} />
                                                        <Skeleton square={true} style={{ margin: "2px" }} height={120} width={160} />
                                                        <Skeleton square={true} style={{ margin: "2px" }} height={120} width={310} />
                                                    </div>
                                                </div>
                                            }
                                            {!isMobile && Value?.content && Value?.content?.map((content, index) => {
                                                if (content?.type === "OffersBlock" && content?.data?.length > 0) {
                                                    let offers = content?.data?.map((item, i) => ({
                                                        ...item,
                                                        image: content?.media[i]?.sizes?.small,
                                                    }));
                                                    return <div key={index}>
                                                        <h2 className='special-offer-title'>Special Offers</h2>
                                                        <ImageTextCard
                                                            data={offers}
                                                        />
                                                    </div>
                                                }
                                            })
                                            }
                                            {!isMobile && Value?.content && Value?.content?.map((content, index) => {
                                                if (content?.type === "CertificatesBlock" && content?.data?.length > 0) {
                                                    return <div key={index}>
                                                        <h1 className='special-offer-title'>
                                                            Industry Affiliations
                                                        </h1>
                                                        <div className='certificateContainer'>
                                                            {
                                                                content?.media.map((media, id) => {

                                                                    return <div className='certificate-card'>
                                                                        <img src={media?.sizes?.small} alt='No image1 found' className="certificate-image" />
                                                                    </div>
                                                                })}

                                                        </div>
                                                    </div>
                                                }
                                            })
                                            }
                                            {!isMobile && Value?.content && Value?.content?.map((content, index) => {
                                                if (content?.type === "ReviewsBlock" && content?.data?.length > 0) {
                                                    return <div key={index}>
                                                        <h1 className='special-offer-title'>
                                                            Reviews
                                                        </h1>
                                                        <div className='reviewcontainer'>
                                                            {
                                                                content?.data?.map((item, id) => {
                                                                    return <>
                                                                        <h1 className='reviewContent'>{item?.description}</h1>
                                                                        <div className='reviewAuthorContainer'>
                                                                            <h2 className='reviewAuthorText'>
                                                                                {item?.name} – {item?.from}
                                                                            </h2>
                                                                            <h2 className='reviewAuthorText'>Traveled to {item?.to}</h2>
                                                                        </div>
                                                                    </>
                                                                })}

                                                        </div>
                                                    </div>
                                                }
                                            })
                                            }
                                        </div>
                                        {Value?.type !== 'offer' &&
                                            Value?.website !== null && (
                                                <PostFooter
                                                    data={Value}
                                                    buttontype={buttontype}
                                                />
                                            )}
                                    </div>)

                            )}
                        </div>
                    </div>

                </div>
                <Footer className="design-component-instance-node-3" setIsFooterVisible={setIsFooterVisible} isVisible={isVisible} />

                <AdvisorList
                    post={Value}
                    visible={isAdvisorList}
                    onClose={() => setIsAdvisorList(false)}
                    setIsdestinationVisible={setIsdestinationVisible}
                    setDestinationPost={setDestinationPost}
                    setDestinationAdvisor={setDestinationAdvisor}
                    reservation={reservation}
                    setReservation={setReservation}
                    occasionpreference={occasionpreference}
                />
                <OccasionModal
                    visible={isOccasionVisible}
                    onClose={() => setIsOccasionVisible(false)}
                    reservation={reservation}
                    setReservation={setReservation}
                    setIsdestinationVisible={setIsdestinationVisible}
                />
                <DestinationModal
                    visible={isDestinationVisible}
                    onClose={() => setIsdestinationVisible(false)}
                    post={destinationPost}
                    reservation={reservation}
                    setReservation={setReservation}
                    setIsOccasionVisible={setIsOccasionVisible}
                    setIsGuestVisible={setIsGuestVisible}
                    setIsAdvisorList={setIsAdvisorList}
                    setDestinationAdvisor={setDestinationAdvisor}
                    setIsRequestSubmitVisible={setIsRequestSubmitVisible}
                />
                <GuestModal
                    visible={isGuestVisible}
                    onClose={() => setIsGuestVisible(false)}
                    reservation={reservation}
                    setReservation={setReservation}
                    setIsBudgetVisible={setIsBudgetVisible}
                    setIsCoupleVisible={setIsCoupleVisible}
                    setIsdestinationVisible={setIsdestinationVisible}
                />
                <BudgetModal
                    visible={isBudgetVisible}
                    onClose={() => setIsBudgetVisible(false)}
                    reservation={reservation}
                    setReservation={setReservation}
                    setIsSubmitVisible={setIsSubmitVisible}
                    setIsGuestVisible={setIsGuestVisible}
                    setIsCoupleVisible={setIsCoupleVisible}
                />
                <CoupleModal
                    visible={isCoupleVisible}
                    onClose={() => setIsCoupleVisible(false)}
                    reservation={reservation}
                    setReservation={setReservation}
                    setIsBudgetVisible={setIsBudgetVisible}
                    setIsGuestVisible={setIsGuestVisible}
                />
                <SubmitModal
                    visible={isSubmitVisible}
                    onClose={() => setIsSubmitVisible(false)}
                    reservation={reservation}
                    setReservation={setReservation}
                    setIsBudgetVisible={setIsBudgetVisible}
                    post={destinationPost}
                    advisor={destinationAdvisor}
                    setIsRequestSubmitVisible={setIsRequestSubmitVisible}
                />
                <RequestSuccessModal
                    visible={isRequestSubmitVisible}
                    onClose={() => setIsRequestSubmitVisible(false)}
                    data={destinationAdvisor ? destinationAdvisor : destinationPost}
                />
                <WelcomeModal
                    visible={isWelcomeVisible}
                    onClose={() => setIsWelcomeVisible(false)}
                    setIsPopupVisible={setIsPopupVisible}
                    setIsJoinNow={setIsJoinNow}
                />
                <PopUpModal
                    visible={isPopupVisible}
                    onClose={closeModal}
                    isJoinNow={isJoinNow}
                    setIsJoinNow={setIsJoinNow}
                    setIsModalVisible={setIsPopupVisible}
                />
                <Authenticate
                    visible={isAuthenticate}
                    onClose={() => setIsAuthenticate(false)}
                    setIsPopupVisible={setIsPopupVisible}
                    setIsJoinNow={setIsJoinNow}
                />

            </div>

        </>
    )
}

export default Hotel;