import React, { useEffect, useState } from "react";
import moment from "moment";
import ButtonSmall from "../ButtonSmall";
import BadgeVisible from "../BadgeVisible";
import { POST_TYPES } from "../../Helpers";
import ModalManager from "../ModalManager";
import { useNavigate } from "react-router-dom";
import useStore from "../../providers/StoreProvider";
import ReactStars from "react-rating-stars-component";
import SimpleImageSlider from "react-simple-image-slider";
import StarRatingComponent from 'react-star-rating-component';
import useNotification from "../../providers/NotificationProvider";
import Occasionpreference from '../../api/models/Occasionpreference';
import "./style.css";

const Searchcard = ({
  imageSrc,
  type,
  title,
  city,
  country,
  starRating,
  price,
  HotelId,
  tokenForNewHotel,
  description,
  ip,
  startDate,
  endDate,
  correlationId,
  lovuhotel,
  LocationId,
  occasion,
  people,
  location,
  ratings,
  count,
  noofnights,
  tenpercent,
  twentypercent,
  encodedRooms,
  images,
  Value
}) => {

  const { store } = useStore();
  const user = store.user;
  let token = localStorage.getItem('data');

  const [notification, createNotification] = useNotification();
  // const [Value, setValue] = useState();
  const [occasionpreference, setOccasionpreference] = useState([]);
  const [isDestinationVisible, setIsdestinationVisible] = useState(false);
  const [isAdvisorList, setIsAdvisorList] = useState(false);
  const [destinationPost, setDestinationPost] = useState();
  const [destinationAdvisor, setDestinationAdvisor] = useState(null);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [isWelcomeVisible, setIsWelcomeVisible] = useState(false);
  let arrival = moment(new Date()).format("MM/DD/YYYY");
  let depature = moment(new Date()).add(6, 'days').format("MM/DD/YYYY");
  const [reservation, setReservation] = useState({
    occasion: '',
    arrival_date: arrival,
    departure_date: depature,
    city: '',
    flights: true,
    flexible_dates: true,
    persons: 2,
    noofchildrens: 1,
    rooms: 1,
    nights: 1,
    budget: 10000,
    type: '',
    message: '',
    desire_destination1: '',
    desire_destination2: '',
    guestsOwnRoom: true,
    includes: 'Everything',
    property_id: destinationAdvisor ? destinationPost?.id : null,
  });
  var currentdate = new Date().getTime();
  var expir = new Date(store?.user?.subscription_expires_at).getTime();

  const getType = data => {
    return {
      property: {
        button: 'Request a private offer',
        onclick: () => {
          if (
            store?.user?.requests_count >= 3 &&
            (!store?.user?.subscription_expires_at || currentdate > expir)
          ) {
            createNotification({
              title: 'Premium required!',
              content:
                'You’ve sent three offer requests already. To send more you need to upgrade premium account.',
              primary: {
                title: 'OK',
              },
            });
            return;
          } else {

            if (!token) {
              setIsWelcomeVisible(true)
            } else {
              setIsAdvisorList(true)
            }
          }
        },
        buttons: {
          website: 'Visit Hotel’s website',
          rewards: 'Rewards membership',
        },
      },
      advisor: {
        button: 'Request a private offer',
        onclick: () => {
          if (
            store?.user?.requests_count >= 3 &&
            (!store?.user?.subscription_expires_at || currentdate > expir)
          ) {
            createNotification({
              title: 'Premium required!',
              content:
                'You’ve sent three offer requests already. To send more you need to upgrade premium account.',
              primary: {
                title: 'OK',
              },
            });
            return;
          } else {
            if (!token) {
              setIsWelcomeVisible(true)
            } else {
              setIsdestinationVisible(true)
            }
          }
        },

        buttons: {
          website: 'Visit my website',
        },
      },
      brand: {
        button: null,
        buttons: {
          properties: `${data?.title} Hotels`,
          advisors: 'Travel Specialists',
          website: 'Visit Website',
          rewards: 'Join Membership Rewards',
        },
      },
      destination: {
        button: 'Request a private offer',
        onclick: () => {
          if (
            store?.user?.requests_count >= 3 &&
            (!store?.user?.subscription_expires_at || currentdate > expir)
          ) {
            createNotification({
              title: 'Premium required!',
              content:
                'You’ve sent three offer requests already. To send more you need to upgrade premium account.',
              primary: {
                title: 'OK',
              },
            });
            return;
          } else {
            if (!token) {
              setIsWelcomeVisible(true)
            } else {
              setIsAdvisorList(true)
            }
          }
        },
        buttons: {
          properties: 'Where to Stay',
          advisors: 'Travel Specialists',
          website: 'Visit Website',
        },
      },
      collection: {
        button: null,
        buttons: {},
      },
      basic: {
        button: 'Request a private offer',
        onclick: () => {
          if (
            store?.user?.requests_count >= 3 &&
            (!store?.user?.subscription_expires_at || currentdate > expir)
          ) {
            createNotification({
              title: 'Premium required!',
              content:
                'You’ve sent three offer requests already. To send more you need to upgrade premium account.',
              primary: {
                title: 'OK',
              },
            });
            return;
          } else {
            if (!token) {
              setIsWelcomeVisible(true)
            } else {
              setIsAdvisorList(true)
            }
          }
        },
        buttons: {
          website: 'Visit Hotel’s website',
        },
      },
      offer: {
        button: data?.website ? 'Reserve this offer' : null,
        buttons: {},
        onclick: () => { window.open(data?.website, '_self') }
      },
      worldiaitenararies: {
        button: data?.website ? 'Reserve this offer' : null,
        buttons: {},
        onclick: () => { window.open(data?.website, '_self') }
      },
    }[data?.type];
  };

  const buttontype = getType(Value);
  useEffect(() => {
    if (isAdvisorList || isDestinationVisible) {
      Occasionpreference.all(1).then(({ data }) => setOccasionpreference(data));
    }
  }, [isAdvisorList, isDestinationVisible]);

  // useEffect(() => {
  //   if (lovuhotel) {
  //     PostModel.showbyname(title?.split('-')?.join('_')?.replace(/\s+/g, '-').toLowerCase())
  //       .then(setValue)
  //       .catch(() => { });
  //   }
  // }, [lovuhotel]);


  useEffect(() => {
    setDestinationPost(Value);
    setReservation({
      ...reservation,
      occasion: user?.occasion,
    })
  }, [Value, user]);


  const closeModal = () => {
    setIsPopupVisible(false);
  };


  //request a private offer


  const [rating, setrating] = useState(starRating)
  const navigate = useNavigate();


  useEffect(() => {
    setrating(starRating)
  }, [starRating, rating])

  const handleHotelRoomHandler = (e) => {
    {
      price == undefined ?
        navigate(`/hotelroom/${HotelId}?filter=${title}&token=${tokenForNewHotel}&ip=${ip}&currentpage=${e}&startDate=${startDate}&endDate=${endDate}&correlationId=${correlationId}&LocationId=${LocationId}&occasion=${occasion}&people=${people}&location=${location}&rooms=${encodedRooms}&hideRooms=${true}`)
        :
        navigate(`/hotelroom/${HotelId}?filter=${title}&token=${tokenForNewHotel}&ip=${ip}&currentpage=${e}&startDate=${startDate}&endDate=${endDate}&correlationId=${correlationId}&LocationId=${LocationId}&occasion=${occasion}&people=${people}&location=${location}&rooms=${encodedRooms}`)
    }
  }

  const [showNav, setShowNav] = useState(true);

  useEffect(() => {
    if (images?.length === 1) {
      setShowNav(false);
      return;
    }
  }, [images]);

  const formattedTitle = title?.split('-')?.join('_')?.replace(/\s+/g, '-').toLowerCase();

  const changeType = () => {
    if (["basic", "property", "brand", "destination", "offer"].includes(type)) {
      return `/${formattedTitle}`;
    } else if (type === "advisor") {
      return `/${'ta'}/${formattedTitle}`;
    } else {
      return `/${type}/${formattedTitle}`;
    }
  };


  return (
    <>
      <div className="search-card-container">
        {
          images?.length > 0 ?
            <div className="search-card-img">
              <SimpleImageSlider
                style={{ position: 'absolute' }}
                width={'100%'}
                height={'100%'}
                images={images}
                showNavs={showNav}
                navSize={{ width: "10px" }}
              />
            </div>
            :
            <img className="search-card-img" src={imageSrc} alt="imgsrc" />
        }
        <div className="search-card-subcontainer">
          <div className="search-rating-content-maincontainer">
            <div className="search-rating-content-container">
              <div className="search-tag-star">
                <div className="search-badge" >
                  {
                    lovuhotel ?
                      <BadgeVisible
                        className="class-33"
                        text={POST_TYPES[type]}
                      />
                      :
                      <BadgeVisible
                        className="class-34"
                        text={type}
                      />
                  }
                </div>
                <div className="search-star">
                  {
                    !lovuhotel && <div className="review-rating">
                      {
                        ratings && ratings != -1 &&
                        <span className="review-rating-span">{ratings}</span>
                      }
                      {
                        count != 0 &&
                        <div className="review-count">{count} Reviews</div>
                      }
                    </div>
                  }
                  {rating ? <StarRatingComponent
                    className='star-component'
                    name="rate1"
                    starCount={Number(5)}
                    value={Number(rating)}
                    starColor="rgb(255, 215, 0)"
                    emptyStarColor="rgb(205, 205, 205)"
                    editing={false}
                  /> : null}
                </div>
              </div>
              <div className="search-card-content">
                <div className="seacrh-title">{title}</div>
                <div className="seacrh-address">{country}{city && ` , ${city}`}</div>
                {description &&
                  <div className="seacrh-address">{description.slice(0, 130) + ' ...'}</div>
                }
              </div>
            </div>
            {
              !lovuhotel && price != undefined &&
              <div className="search-card-features-container">
                <div className="search-card-features-content">Free Cancellation</div>
                <div className="zentrum-dot"></div>
                <div className="search-card-features-content">Breakfast Included</div>
              </div>
            }
          </div>
          <div className="mobile-free-cancel">
            {starRating && <ReactStars
              count={Number(5)}
              edit={false}
              value={Number(starRating)}
              isHalf={true}
              size={Number(22)}
              color="rgba(205, 205, 205, 1)"
              activeColor="#ffd700"
            />}
            <div className="mobile-search-price">
              {lovuhotel && price && <div className="search-price">${price} <div className="search-price-sub"> per night</div></div>}
              {
                !lovuhotel && price != undefined &&
                <div className="search-price-sub mobile-sub"> Price for <span className="span-search-price-sub"> {noofnights} {noofnights > 1 ? 'nights' : 'night'}</span></div>
              }
              {
                !lovuhotel &&
                <div className="search-price-container">
                  {price != undefined && (price ? <div className="search-price">
                    {
                      lovuhotel ?
                        ''
                        :
                        token === null ?
                          ''
                          :
                          token !== null && (user?.subscription_sku == null || currentdate > expir) ?

                            <span className="search-price strick"><s>${tenpercent}  </s></span>
                            :
                            <span className="search-price strick"><s>${twentypercent}  </s></span>

                    }
                    ${price}</div>

                    : <div className="search-price skeleton-box search-price-height"></div>)}
                </div>
              }

            </div>
            <div className="mobile-available-button">
              {
                lovuhotel ?
                  <>
                    {buttontype?.button !== null &&
                      <ButtonSmall buttonstyle="default" className="addition-btn-style" textStyle="available-btn" text={buttontype?.button} handleClick={buttontype?.onclick} />
                    }
                    <ButtonSmall className="button-instance-black-outline searchcard-loadmore-btn" buttonstyle="button-outline" textStyle="reservation-cancel-text" text="Learn More" handleClick={() => navigate(`/${changeType()}`)} />
                  </>

                  :
                  <>
                    {
                      price != undefined ? <ButtonSmall className="addition-btn-style" buttonstyle="default" text="See Available Rooms" textStyle="available-btn" handleClick={() => handleHotelRoomHandler('room')} /> : <div className="search-card-sold-out">We are sold out</div>
                    }
                    <ButtonSmall className="button-instance-black-outline reservation-cancel-btn" buttonstyle="button-outline" textStyle="reservation-cancel-text" text="Learn More" handleClick={() => handleHotelRoomHandler('hotel')} />
                  </>
              }
            </div>
            {
              !lovuhotel &&
              <div className={`lovu-discount-container ${token === null ? '' : token !== null && (user?.subscription_sku == null || currentdate > expir) ? '' : 'lovu-discount-none'}`}>
                {
                  token == null
                    ?
                    <>
                      Sign in for <span>up to 10% off or</span>  join LOVU Membership<span> for up to 20% off</span>
                    </>
                    :
                    <>
                      Upgrade to LOVU Membership <span>for up to 20% off</span>
                    </>

                }
              </div>
            }
          </div>

          <div className="hotel-room-container">
            {
              !lovuhotel && price != undefined &&
              <div className="search-price-sub"> Price for <span className="span-search-price-sub"> {noofnights} {noofnights > 1 ? 'nights' : 'night'}</span></div>
            }
            <div className="search-price-container">
              {price != undefined && (price ? <div className="search-price">
                {
                  lovuhotel ?
                    ''
                    :
                    token === null ?
                      ''
                      :
                      token !== null && (user?.subscription_sku == null || currentdate > expir) ?

                        <span className="search-price strick"><s>${tenpercent}  </s></span>
                        :
                        <span className="search-price strick"><s>${twentypercent}  </s></span>

                }
                ${price} {lovuhotel && <div className="search-price-sub lovu-sub-price"> per night</div>}</div>

                : <div className="search-price skeleton-box search-price-height"></div>)}
            </div>
            <div className="free-camcel-container">
              {
                lovuhotel ?
                  <>
                    {
                      type == 'worldiaitenararies' ?
                        <div></div>
                        :
                        <ButtonSmall className="button-instance-black-outline reservation-cancel-btn" buttonstyle="button-outline" textStyle="reservation-cancel-text" text="Learn More" handleClick={() => navigate(`${changeType()}`)} />
                    }
                    {buttontype?.button !== null &&
                      <ButtonSmall buttonstyle="default" className="addition-btn-style" textStyle="available-btn" text={buttontype?.button} handleClick={buttontype?.onclick} />
                    }
                  </>
                  :
                  <>
                    <ButtonSmall className="button-instance-black-outline reservation-cancel-btn" buttonstyle="button-outline" textStyle="reservation-cancel-text" text="Learn More" handleClick={() => handleHotelRoomHandler('hotel')} />
                    {
                      price != undefined ? <ButtonSmall className="addition-btn-style" buttonstyle="default" text="See Available Rooms" textStyle="available-btn" handleClick={() => handleHotelRoomHandler('room')} /> : <div className="search-card-sold-out">We are sold out</div>
                    }
                  </>
              }
            </div>
            {
              !lovuhotel && price != undefined &&
              <div className={`lovu-discount-container ${token === null ? '' : token !== null && (user?.subscription_sku == null || currentdate > expir) ? '' : 'lovu-discount-none'}`}>
                {
                  token == null
                    ?
                    <>
                      Sign in for <span>up to 10% off or</span>  join LOVU Membership<span> for up to 20% off</span>
                    </>
                    :
                    <>
                      Upgrade to LOVU Membership <span>for up to 20% off</span>
                    </>

                }
              </div>
            }
          </div>
        </div >
        <ModalManager
          isAdvisorList={isAdvisorList}
          post={Value}
          setIsAdvisorList={() => setIsAdvisorList(false)}
          reservation={reservation}
          setReservation={setReservation}
          isWelcomeVisible={isWelcomeVisible}
          closeModal={closeModal}
          setIsWelcomeVisible={setIsWelcomeVisible}
          setIsdestinationVisible={setIsdestinationVisible}
          setIsPopupVisible={setIsPopupVisible}
          setDestinationAdvisor={setDestinationAdvisor}
          destinationPost={destinationPost}
          destinationAdvisor={destinationAdvisor}
          isDestinationVisible={isDestinationVisible}
          isPopupVisible={isPopupVisible}
          setDestinationPost={setDestinationPost}
          occasionpreference={occasionpreference}
        />
      </div >
    </>

  );
};

export default Searchcard