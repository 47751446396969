import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BadgeVisible from "../BadgeVisible";
import { POST_TYPES } from "../../Helpers";
import PostModel from "../../api/models/Post";
import { extractColors } from 'extract-colors'
import ReactStars from "react-rating-stars-component";
import "./style.css";

const Card = ({
  cardStyle,
  backgroundColor,
  imageSrc,
  type,
  title,
  city,
  country,
  starRating,
  hasRatingPrice,
  price,
  id,
  length,
  isBadgeVisible = true,
  url,
  isItinerary,
  setIsItineararyLoading
}) => {

  const [imageColor, setImageColor] = useState(null);
  let tokendata = localStorage.getItem('data');
  const formattedTitle = title?.split('-')?.join('_')?.replace(/\s+/g, '-').toLowerCase();

  useEffect(() => {
    if (imageSrc != undefined) {
      const options = {
        crossOrigin: "anonymous",
        pixels: 64000,
        distance: 0.60,
        colorValidator: (red, green, blue, alpha = 255) => alpha > 250,
        saturationDistance: 0.2,
        lightnessDistance: 0.2,
        hueDistance: 0.083333333
      }
      if (!backgroundColor || backgroundColor == 'transparent') {
        extractColors(imageSrc, options)
          .then((e) => {
            setImageColor(e[0].hex);
          }).catch(setImageColor('rgb(104, 125, 101)'));
      } else {
        setImageColor(backgroundColor);
      }
    }
  }, []);

  const handleTripUrl = async () => {
    if (tokendata && type === 'worldiaitenararies') {
      setIsItineararyLoading(true);
      try {
        const res = await PostModel.itinearary();
        if (res) {
          setIsItineararyLoading(false);
          const fullurl = `${url}?${res.query}`;
          window.location.href = fullurl;
        }
      } catch (error) {
        setIsItineararyLoading(false);
      }

    } else {
      if (type === 'worldiaitenararies') {
        window.location.href = url;
      }
    }
  };

  const changeType = () => {
    if (type === "worldiaitenararies") {
      if (url) {
        return;
      }
    } else if (["basic", "property", "brand", "destination", "offer"].includes(type)) {
      return `/${formattedTitle}`;
    } else if (type === "advisor") {
      return `/${'ta'}/${formattedTitle}`;
    } else {
      return `/${type}/${formattedTitle}`;
    }
  };

  const cardContent = (
    <>
      {isBadgeVisible ? (
        <div className={`frame-of-badges`}>
          {type !== 'basic' && (
            <BadgeVisible
              className={`${type === "brand" || type === "advisor" ? "class-34" : "class-33"}`}
              text={type === 'property' ? cardStyle : POST_TYPES[type]}
            />
          )}
        </div>
      ) : <div></div>}

      <div className="card-meta">
        <div className="hotel-location">
          <div className="name-dark">
            <p className="text-wrapper-5">{title}</p>
          </div>
          <div className="location">
            {city}{city && country ? ',' : ""} {country && country}
          </div>
        </div>
        <>
          {hasRatingPrice && (
            <div className="rating-price">
              <div className="star-rating">
                <ReactStars
                  count={5}
                  edit={false}
                  value={starRating}
                  isHalf={true}
                  size={19}
                  color="#fff"
                  activeColor="#ffd700"
                />
              </div>
              {price && <div className="price-from">from ${price}</div>}
            </div>
          )}
          {isItinerary && (
            <div className="rating-price">
              {(length != null || length != undefined) && <div className="price-from">{length} days</div>}
              {price && <div className="price-from">from ${price}</div>}
            </div>
          )}
          {type === 'offer' && (
            <div className="rating-price">
              {price && <div className="price-from">from ${price}</div>}
            </div>
          )}
        </>
      </div>
    </>
  )

  return (
    type == 'worldiaitenararies' ?
      (<a href={changeType()} onClick={() => handleTripUrl()} className={`card ${cardStyle}`}
        style={{
          background: `linear-gradient(180deg, rgb(0 0 0 / 0%) 0%, ${imageColor} 100%), url(${imageSrc}) no-repeat`
        }}>
        {cardContent}
      </a>) :
      (<Link to={changeType()} onClick={() => handleTripUrl()} className={`card ${cardStyle}`}
        style={{
          background: `linear-gradient(180deg, rgb(0 0 0 / 0%) 0%, ${imageColor} 100%), url(${imageSrc}) no-repeat`
        }}>
        {cardContent}
      </Link>)
  );
};

export default Card;
