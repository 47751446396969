import React, { useCallback, useEffect, useState } from 'react'
import Content from '../Content';
import Gallery from '../Gallery';
import { Skeleton as Sk } from "antd";
import ReactPlayer from 'react-player/lazy'
import ImageTextCard from '../ImageTextCard';
import Skeleton from 'react-loading-skeleton';

const ContentBlock = (props) => {
    const { data } = props;
    const [isMobile, setIsMobile] = useState(false);
    const [isGaleryVisible, setIsGaleryVisible] = useState(false);
    const [currentImage, setCurrentImage] = useState(0);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const openImageViewer = useCallback((index) => {
        setCurrentImage(index);
        setIsGaleryVisible(true);
    }, []);

    const closeImageViewer = () => {
        setCurrentImage(0);
        setIsGaleryVisible(false);
    };

    return (
        <>
            {data ? data?.content?.map((content, index) => {
                switch (content?.type) {

                    case 'ContentBlock':
                        if (!content?.data) {
                            return false;
                        }
                        return <Content key={index} data={content?.data} />
                    case 'GalleryBlock':
                        if (!content.media.length) {
                            return false;
                        }
                        return (<div className={isMobile ? 'image-card' : 'image-card-none'}>
                            <div className='main-flex-row'>
                                <div className={`flex-row leftColumn`}>
                                    {content.media
                                        .filter((el, filterIndex) => [0, 1, 2].includes(filterIndex))
                                        .map((el, index) => {
                                            const completed = [0].includes(index) ? "bigSquare" : [1].includes(index) ? "squareImage" : "squareImage1";
                                            return <img onClick={() => openImageViewer(el?.id)} key={index} src={el?.sizes.small} alt='No images1 found' className={completed} />
                                        }
                                        )}
                                </div>
                                <div className={`flex-row rightColumn`}>
                                    {content.media
                                        .filter((el, filterIndex) => [3, 4].includes(filterIndex))
                                        .map((el, index) => {
                                            const completed = [0].includes(index) ? "squareImage2" : "portraitBigImage";
                                            return <img onClick={() => openImageViewer(el?.id)} key={index} src={el?.sizes.small} alt='No images1 found' className={completed} />
                                        }
                                        )}
                                </div>
                            </div>
                            <div className={`secondRow`}>
                                {content.media
                                    .filter((el, filterIndex) => [5, 6, 7, 8, 9, 10, 11, 12, 13, 14].includes(filterIndex))
                                    .map((el, index) => {
                                        const completed =
                                            ([0].includes(index) &&
                                                "secondRowImage1") ||
                                            ([1].includes(index) &&
                                                "secondRowImage2") ||
                                            ([2, 3, 4].includes(index) &&
                                                "secondRowImage3") ||
                                            ([5].includes(index) &&
                                                "secondRowImage4") ||
                                            ([6].includes(index) &&
                                                "secondRowImage5") ||
                                            ([7, 8, 9].includes(index) &&
                                                "secondRowImage3");
                                        return <img onClick={() => openImageViewer(el?.id)} key={index} src={el?.sizes.small} alt='No images1 found' className={completed} />
                                    }
                                    )}
                            </div>

                            <Gallery
                                closeImageViewer={closeImageViewer}
                                currentIndex={currentImage}
                                data={content.media}
                                isGaleryVisible={isGaleryVisible}
                            />
                        </div>
                        );
                    case 'VideoBlock':
                        if (!content?.media?.length) {
                            return false;
                        }
                        return <ReactPlayer
                            key={index}
                            height="auto"
                            width="100%"
                            url={content?.media[0]?.sizes.full}
                            controls={true}
                            style={{ marginTop: 10 }}
                        />
                    case 'OffersBlock':
                        if (!(content.data && content.data.length)) {
                            return false;
                        }
                        let offers = content.data.map((item, i) => ({
                            ...item,
                            image: content.media[i].sizes.small,
                        }));
                        return <>
                            {data?.type === 'basic' || data?.type === 'advisor' || data?.type === 'offer' || data?.type === 'collection' ?
                                <>
                                    <h2 className='special-offer-title'>Special Offers</h2>
                                    <ImageTextCard
                                        data={offers}
                                    />
                                </>
                                : isMobile &&
                                <>
                                    <h2 className='special-offer-title'>Special Offers</h2>
                                    <ImageTextCard
                                        data={offers}
                                    />
                                </>}
                        </>
                    case 'CertificatesBlock':
                        if (!content.media.length) {
                            return false;
                        }
                        return <>

                            {data?.type === 'basic' || data?.type === 'advisor' || data?.type === 'offer' || data?.type === 'collection' ?
                                <>
                                    <h1 className='special-offer-title'>
                                        Industry Affiliations
                                    </h1>
                                    <div className='certificateContainer'>
                                        {
                                            content?.media.map((media, id) => {

                                                return <div className='certificate-card'>
                                                    <img src={media.sizes.small} alt='No image1 found' className="certificate-image" />
                                                </div>
                                            })}

                                    </div>
                                </>
                                : isMobile &&
                                <>
                                    <h1 className='special-offer-title'>
                                        Industry Affiliations
                                    </h1>
                                    <div className='certificateContainer'>
                                        {
                                            content?.media.map((media, id) => {

                                                return <div className='certificate-card'>
                                                    <img src={media.sizes.small} alt='No image1 found' className="certificate-image" />
                                                </div>
                                            })}

                                    </div>
                                </>}

                        </>
                    case 'ReviewsBlock':
                        if (!(content.data && content.data.length)) {
                            return false;
                        }
                        return <>

                            {
                                data?.type === 'basic' || data?.type === 'advisor' || data?.type === 'offer' || data?.type === 'collection' ?
                                    <>
                                        <h1 className='special-offer-title'>
                                            Reviews
                                        </h1>
                                        <div className='reviewcontainer'>
                                            {
                                                content?.data.map((item, id) => {
                                                    return <>
                                                        <h1 className='reviewContent'>{item.description}</h1>
                                                        <div className='reviewAuthorContainer'>
                                                            <h2 className='reviewAuthorText'>
                                                                {item.name} – {item.from}
                                                            </h2>
                                                            <h2 className='reviewAuthorText'>Traveled to {item.to}</h2>
                                                        </div>
                                                    </>
                                                })}

                                        </div>
                                    </>
                                    :
                                    isMobile &&
                                    <>
                                        <h1 className='special-offer-title'>
                                            Reviews
                                        </h1>
                                        <div className='reviewcontainer'>
                                            {
                                                content?.data.map((item, id) => {
                                                    return <>
                                                        <h1 className='reviewContent'>{item.description}</h1>
                                                        <div className='reviewAuthorContainer'>
                                                            <h2 className='reviewAuthorText'>
                                                                {item.name} – {item.from}
                                                            </h2>
                                                            <h2 className='reviewAuthorText'>Traveled to {item.to}</h2>
                                                        </div>
                                                    </>
                                                })}

                                        </div>
                                    </>
                            }

                        </>


                    default: return
                }
            })
                :
                <>
                    <Sk title={2} paragraph={{ rows: 8 }} className='skeleton-content' />
                    <Sk paragraph={{ rows: 8 }} />
                    <div className={isMobile ? 'image-card' : 'image-card-none'}>
                        <div className={`secondRow`}>
                            <Skeleton square={true} style={{ margin: "2px" }} height={120} width={160} />
                            <Skeleton square={true} style={{ margin: "2px" }} height={120} width={310} />
                            <Skeleton square={true} style={{ margin: "2px" }} height={120} width={310} />
                        </div>
                    </div>
                </>
            }
        </>

    )
}

export default ContentBlock