import React from "react";
import { Skeleton as Sk } from "antd";
import ButtonSmall from "../../Components/ButtonSmall";
import Skeleton from "react-loading-skeleton";

const ImageTextItem = ({
    index,
    item,
}) => {
    return (
        <div className="image-text-item">
            {index === 0 ? <img className="vector" alt="Vector" src="https://c.animaapp.com/dRA3vJLF/img/vector-1.svg" />
                : null}
            <div className="heading-and-wrapper">
                <div className="heading-and">
                    {index === 2 ?
                        <img className="vector-2" alt="Vector" src="https://c.animaapp.com/dRA3vJLF/img/vector.svg" />
                        : null}

                    {item?.imageSrc ?
                        <img className="image-text-thumbnail" alt="Image1" src={item.imageSrc} />
                        : <div className="image-text-thumbnail skeleton-box" />

                    }
                    {item?.heading ?
                        <p className="subheading">{item.subHeading} </p>
                        : <Sk />
                    }
                    <div className="heading-and-text">
                        <div className="heading-and-icon">
                            <div className="heading-2">{item?.heading ? item?.heading : null}</div>
                        </div>
                        <p className="supporting-text-2">{item?.text ? item?.text : null} </p>
                    </div>
                    {item?.heading ?
                        <a href={item?.href}>
                            {item?.buttonText &&
                                <ButtonSmall
                                    textStyle="search-btn"
                                    className="button-small-5"
                                    buttonstyle="default"
                                    text={item.buttonText}
                                    handleClick={item?.handleClick}
                                />
                            }
                        </a>
                        : <Skeleton width={90} height={40} />
                    }
                </div>
            </div>
        </div>
    );
};

export default ImageTextItem;