import React, { useEffect, useState } from 'react'
import Card from '../../Components/Card';
import Footer from '../../Components/Footer'
import NavBar from '../../Components/NavBar'
import { useParams } from 'react-router-dom';
import Banner from '../../Components/Banner';
import PostModel from "../../api/models/Post"
import Skeleton from 'react-loading-skeleton'
import MetaTags from '../../Components/MetaTags';
import 'react-loading-skeleton/dist/skeleton.css'
import SearchBar from '../../Components/SearchBar';

const Collection = ({ setProgress }) => {

    const params = useParams();
    const [Value, setValue] = useState();
    const [isVisible, setIsVisible] = useState(true);

    useEffect(() => {
        setProgress(10)
        setProgress(20)
        setProgress(60)
        setProgress(100)
    }, [setProgress])

    useEffect(() => {
        PostModel.showbyname(params?.id)
            .then(setValue)
            .catch(() => { });
    }, [params.id]);

    return (
        <>
            <MetaTags
                title={Value?.meta_title == null ? `LOVU: ${Value?.title}` : Value?.meta_title}
                description={Value?.meta_description == null ? Value?.description : Value?.meta_description}
                imageScr={Value?.meta_image?.length == 0 ? Value?.featured_image?.original_url : Value?.meta_image[0]?.original_url}
            />
            <div className="collectionpage" >
                <NavBar navigationDark={true} />
                {Value ? (<Banner
                    isSearch={true}
                    imageSrc={Value?.featured_image?.original_url}
                    widget={
                        <SearchBar
                            heightToHideFrom={90}
                            isVisible={isVisible}
                            setIsVisible={setIsVisible}
                        />
                    }
                />) : <div className='hero skeleton-box1' />}
                <div className="pageContent">
                    <div>
                        <div className='collection-content'>
                            <div className="collection-texts">
                                {Value?.title ?
                                    <h1 className="collection-title">{Value?.title}</h1>
                                    :
                                    <Skeleton width={350} />
                                }
                                {Value?.description ?
                                    <p className="collection-description">{Value?.description}</p>
                                    :
                                    <>
                                        <Skeleton />
                                        <Skeleton width={500} />
                                    </>
                                }
                            </div>
                            <div className="card-list-container">
                                {Value?.related ? (
                                    <div className="card-list-wrap">
                                        {Value?.type === 'collection' && (
                                            Value?.related?.map((content, index) => <Card
                                                key={index}
                                                imageSrc={content?.featured_image?.original_url}
                                                title={content?.title}
                                                type={content?.type}
                                                city={content?.city}
                                                country={content?.country}
                                                hasRatingPrice={content?.rating && true}
                                                starRating={content?.rating}
                                                price={content?.price}
                                                backgroundColor={content?.image_color ? content?.image_color : null}
                                                cardStyle={content?.template_type}
                                                id={content?.id}
                                            />)
                                        )}
                                    </div>

                                ) : <div className="card-list-wrap" >
                                    <div className="card skeleton-box" />
                                    <div className="card skeleton-box" />
                                    <div className="card skeleton-box" />
                                    <div className="card skeleton-box" />
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
                <Footer className="design-component-instance-node-3" isVisible={isVisible} />
            </div>
        </>
    )
}

export default Collection;