import React, { useEffect, useState } from "react";
import Banner from "../../../Components/Banner";
import Footer from "../../../Components/Footer";
import NavBar from "../../../Components/NavBar";
import MetaTags from "../../../Components/MetaTags";
import Metatag from "../../../api/models/Metatag.js";
import bannerImage from "../../../assets/images/ZentiumSearchBanner.jpeg"

const Contact = ({ setProgress }) => {

    const [seo, setSeo] = useState();
    useEffect(() => {
        setProgress(10)
        setProgress(20)
        setProgress(60)
        setProgress(100)
    }, [setProgress])

    useEffect(() => {
        Metatag.metaslug('contact').then(result =>
            setSeo(result)
        );
    }, []);

    return (
        <>
            <MetaTags
                title={seo?.title != null ? seo?.title : "LOVU: Contact"}
                description={seo?.description != null ? seo?.description : "Discover handpicked romantic destinations with LOVU. Connect with expert Romance Travel Advisors to plan the perfect honeymoon, anniversary, or getaway."}
                imageScr={seo?.meta_image[0]?.original_url != undefined ? seo?.meta_image[0]?.original_url : bannerImage}
            />
            <div className="homepage privacy">
                <NavBar navigationDark={true} />
                {/* <Banner
                    isSearch={true}
                    imageSrc={bannerImage}
                /> */}
                <div className="pageContent">
                    <div className="primarySection">
                        <div className="privacy-sub-container">
                            <div className="privacy-content"><div className="privacy-subtitle">Reservations Support:</div> <a href="mailto:reservations@romance.travel" className="lovu-contact-phone">reservations@romance.travel</a></div>
                        </div>
                        <h2 className="privacy-main-subtitle"> Corporate Contact: </h2>
                        <div className="privacy-sub-container">
                            <div className="privacy-content"><div className="privacy-subtitle">Email:</div><a href="mailto:info@romance.travel" className="lovu-contact-phone">info@romance.travel</a></div>
                            <div className="privacy-content"><div className="privacy-subtitle">Telephone number:</div> <a href="tel:516-675-6505" className="lovu-contact-phone">516-675-6505</a></div>
                        </div>
                        <h2 className="privacy-main-subtitle"> Press Contact: </h2>
                        <div className="privacy-sub-container">
                            <div className="privacy-content">
                                For more information or to request an interview with our founder, please contact:<br />
                                <div className="privacy-subtitle">Hemsworth Communications</div> at <a href="mailto:LOVUPR@HemsworthCommunications.com" className="lovu-contact-mail"> LOVUPR@HemsworthCommunications.com.</a><br />
                                Please note your specific request and deadline, if applicable, in the email.
                            </div>
                        </div>
                    </div>
                </div>

                <Footer className="design-component-instance-node-3" />
            </div>
        </>
    );
};

export default Contact;