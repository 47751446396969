import React from 'react'
import ImageViewer from 'react-simple-image-viewer';
import "./style.css"



const Gallery = ({ currentIndex, data, closeImageViewer, isGaleryVisible, isZentrumImage, hotelRoom }) => {


    var CurrentIndexValue = data.map(function (img) { return img.id; }).indexOf(currentIndex);

    return (
        <>
            <>
                {
                    isGaleryVisible ?
                        <>
                            <ImageViewer
                                src={hotelRoom ? data.map(image => image?.links[0]?.url) : isZentrumImage ? data.map((el) => el) : data.map((el) => el?.sizes?.small)}
                                currentIndex={isZentrumImage || hotelRoom ? currentIndex : CurrentIndexValue}
                                disableScroll={true}
                                closeOnClickOutside={true}
                                onClose={closeImageViewer}
                                closeImageViewer={closeImageViewer}
                            />
                        </>
                        : null
                }
            </>
            {/* // } */}

        </>


    )
}

export default Gallery