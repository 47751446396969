import React from "react";

const RightArrow = ({ className }) => {
    return (
        <svg className={className} width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.5078 8L9.64844 2.45312C9.60938 2.41406 9.57031 2.375 9.53125 2.375C9.45312 2.375 9.375 2.49219 9.375 2.57031V5.8125C9.375 6.35938 8.94531 6.75 8.4375 6.75H1.875V9.25H8.39844C8.94531 9.25 9.33594 9.67969 9.33594 10.1875V13.4688C9.33594 13.5469 9.45312 13.625 9.53125 13.625C9.57031 13.625 9.60938 13.625 9.64844 13.5859L15.5078 8ZM9.53125 15.5C8.39844 15.5 7.5 14.6016 7.5 13.4688V11.125H1.875C0.820312 11.125 0 10.3047 0 9.25V6.75C0 5.73438 0.820312 4.875 1.875 4.875H7.5V2.57031C7.5 1.4375 8.39844 0.5 9.53125 0.5C10.0781 0.5 10.5859 0.734375 10.9375 1.08594L16.9531 6.82812C17.3047 7.14062 17.5 7.57031 17.5 8C17.5 8.46875 17.3047 8.89844 16.9531 9.21094L10.9375 14.9531C10.5859 15.3047 10.0781 15.5 9.53125 15.5Z" fill="#3B3B4D" />
        </svg>

    );
};

export default RightArrow;