import React from "react";

const Plus = ({ className, isBlack }) => {
    return (
        <svg className={className} width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="x">
                <path id="Shape" d="M24.5 12.9829L1.50097 12.982" stroke={isBlack ? "black" : "white"} stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                <path id="Shape_2" d="M12.9665 1.49951V24.4995" stroke={isBlack ? "black" : "white"} stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
            </g>
        </svg>

    );
};

export default Plus;