
import React, { useEffect, useState } from 'react';
import { Checkbox, Modal, Spin } from 'antd';
import { getOnboarding } from '../../../api/Requests';
import useStore from '../../../providers/StoreProvider';
import ButtonSmall from '../../../Components/ButtonSmall';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Occasionpreference from '../../../api/models/Occasionpreference';
import Wedding from "../../../../src/assets/images/occasion/wedding.jpg"
import Babymoon from "../../../../src/assets/images/occasion/babymoon.jpg"
import Proposal from "../../../../src/assets/images/occasion/proposal.jpg"
import Honeymoon from "../../../../src/assets/images/occasion/honeymoon.jpg"
import VowRenewal from "../../../../src/assets/images/occasion/vowRenewal.jpg"
import Anniversary from "../../../../src/assets/images/occasion/anniversary.jpg"
import NoOccassion from "../../../../src/assets/images/occasion/noOccassion.jpg"
import RomanticGetaway from "../../../../src/assets/images/occasion/romanticGetaway.jpg"
import "./style.css";

const OnBoarding = ({
    visible, onClose
}) => {

    const { store, setStore } = useStore();
    const user = store.user;
    const [occasionpreference, setOccasionpreference] = useState([]);
    const [userData, setUserData] = useState({
        occasion: null,
        tags: [],
        occasionpreferences: []
    });

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pageIndex, setPageIndex] = useState(0);
    const occasions = [
        {
            name: 'Wedding',
            url: Wedding,
        },
        {
            name: 'Honeymoon',
            url: Honeymoon,
        },
        {
            name: 'Anniversary',
            url: Anniversary,
        },
        {
            name: 'Vow Renewal',
            url: VowRenewal,
        },
        {
            name: 'Proposal',
            url: Proposal,
        },
        {
            name: 'Babymoon',
            url: Babymoon,
        },
        {
            name: 'Romantic Getaway',
            url: RomanticGetaway,
        },
        {
            name: 'Celebrating Love',
            url: NoOccassion,
        },
    ];

    useEffect(() => {
        if (pageIndex === 1) {
            getOnboarding()
                .then(setData)
                .catch(() => { });
        }

    }, [pageIndex]);

    useEffect(() => {
        Occasionpreference.all(1).then(({ data }) => setOccasionpreference(data));
    }, []);

    const merge = (arr1, arr2) => {
        const temp = []
        if (arr1.length > 0 && arr2.length > 0) {
            arr1.forEach(x => {
                arr2.forEach(y => {
                    if (x.name === y.name) {
                        temp.push({ ...x, ...y })
                    }
                })
            })
        } else if (arr1.length > 0) {
            arr1.forEach(x => {
                temp.push({ ...x })
            })
        } else if (arr2.length > 0) {
            arr2.forEach(x => {
                temp.push({ ...x })
            })
        }

        return temp
    }

    const occasionList = merge(occasionpreference, occasions);
    useEffect(() => {
        setPageIndex(0)
        setUserData({
            occasion: null,
            tags: [],
            occasionpreferences: []
        })
    }, [visible]);

    function selectTag(id) {
        if (userData.tags.includes(id)) {
            userData.tags.splice(userData.tags.indexOf(id), 1);
            return setUserData({
                ...userData,
                tags: userData.tags,
            });
        }
        setUserData({
            ...userData,
            tags: [...userData.tags, id],
        });
    }

    const handleBack = () => {
        if (pageIndex == 1) {
            setPageIndex(prev => prev - 1);
        } else if (pageIndex == 0) {
            onClose();
        }
    }

    function handleOnPress() {
        if (pageIndex === 1) {
            setLoading(true)
            user.update(userData).then(() => {
                setStore(prev => ({ ...prev, user: user.copy() }));
                setLoading(false)
                onClose();
            });
            return;
        }
        setPageIndex(prev => prev + 1);
    }

    return (
        <Modal
            className='onboardingmodal-maincontainer'
            open={visible}
            maskClosable={false}
            onCancel={onClose}
            footer={[]}
        >
            <div className='onboardingmodal'>
                {pageIndex === 1 && <ArrowBackIcon onClick={handleBack} className='back-arrow' />}
                <div style={{ margin: 'auto 0' }}>
                    <div>
                        <div className='onboardingmodal-mainContentContainer'>
                            {
                                pageIndex === 0 && (
                                    <>
                                        <div className='contentContainer'>
                                            <div className='onboardingHeader'>Hey Luv-Bird! What are we planning
                                                today?</div>
                                            <div className='occasionContainer'>
                                                {occasionList?.map((el, key) => {
                                                    return (
                                                        <div
                                                            key={key}
                                                            className='occasionitem'
                                                            onClick={() => {
                                                                setUserData({
                                                                    ...userData,
                                                                    occasion: el.name,
                                                                    occasionpreferences: [el.id]
                                                                });
                                                                handleOnPress();
                                                            }}
                                                        >
                                                            <img key={key} src={el['url']} alt='No images1 found' className='occasionImage' />
                                                            <h1 className='occasionLabel'>
                                                                {el['name']}
                                                            </h1>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    </>
                                )
                            }
                            {pageIndex === 1 && Boolean(data.length) && (
                                <>
                                    <div>
                                        <div className='onboardingHeader'> How romantic! Where do you see this
                                            happening?</div>
                                        <div className='onboardingSelectionNextContainer'>
                                            {data.map((el, index) => (
                                                <div
                                                    key={index}
                                                    className='onboardingSelection'>
                                                    <Checkbox checked={userData.tags.includes(el.id)} onChange={() => selectTag(el.id)}>
                                                    </Checkbox>
                                                    <div onClick={() => selectTag(el.id)} className='selectionTitle'>
                                                        {el.name}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                        <div className='continueButtonContainer'>
                                            {loading ? <Spin style={{ marginLeft: '8px' }} />
                                                :
                                                <ButtonSmall buttonstyle="default" className='onboarding-btn' disabled={userData.tags.length ? '' : 'disabled-destination-btn'} text="GET STARTED" handleClick={handleOnPress} />
                                            }
                                        </div>
                                    </div>

                                </>
                            )}
                            <div className='counterContainer'>
                                <ul className="pagination-counter">
                                    {[0, 1].map((_, index) => (
                                        <li
                                            key={index}
                                            className={index === pageIndex ? "active" : ""}
                                        />
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </Modal >
    );
};

export default OnBoarding;