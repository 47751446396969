import React, { useCallback, useEffect, useState } from 'react';
import { Skeleton } from "antd";
import PopUpModal from '../Auth/PopUpModal';
import NavBar from '../../Components/NavBar';
import Footer from '../../Components/Footer';
import Banner from '../../Components/Banner';
import PostModel from "../../api/models/Post";
import Content from '../../Components/Content';
import Gallery from '../../Components/Gallery';
import MetaTags from '../../Components/MetaTags';
import WelcomeModal from '../Auth/WelcomeScreen';
import BackArrow from "../../assets/icons/BackArrow";
import ReactStars from "react-rating-stars-component";
import GalleryIcon from "../../assets/icons/GalleryIcon";
import ZentrumRoomCard from '../../Components/ZentrumRoomCard';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import bannerImage from "../../assets/images/ZentiumSearchBanner.jpeg";
import airConditioningIcon from '../../assets/icons/Amenities/air conditioning.svg';
import airportShuttleIcon from '../../assets/icons/Amenities/airport shuttle.svg';
import atmIcon from '../../assets/icons/Amenities/atm.svg';
import balconyIcon from '../../assets/icons/Amenities/balcony.svg';
import banquetIcon from '../../assets/icons/Amenities/banquet.svg';
import barIcon from '../../assets/icons/Amenities/bar.svg';
import barbequeIcon from '../../assets/icons/Amenities/barbeque.svg';
import beachIcon from '../../assets/icons/Amenities/beach.svg';
import bicycleRentalsIcon from '../../assets/icons/Amenities/bicycle rentals.svg';
import billiardsIcon from '../../assets/icons/Amenities/billiards.svg';
import breakfastIcon from '../../assets/icons/Amenities/breakfast.svg';
import bussinessCenterIcon from '../../assets/icons/Amenities/business center.svg';
import casinoIcon from '../../assets/icons/Amenities/casino.svg';
import childcareServiceIcon from '../../assets/icons/Amenities/childcare service.svg';
import cleaningServiceIcon from '../../assets/icons/Amenities/cleaning service.svg';
import clubhouseIcon from '../../assets/icons/Amenities/clubhouse.svg';
import conciergeServicesIcon from '../../assets/icons/Amenities/concierge services.svg';
import conveniencesIcon from '../../assets/icons/Amenities/conveniences.svg';
import currencyExchangeIcon from '../../assets/icons/Amenities/currency-exchange.svg';
import disbaleFriendlyIcon from '../../assets/icons/Amenities/disable friendly.svg';
import dockIcon from '../../assets/icons/Amenities/dock.svg';
import electricCarChargingStationsIcon from '../../assets/icons/Amenities/electric car charging stations.svg';
import elevatorIcon from '../../assets/icons/Amenities/elevator.svg';
import familyfirendlyIcon from '../../assets/icons/Amenities/familyfriendly.svg';
import fitnessFacilityIcon from '../../assets/icons/Amenities/fitness facility.svg';
import foodAndDrinkIcon from '../../assets/icons/Amenities/foodanddrink.svg';
import gameroomIcon from '../../assets/icons/Amenities/game room.svg';
import gardenIcon from '../../assets/icons/Amenities/garden.svg';
import giftshopIcon from '../../assets/icons/Amenities/gift shop.svg';
import goldIcon from '../../assets/icons/Amenities/golf.svg';
import guestservicesIcon from '../../assets/icons/Amenities/guestservices.svg';
import hairsalonIcon from '../../assets/icons/Amenities/hairsalon.svg';
import hotelsIcon from '../../assets/icons/Amenities/hotels.svg';
import internetIcon from '../../assets/icons/Amenities/internet.svg';
import karaokeIcon from '../../assets/icons/Amenities/karaoke.svg';
import kitchenetteIcon from '../../assets/icons/Amenities/kitchenette.svg';
import laundryServicesIcon from '../../assets/icons/Amenities/laundry services.svg';
import libraryIcon from '../../assets/icons/Amenities/library.svg';
import loungeIcon from '../../assets/icons/Amenities/lounge.svg';
import medicalIcon from '../../assets/icons/Amenities/medical.svg';
import multilingualIcon from '../../assets/icons/Amenities/Multilingual.svg';
import nightclubIcon from '../../assets/icons/Amenities/night club.svg';
import NonSmokingIcon from '../../assets/icons/Amenities/non smoking.svg';
import parkingIcon from '../../assets/icons/Amenities/parking.svg';
import petsAllowedIcon from '../../assets/icons/Amenities/pets allowed.svg';
import refrigeratorIcon from '../../assets/icons/Amenities/refrigerator.svg';
import restaurantIcon from '../../assets/icons/Amenities/restaurant.svg';
import roofTopTerraceIcon from '../../assets/icons/Amenities/rooftopterrace.svg';
import roomServiceIcon from '../../assets/icons/Amenities/room service.svg';
import safeDepositBoxIcon from '../../assets/icons/Amenities/safe deposit box.svg';
import sailingIcon from '../../assets/icons/Amenities/sailing.svg';
import saunaIcon from '../../assets/icons/Amenities/sauna.svg';
import sharedMicrowaveIcon from '../../assets/icons/Amenities/sharedmicrowave.svg';
import skiIcon from '../../assets/icons/Amenities/ski.svg';
import snorkelingNearbyIcon from '../../assets/icons/Amenities/snorkeling nearby.svg';
import spaIcon from '../../assets/icons/Amenities/spa.svg';
import suiteIcon from '../../assets/icons/Amenities/suite.svg';
import surfingIcon from '../../assets/icons/Amenities/surfing.svg';
import swimmingPoolIcon from '../../assets/icons/Amenities/swimming pool.svg';
import televisionIcon from '../../assets/icons/Amenities/television.svg';
import tennisIcon from '../../assets/icons/Amenities/tennis.svg';
import thingstodoIcon from '../../assets/icons/Amenities/thingstodo.svg';
import transportationIcon from '../../assets/icons/Amenities/transportation.svg';
import traveldeskIcon from '../../assets/icons/Amenities/travel desk.svg';
import unavailableIcon from '../../assets/icons/Amenities/unavailable.svg';
import villaIcon from '../../assets/icons/Amenities/villa.svg';
import villasIcon from '../../assets/icons/Amenities/villas.svg';
import waterparkIcon from '../../assets/icons/Amenities/waterpark.svg';
import waterslideIcon from '../../assets/icons/Amenities/waterslide.svg';
import "./style.css";

const ZentrumRooms = ({ setProgress }) => {

    const location = useLocation();
    const navigate = useNavigate();
    const searchParams = new URLSearchParams(location.search);
    const hotelTitle = searchParams.get('filter');
    const tokenForNewHotel = searchParams.get('token');
    const ip = searchParams.get('ip');
    const currentpage = searchParams.get('currentpage');
    const startDate = searchParams.get('startDate');
    const endDate = searchParams.get('endDate');
    const correlationId = searchParams.get('correlationId');
    const LocationId = searchParams.get('LocationId');
    const occasion = searchParams.get('occasion');
    const people = searchParams.get('people');
    const locationTitle = searchParams.get('location');
    const encodedRooms = searchParams.get('rooms');
    const hideRooms = searchParams.get('hideRooms');
    const { HotelId } = useParams();


    const [pageIndex, setPageIndex] = useState('1');
    const [hotelLoader, setHotelLoader] = useState(false);
    const [hotelContent, setHotelContent] = useState([]);
    const [hotelImages, setHotelImages] = useState([]);
    const [isImageViewerOpen, setIsImageViewerOpen] = useState(false);
    const [roomLoader, setRoomLoader] = useState(false);
    const [hotelRoomContent, setHotelRoomContent] = useState([]);
    const [selectedRoomIds, setSelectedRoomIds] = useState([]);
    const [isPolicyVisible, setIsPolicyVisible] = useState(false);
    const [isWelcomeVisible, setIsWelcomeVisible] = useState(false);
    const [isPopupVisible, setIsPopupVisible] = useState(false);
    const [isJoinNow, setIsJoinNow] = useState(false);
    const [currentImage, setCurrentImage] = useState(0);

    const closeModal = () => {
        setIsPopupVisible(false);
    };

    useEffect(() => {
        setProgress(10)
        setProgress(20)
        setProgress(60)
        setProgress(100)
    }, [setProgress])

    useEffect(() => {
        if (currentpage === 'room') {
            setPageIndex('1')
        } else if (currentpage === 'hotel') {
            setPageIndex('2')

        }

    }, [currentpage])

    const handleBackToSearchResult = () => {
        navigate(`/search/${LocationId}?occasion=${occasion}&people=${people}&startdate=${startDate}&enddate=${endDate}&location=${locationTitle}&rooms=${encodedRooms}`);
    }


    useEffect(() => {
        getHotelContent();
        getHotelRoomContent();
    }, [HotelId, ip, correlationId])


    const getHotelContent = async () => {
        if (ip) {
            setHotelLoader(true)
            let tokendata = localStorage.getItem('data');
            let rawuser = localStorage.getItem('user');
            const user = JSON.parse(rawuser);
            var currentdate = new Date().getTime();
            var expir = new Date(user?.subscription_expires_at).getTime();
            var channelId = tokendata === null ? process.env.REACT_APP_PUBLIC_ZENTRUM_CHANNEL_ID : tokendata !== null && (user?.subscription_sku == null || currentdate > expir) ? process.env.REACT_APP_NON_PAID_ZENTRUM_CHANNEL_ID : process.env.REACT_APP_MEMBER_PAID_ZENTRUM_CHANNEL_ID;
            await PostModel.fetchhotelcontent(HotelId, ip, correlationId, channelId)
                .then((res) => {
                    setHotelContent(res.hotels);
                    setHotelLoader(false)
                    setHotelImages(res?.hotels?.[0]?.images);
                }
                )
                .catch(() => { });
        }
    }


    const getHotelRoomContent = async () => {
        if (ip && HotelId) {
            setRoomLoader(true)
            PostModel.roomsAndRate(HotelId, tokenForNewHotel, ip, correlationId)
                .then((response) => {
                    if (response) {
                        const data = response.hotel;


                        const allRates = data.standardizedRooms.flatMap(room => {
                            const rates = room?.mappedRoomRates?.flatMap(mappedRate => {
                                const foundRate = data.rates.find(rate => rate?.id === mappedRate?.rateId);
                                return foundRate ? [foundRate] : [];
                            });

                            return rates != undefined ? rates : [];
                        });


                        let standardWithRatesAndGroupIdAndImages = data.rooms.map(room => {
                            const matchedRate = allRates.find(rate => rate.occupancies.some(occupancy => occupancy.roomId === room.id));
                            const matchedRecommendation = data.recommendations.find(recommendation => recommendation.rates.includes(matchedRate?.id));
                            const matchedStandardizedRoom = data.standardizedRooms.find(standardizedRoom => standardizedRoom.id === matchedRate?.occupancies[0]?.stdRoomId);
                            return {
                                ...room,
                                rate: matchedRate || null,
                                recommendation: matchedRecommendation || null,
                                images: matchedStandardizedRoom?.images || null
                            };
                        });
                        if (standardWithRatesAndGroupIdAndImages.every(room => room.rate === null)) {
                            setHotelRoomContent([]);
                            setRoomLoader(false);
                        } else {
                            standardWithRatesAndGroupIdAndImages.sort((a, b) => {
                                if (a.images.length === 0 && b.images.length !== 0) {
                                    return 1;
                                } else if (a.images.length !== 0 && b.images.length === 0) {
                                    return -1;
                                } else {
                                    return 0;
                                }
                            });
                            standardWithRatesAndGroupIdAndImages = standardWithRatesAndGroupIdAndImages.filter(obj => obj.recommendation != null);

                            setHotelRoomContent(standardWithRatesAndGroupIdAndImages);
                            setRoomLoader(false);
                        }
                    }
                })
                .catch((e) => {
                    console.log(e, 'error');

                });
        }
    }


    const openImageViewer = useCallback((index) => {
        setCurrentImage(index);
        setIsImageViewerOpen(true);
    }, []);

    const closeImageViewer = () => {
        setCurrentImage(0);
        setIsImageViewerOpen(false);
    };


    const handleRoomRate = (RoomRate) => {
        if (RoomRate) {

            const timeDifference = new Date(endDate).getTime() - new Date(startDate).getTime();
            const daysDifference = timeDifference / (1000 * 3600 * 24);
            const rate = Math.round(RoomRate / daysDifference);

            return rate.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
    }


    const [sticky, setSticky] = useState(false);

    const handleScroll = () => {
        const header = document.getElementById('mynavigation');
        const stickyOffset = header?.offsetTop;
        if (window.pageYOffset > stickyOffset) {
            setSticky(true);
        } else {
            setSticky(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleZentrumRoomImage = (image) => {
        if (image?.length > 0) {
            const result = image?.map((value, index) => {
                if (Array.isArray(value?.links) && value.links.length >= 0) {
                    return value.links[0];
                } else {
                    console.warn(`No valid links found for index ${index}`, value);
                    return null;
                }
            });
            return result;
        } else {
            return image;
        }
    }

    const iconMapping = {
        'air-conditioning': airConditioningIcon,
        'airport-shuttle': airportShuttleIcon,
        'atm': atmIcon,
        'balcony': balconyIcon,
        'banquet': banquetIcon,
        'bar': barIcon,
        'barbeque': barbequeIcon,
        'beach': beachIcon,
        'bicycle-rentals': bicycleRentalsIcon,
        'billiards': billiardsIcon,
        'breakfast': breakfastIcon,
        'business-center': bussinessCenterIcon,
        'casino': casinoIcon,
        'childcare-service': childcareServiceIcon,
        'cleaning-service': cleaningServiceIcon,
        'clubhouse': clubhouseIcon,
        'concierge-services': conciergeServicesIcon,
        'conveniences': conveniencesIcon,
        'currency-exchange': currencyExchangeIcon,
        'disable-friendly': disbaleFriendlyIcon,
        'dock': dockIcon,
        'electric car charging stations': electricCarChargingStationsIcon,
        'elevator': elevatorIcon,
        'familyfriendly': familyfirendlyIcon,
        'fitness-facility': fitnessFacilityIcon,
        'foodanddrink': foodAndDrinkIcon,
        'game-room': gameroomIcon,
        'garden': gameroomIcon,
        'gift-shop': gameroomIcon,
        'golf': gameroomIcon,
        'guestservices': guestservicesIcon,
        'hairsalon': hairsalonIcon,
        'hotels': hotelsIcon,
        'internet': internetIcon,
        'karaoke': karaokeIcon,
        'kitchenette': kitchenetteIcon,
        'laundry-services': laundryServicesIcon,
        'library': libraryIcon,
        'lounge': loungeIcon,
        'medical': medicalIcon,
        'Multilingual': multilingualIcon,
        'night-club': nightclubIcon,
        'non-smoking': NonSmokingIcon,
        'parking': parkingIcon,
        'pets-allowed': petsAllowedIcon,
        'refrigerator': refrigeratorIcon,
        'restaurant': restaurantIcon,
        'rooftopterrace': roofTopTerraceIcon,
        'room-service': roomServiceIcon,
        'safe-deposit-box': safeDepositBoxIcon,
        'sailing': sailingIcon,
        'sauna': saunaIcon,
        'sharedmicrowave': sharedMicrowaveIcon,
        'ski': skiIcon,
        'snorkeling-nearby': snorkelingNearbyIcon,
        'spa': spaIcon,
        'suite': suiteIcon,
        'surfing': surfingIcon,
        'swimming-pool': swimmingPoolIcon,
        'television': televisionIcon,
        'tennis': tennisIcon,
        'thingstodo': thingstodoIcon,
        'transportation': transportationIcon,
        'travel-desk': traveldeskIcon,
        'unavailable': unavailableIcon,
        'villa': villaIcon,
        'villas': villasIcon,
        'waterpark': waterparkIcon,
        'waterslide': waterslideIcon,
    };

    const normalizeName = (name) => {
        return name?.toLowerCase().replace(/\s+/g, '-');
    };
    console.log(encodedRooms, 'rooms');


    return (
        <>
            <MetaTags
                title={`LOVU: ${!hotelLoader && hotelTitle}`}
                imageScr={bannerImage}
            />
            <div className="zentrumroom">
                <NavBar navigationDark={true} />
                <Banner
                    isSearch={true}
                    imageSrc={bannerImage}
                    widget={
                        <div className='widget'>
                            <div className='zentrumroom-widget'>
                                <div className='zentruroom-wiget-subcontainer'>
                                    {
                                        hotelLoader ?
                                            <div className='seacrh-title skeleton-box seacrh-title-box '></div>
                                            :
                                            <div className='seacrh-title'>{hotelTitle}</div>
                                    }
                                    {
                                        hotelLoader ?
                                            <div className="zentrum-room-rating-container">
                                                <div className="review-rating-span skeleton-box review-rating-span-box" ></div>
                                                <div className="review-count skeleton-box review-count-box"></div>
                                                <div className='zentrum-star skeleton-box'></div>
                                            </div>
                                            :
                                            <div className="zentrum-room-rating-container">
                                                {
                                                    hotelContent[0]?.reviews && hotelContent[0]?.reviews?.length > 0 && hotelContent[0]?.reviews[0]?.rating && hotelContent[0]?.reviews[0]?.rating != -1 &&
                                                    <div className="review-rating-span">{hotelContent[0]?.reviews[0]?.rating}</div>
                                                }
                                                {
                                                    hotelContent[0]?.reviews && hotelContent[0]?.reviews?.length > 0 && hotelContent[0]?.reviews[0]?.count && hotelContent[0]?.reviews[0]?.count != 0 &&

                                                    <div className="review-count">{hotelContent[0]?.reviews[0]?.count} Reviews</div>
                                                }
                                                {
                                                    hotelContent[0]?.starRating &&
                                                    <ReactStars
                                                        count={Number(5)}
                                                        edit={false}
                                                        value={Number(hotelContent[0]?.starRating)}
                                                        isHalf={true}
                                                        size={22}
                                                        color="#fff"
                                                        activeColor="#ffd700"
                                                    />
                                                }
                                            </div>
                                    }
                                </div>
                                {hotelLoader ?
                                    <div className='seacrh-address skeleton-box seacrh-address-box'></div>
                                    :
                                    <div className='seacrh-address'>{hotelContent[0]?.contact?.address?.line1},{hotelContent[0]?.contact?.address?.city?.name},{hotelContent[0]?.contact?.address?.country?.name}</div>

                                }
                            </div>
                        </div>
                    }
                />
                <div className="pageContent">
                    <div className='zentrumroom-subcontainer'>
                        <div className='zentrumroom-widget widget-mobile'>
                            <div className='seacrh-title'>{hotelTitle}</div>
                            <div className='seacrh-address'>{hotelContent[0]?.contact?.address?.line1},{hotelContent[0]?.contact?.address?.city?.name},{hotelContent[0]?.contact?.address?.country?.name}</div>

                            <div className="zentrum-room-rating-container">
                                {
                                    hotelContent[0]?.reviews && hotelContent[0]?.reviews.length > 0 && hotelContent[0]?.reviews[0]?.rating && hotelContent[0]?.reviews[0]?.rating != -1 &&
                                    <div className="review-rating-span">{hotelContent[0]?.reviews[0]?.rating}</div>
                                }
                                {
                                    hotelContent[0]?.reviews && hotelContent[0]?.reviews.length > 0 && hotelContent[0]?.reviews[0]?.count && hotelContent[0]?.reviews[0]?.count != 0 &&

                                    <div className="review-count">{hotelContent[0]?.reviews[0]?.count} Reviews</div>
                                }
                                {
                                    hotelContent[0]?.starRating &&
                                    <ReactStars
                                        count={Number(5)}
                                        edit={false}
                                        value={Number(hotelContent[0]?.starRating)}
                                        isHalf={true}
                                        size={22}
                                        color="#fff"
                                        activeColor="#ffd700"
                                    />
                                }
                            </div>
                        </div>
                        <div onClick={handleBackToSearchResult} className='zentrum-back-to-search'>
                            <BackArrow className='zentrum-back-arrow' />
                            <div className='zentrum-back-content'>Back To Search Results</div>
                        </div>
                        <div className='zentrum-room-image-container'>
                            <div className='zentrum-room-image-subcontainer' >
                                {
                                    hotelLoader ?
                                        <div className='zentrum-big-image skeleton-box' />
                                        :

                                        hotelImages?.filter((el, filterIndex) =>
                                            [0]?.includes(filterIndex))
                                            ?.map((el, index) => {
                                                return <img onClick={() => openImageViewer(index)} key={index} className='zentrum-big-image' src={el?.links[0]?.url} alt='no images' />
                                            }
                                            )

                                }
                                <div className='zentrum-gallery-icon' onClick={() => openImageViewer()} >
                                    <GalleryIcon fill={true} />
                                    <div className='zentrum-room-img-count'>{hotelImages?.length}</div>
                                </div>
                                <Gallery
                                    data={hotelImages}
                                    currentIndex={currentImage}
                                    isGaleryVisible={isImageViewerOpen}
                                    closeImageViewer={closeImageViewer}
                                    hotelRoom={true}
                                />

                            </div>

                            <div className='zentrum-room-image-subcontainer' >
                                {

                                    hotelLoader ?
                                        <div className='zentrum-big-image skeleton-box' />
                                        :
                                        hotelImages?.filter((el, filterIndex) =>
                                            [1]?.includes(filterIndex))
                                            ?.map((el, index) => {
                                                return <img onClick={() => openImageViewer(index + 1)} key={index} className='zentrum-big-image' src={el?.links[0]?.url} alt='no images' />
                                            }
                                            )
                                }
                            </div>
                            <div className='zentrum-room-image-subcontainer image-last-container'>

                                <div className='image-last-subcontainer'>
                                    {
                                        hotelLoader ?
                                            <>
                                                <div className='zentrum-small-image skeleton-box' />
                                                <div className='zentrum-small-image skeleton-box' />
                                            </>
                                            :
                                            hotelImages?.filter((el, filterIndex) =>
                                                [2, 3]?.includes(filterIndex))
                                                ?.map((el, index) => {
                                                    return <img onClick={() => openImageViewer(index + 2)} key={index} className='zentrum-small-image' src={el?.links[0]?.url} alt='no images' />
                                                }
                                                )
                                    }
                                </div>
                                <div className='image-last-subcontainer'>
                                    {
                                        hotelLoader ?
                                            <>
                                                <div className='zentrum-small-image skeleton-box' />
                                                <div className='zentrum-small-image skeleton-box' />
                                            </>
                                            :
                                            hotelImages?.filter((el, filterIndex) =>
                                                [4, 5]?.includes(filterIndex))
                                                ?.map((el, index) => {
                                                    return <img onClick={() => openImageViewer(index + 3)} key={index} className='zentrum-small-image' src={el?.links[0]?.url} alt='no images' />
                                                }
                                                )
                                    }
                                </div>
                            </div>
                        </div>
                        <div className={`zentrum-room-navigation-container ${sticky ? 'stickynavigation' : ''}`} id='mynavigation'>
                            {hideRooms == null &&

                                <div onClick={() => setPageIndex('1')} className={`zentrum-button  ${pageIndex === '1' && 'zentrum-button-active'}`}> Available Room</div>
                            }

                            <div onClick={() => setPageIndex('2')} className={`zentrum-button  ${pageIndex === '2' && 'zentrum-button-active'}`}> About Hotel</div>
                        </div>
                        {
                            pageIndex === '1'
                            &&
                            <>
                                <div className='zentrum-roomcard-container'>
                                    {
                                        roomLoader &&
                                        <>
                                            {[...Array(5)].map((_, index) => (
                                                <div key={index} className="zentrum-roomcard">
                                                    <div className="zentrum-roomcard-image-container">
                                                        <div className="zentrum-roomcard-image skeleton-box" />
                                                    </div>
                                                    <div className="zentrum-roomcard-content-container">
                                                        <div className="zentrum-roomcard-content-subcontainer-one">
                                                            <div className="seacrh-title skeleton-box seacrh-title-box "></div>
                                                            <div className="seacrh-address skeleton-box seacrh-address-box "></div>
                                                        </div>
                                                        <div className="zentrum-roomcard-content-subcontainer-two">
                                                            <div className="zentrum-room-policy">
                                                                <div className="zentrum-room-policy-content skeleton-box policy-content-box "></div>
                                                                <div className="zentrum-dot skeleton-box dot-box"></div>
                                                                <div className="zentrum-room-policy-content skeleton-box policy-content-box"></div>
                                                                <div className="zentrum-dot skeleton-box dot-box"></div>
                                                                <div className="zentrum-room-policy-content skeleton-box policy-content-box" ></div>
                                                            </div>
                                                            <div className="zentrum-room-rates">
                                                                <div className="zentrum-room-price skeleton-box room-price-box"></div>
                                                                <div className='zentrum-book-btn skeleton-box zentrum-book-btn-box'></div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            ))}
                                        </>
                                    }
                                    {
                                        !roomLoader && hotelRoomContent?.length > 0 ?
                                            hotelRoomContent?.map((room, index) => (
                                                <ZentrumRoomCard
                                                    key={index}
                                                    description={room?.description}
                                                    name={room?.name}
                                                    smokingAllowed={room?.smokingAllowed}
                                                    images={handleZentrumRoomImage(room?.images)}
                                                    rateDescription={room?.rate?.boardBasis?.type}
                                                    rate={handleRoomRate(room?.rate?.totalRate)}
                                                    refundability={room?.rate?.refundable}
                                                    handlePolicyHandler={() => setIsPolicyVisible(true)}
                                                    policies={hotelContent[0]?.policies && hotelContent[0]?.policies}
                                                    isPolicyVisible={isPolicyVisible}
                                                    onClose={() => setIsPolicyVisible(false)}
                                                    HotelId={HotelId}
                                                    hotelTitle={hotelTitle}
                                                    tokenForNewHotel={tokenForNewHotel}
                                                    ip={ip}
                                                    startDate={startDate}
                                                    endDate={endDate}
                                                    recommendationId={room?.recommendation?.id}
                                                    correlationId={correlationId}
                                                    setIsWelcomeVisible={setIsWelcomeVisible}
                                                    LocationId={LocationId}
                                                    occasion={occasion}
                                                    people={people}
                                                    locationTitle={locationTitle}
                                                    rooms={encodedRooms}
                                                />
                                            )
                                            )
                                            :
                                            <div className='zentrum-no-rooms'>No Rooms Available</div>
                                    }

                                </div>

                            </>
                        }
                        {
                            pageIndex === '2'
                            &&

                            <div>
                                {
                                    hotelLoader
                                        ?
                                        [...Array(3)].map((_, index) => (
                                            <Skeleton key={index} paragraph={{ rows: 4 }} style={{ marginBottom: '50px' }} />
                                        ))
                                        :
                                        (hotelContent.length >= 0 && hotelContent[0]?.descriptions) ?
                                            <>
                                                {hotelContent[0]?.descriptions.map((value) => {
                                                    return <>
                                                        <h2 className="zentrumhotel-main-title">{value?.type?.match(/[A-Z][a-z]+|[0-9]+/g)?.join(" ")}</h2>
                                                        <p className='para-1'>
                                                            <Content data={value?.text} />
                                                        </p>
                                                    </>
                                                })}
                                            </> : null
                                }

                                {
                                    hotelLoader ?
                                        <>
                                            <div className="zentrumhotel-main-title skeleton-box zentrumhotel-main-title-box"></div>
                                            <div className="privacy-list-container">
                                                {[...Array(15)].map((_, index) => (
                                                    <div key={index} className="list-bullets list-bullets-box ">
                                                        <div className='skeleton-box li-box'></div>
                                                    </div>
                                                )
                                                )}
                                            </div>
                                        </>
                                        :
                                        <>

                                            {
                                                hotelContent[0]?.facilityGroups &&
                                                <h2 className="zentrumhotel-main-title">Amenities</h2>
                                            }
                                            <div className="privacy-list-container">
                                                {hotelContent[0]?.facilityGroups?.map((item, index) => {
                                                    const normalizedName = normalizeName(item?.name);
                                                    const iconSrc = iconMapping[normalizedName];
                                                    return (
                                                        <ul key={index} className="list-bullets">
                                                            <li>{iconSrc && <img className='amenities-icons' src={iconSrc} alt={item?.name} />}  {item?.name} </li>
                                                        </ul>
                                                    )
                                                })}
                                            </div>
                                        </>
                                }
                                {
                                    hotelLoader ?
                                        <>
                                            <div className="zentrumhotel-main-title skeleton-box zentrumhotel-main-title-box"></div>
                                            <div className='zentrum-map-box skeleton-box'></div>
                                        </>
                                        :
                                        <div style={{ width: '100%', height: '500px', marginBottom: '30px' }}>
                                            <h2 className="zentrumhotel-main-title">Map</h2>
                                            <iframe
                                                width="100%"
                                                height="450px"
                                                frameborder="0"
                                                scrolling="no"
                                                marginheight="0"
                                                marginwidth="0"
                                                src={`https://maps.google.com/maps?q=${parseFloat(hotelContent[0]?.geoCode?.lat) - 0.1},${parseFloat(hotelContent[0]?.geoCode?.long) - 0.1}&hl=en&z=14&output=embed`}
                                                title="Google Map"
                                                allow="geolocation; fullscreen"
                                            >
                                            </iframe>
                                        </div>
                                }
                            </div>
                        }
                    </div>
                </div>
                <Footer className="design-component-instance-node-3" correlationId={correlationId} />
                <WelcomeModal
                    visible={isWelcomeVisible}
                    onClose={() => setIsWelcomeVisible(false)}
                    setIsPopupVisible={setIsPopupVisible}
                    setIsJoinNow={setIsJoinNow}
                />
                <PopUpModal
                    visible={isPopupVisible}
                    onClose={closeModal}
                    isJoinNow={isJoinNow}
                    setIsJoinNow={setIsJoinNow}
                    setIsModalVisible={setIsPopupVisible}
                />
            </div>
        </>
    )
}

export default ZentrumRooms;