import React, { useEffect, useState } from 'react'
import Footer from '../../Components/Footer'
import NavBar from '../../Components/NavBar'
import { useParams } from 'react-router-dom';
import Banner from '../../Components/Banner';
import MetaTags from '../../Components/MetaTags';
import Articles from "../../api/models/Article.js";
import Occassions from "../../assets/images/Occassions.png"
import ArticleContent from '../../Components/ArticleContent/index.jsx';
import "./style.css";

const Article = ({ setProgress }) => {

    const params = useParams();
    const [Value, setValue] = useState();

    useEffect(() => {
        setProgress(10)
        setProgress(20)
        setProgress(60)
        setProgress(100)
    }, [setProgress])

    useEffect(() => {
        Articles.showarticlebyslug(params.id).then(result =>
            setValue(result)
        );
    }, [params.id]);


    return (
        <>
            <MetaTags
                title={Value?.meta_title == null ? `LOVU: ${Value?.title}` : Value?.meta_title}
                description={Value?.meta_description == null ? 'Discover handpicked romantic destinations with LOVU. Connect with expert Romance Travel Advisors to plan the perfect honeymoon, anniversary, or getaway.' : Value?.meta_description}
                imageScr={Value?.meta_image?.length == 0 ? Value?.featured_image?.original_url : Value?.meta_image[0]?.original_url}
            />
            <div className="article" >
                <NavBar navigationDark={true} />
                <Banner
                    isProperties
                    imageSrc={Value?.featured_image?.original_url ? Value?.featured_image?.original_url : Occassions}
                />

                <div className="pageContent">
                    <div className='articleContent'>
                        {
                            Value &&
                            <ArticleContent data={Value?.content} />
                        }
                    </div>
                </div>
                <Footer className="design-component-instance-node-3" />
            </div>
        </>
    )
}

export default Article;