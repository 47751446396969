import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import Upgrade from "../Upgrade";
import Intro from "../Auth/Intro";
import Search from "../../Pages/Search";
import Post from "../../api/models/Post"
import Card from "../../Components/Card";
import Select from 'react-dropdown-select';
import OnBoarding from "../Auth/OnBoarding";
import { useForm } from 'antd/es/form/Form';
import PopUpModal from "../Auth/PopUpModal";
import Footer from "../../Components/Footer";
import NavBar from "../../Components/NavBar";
import Banner from "../../Components/Banner";
import Loader from "../../Components/Loader";
import MetaTags from "../../Components/MetaTags"
import { TAGS, removeData } from "../../Helpers";
import Category from "../../api/models/Category";
import WelcomeModal from "../Auth/WelcomeScreen";
import Article from "../../api/models/Article.js";
import Metatag from "../../api/models/Metatag.js";
import ResetPassword from "../Auth/ResetPassword";
import ButtonTag from "../../Components/ButtonTag";
import ImageText from "../../Components/ImageText";
import SearchBar from "../../Components/SearchBar";
import { messaging } from "../../firebaseConfig.js";
import useStore from "../../providers/StoreProvider";
import useAuth from "../../providers/AuthProvider.js";
import UpgradePayment from "../Upgrade/UpgradePayment";
import ButtonSmall from "../../Components/ButtonSmall";
import Romantic from "../../assets/images/Romantic.png";
import bannerImage from "../../assets/images/Banner.jpg";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CenteredPopup from "../../Components/CenteredPopup";
import Occassions from "../../assets/images/Occassions.png";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import TripDesigner from "../../assets/images/Trip Designer.jpg";
import MemberBenefit from "../../assets/images/MemberBenefit.jpg";
import Occasionpreference from '../../api/models/Occasionpreference';
import useNotification from "../../providers/NotificationProvider.js";
import { Form, Spin, Input, message, Button as AntdButton } from "antd";
import Proposals from "../../assets/images/occasion/occasions/proposal.jpg";
import Babymoons from "../../assets/images/occasion/occasions/babymoons.jpg";
import Honeymoon from "../../assets/images/occasion/occasions/honeymoon.jpg";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import ZentiumSearchBanner from "../../assets/images/ZentiumSearchBanner.jpeg";
import VowRenewal from "../../assets/images/occasion/occasions/vow-renewal.jpg";
import Anniversaries from "../../assets/images/occasion/occasions/anniversaries.jpg";
import DestinationWedding from "../../assets/images/occasion/occasions/destination-wedding.jpg";
import "./style.css";

let page;
let lastPage;

const Home = ({ setProgress }, props) => {

  const location = useLocation();
  const param = useParams();
  const searchParams = new URLSearchParams(location.search);

  let tokendata = localStorage.getItem('data');
  let rawuser = localStorage.getItem('user');
  const rawdestination = localStorage.getItem('destination');

  const token = searchParams.get('token');
  const email = searchParams.get('email');
  const joinNow = searchParams.get('joinnow');
  const Login = searchParams.get('login');
  const Logout = searchParams.get('logout');
  const RedirectUrl = searchParams.get('url');
  const search = searchParams.get('search');

  const hasTokenAndEmail = token !== null && email !== null;
  const destination = JSON.parse(rawdestination);
  const user = JSON.parse(rawuser);
  const navigation = useNavigate();



  const { store, setStore } = useStore();
  const [seo, setSeo] = useState();
  const [posts, setPosts] = useState([]);
  const [bannerData, setBannerData] = useState();
  const [articles, setArticles] = useState([]);
  const [categorie, SetCategorie] = useState([]);
  const [loading, setLoading] = useState(false);
  const [occasions, setOccasions] = useState([]);
  const [isVisible, setIsVisible] = useState(true);
  const [isJoinNow, setIsJoinNow] = useState(false);
  const [subLoading, setSubLoading] = useState(false);
  const [clientSecret, setClientSecret] = useState("");
  const [subscriptionid, setSubscriptionId] = useState();
  const [bannerLoader, setBannerLoader] = useState(false);
  const [isNewsLetter, setIsNewsLetter] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [onBoardingShow, setOnBoardingShow] = useState(false);
  const [isIntroVisible, setIsIntroVisible] = useState(false);
  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const [isWelcomeVisible, setIsWelcomeVisible] = useState(false);
  const [isUpgradeVisible, setIsUpgradeVisible] = useState(false);
  const [isUpgradePayment, setIsUpgradePayment] = useState(false);
  const [isItineararyLoading, setIsItineararyLoading] = useState(false);
  const [isNotificationVisible, setIsNotificationVisible] = useState(false);
  const [randomSeed, setRandomSeed] = useState(parseInt(Math.random() * 10000));
  const [params, setParams] = useState(destination !== null ? destination : {});
  const [selectedOption, setSelectedOption] = useState([{ name: 'Show All', value: 'all' }])



  const [form] = Form.useForm();
  const { data } = useForm({
    email: '',
  });

  const { logout } = useAuth();
  const [notification, createNotification] = useNotification();

  const clearCacheData = () => {
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
  };

  const clearFCMToken = async () => {
    try {
      await messaging.deleteToken();
      console.log('FCM token deleted.');
    } catch (error) {
      console.error('Error deleting FCM token:', error);
    }
  };

  useEffect(() => {
    if (joinNow == '') {
      setIsPopupVisible(true);
      setIsJoinNow(true)
    }
    if (Login == '') {
      setIsPopupVisible(true)
      setIsJoinNow(false)
    }
    if (search == '') {
      setIsSearchVisible(true)
    }
    if (Logout == '') {
      createNotification({
        title: 'Warning',
        content:
          'Are you sure you want to  logout?',
        primary: {
          title: 'Logout',
          onPress: () => {
            logout();
            clearCacheData();
            localStorage.clear();
            clearFCMToken().then(() => navigation('/'))
          }
        },
        secondary: {
          title: 'Cancel',
        },
      })
    }
  }, [joinNow, Login, search, Logout])

  useEffect(() => {
    if (store.token && RedirectUrl !== null) {
      window.location.href = RedirectUrl;
    }
  }, [store.token])

  useEffect(() => {
    setProgress(10)
    setProgress(20)
    setProgress(60)
    setProgress(100)
  }, [setProgress])

  useEffect(() => {
    if (destination) {
      setParams(destination);
      removeData('destination')
    }
  }, [destination])

  useEffect(() => {
    if (hasTokenAndEmail) {
      return setIsModalVisible(true)
    }
  }, [param?.value]);

  useEffect(() => {
    Article.all(1).then(result =>
      setArticles(result?.data)
    );
    Occasionpreference.all(1).then(({ data }) => setOccasions(data));
    Metatag.metaslug('home').then(result =>
      setSeo(result)
    );
    Category.all().then(({ data }) => {
      const filteredCategories = data.filter(
        category => category.is_published && category.navigation,
      );
      SetCategorie(filteredCategories);
      setStore({ ...store, categories: filteredCategories });
    }).catch((e) => message.error(e.message));
  }, []);

  useEffect(() => {
    loadPage(true);
  }, [store.posts]);


  function loadPage(first = false, refresh = false) {
    if (refresh) {
      Category.all().then(({ data }) => {
        const filteredCategories = data.filter(
          category => category.is_published && category.navigation,
        );

        setStore({ ...store, categories: filteredCategories });
      }).catch((e) => message.error(e.message));

      setRandomSeed(parseInt(Math.random() * 1000000));
    }
    if (first) {
      setPosts([])
      page = 1;
      lastPage = 1;
    }
    if (page > lastPage) {
      return;
    }
    setLoading(true);
    Post.setFilter('tags', []);
    Post.setFilter('categories', null);
    if (Object.keys(params).length === 0) {
      Post.setFilter('types', [
        'property',
        'basic',
        'advisor',
        'brand',
        'destination',
        'collection',
        'worldiaitenararies'
      ])
        .when(store.posts === 'me', model =>
          model.setFilter(
            'tags',
            store?.token ? store?.user?.tags : [''],
          ),
        )
        .when(store.posts === 'favorites', model =>
          model.setFilter('include', store.user.posts),
        )
        .when(typeof store.posts === 'number', model =>
          model.setFilter('categories', store.posts),
        )
        .when(['advisor', 'offer', 'worldiaitenararies'].includes(store.posts), model =>
          model.setFilter('types', store.posts),
        );
    } else {
      Post.when(params, model =>
        model
          .setFilter('types', params.types)
          .setFilter('parent', params.parent),
      )

        .when(store.posts === 'favorites', model =>
          model.setFilter('include', store.user.posts),
        )
        .when(typeof store.posts === 'number', model =>
          model.setFilter('categories', store.posts),
        )
        .when(['advisor', 'offer'].includes(store.posts), model =>
          store.posts === 'offer' && !store?.token
            ? model.setFilter('tags', [''])
            : model.setFilter('types', store.posts),
        );
    }
    Post.setFilter('seed', randomSeed)
      .all(page)
      .then(data => {
        page === 1
          ? setPosts(data.data)
          : setPosts(posts.concat(data.data));
        lastPage = data.meta.last_page;
        page = data.links.next == null ? page + 1 : data?.links?.next.split('?')[1].split('=')[1];
      })
      .catch(() => { })
      .finally(() => {
        setLoading(false);
      });
  }


  useEffect(() => {

    const handleOccasionBasedBanner = (occasion) => {
      const occasionButton = () => {
        return <ButtonSmall className="" buttonstyle="default " text={`Curated ${occasion}`} />
      }
      switch (occasion) {
        case "Wedding":

          return (
            { url: DestinationWedding, title: 'Welcome to LOVU', heading: 'Book dream getaways with curated romance experiences!', anchor: 'occassions/destinationwedding', actionButton: occasionButton() }
          );
        case "Honeymoon":
          return (
            { url: Honeymoon, title: 'Welcome to LOVU', heading: 'Book dream getaways with curated romance experiences!', anchor: 'occassions/honeymoon', actionButton: occasionButton() }
          );
        case "Anniversary":
          return (
            { url: Anniversaries, title: 'Welcome to LOVU', heading: 'Book dream getaways with curated romance experiences!', anchor: 'occassions/anniversary', actionButton: occasionButton() }
          );
        case "Proposal":
          return (
            { url: Proposals, title: 'Welcome to LOVU', heading: 'Book dream getaways with curated romance experiences!', anchor: 'occassions/proposal', actionButton: occasionButton() }
          );
        case "Vow Renewal":
          return (
            { url: VowRenewal, title: 'Welcome to LOVU', heading: 'Book dream getaways with curated romance experiences!', anchor: 'occassions/vowrenewal', actionButton: occasionButton() }
          );
        case "Babymoon":
          return (
            { url: Babymoons, title: 'Welcome to LOVU', heading: 'Book dream getaways with curated romance experiences!', anchor: 'occassions/babymoon', actionButton: occasionButton() }
          );
        default:
          return {};
      }

    }
    if (!tokendata) {
      if (['me'].includes(store?.posts)) {
        setIsWelcomeVisible(true)
      }
    }

    if (tokendata) {
      setBannerData();
      setBannerLoader(true);
    } else {
      setBannerData();
      setBannerLoader(true);
    }
    if (store?.token) {
      if (store.user.tags.length === 0) {
        setIsIntroVisible(true);
      }
      const filterOccasion = occasions
        ?.filter(tag => (store?.user?.occasionpreferences?.indexOf(tag?.id) > -1)).map((item) => item.name);

      if (filterOccasion?.length > 0) {
        const updatedFirstImage = handleOccasionBasedBanner(filterOccasion[0]);
        const newOccasionImages = filterOccasion.map((occasion) =>
          handleOccasionBasedBanner(occasion)
        );
        setBannerData([updatedFirstImage,
          { url: ZentiumSearchBanner, title: '', heading: 'Customize and book itineraries', anchor: 'https://trips.lovu.travel/', actionButton: SeeHow(), },
          { url: Occassions, title: '', heading: 'Top Destination Wedding Hotspots', anchor: '/occassions/destinationwedding', actionButton: learnMore() }]);
      } else {
        setBannerData([
          { url: bannerImage, title: 'Welcome to LOVU', heading: 'Book dream getaways with curated romance experiences!', actionButton: actionButton() },
          { url: ZentiumSearchBanner, title: '', heading: 'Customize and book itineraries', anchor: 'https://trips.lovu.travel/', actionButton: SeeHow(), },
          { url: Occassions, title: '', heading: 'Top Destination Wedding Hotspots', anchor: '/occassions/destinationwedding', actionButton: learnMore() },
        ]);
      }

    } else {
      setBannerData([
        { url: bannerImage, title: 'Welcome to LOVU', heading: 'Book dream getaways with curated romance experiences!', actionButton: actionButton() },
        { url: ZentiumSearchBanner, title: '', heading: 'Customize and book itineraries', anchor: 'https://trips.lovu.travel/', actionButton: SeeHow(), },
        { url: Occassions, title: '', heading: 'Top Destination Wedding Hotspots', anchor: '/occassions/destinationwedding', actionButton: learnMore() },
      ]);
    }

  }, [store?.posts, store?.token, occasions]);



  const actionButton = () => {
    return tokendata === null && <>
      <ButtonSmall className="" buttonstyle="default " text="GET STARTED" handleClick={() => { setIsPopupVisible(true); setIsJoinNow(true) }} />
      <ButtonSmall className="button-instance-black " buttonstyle="default" text="PREMIUM BENEFITS" handleClick={() => setIsUpgradeVisible(true)} />
    </>
  }
  const SeeHow = () => {
    return <ButtonSmall className="" buttonstyle="default " text="See How" />
  }

  const learnMore = () => {
    return <ButtonSmall className="" buttonstyle="default " text="Learn More" />
  }

  let text =
    "😢 We're busy curating awesome hotels and specials, check back soon!";

  if (!store?.token) {
    text = `😢 ${TAGS?.find(e => e.value === store?.posts)?.name
      } only available for registered users.`;
  }

  const closeModal = () => {
    setIsPopupVisible(false);
  };

  const handleMemberBenefit = () => {
    if (tokendata === null) {

      setIsPopupVisible(true);
      setIsJoinNow(true)
    } else {
      setIsUpgradeVisible(true)
    }
  }

  const handleRomantic = () => {
    navigation('/destinations')
  }


  var currentdate = new Date().getTime();
  var expir = new Date(user?.subscription_expires_at).getTime();


  const subContainerRef = useRef(null);

  const handleForwardClick = () => {
    if (subContainerRef.current) {
      subContainerRef.current.scrollLeft += subContainerRef.current.clientWidth;
    }
  };

  const handleBackClick = () => {
    if (subContainerRef.current) {
      subContainerRef.current.scrollLeft -= subContainerRef.current.clientWidth;
    }
  };

  const onFinishSubscription = (values) => {
    setSubLoading(true);
    const state = { email: values.email };
    axios.get(`/subscribe/${values.email}`).then((res) => {
      if (res?.data == 200) {
        setIsNewsLetter(true);
      } else {
        message.error('Some thing went wrong please try again')
      }
      res?.data && setSubLoading(false)
    });
  };

  const handleUrl = async () => {
    setIsItineararyLoading(true);
    if (tokendata) {
      await Post.itinearary()
        .then((res) => {
          if (res) {
            setIsItineararyLoading(false);
            let fullurl = 'https://trips.lovu.travel/destinations' + '?' + res?.query;
            window.location.href = fullurl;
          }
        }
        )
        .catch(() => { });


    } else {
      window.location.href = 'https://trips.lovu.travel/destinations';

    }
  }

  const handleNavigation = (e) => {
    setSelectedOption(e);
    setStore({ ...store, posts: e[0]?.value })
  }


  return (
    <>
      <MetaTags
        title={seo?.title != null ? seo?.title : "LOVU: Romantic Getaways, Hotels &amp; Travel Advisors | Plan Your Dream Trip"}
        description={seo?.description != null ? seo?.description : "Discover handpicked romantic destinations with LOVU. Connect with expert Romance Travel Advisors to plan the perfect honeymoon, anniversary, or getaway."}
        imageScr={seo?.meta_image[0]?.original_url != undefined ? seo?.meta_image[0]?.original_url : "https://lovutravel.com/wp-content/uploads/2023/05/hero10.png"}
      />
      <div className={`homepage ${tokendata !== null && 'content-top'}`}>
        <NavBar navigationDark={true} />
        <Banner
          title="Welcome to LOVU"
          heading="Book dream getaways with curated romance experiences!"
          imageSrc={bannerData}
          bannerLoader={bannerLoader}
          setBannerLoader={setBannerLoader}
          widget={
            <SearchBar
              heightToHideFrom={520}
              isVisible={isVisible}
              setIsVisible={setIsVisible}
            />
          }
        />
        <div className="pageContent">
          <ImageText data={[
            {
              imageSrc: TripDesigner,
              subHeading: "BE IN CONTROL OF EVERY STEP",
              heading: "Design and Book Your Perfect Honeymoon",
              text: "Seamlessly plan and book your dream honeymoon, from flights and accommodations to organizing romantic activities and excursions.  Design your unforgettable, personalized experience.",
              buttonText: "GET STARTED",
              // href: 'https://trips.lovu.travel/'
              handleClick: handleUrl
            },
            {
              imageSrc: tokendata === null ? MemberBenefit : tokendata !== null && (user?.subscription_sku == null || currentdate > expir) ? MemberBenefit : Romantic,
              subHeading: tokendata === null ? "JOIN THE CLUB" : tokendata !== null && (user?.subscription_sku == null || currentdate > expir) ? "JOIN THE CLUB" : 'Embark On The Journey of Love',
              heading: tokendata === null ? "Member Benefits" : tokendata !== null && (user?.subscription_sku == null || currentdate > expir) ? "Member Benefits" : 'Romantic Destinations',
              text: tokendata === null ? "Planning not your thing? Not sure where to start? Let LOVU connect you with one of our dedicated travel advisors to help plan your trip for you." : tokendata !== null && (user?.subscription_sku == null || currentdate > expir) ? "Planning not your thing? Not sure where to start? Let LOVU connect you with one of our dedicated travel advisors to help plan your trip for you." : 'Destinations to foster deep, romantic connections between you and your partner. ',
              buttonText: tokendata === null ? 'sign up' : tokendata !== null && (user?.subscription_sku == null || currentdate > expir) ? 'upgrade now' : 'Learn More',
              handleClick: tokendata === null ? handleMemberBenefit : tokendata !== null && (user?.subscription_sku == null || currentdate > expir) ? handleMemberBenefit : handleRomantic,
            },
            {
              imageSrc: Occassions,
              subHeading: "READY FOR EXPERIENCE OF A LIFETIME?",
              heading: "Occassions",
              text: "No matter your occassion – we have a best deal for you available right now!",
              buttonText: "INSPIRE ME",
              href: '/occassions'
            }
          ]} />
          <div className="title-wrap">
            <h2 className="section-title">Romantic hotspots curated by travel experts!</h2>
          </div>
          <div className="mobile-navigation">
            <Select
              values={selectedOption}
              labelField="name"
              valueField="value"
              searchable={false}
              onChange={handleNavigation}
              options={TAGS.filter(
                e =>
                  e.value !== 'favorites' || store?.user?.posts?.length,).concat(
                    categorie?.map(e => ({
                      name: e?.name,
                      value: e?.id,
                    })),
                  )}
              className="home-drop"

            />
          </div>
          <div className="navigation">
            <ButtonTag
              data={TAGS.filter(
                e =>
                  e.value !== 'favorites' || store?.user?.posts?.length,).concat(
                    categorie?.map(e => ({
                      name: e?.name,
                      value: e?.id,
                    })),
                  )}
              selected={store.posts}
              handleClick={e => setStore({ ...store, posts: e })}
            />
          </div>
          <div className="card-list-container">
            <div className="card-list-wrap">
              {posts.map((v, i) => <Card
                key={i}
                imageSrc={v?.featured_image?.original_url}
                title={v?.title}
                type={v?.type}
                city={v?.city}
                country={v?.country}
                hasRatingPrice={v?.rating ? true : false}
                starRating={v?.rating}
                price={v?.price}
                backgroundColor={v?.image_color ? v?.image_color : null}
                cardStyle={v?.template_type}
                id={v?.id}
                isItinerary={v?.type === 'worldiaitenararies' ? true : false}
                length={v?.length}
                url={v?.website}
                setIsItineararyLoading={setIsItineararyLoading}
              />)}
            </div>
            {
              (posts?.length === 0 && !loading) && <div className="lovu-unregister-title">{text}</div>
            }
            {loading ? <div className="card-list-wrap" style={{ marginTop: -38 }}>
              <div className="card skeleton-box" />
              <div className="card skeleton-box" />
              <div className="card skeleton-box" />
              <div className="card skeleton-box" />
            </div> : page > lastPage ? null : <ButtonSmall handleClick={() => loadPage()} className="button-instance-black-outline " buttonstyle="button-outline" text="LOAD MORE" />}
          </div>
          <div className="publication-main-container">
            {
              articles?.length > 0 &&
              <h2 className="publications-title">From Our Travel Desk</h2>
            }
            <div className="publication-sub-container" ref={subContainerRef}>
              {articles?.length > 0 && articles.map((title, index) => (
                <div className="publication-card" key={index}>
                  <Link to={`/article/${title?.slug}`}><img src={title?.featured_image?.original_url ? title?.featured_image?.original_url : Occassions} alt="no-image-found" className="publication-image" /></Link>
                  <Link to={`/article/${title?.slug}`}><h2 className="publication-title">{title?.title}</h2></Link>
                </div>
              ))}
            </div>
            {
              articles?.length > 0 &&
              <div className="arrow-icons">
                <div className="arrow-icon" onClick={handleBackClick}>
                  <ArrowBackIcon style={{ color: '#667085' }} />
                </div>
                <div className="arrow-icon" onClick={handleForwardClick}>
                  <ArrowForwardIcon style={{ color: '#667085' }} />
                </div>
              </div>
            }

          </div>
        </div>
        <div className="home-newsletter-img">
          <div className="home-newsletter-content">
            <div className="home-newsletter-main-content">
              <div className="home-newsletter-title">Sign up for our newsletter</div>
              <div className="home-newsletter-subtitle">Be the first to discover exclusive deals and offers for romantic travel.</div>
            </div>
            <div className="home-newsletter-input-container">
              {isNewsLetter ?
                <div className="home-newsletter-success-text">Thank you for subscribing to our newsletter. </div>
                :
                <Form
                  form={form}
                  name="basic"
                  labelCol={{ span: 8 }}
                  className="subscribe-form"
                  initialValues={data}
                  onFinish={onFinishSubscription}
                  // onFinishFailed={onFinishFailed}
                  autoComplete="off"
                >
                  <Form.Item
                    name="email"
                    rules={[{ required: true, message: 'Please Enter Your Email' },
                    {
                      pattern: new RegExp(/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i),
                      message: "Enter a Valid Email",
                    }]}
                    hasFeedback
                  >

                    <Input className='home-newsletter-input' type="email" placeholder='Enter your email' />
                  </Form.Item>
                  <Form.Item wrapperCol={{ span: 24 }}>
                    {subLoading ? <Spin style={{ marginLeft: '8px' }} />
                      :
                      <AntdButton type="primary" htmlType="submit" buttonstyle="default" className='home-newsletter-btn'>
                        Subscribe
                      </AntdButton>}
                  </Form.Item>

                </Form>
              }
            </div>
          </div>
        </div>
        <Footer className="design-component-instance-node-3" isVisible={isVisible} />
        <Intro
          visible={isIntroVisible}
          setOnBoardingShow={setOnBoardingShow}
          onClose={() => setIsIntroVisible(false)}
        />
        <Search
          visible={isSearchVisible}
          onClose={() => setIsSearchVisible(false)}
        />
        <OnBoarding
          visible={onBoardingShow}
          onClose={() => setOnBoardingShow(false)}
        />
        <WelcomeModal
          visible={isWelcomeVisible}
          onClose={() => setIsWelcomeVisible(false)}
          setIsPopupVisible={setIsPopupVisible}
          setIsJoinNow={setIsJoinNow}
        />
        <PopUpModal
          visible={isPopupVisible}
          onClose={closeModal}
          isJoinNow={isJoinNow}
          setIsJoinNow={setIsJoinNow}
          setIsModalVisible={setIsPopupVisible}
        />
        <ResetPassword
          email={email}
          token={token}
          visible={isModalVisible}
          onClose={() => setIsModalVisible(false)}
          setIsModalVisible={setIsModalVisible}
        />
        <CenteredPopup
          visible={isNotificationVisible}
          title={"Premuim required!"}
          content={"You've sent three offer requests already. To send ore you need to upgrade premium account."}
          actions={"Ok"}
          onClose={() => setIsNotificationVisible(false)}
          setIsModalVisible={setIsNotificationVisible}
        />
        <Upgrade
          visible={isUpgradeVisible}
          onClose={() => setIsUpgradeVisible(false)}
          setIsPopupVisible={setIsPopupVisible}
          setIsJoinNow={setIsJoinNow}
          setIsUpgradePayment={setIsUpgradePayment}
          setClientSecret={setClientSecret}
          setSubscriptionId={setSubscriptionId}
        />
        <UpgradePayment
          visible={isUpgradePayment}
          clientSecret={clientSecret}
          subscriptionid={subscriptionid}
          onClose={() => setIsUpgradePayment(false)}
          upgradeClose={() => setIsUpgradeVisible(false)}
        />
        <Loader
          visible={isItineararyLoading}
          onClose={() => setIsItineararyLoading(false)}
        />
      </div>
    </>
  );
};

export default Home;