import React, { useEffect, useState } from "react";
import axios from "axios";
import { Spin } from 'antd';
import moment from "moment";
import Banner from "../../Components/Banner";
import Footer from "../../Components/Footer";
import NavBar from "../../Components/NavBar";
import PostModel from "../../api/models/Post";
import MetaTags from "../../Components/MetaTags";
import Metatag from "../../api/models/Metatag.js";
import useStore from "../../providers/StoreProvider";
import lovuLogo from "../../assets/LOVU_full_logo.svg"
import ButtonSmall from "../../Components/ButtonSmall";
import RightArrow from "../../assets/icons/RightArrow";
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import bannerImage from "../../assets/images/ZentiumSearchBanner.jpeg"
import "./style.css";

const Reservation = ({ setProgress }) => {

    const [seo, setSeo] = useState();
    const [correlationId, setCorrelationId] = useState();
    const [isMobile, setIsMobile] = useState(false);
    const [loading, setLoading] = useState(false);
    const [reserve, setReserve] = useState();
    const [hotelloading, setHotelLoading] = useState(false);
    const [hotelReservation, setHotelReservation] = useState();
    const [ip, setIP] = useState("");
    const [cancelLoader, setCancelLoader] = useState(false);

    const navigate = useNavigate();
    const params = useParams();
    const { store } = useStore();

    useEffect(() => {
        Metatag.metaslug('reservation').then(result =>
            setSeo(result)
        );
    }, []);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        setProgress(10)
        setProgress(20)
        setProgress(60)
        setProgress(100)
    }, [])

    useEffect(() => {
        setLoading(true);
        setHotelLoading(true)
        getreservation();
        getIpAddress();
        getCorrelationId();
    }, [store?.user?.id])

    const getIpAddress = async () => {
        const res = await axios.get("https://api.ipify.org/?format=json", { headers: { Authorization: null } });
        setIP(res.data.ip);
    };

    const getCorrelationId = () => {
        PostModel.getCorrelationId()
            .then((res) => {
                setCorrelationId(res);
            })
            .catch((error) => { console.error('Error config:', error) });
    }

    const getreservation = async () => {
        if (store?.user?.id) {
            await PostModel.getreservation(store?.user?.id)
                .then((res) => {
                    setReserve(res?.data);
                    setLoading(false);
                }
                )
                .catch(() => { });
        }
    }

    useEffect(() => {
        if (reserve?.length > 0) {
            if (!isMobile) {
                navigate(`/reservations/${reserve[0]?.booking_id}`)
            }
        }
    }, [reserve])

    useEffect(() => {
        let body = { "bookingId": params?.id }
        getbookingdetail(body)
    }, [params?.id])



    const getbookingdetail = async (body) => {
        setHotelLoading(true);
        if (ip) {
            await PostModel.getbookingdetail(ip, correlationId, body)
                .then((res) => {
                    setHotelReservation(res?.hotelBooking)
                    setHotelLoading(false)
                }
                )
                .catch(() => { });
        }
    }

    const handleCancellation = async (bookingId) => {
        setCancelLoader(true);
        try {
            await axios.post(`/payments/cancelandRefund`, {
                bookingId, ip, correlationId
            }).then(result => {
                if (result?.data?.bookingStatus == "Cancelled") {
                    let body = { "bookingId": bookingId }
                    getbookingdetail(body)
                    setCancelLoader(false);
                }
            });
        } catch (error) {
            console.log(error);
        }
    }



    return (
        <>
            <MetaTags
                title={seo?.title != null ? seo?.title : "LOVU: Reservation"}
                description={seo?.description != null ? seo?.description : "Discover handpicked romantic destinations with LOVU. Connect with expert Romance Travel Advisors to plan the perfect honeymoon, anniversary, or getaway."}
                imageScr={seo?.meta_image[0]?.original_url != undefined ? seo?.meta_image[0]?.original_url : lovuLogo}
            />
            <div className="reservations">
                <NavBar navigationDark={true} />
                {/* <Banner
                    isSearch={true}
                    imageSrc={bannerImage}
                /> */}
                <div className="pageContent">
                    {
                        !loading && reserve?.length == 0 ?
                            <div className="no-reservation">No booking found</div>
                            :
                            <>
                                <h1 className={`reservation-title ${params?.id && isMobile ? 'offer-list-card-maincontainer-none' : ''}`}>My Offer Requests</h1>
                                <div className="reservation-sub-content">
                                    <div className={`reservation-offer-list-card-maincontainer ${params?.id && isMobile ? 'offer-list-card-maincontainer-none' : ''}`}>
                                        {
                                            loading ?
                                                [...Array(5)].map((_, index) => (
                                                    <div key={index} className={`reservation-offer-list-card-subcontainer-selected`}>
                                                        <div className="reservation-subcontainer-title skeleton-box subcontainer-title-box"></div>
                                                        <div className="reservation-subcontainer-date"><span className="subcontainer-date-box1 skeleton-box"></span><span className="subcontainer-date-box2 skeleton-box"></span><span className="subcontainer-date-box3 skeleton-box"></span><span className="skeleton-box"></span></div>
                                                    </div>
                                                ))
                                                :
                                                reserve?.map((item, index) =>
                                                    <div onClick={() => {
                                                        navigate(`/reservations/${item?.booking_id}`);
                                                        setCancelLoader(false);
                                                    }} className={`reservation-offer-list-card-subcontainer${params?.id == item?.booking_id ? '-selected' : ""}`}>
                                                        <div className="reservation-subcontainer-title">{item?.hotel_name}</div>
                                                        <div className="reservation-subcontainer-date"><span>{moment(item?.trip_start_date).format('DD MMM, ddd YYYY')}</span><RightArrow className={'rightarrow'} />  <span>{moment(item?.trip_end_date).format('DD MMM, ddd YYYY')}</span></div>
                                                    </div>
                                                )


                                        }
                                    </div>
                                    {(params?.id && isMobile) &&
                                        <div className="reservation-back-page-container" onClick={() => navigate('/reservations')}>
                                            <ArrowBackIcon />
                                            <div className="back-arrow-text">Offers Page</div>
                                        </div>}
                                    {
                                        hotelloading ?
                                            <div className={`reservation-offer-card-maincontainer ${!params?.id && isMobile ? 'offer-card-maincontainer-none' : ''}`}>
                                                <div className="reservation-offer-card-subcontainer">
                                                    <div className="reservation-offer-card-title skeleton-box offer-card-title-box"></div>
                                                    <div className="reservation-offer-card-subcontainer-main">
                                                        <div className="reservation-offer-card-imagecontainer skeleton-box" />
                                                        <div className="reservation-offer-card-contentcontainer">
                                                            <div className="reservation-offer-card-hotel-container">
                                                                <div className="reservation-offer-card-content-title skeleton-box offer-card-content-title-box"></div>
                                                                <div className="reservation-offer-card-content-address skeleton-box offer-card-content-address-box"></div>
                                                                <div className="reservation-offer-card-content-address skeleton-box offer-card-content-address-box"></div>
                                                            </div>
                                                            <div className="reservation-offer-card-room-container">
                                                                <div className="reservation-offer-card-room-title skeleton-box offer-card-room-title-box"></div>
                                                                <div className="reservation-offer-card-room-detail offer-card-room-detail-box">
                                                                    <span className="subcontainer-date-box1 skeleton-box"></span>
                                                                    <span className="subcontainer-date-box1 skeleton-box"></span>
                                                                    <span className="subcontainer-date-box1 skeleton-box"></span>
                                                                </div>
                                                                <div className='reservation-room-check-in-out-container'>
                                                                    <div className="reservation-room-check-in-container">
                                                                        <div className='reservation-room-check-in-title skeleton-box room-check-in-title-box'></div>
                                                                        <div className='reservation-room-check-in-date skeleton-box room-check-in-date-box'></div>
                                                                    </div>
                                                                    <span className="reservation-room-check-in-out-arrow-box skeleton-box"></span>
                                                                    <div className="reservation-room-check-in-container">
                                                                        <div className='reservation-room-check-in-title skeleton-box room-check-in-title-box'></div>
                                                                        <div className='reservation-room-check-in-date skeleton-box room-check-in-date-box'></div>
                                                                    </div>
                                                                </div>
                                                                <div className='reservation-room-price-container skeleton-box room-price-container-box'>
                                                                </div>
                                                                <div className="reservation-confirmation-btn-container">
                                                                    <div className="confirmation-btn-container-box skeleton-box"></div>
                                                                </div>
                                                                <div className="reservation-offer-card-content-title skeleton-box offer-card-cancel-title-box"></div>
                                                                <div>
                                                                    <ul className="cancelation-bullets cancelation-bullets-box">
                                                                        <li className="cancelation-li-box skeleton-box"></li>
                                                                        <li className="cancelation-li-box skeleton-box"></li>
                                                                    </ul>
                                                                </div>
                                                                <div className="reservation-confirmation-btn-container">
                                                                    <div className="confirmation-btn-container-box skeleton-box"></div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <>

                                                <div className={`reservation-offer-card-maincontainer ${!params?.id && isMobile ? 'offer-card-maincontainer-none' : ''}`}>
                                                    <div className="reservation-offer-card-subcontainer">
                                                        <div className="reservation-offer-card-title">Booking Details</div>
                                                        <div className="reservation-offer-card-subcontainer-main">
                                                            <img className="reservation-offer-card-imagecontainer" src={hotelReservation?.hotel?.heroImage} alt="no image found" />
                                                            <div className="reservation-offer-card-contentcontainer">
                                                                <div className="reservation-offer-card-hotel-container">
                                                                    <div className="reservation-offer-card-content-title">{hotelReservation?.hotel?.name}</div>
                                                                    <div className="reservation-offer-card-content-address">{hotelReservation?.hotel?.contact?.address?.line1}, {hotelReservation?.hotel?.contact?.address?.city?.name}, {hotelReservation?.hotel?.contact?.address?.country?.name} {hotelReservation?.hotel?.contact?.address?.postalCode}</div>
                                                                    <div className="reservation-offer-card-content-address">{hotelReservation?.hotel?.descriptions[0]?.text}</div>
                                                                </div>
                                                                <div className="reservation-offer-card-room-container">
                                                                    <div className="reservation-offer-card-room-title">{hotelReservation?.rooms[0]?.name}</div>
                                                                    <div className="reservation-offer-card-room-detail">
                                                                        <span># of rooms: {hotelReservation?.rates[0]?.occupancies?.length}</span>
                                                                        <span># of people: {hotelReservation?.roomsAllocations[0]?.guests?.length}</span>
                                                                        {/* <span>Breakfast for 2</span> */}
                                                                    </div>
                                                                    <div className='reservation-room-check-in-out-container'>
                                                                        <div>
                                                                            <div className='reservation-room-check-in-title'>Check-In</div>
                                                                            <div className='reservation-room-check-in-date'>{moment(hotelReservation?.tripStartDate).format('DD MMM, ddd YYYY')}</div>
                                                                        </div>
                                                                        <RightArrow className={'reservation-room-arrow'} />
                                                                        <div>
                                                                            <div className='reservation-room-check-in-title'>Check-Out</div>
                                                                            <div className='reservation-room-check-in-date'>{moment(hotelReservation?.tripEndDate).format('DD MMM, ddd YYYY')}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='reservation-room-price-container'>
                                                                        <div>Total:</div>
                                                                        <span>${hotelReservation?.rates[0]?.totalRate}</span>
                                                                    </div>
                                                                    {
                                                                        hotelReservation?.bookingStatus !== 'Cancelled'
                                                                            ?
                                                                            <>
                                                                                <div className="reservation-confirmation-btn-container">
                                                                                    <ButtonSmall buttonstyle="default" className="reservation-confirm-btn" textStyle="reservation-confirm-text" text='Send confirmation email' handleClick={getreservation} />
                                                                                </div>
                                                                                <div className="reservation-offer-card-content-title">Cancellation Policies</div>
                                                                                <div>
                                                                                    {
                                                                                        hotelReservation?.rates[0]?.cancellationPolicies[0]?.rules?.map((item, index) => {
                                                                                            return (

                                                                                                <ul key={index} className="cancelation-bullets">
                                                                                                    <li>Cancellation penalty between {moment(item?.start).format('YYYY-MM-DD')} to {moment(item?.end).format('YYYY-MM-DD')} is ${item?.value}</li>
                                                                                                </ul>
                                                                                            )

                                                                                        })
                                                                                    }
                                                                                </div>
                                                                                <div className="reservation-confirmation-btn-container">
                                                                                    {cancelLoader ?
                                                                                        <Spin style={{ marginLeft: '8px' }} />
                                                                                        :
                                                                                        <ButtonSmall className="button-instance-black-outline reservation-cancel-btn" buttonstyle="button-outline" textStyle="reservation-cancel-text" text="Cancel Booking" handleClick={() => handleCancellation(hotelReservation?.bookingId)} />
                                                                                    }
                                                                                </div>
                                                                            </>
                                                                            :
                                                                            <div className="reservation-canceled-text"> Booking was cancelled on {moment(hotelReservation?.cancellationDate).format('DD-MM-YYYY')} </div>
                                                                    }
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                    }
                                </div>
                            </>
                    }
                </div>
                <Footer className="design-component-instance-node-3" />
            </div >
        </>
    );
};

export default Reservation;