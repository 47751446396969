import './App.css';
import { StoreProvider } from './providers/StoreProvider';
import { AuthProvider } from './providers/AuthProvider';
import APIProvider from './providers/APIProvider';
import NavigationProvider from './providers/NavigationProvider';
import { NotificationProvider } from './providers/NotificationProvider';
import { getToken, onMessage } from "firebase/messaging";
import { messaging } from "./firebaseConfig";
import { notification } from 'antd';
import { useEffect } from 'react';

function App() {


  async function requestPermission() {
    const permission = await Notification.requestPermission();

    if (permission === "granted") {
      const token = await getToken(messaging, {
        vapidKey: "BPaRaONLDU7BiOZegGoAFUyE8tcVVSJE2kkgCjzzMFfkxXra-k-gM1byZcls5EbTYUNHJxpUHIsi3sAupjmc--I",
      });
    } else if (permission === "denied") {
    }
  }

  useEffect(() => {
    requestPermission();
  }, []);

  onMessage(messaging, (payload) => {
    console.log(payload, "payload from App.js");
  });

  return (
    <div className="App">
      <NotificationProvider>
        <StoreProvider>
          <AuthProvider>
            <APIProvider>
              <NavigationProvider />
            </APIProvider>
          </AuthProvider>
        </StoreProvider>
      </NotificationProvider>
    </div>
  );
}


export default App;
