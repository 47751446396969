import React from "react";
import "./style.css";

const Badge = ({ text = "Label" }) => {
  return (
    <div className={`badge-container`}>
      <div className={`badge-text`}>{text}</div>
    </div>
  );
};

export default Badge