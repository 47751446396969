import React, { useEffect, useState } from "react";
import Tag from '../../../api/models/Tag';
import Post from "../../../api/models/Post"
import Card from "../../../Components/Card";
import NavBar from "../../../Components/NavBar";
import Banner from "../../../Components/Banner";
import Footer from "../../../Components/Footer";
import MetaTags from "../../../Components/MetaTags"
import Metatag from "../../../api/models/Metatag.js";
import SearchBar from '../../../Components/SearchBar'
import ButtonSmall from "../../../Components/ButtonSmall";
import bannerImage from "../../../assets/images/occasion/occasions/babymoons.jpg"

let page;
let lastPage;


const Babymoon = ({ setProgress }) => {

    const [seo, setSeo] = useState();
    const [tags, setTags] = useState([]);
    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isVisible, setIsVisible] = useState(true);

    useEffect(() => {
        Tag.all(1).then(({ data }) => {
            let val = data?.map((item) => item).filter((items) => items.name === 'babymoon')
            setTags(val[0]?.id)
        });
    }, [])

    useEffect(() => {
        Metatag.metaslug('babymoon').then(result =>
            setSeo(result)
        );
    }, []);

    useEffect(() => {
        if (tags?.length !== 0) {
            loadPage(true);
        }
    }, [tags]);

    function loadPage(first = false) {
        if (first) {
            setPosts([])
            page = 1;
            lastPage = 1;
        }
        if (page > lastPage) {
            return;
        }
        setLoading(true);
        Post.tagfilter(page, tags)
            .then(data => {
                page === 1
                    ? setPosts(data.data)
                    : setPosts(posts.concat(data.data));
                lastPage = data.meta.last_page;
                page = data.links.next == null ? page + 1 : data?.links?.next.split('?')[1].split('=')[1];
            })
            .catch(() => { })
            .finally(() => {
                setLoading(false);
            });
    }

    useEffect(() => {
        setProgress(10)
        setProgress(20)
        setProgress(60)
        setProgress(100)
    }, [setProgress])

    return (
        <>
            <MetaTags
                title={seo?.title != null ? seo?.title : "LOVU: Ultimate Babymoon Destinations"}
                description={seo?.description != null ? seo?.description : "A Guide for Expectant Parents"}
                imageScr={seo?.meta_image[0]?.original_url != undefined ? seo?.meta_image[0]?.original_url : bannerImage}
            />
            <div className="homepage occassions">
                <Banner
                    isProperties
                    imageSrc={bannerImage}
                    title="Ultimate Babymoon Destinations"
                    heading="A Guide for Expectant Parents"
                    widget={
                        <SearchBar
                            heightToHideFrom={520}
                            isVisible={isVisible}
                            setIsVisible={setIsVisible}
                        />
                    }
                />
                <div className="pageContent">
                    <div className="primarySection">
                        <h2 className="occassions-main-title">Ultimate Babymoon Destinations: A Guide for Expectant Parents</h2>
                        <h2 className="occassions-main-subtitle"> Discover Serene and Safe Babymoon Retreats for Pre-Parental Bliss</h2>
                        <div className="occassions-main-content">
                            Babymoon travel is the perfect way for expectant parents to relax and bond before the
                            whirlwind of parenthood begins. Envision tranquil retreats to serene beaches, lush
                            landscapes, and luxurious resorts that cater specifically to couples awaiting their new
                            arrival. Destinations like the serene Seychelles, the peaceful Costa Rican Cloud Forest,
                            and the picturesque Caribbean islands offer the ideal backdrop for babymooners
                            seeking both relaxation and adventure. Safety, comfort, and convenience are
                            paramount, with many resorts offering prenatal spa treatments, romantic dining options,
                            and activities tailored to pregnant travelers.
                        </div>
                    </div>

                    <h2 className="explore-description">Explore destinations and hotels that are perfect for your dream babymoon :</h2>
                    <div className="card-list-container">
                        <div className="card-list-wrap">
                            {posts.map((v, i) => <Card
                                key={i}
                                imageSrc={v?.featured_image?.original_url}
                                title={v?.title}
                                type={v?.type}
                                city={v?.city}
                                country={v?.country}
                                hasRatingPrice={v?.rating ? true : false}
                                starRating={v?.rating}
                                price={v?.price}
                                backgroundColor={v?.image_color ? v?.image_color : null}
                                cardStyle={v?.template_type}
                                id={v?.id}
                            />)}
                        </div>
                        {
                            (posts?.length === 0 && !loading) && <div style={{ color: 'black' }}>No Data Found</div>
                        }
                        {loading ? <div className="card-list-wrap" style={{ marginTop: -38 }}>
                            <div className="card skeleton-box" />
                            <div className="card skeleton-box" />
                            <div className="card skeleton-box" />
                            <div className="card skeleton-box" />
                        </div> : page > lastPage ? null : <ButtonSmall handleClick={() => loadPage()} className="button-instance-black-outline " buttonstyle="button-outline" text="LOAD MORE" />}
                    </div>
                </div>
                <Footer className="design-component-instance-node-3" isVisible={isVisible} />
                <NavBar navigationDark={true} />

            </div>
        </>
    );
};

export default Babymoon;