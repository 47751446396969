import React from "react";
import ButtonSmall from "../../Components/ButtonSmall";
import moment from "moment";

const ImageTextCardItem = ({
    index,
    item
}) => {
    const [start, stop] = item?.dates;
    const startDate = moment(start).format('MM/DD/YYYY');
    const EndDate = moment(stop).format('MM/DD/YYYY');
    const link = item?.link;

    return (
        <div className="image-text-item">
            <div className="heading-and-wrapper">
                <div className="heading-and">
                    <img className="image-text-thumbnail" alt="Image1" src={item?.image} />
                    <p className="subheading">{item?.title} </p>
                    <div className="heading-and-text">
                        <div className="heading-and-icon">
                            {
                                (start || stop) ?
                                    <div className="heading-2">{(start || stop) && `For travel between ${startDate} – ${EndDate}`}</div>
                                    :
                                    <div className="heading-2">{item?.sub_title.slice(0, 41) + "..."}</div>

                            }
                        </div>
                        <p className="supporting-text-2"> {item?.description.slice(0, 100) + "..."}</p>
                    </div>
                    <a href={link} target="blank">
                        <ButtonSmall
                            className="button-small-5"
                            getInspiredGetClassName="button-small-4"
                            buttonstyle="default"
                            text={item?.label}
                        />
                    </a>
                </div>
            </div>
        </div>
    );
};

export default ImageTextCardItem;