import React from "react";

const People = ({ className }) => {
    return (
        <svg className={className} width="30" height="30" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.01" fill-rule="evenodd" clip-rule="evenodd" d="M0 0V50H50V0H0Z" fill="#F81C1C" fill-opacity="0.338428" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M20.6644 12.5002C20.6644 10.199 18.7442 8.3335 16.3755 8.3335C14.0068 8.3335 12.0865 10.199 12.0865 12.5002C12.0865 14.8013 14.0068 16.6668 16.3755 16.6668C18.7442 16.6668 20.6644 14.8013 20.6644 12.5002ZM21.1525 18.892L24.3692 31.392C24.623 32.3783 23.8537 33.3335 22.8089 33.3335H19.056V40.1043C19.056 40.9673 18.336 41.6668 17.4477 41.6668H15.3032C14.4149 41.6668 13.6949 40.9673 13.6949 40.1043V33.3335H9.94204C8.89547 33.3335 8.12829 32.3768 8.38174 31.392L11.5984 18.892C11.6854 18.554 11.8863 18.2539 12.169 18.0394C12.4517 17.825 12.8001 17.7085 13.1587 17.7085H13.92C15.5076 18.4177 17.3089 18.3884 18.8309 17.7085H19.5922C19.9508 17.7085 20.2992 17.825 20.5819 18.0394C20.8647 18.2539 21.0655 18.554 21.1525 18.892ZM39.5219 12.5002C39.5219 10.199 37.6016 8.3335 35.2329 8.3335C32.8642 8.3335 30.944 10.199 30.944 12.5002C30.944 14.8013 32.8642 16.6668 35.2329 16.6668C37.6016 16.6668 39.5219 14.8013 39.5219 12.5002ZM37.6883 17.7085H38.4496C40.2262 17.7085 41.6663 19.1076 41.6663 20.8335V29.6877C41.6663 30.5506 40.9463 31.2502 40.058 31.2502H38.9857V40.1043C38.9857 40.9673 38.2657 41.6668 37.3774 41.6668H33.0884C32.2002 41.6668 31.4801 40.9673 31.4801 40.1043V31.2502H30.4079C29.5196 31.2502 28.7995 30.5506 28.7995 29.6877V20.8335C28.7995 19.1076 30.2397 17.7085 32.0162 17.7085H32.7775C34.3651 18.4177 36.1664 18.3884 37.6883 17.7085Z" fill="white" />
        </svg>

    );
};

export default People;