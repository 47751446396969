import React, { useEffect, useState } from "react";
import Tag from '../../../api/models/Tag';
import Post from "../../../api/models/Post"
import Card from "../../../Components/Card";
import NavBar from "../../../Components/NavBar";
import Banner from "../../../Components/Banner";
import Footer from "../../../Components/Footer";
import MetaTags from "../../../Components/MetaTags"
import Metatag from "../../../api/models/Metatag.js";
import SearchBar from '../../../Components/SearchBar'
import ButtonSmall from "../../../Components/ButtonSmall";
import bannerImage from "../../../assets/images/occasion/occasions/honeymoon.jpg"

let page;
let lastPage;

const Honeymoon = ({ setProgress }) => {

    const [seo, setSeo] = useState();
    const [tags, setTags] = useState([]);
    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isVisible, setIsVisible] = useState(true);

    useEffect(() => {
        Metatag.metaslug('honeymoon').then(result =>
            setSeo(result)
        );
    }, []);

    useEffect(() => {
        Tag.all(1).then(({ data }) => {
            let val = data?.map((item) => item).filter((items) => items.name?.toLowerCase() == 'honeymoon').map((item) => item.id);
            console.log(val, 'valuessss');

            setTags(val)
        });
    }, [])

    useEffect(() => {
        if (tags?.length !== 0) {
            loadPage(true);
        }
    }, [tags]);

    function loadPage(first = false) {
        if (first) {
            setPosts([])
            page = 1;
            lastPage = 1;
        }
        if (page > lastPage) {
            return;
        }
        setLoading(true);
        Post.tagfilter(page, tags)
            .then(data => {
                page === 1
                    ? setPosts(data.data)
                    : setPosts(posts.concat(data.data));
                lastPage = data.meta.last_page;
                page = data.links.next == null ? page + 1 : data?.links?.next.split('?')[1].split('=')[1];
            })
            .catch(() => { })
            .finally(() => {
                setLoading(false);
            });
    }

    useEffect(() => {
        setProgress(10)
        setProgress(20)
        setProgress(60)
        setProgress(100)
    }, [setProgress])

    return (
        <>
            <MetaTags
                title={seo?.title != null ? seo?.title : "LOVU: Dream Honeymoon Destinations"}
                description={seo?.description != null ? seo?.description : "Start Your Forever in Paradise"}
                imageScr={seo?.meta_image[0]?.original_url != undefined ? seo?.meta_image[0]?.original_url : bannerImage}
            />
            <div className="homepage occassions">
                <Banner
                    isProperties
                    imageSrc={bannerImage}
                    title="Dream Honeymoon Destinations"
                    heading="Start Your Forever in Paradise"
                    widget={
                        <SearchBar
                            heightToHideFrom={520}
                            isVisible={isVisible}
                            setIsVisible={setIsVisible}
                        />
                    }
                />
                <div className="pageContent">
                    <div className="primarySection">
                        <h2 className="occassions-main-title">Dream Honeymoon Destinations: Start Your Forever in Paradise</h2>
                        <h2 className="occassions-main-subtitle">Crafting the Ultimate Romantic Getaway for Newlyweds</h2>
                        <div className="occassions-main-content">
                            Embarking on a honeymoon is more than just a regular vacation. It's the beginning of a
                            new chapter in the newlywed couple's life, offering them a chance to escape to idyllic
                            retreats and experience moments of pure romance. If you're planning your dream
                            honeymoon, consider destinations that combine luxury with tranquility, such as secluded
                            beaches, lavish resorts, and cultural hotspots that offer a mix of relaxation and
                            exploration. Customize your honeymoon to include a blend of leisurely days spent by
                            the sea, romantic dinners under the stars, and thrilling activities that deepen your bond.
                            Focus on choosing destinations that offer a unique and unforgettable experience, be it a
                            serene villa in Bali, an enthralling safari in Africa, or a cozy chalet in the Alps.
                            Remember, the best honeymoons are those that reflect the couple's personality and
                            desires, creating memories that will last a lifetime.
                        </div>
                    </div>

                    <h2 className="explore-description">Explore destinations and hotels that are perfect for your dream honeymoon:</h2>
                    <div className="card-list-container">
                        <div className="card-list-wrap">
                            {posts.map((v, i) => <Card
                                key={i}
                                imageSrc={v?.featured_image?.original_url}
                                title={v?.title}
                                type={v?.type}
                                city={v?.city}
                                country={v?.country}
                                hasRatingPrice={v?.rating ? true : false}
                                starRating={v?.rating}
                                price={v?.price}
                                backgroundColor={v?.image_color ? v?.image_color : null}
                                cardStyle={v?.template_type}
                                id={v?.id}
                            />)}
                        </div>
                        {
                            (posts?.length === 0 && !loading) && <div style={{ color: 'black' }}>No Data Found</div>
                        }
                        {loading ? <div className="card-list-wrap" style={{ marginTop: -38 }}>
                            <div className="card skeleton-box" />
                            <div className="card skeleton-box" />
                            <div className="card skeleton-box" />
                            <div className="card skeleton-box" />
                        </div> : page > lastPage ? null : <ButtonSmall handleClick={() => loadPage()} className="button-instance-black-outline " buttonstyle="button-outline" text="LOAD MORE" />}
                    </div>
                </div>
                <Footer className="design-component-instance-node-3" isVisible={isVisible} />
                <NavBar navigationDark={true} />

            </div>
        </>

    );
};

export default Honeymoon;