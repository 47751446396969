import React, { useEffect, useState } from "react";
import Banner from "../../../Components/Banner";
import Footer from "../../../Components/Footer";
import NavBar from "../../../Components/NavBar";
import MetaTags from "../../../Components/MetaTags";
import Metatag from "../../../api/models/Metatag.js";
import bannerImage from "../../../assets/images/ZentiumSearchBanner.jpeg"
import "./style.css";

const Privacy = ({ setProgress }) => {

    const [seo, setSeo] = useState();

    useEffect(() => {
        setProgress(10)
        setProgress(20)
        setProgress(60)
        setProgress(100)
    }, [setProgress])

    useEffect(() => {
        Metatag.metaslug('privacy_policy').then(result =>
            setSeo(result)
        );
    }, []);

    return (
        <>
            <MetaTags
                title={seo?.title != null ? seo?.title : "LOVU: Privacy-Policy"}
                description={seo?.description != null ? seo?.description : 'This privacy notice for Lovu Travel Inc. ("Company," "we," "us," or "our"), describes how and why we might collect, store, use, and/or share ("process") your information when you use our services'}
                imageScr={seo?.meta_image[0]?.original_url != undefined ? seo?.meta_image[0]?.original_url : bannerImage}
            />
            <div className="homepage privacy">
                <NavBar navigationDark={true} />
                {/* <Banner
                    isSearch={true}
                    imageSrc={bannerImage}
                /> */}
                <div className="pageContent">
                    <div className="primarySection">
                        <h1 className="privacy-main-title">PRIVACY NOTICE</h1>
                        <h2 className="privacy-main-subtitle">Last updated April 01, 2024</h2>
                        <div className="privacy-content">This privacy notice for Lovu Travel Inc. ("Company," "we," "us," or "our"), describes how and why
                            we might collect, store, use, and/or share ("process") your information when you use our services
                            ("Services"), such as when you:</div>
                        <div className="privacy-list-container">
                            <ul className="list-bullets">
                                <li>Download and use our mobile application (LOVU Travel), or any other application of ours
                                    that links to this privacy notice</li>
                                <li>Engage with us in other related ways, including any sales, marketing, or events</li>
                            </ul>
                        </div>

                        <div className="privacy-sub-container">
                            <div className="privacy-content"><div className="privacy-subtitle">Questions or concerns?</div>Reading this privacy notice will help you understand your privacy rights
                                and choices. If you do not agree with our policies and practices, please do not use our Services. If
                                you still have any questions or concerns, please contact us at shri@romance.travel.</div>
                        </div>
                        <h2 className="privacy-main-subtitle">SUMMARY OF KEY POINTS</h2>
                        <div className="privacy-subtitle">This summary provides key points from our privacy notice, but you can find out more details
                            about any of these topics by clicking the link following each key point or by using our table of
                            contents below to find the section you are looking for. You can also click <a href="#toc" style={{ textDecoration: 'underline', color: "#3B3B4D" }}>here</a> to go directly
                            to our table of contents.
                        </div>
                        <div className="privacy-sub-container">
                            <div className="privacy-content"><div className="privacy-subtitle">What personal information do we process?</div>When you visit, use, or navigate our Services, we
                                may process personal information depending on how you interact with Lovu Travel Inc. and the
                                Services, the choices you make, and the products and features you use. Click <a href="#personalinfo" style={{ textDecoration: 'underline', color: "#3B3B4D" }}>here</a> to learn more.</div>
                        </div>
                        <div className="privacy-sub-container">
                            <div className="privacy-content"><div className="privacy-subtitle">Do we process any sensitive personal information?</div>We do not process sensitive personal
                                information.
                            </div>
                        </div>
                        <div className="privacy-sub-container">
                            <div className="privacy-content"><div className="privacy-subtitle">Do you receive any information from third parties?</div>We do not receive any information from third
                                parties.
                            </div>
                        </div>
                        <div className="privacy-sub-container">
                            <div className="privacy-content"><div className="privacy-subtitle">How do you process my information?</div>We process your information to provide, improve, and
                                administer our Services, communicate with you, for security and fraud prevention, and to comply
                                with law. We may also process your information for other purposes with your consent. We process
                                your information only when we have a valid legal reason to do so. Click <a href="#infouse" style={{ textDecoration: 'underline', color: "#3B3B4D" }}>here</a> to learn more.

                            </div>
                        </div>
                        <div className="privacy-sub-container">
                            <div className="privacy-content"><div className="privacy-subtitle">In what situations and with which parties do we share personal information?</div>
                                We may share
                                information in specific situations and with specific third parties. Click <a href="#whoshare" style={{ textDecoration: 'underline', color: "#3B3B4D" }}>here</a> to learn more.
                            </div>
                        </div>
                        <div className="privacy-sub-container">
                            <div className="privacy-content">
                                <div className="privacy-subtitle">How do we keep your information safe?</div>
                                We have organizational and technical processes and
                                procedures in place to protect your personal information. However, no electronic transmission over
                                the internet or information storage technology can be guaranteed to be 100% secure, so we cannot
                                promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be
                                able to defeat our security and improperly collect, access, steal, or modify your information. Click
                                <a href="#infosafe" style={{ textDecoration: 'underline', color: "#3B3B4D" }}>here</a> to learn more.
                            </div>
                        </div>
                        <div className="privacy-sub-container">
                            <div className="privacy-content">
                                <div className="privacy-subtitle">What are your rights?</div>
                                Depending on where you are located geographically, the applicable privacy
                                law may mean you have certain rights regarding your personal information. Click <a href="#privacyrights" style={{ textDecoration: 'underline', color: "#3B3B4D" }}>here</a> to learn more.
                            </div>
                        </div>
                        <div className="privacy-sub-container">
                            <div className="privacy-content">
                                <div className="privacy-subtitle">How do I exercise my rights?</div>
                                The easiest way to exercise your rights is by filling out our data
                                subject request form available here: https://lovu.travel/contacts, or by contacting us. We will consider
                                and act upon any request in accordance with applicable data protection laws.
                            </div>
                        </div>
                        <div className="privacy-content">
                            <div className="privacy-subtitle">How do I exercise my rights?</div>
                            The easiest way to exercise your rights is by filling out our data subject request form available here: https://lovu.travel/contacts, or by contacting us. We will consider and act upon any request in accordance with applicable data protection laws.
                        </div>
                        <div className="privacy-sub-container">
                            <div className="privacy-content">
                                Want to learn more about what Lovu Travel Inc. does with any information we collect? Click <a href="#toc" style={{ textDecoration: 'underline', color: "#3B3B4D" }}>here</a> to
                                review the notice in full.
                            </div>
                        </div>
                        <h2 className="privacy-main-subtitle" id="toc">TABLE OF CONTENTS</h2>
                        <div className="privacy-list-container">
                            <ul className="list-bullets">
                                <li><a href="#infocollect" style={{ textDecoration: 'underline', color: "#3B3B4D" }} >1. WHAT INFORMATION DO WE COLLECT?</a></li>
                                <li><a href="#infouse" style={{ textDecoration: 'underline', color: "#3B3B4D" }}>2. HOW DO WE PROCESS YOUR INFORMATION?</a></li>
                                <li><a href="#legalbases" style={{ textDecoration: 'underline', color: "#3B3B4D" }}>3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?</a></li>
                                <li><a href="#whoshare" style={{ textDecoration: 'underline', color: "#3B3B4D" }}>4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</a></li>
                                <li><a href="#cookies" style={{ textDecoration: 'underline', color: "#3B3B4D" }}>5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</a></li>
                                <li><a href="#sociallogins" style={{ textDecoration: 'underline', color: "#3B3B4D" }}>6. HOW DO WE HANDLE YOUR SOCIAL LOGINS?</a></li>
                                <li><a href="#inforetain" style={{ textDecoration: 'underline', color: "#3B3B4D" }}>7. HOW LONG DO WE KEEP YOUR INFORMATION?</a></li>
                                <li><a href="#infosafe" style={{ textDecoration: 'underline', color: "#3B3B4D" }}>8. HOW DO WE KEEP YOUR INFORMATION SAFE?</a></li>
                                <li><a href="#infominors" style={{ textDecoration: 'underline', color: "#3B3B4D" }}>9. DO WE COLLECT INFORMATION FROM MINORS?</a></li>
                                <li><a href="#privacyrights" style={{ textDecoration: 'underline', color: "#3B3B4D" }}>10. WHAT ARE YOUR PRIVACY RIGHTS?</a></li>
                                <li><a href="#DNT" style={{ textDecoration: 'underline', color: "#3B3B4D" }}>11. CONTROLS FOR DO-NOT-TRACK FEATURES</a></li>
                                <li><a href="#caresidents" style={{ textDecoration: 'underline', color: "#3B3B4D" }}>12. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</a></li>
                                <li><a href="#policyupdates" style={{ textDecoration: 'underline', color: "#3B3B4D" }}>13. DO WE MAKE UPDATES TO THIS NOTICE?</a></li>
                                <li><a href="#contact" style={{ textDecoration: 'underline', color: "#3B3B4D" }}>14. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</a></li>
                                <li><a href="#request" style={{ textDecoration: 'underline', color: "#3B3B4D" }}>15. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</a></li>
                            </ul>
                        </div>
                        <h2 className="privacy-main-subtitle" id="infocollect">1. WHAT INFORMATION DO WE COLLECT?</h2>
                        <h2 className="privacy-main-subtitle" id="personalinfo">Personal information you disclose to us?</h2>
                        <div className="privacy-sub-container">
                            <div className="privacy-content">
                                <div className="privacy-subtitle">In Short:</div>
                                We collect personal information that you provide to us.
                            </div>
                        </div>
                        <div className="privacy-sub-container">
                            <div className="privacy-content">
                                We collect personal information that you voluntarily provide to us when you register on the Services,
                                express an interest in obtaining information about us or our products and Services, when you
                                participate in activities on the Services, or otherwise when you contact us
                            </div>
                        </div>
                        <div className="privacy-sub-container">
                            <div className="privacy-content">
                                <div className="privacy-subtitle">Personal Information Provided by You. </div>
                                The personal information that we collect depends on the
                                context of your interactions with us and the Services, the choices you make, and the products and
                                features you use. The personal information we collect may include the following:
                            </div>
                        </div>
                        <div className="privacy-list-container">
                            <ul className="list-bullets">
                                <li>names</li>
                                <li>phone numbers</li>
                                <li>email addresses</li>
                                <li>usernames</li>
                                <li>passwords</li>
                                <li>contact preferences</li>
                                <li>contact or authentication data</li>
                            </ul>
                        </div>
                        <div className="privacy-sub-container">
                            <div className="privacy-content">
                                <div className="privacy-subtitle">Sensitive Information.</div>
                                We do not process sensitive information.
                            </div>
                        </div>
                        <div className="privacy-sub-container">
                            <div className="privacy-content">
                                <div className="privacy-subtitle">Social Media Login Data.</div>
                                We may provide you with the option to register with us using your existing
                                social media account details, like your Facebook, Twitter, or other social media account. If you
                                choose to register in this way, we will collect the information described in the section called <a href="#sociallogins" style={{ textDecoration: 'underline', color: "#3B3B4D" }}>"HOW
                                    DO WE HANDLE YOUR SOCIAL LOGINS?"</a> below.</div>
                        </div>
                        <div className="privacy-sub-container">
                            <div className="privacy-content">
                                All personal information that you provide to us must be true, complete, and accurate, and you must
                                notify us of any changes to such personal information.
                            </div>
                        </div>
                        <h2 className="privacy-main-subtitle">
                            Information automatically collected</h2>
                        <div className="privacy-sub-container">
                            <div className="privacy-content">
                                <div className="privacy-subtitle">In Short:</div>
                                Some information — such as your Internet Protocol (IP) address and/or browser and
                                device characteristics — is collected automatically when you visit our Services.
                                We automatically collect certain information when you visit, use, or navigate the Services. This
                                information does not reveal your specific identity (like your name or contact information) but may
                                include device and usage information, such as your IP address, browser and device characteristics,
                                operating system, language preferences, referring URLs, device name, country, location, information
                                about how and when you use our Services, and other technical information. This information is
                                primarily needed to maintain the security and operation of our Services, and for our internal analytics
                                and reporting purposes.
                            </div>
                        </div>
                        <div className="privacy-content">
                            Like many businesses, we also collect information through cookies and similar technologies.
                        </div>
                        <div className="privacy-sub-container"><div className="privacy-content">
                            The information we collect includes:
                        </div></div>
                        <div className="privacy-list-container">
                            <ul className="list-bullets">
                                <li>Log and Usage Data. Log and usage data is service-related, diagnostic, usage, and
                                    performance information our servers automatically collect when you access or use our
                                    Services and which we record in log files. Depending on how you interact with us, this log
                                    data may include your IP address, device information, browser type, and settings and
                                    information about your activity in the Services (such as the date/time stamps associated with
                                    your usage, pages and files viewed, searches, and other actions you take such as which
                                    features you use), device event information (such as system activity, error reports
                                    (sometimes called "crash dumps"), and hardware settings).</li>
                                <li>Location Data. We collect location data such as information about your device's location,
                                    which can be either precise or imprecise. How much information we collect depends on the
                                    type and settings of the device you use to access the Services. For example, we may use
                                    GPS and other technologies to collect geolocation data that tells us your current location
                                    (based on your IP address). You can opt out of allowing us to collect this information either
                                    by refusing access to the information or by disabling your Location setting on your device.
                                    However, if you choose to opt out, you may not be able to use certain aspects of the
                                    Services.
                                </li>
                            </ul>
                        </div>
                        <h2 className="privacy-main-subtitle" id="infouse">2. HOW DO WE PROCESS YOUR INFORMATION?</h2>
                        <div className="privacy-sub-container">
                            <div className="privacy-content">
                                <div className="privacy-subtitle">In Short:</div>
                                We process your information to provide, improve, and administer our Services,
                                communicate with you, for security and fraud prevention, and to comply with law. We may also
                                process your information for other purposes with your consent
                            </div>
                        </div>
                        <div className="privacy-subtitle">We process your personal information for a variety of reasons, depending on how you
                            interact with our Services, including:
                        </div>
                        <div className="privacy-list-container">
                            <ul className="list-bullets">
                                <li> <span className="privacy-subtitle">To facilitate account creation and authentication and otherwise manage user
                                    accounts.</span> We may process your information so you can create and log in to your account,
                                    as well as keep your account in working order.
                                </li>
                                <li><span className="privacy-subtitle">To request feedback.</span>We may process your information when necessary to request
                                    feedback and to contact you about your use of our Services.
                                </li>
                                <li><span className="privacy-subtitle">To send you marketing and promotional communications.</span>
                                    We may process the personal
                                    information you send to us for our marketing purposes, if this is in accordance with your
                                    marketing preferences. You can opt out of our marketing emails at any time. For more
                                    information, see <a href="#privacyrights" style={{ textDecoration: 'underline', color: "#3B3B4D" }}>"WHAT ARE YOUR PRIVACY RIGHTS?"</a> below).
                                </li>
                                <li>
                                    <span className="privacy-subtitle">To deliver targeted advertising to you.</span>
                                    We may process your information to develop and
                                    display personalized content and advertising tailored to your interests, location, and more.
                                </li>
                                <li>
                                    <span className="privacy-subtitle">To protect our Services.</span>
                                    We may process your information as part of our efforts to
                                    keep our Services safe and secure, including fraud monitoring and prevention.
                                </li>
                                <li>
                                    <span className="privacy-subtitle">To identify usage trends.</span>
                                    We may process information about how you use our
                                    Services to better understand how they are being used so we can improve them.
                                </li>
                                <li>
                                    <span className="privacy-subtitle">To determine the effectiveness of our marketing and promotional campaigns.</span>
                                    We may
                                    process your information to better understand how to provide marketing and promotional
                                    campaigns that are most relevant to you.
                                </li>
                                <li>
                                    <span className="privacy-subtitle">To save or protect an individual's vital interest.</span>
                                    We may process your information
                                    when necessary to save or protect an individual’s vital interest, such as to prevent
                                    harm.
                                </li>

                            </ul>
                        </div>
                        <h2 className="privacy-main-subtitle" id="legalbases">3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR
                            INFORMATION?</h2>
                        <div className="privacy-sub-container">
                            <div className="privacy-content">
                                <div className="privacy-subtitle">In Short:</div>
                                We only process your personal information when we believe it is necessary and we have a
                                valid legal reason (i.e., legal basis) to do so under applicable law, like with your consent, to comply
                                with laws, to provide you with services to enter into or fulfill our contractual obligations, to protect
                                your rights, or to fulfill our legitimate business interests.
                            </div>
                        </div>
                        <div className="privacy-subtitle">If you are located in the EU or UK, this section applies to you.</div>


                        <div className="privacy-sub-container">
                            <div className="privacy-content">
                                The General Data Protection Regulation (GDPR) and UK GDPR require us to explain the valid legal
                                bases we rely on in order to process your personal information. As such, we may rely on the
                                following legal bases to process your personal information:
                            </div>
                        </div>
                        <div className="privacy-list-container">
                            <ul className="list-bullets">
                                <li> <span className="privacy-subtitle">Consent.</span>
                                    We may process your information if you have given us permission (i.e., consent) to
                                    use your personal information for a specific purpose. You can withdraw your consent at any
                                    time. Click <a href="#withdrawconsent" style={{ textDecoration: 'underline', color: "#3B3B4D" }} >here</a> to learn more.
                                </li>
                                <li><span className="privacy-subtitle">Legitimate Interests.</span>We may process your information when we believe it is
                                    reasonably necessary to achieve our legitimate business interests and those
                                    interests do not outweigh your interests and fundamental rights and freedoms. For
                                    example, we may process your personal information for some of the purposes
                                    described in order to:
                                </li>
                                <ul className="list-bullets">
                                    <li>
                                        Send users information about special offers and discounts on our products and
                                        services
                                    </li>
                                    <li>
                                        Develop and display personalized and relevant advertising content for our
                                        users
                                    </li>
                                    <li>
                                        Analyze how our services are used so we can improve them to engage and
                                        retain users
                                    </li>
                                    <li>
                                        Support our marketing activities
                                    </li>
                                    <li>
                                        Diagnose problems and/or prevent fraudulent activities
                                    </li>
                                    <li>
                                        Understand how our users use our products and services so we can improve
                                        user experience
                                    </li>
                                </ul>
                                <li><span className="privacy-subtitle">Legal Obligations.</span>
                                    We may process your information where we believe it is
                                    necessary for compliance with our legal obligations, such as to cooperate with a law
                                    enforcement body or regulatory agency, exercise or defend our legal rights, or
                                    disclose your information as evidence in litigation in which we are involved.
                                </li>

                                <li>
                                    <span className="privacy-subtitle">Vital Interests.</span>
                                    We may process your information where we believe it is necessary to
                                    protect your vital interests or the vital interests of a third party, such as situations
                                    involving potential threats to the safety of any person
                                </li>

                            </ul>
                        </div>
                        <div className="privacy-subtitle">If you are located in Canada, this section applies to you.</div>
                        <div className="privacy-sub-container">
                            <div className="privacy-content">
                                We may process your information if you have given us specific permission (i.e., express
                                consent) to use your personal information for a specific purpose, or in situations where your
                                permission can be inferred (i.e., implied consent). You can withdraw your consent at any
                                time. Click <a href="#withdrawconsent" style={{ textDecoration: 'underline', color: "#3B3B4D" }}>here</a> to learn more.
                            </div>
                        </div>
                        <div className="privacy-sub-container">
                            <div className="privacy-content">
                                In some exceptional cases, we may be legally permitted under applicable law to process
                                your information without your consent, including, for example:
                            </div>
                        </div>
                        <div className="privacy-list-container">
                            <ul className="list-bullets">

                                <li>If collection is clearly in the interests of an individual and consent cannot be obtained
                                    in a timely way</li>
                                <li>
                                    For investigations and fraud detection and prevention

                                </li>
                                <li>
                                    For business transactions provided certain conditions are met

                                </li>
                                <li>
                                    If it is contained in a witness statement and the collection is necessary to assess,
                                    process, or settle an insurance claim

                                </li>
                                <li>
                                    For identifying injured, ill, or deceased persons and communicating with next of kin
                                </li>
                                <li>
                                    If we have reasonable grounds to believe an individual has been, is, or may be victim
                                    of financial abuse
                                </li>
                                <li>
                                    If it is reasonable to expect collection and use with consent would compromise the
                                    availability or the accuracy of the information and the collection is reasonable for
                                    purposes related to investigating a breach of an agreement or a contravention of the
                                    laws of Canada or a province
                                </li>
                                <li>
                                    If disclosure is required to comply with a subpoena, warrant, court order, or rules of
                                    the court relating to the production of records
                                </li>
                                <li>
                                    If it was produced by an individual in the course of their employment, business, or profession
                                    and the collection is consistent with the purposes for which the information was produced

                                </li>
                                <li>
                                    If the collection is solely for journalistic, artistic, or literary purposes
                                </li>
                                <li>
                                    If the information is publicly available and is specified by the regulations</li>

                            </ul>
                        </div>
                        <h2 className="privacy-main-subtitle" id="whoshare">4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL
                            INFORMATION?</h2>
                        <div className="privacy-sub-container">
                            <div className="privacy-content">
                                <div className="privacy-subtitle">In Short:</div>
                                We may share information in specific situations described in this section and/or with the
                                following third parties.
                            </div>
                        </div>
                        <div className="privacy-content">
                            We may need to share your personal information in the following situations:
                        </div>
                        <div className="privacy-list-container">
                            <ul className="list-bullets">
                                <li> <span className="privacy-subtitle">Business Transfers. </span>
                                    We may share or transfer your information in connection with, or
                                    during negotiations of, any merger, sale of company assets, financing, or acquisition of all or
                                    a portion of our business to another company
                                </li>
                                <li> <span className="privacy-subtitle">When we use Google Maps Platform APIs.</span>
                                    We may share your information with certain
                                    Google Maps Platform APIs (e.g., Google Maps API, Places API). To find out more about
                                    Google’s Privacy Policy, please refer to this <a href="https://policies.google.com/privacy" style={{ textDecoration: 'underline', color: "#3B3B4D" }} target="_blank">link</a>. We obtain and store on your device
                                    ('cache') your location. You may revoke your consent anytime by contacting us at the contact
                                    details provided at the end of this document.
                                </li>
                                <li> <span className="privacy-subtitle">Business Partners.</span>
                                    We may share your information with our business partners to
                                    offer you certain products, services, or promotions.
                                </li>



                            </ul>
                        </div>
                        <h2 className="privacy-main-subtitle" id="cookies">5. DO WE USE COOKIES AND OTHER TRACKING
                            TECHNOLOGIES?</h2>
                        <div className="privacy-sub-container">
                            <div className="privacy-content">
                                <div className="privacy-subtitle">In Short:</div>
                                We may use cookies and other tracking technologies to collect and store your information.
                            </div>
                        </div>
                        <div className="privacy-content">
                            We may use cookies and similar tracking technologies (like web beacons and pixels) to access or
                            store information. Specific information about how we use such technologies and how you can refuse
                            certain cookies is set out in our Cookie Notice.
                        </div>
                        <h2 className="privacy-main-subtitle" id="sociallogins">6. HOW DO WE HANDLE YOUR SOCIAL LOGINS?</h2>
                        <div className="privacy-sub-container">
                            <div className="privacy-content">
                                <div className="privacy-subtitle">In Short:</div>
                                If you choose to register or log in to our services using a social media account, we may
                                have access to certain information about you.
                            </div>
                        </div>
                        <div className="privacy-sub-container">
                            <div className="privacy-content">
                                Our Services offer you the ability to register and log in using your third-party social media account
                                details (like your Facebook or Twitter logins). Where you choose to do this, we will receive certain
                                profile information about you from your social media provider. The profile information we receive may
                                vary depending on the social media provider concerned, but will often include your name, email
                                address, friends list, and profile picture, as well as other information you choose to make public on
                                such a social media platform.
                            </div>
                        </div>
                        <div className="privacy-sub-container">
                            <div className="privacy-content">
                                We will use the information we receive only for the purposes that are described in this privacy notice
                                or that are otherwise made clear to you on the relevant Services. Please note that we do not control,
                                and are not responsible for, other uses of your personal information by your third-party social media
                                provider. We recommend that you review their privacy notice to understand how they collect, use
                                and share your personal information, and how you can set your privacy preferences on their sites
                                and apps.
                            </div>
                        </div>
                        <h2 className="privacy-main-subtitle" id="inforetain">7. HOW LONG DO WE KEEP YOUR INFORMATION?</h2>
                        <div className="privacy-sub-container">
                            <div className="privacy-content">
                                <div className="privacy-subtitle">In Short:</div>
                                We keep your information for as long as necessary to fulfill the purposes outlined in this
                                privacy notice unless otherwise required by law.
                            </div>
                        </div>
                        <div className="privacy-sub-container">
                            <div className="privacy-content">
                                We will only keep your personal information for as long as it is necessary for the purposes set out in
                                this privacy notice, unless a longer retention period is required or permitted by law (such as tax,
                                accounting, or other legal requirements). No purpose in this notice will require us keeping your
                                personal information for longer than twelve (12) months past the termination of the user's account.
                            </div>
                        </div>
                        <div className="privacy-sub-container">
                            <div className="privacy-content">
                                When we have no ongoing legitimate business need to process your personal information, we will
                                either delete or anonymize such information, or, if this is not possible (for example, because your
                                personal information has been stored in backup archives), then we will securely store your personal
                                information and isolate it from any further processing until deletion is possible.
                            </div>
                        </div>
                        <h2 className="privacy-main-subtitle" id="infosafe">8. HOW DO WE KEEP YOUR INFORMATION SAFE?</h2>
                        <div className="privacy-sub-container">
                            <div className="privacy-content">
                                <div className="privacy-subtitle">In Short:</div>
                                We aim to protect your personal information through a system of organizational and
                                technical security measures.
                            </div>
                        </div>
                        <div className="privacy-sub-container">
                            <div className="privacy-content">
                                We have implemented appropriate and reasonable technical and organizational security measures
                                designed to protect the security of any personal information we process. However, despite our
                                safeguards and efforts to secure your information, no electronic transmission over the Internet or
                                information storage technology can be guaranteed to be 100% secure, so we cannot promise or
                                guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat
                                our security and improperly collect, access, steal, or modify your information. Although we will do our
                                best to protect your personal information, transmission of personal information to and from our
                                Services is at your own risk. You should only access the Services within a secure environment.
                            </div>
                        </div>
                        <h2 className="privacy-main-subtitle" id="infominors">9. DO WE COLLECT INFORMATION FROM MINORS?</h2>
                        <div className="privacy-sub-container">
                            <div className="privacy-content">
                                <div className="privacy-subtitle">In Short:</div>
                                We do not knowingly collect data from or market to children under 18 years of age.                            </div>
                        </div>
                        <div className="privacy-sub-container">
                            <div className="privacy-content">
                                We do not knowingly solicit data from or market to children under 18 years of age. By using the
                                Services, you represent that you are at least 18 or that you are the parent or guardian of such a
                                minor and consent to such minor dependent’s use of the Services. If we learn that personal
                                information from users less than 18 years of age has been collected, we will deactivate the account
                                and take reasonable measures to promptly delete such data from our records. If you become aware
                                of any data we may have collected from children under age 18, please contact us at
                                info@romance.travel.
                            </div>
                        </div>
                        <h2 className="privacy-main-subtitle" id="privacyrights">10. WHAT ARE YOUR PRIVACY RIGHTS?</h2>
                        <div className="privacy-sub-container">
                            <div className="privacy-content">
                                <div className="privacy-subtitle">In Short:</div>
                                In some regions, such as the European Economic Area (EEA), United Kingdom (UK), and
                                Canada, you have rights that allow you greater access to and control over your personal information.
                                You may review, change, or terminate your account at any time.</div>
                        </div>
                        <div className="privacy-sub-container">
                            <div className="privacy-content">
                                In some regions (like the EEA, UK, and Canada), you have certain rights under applicable data
                                protection laws. These may include the right (i) to request access and obtain a copy of your personal
                                information, (ii) to request rectification or erasure; (iii) to restrict the processing of your personal
                                information; and (iv) if applicable, to data portability. In certain circumstances, you may also have the
                                right to object to the processing of your personal information. You can make such a request by
                                contacting us by using the contact details provided in the section <a href="#contact" style={{ textDecoration: 'underline', color: "#3B3B4D" }}>“HOW CAN YOU CONTACT US
                                    ABOUT THIS NOTICE?”</a> below.
                            </div>
                        </div>
                        <div className="privacy-sub-container">
                            <div className="privacy-content">
                                We will consider and act upon any request in accordance with applicable data protection laws.
                                If you are located in the EEA or UK and you believe we are unlawfully processing your personal
                                information, you also have the right to complain to your local data protection supervisory authority.
                                You can find their contact details here:
                            </div>
                        </div>
                        <div className="privacy-content">
                            <a href="https://ec.europa.eu/newsroom/article29/items/612080/en" style={{ textDecoration: 'underline', color: "#3B3B4D" }} target="blank">https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm.</a>
                        </div>
                        <div className="privacy-sub-container">
                            <div className="privacy-content">
                                If you are located in Switzerland, the contact details for the data protection authorities are available
                                <a href="https://www.edoeb.admin.ch/edoeb/en/home.html" style={{ textDecoration: 'underline', color: "#3B3B4D" }} target="_blank">here: https://www.edoeb.admin.ch/edoeb/en/home.html.</a>
                            </div>
                        </div>
                        <div className="privacy-sub-container">
                            <div className="privacy-content">
                                <div className="privacy-subtitle" id="withdrawconsent">Withdrawing your consent: </div>
                                If we are relying on your consent to process your personal information,
                                which may be express and/or implied consent depending on the applicable law, you have the right to
                                withdraw your consent at any time. You can withdraw your consent at any time by contacting us by
                                using the contact details provided in the section <a href="#contact" style={{ textDecoration: 'underline', color: "#3B3B4D" }}>"HOW CAN YOU CONTACT US ABOUT THIS
                                    NOTICE?"</a> below or updating your preferences.
                            </div>
                        </div>
                        <div className="privacy-sub-container">
                            <div className="privacy-content">
                                However, please note that this will not affect the lawfulness of the processing before its withdrawal,
                                nor when applicable law allows, will it affect the processing of your personal information conducted in
                                reliance on lawful processing grounds other than consent.
                            </div>
                        </div>
                        <div className="privacy-sub-container">
                            <div className="privacy-content">
                                <div className="privacy-subtitle">Opting out of marketing and promotional communications:</div>
                                You can unsubscribe from our
                                marketing and promotional communications at any time by clicking on the unsubscribe link in the
                                emails that we send, replying “STOP” or “UNSUBSCRIBE” to the SMS messages that we send, or
                                by contacting us using the details provided in the section <a href="#contact" style={{ textDecoration: 'underline', color: "#3B3B4D" }}>"HOW CAN YOU CONTACT US ABOUT THIS
                                    NOTICE?"</a> below. You will then be removed from the marketing lists. However, we may still
                                communicate with you — for example, to send you service-related messages that are necessary for
                                the administration and use of your account, to respond to service requests, or for other
                                non-marketing purposes.
                            </div>
                        </div>
                        <h2 className="privacy-main-subtitle">Account Information</h2>
                        <div className="privacy-sub-container">
                            <div className="privacy-content">

                                If you would at any time like to review or change the information in your account or
                                terminate your account, you can:
                            </div>
                        </div>
                        <div className="privacy-list-container">
                            <ul className="list-bullets">
                                <li>Log in to your account settings and update your user account.</li>
                            </ul>
                        </div>
                        <div className="privacy-sub-container">
                            <div className="privacy-content">
                                Upon your request to terminate your account, we will deactivate or delete your account and
                                information from our active databases. However, we may retain some information in our files to
                                prevent fraud, troubleshoot problems, assist with any investigations, enforce our legal terms and/or
                                comply with applicable legal requirements.
                            </div>
                        </div>
                        <div className="privacy-sub-container">
                            <div className="privacy-content">
                                If you have questions or comments about your privacy rights, you may email us at
                                info@romance.travel.
                            </div>
                        </div>
                        <h2 className="privacy-main-subtitle" id="DNT">11. CONTROLS FOR DO-NOT-TRACK FEATURES</h2>
                        <div className="privacy-sub-container">
                            <div className="privacy-content">
                                Most web browsers and some mobile operating systems and mobile applications include a
                                Do-Not-Track ("DNT") feature or setting you can activate to signal your privacy preference not to
                                have data about your online browsing activities monitored and collected. At this stage no uniform
                                technology standard for recognizing and implementing DNT signals has been finalized. As such, we
                                do not currently respond to DNT browser signals or any other mechanism that automatically
                                communicates your choice not to be tracked online. If a standard for online tracking is adopted that
                                we must follow in the future, we will inform you about that practice in a revised version of this privacy
                                notice.
                            </div>
                        </div>
                        <h2 className="privacy-main-subtitle" id="caresidents">12. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY
                            RIGHTS?
                        </h2>
                        <div className="privacy-sub-container">
                            <div className="privacy-content">
                                <div className="privacy-subtitle">In Short:</div>
                                Yes, if you are a resident of California, you are granted specific rights regarding access to
                                your personal information.
                            </div>
                        </div>
                        <div className="privacy-sub-container">
                            <div className="privacy-content">
                                California Civil Code Section 1798.83, also known as the "Shine The Light" law, permits our users
                                who are California residents to request and obtain from us, once a year and free of charge,
                                information about categories of personal information (if any) we disclosed to third parties for direct
                                marketing purposes and the names and addresses of all third parties with which we shared personal
                                information in the immediately preceding calendar year. If you are a California resident and would
                                like to make such a request, please submit your request in writing to us using the contact information
                                provided below.
                            </div>
                        </div>
                        <div className="privacy-sub-container">
                            <div className="privacy-content">
                                If you are under 18 years of age, reside in California, and have a registered account with Services,
                                you have the right to request removal of unwanted data that you publicly post on the Services. To
                                request removal of such data, please contact us using the contact information provided below and
                                include the email address associated with your account and a statement that you reside in
                                California. We will make sure the data is not publicly displayed on the Services, but please be aware
                                that the data may not be completely or comprehensively removed from all our systems (e.g.,
                                backups, etc.).
                            </div>
                        </div>
                        <h2 className="privacy-main-subtitle">CCPA Privacy Notice</h2>
                        <div className="privacy-content">
                            The California Code of Regulations defines a "resident" as:
                        </div>
                        <div className="privacy-sub-container">
                            <div className="privacy-content">
                                (1) every individual who is in the State of California for other than a temporary or transitory
                                purpose and<br />
                                (2) every individual who is domiciled in the State of California who is outside the State of
                                California for a temporary or transitory purpose
                            </div>
                        </div>
                        <div className="privacy-content">
                            All other individuals are defined as "non-residents."
                        </div>
                        <div className="privacy-sub-container">
                            <div className="privacy-content">
                                If this definition of "resident" applies to you, we must adhere to certain rights and obligations
                                regarding your personal information.
                            </div>
                        </div>
                        <div className="privacy-subtitle">What categories of personal information do we collect?</div>
                        <div className="privacy-sub-container">
                            <div className="privacy-content">
                                We have collected the following categories of personal information in the past twelve (12) months:
                            </div>
                        </div>
                        <div className="privacy-table-container">

                            <table >
                                <tr>
                                    <th>Category</th>
                                    <th>Examples</th>
                                    <th>Collected</th>
                                </tr>
                                <tr>
                                    <td>A.Identifiers</td>
                                    <td>Contact details, such as real name, alias, postal
                                        address, telephone or mobile contact number,
                                        unique personal identifier, online identifier,
                                        Internet Protocol address, email address, and
                                        account name
                                    </td>
                                    <td>NO</td>
                                </tr>
                                <tr>
                                    <td>B. Personal information
                                        categories listed in the
                                        California Customer Records
                                        statute</td>
                                    <td>Name, contact information, education,
                                        employment, employment history, and financial
                                        information
                                    </td>
                                    <td>YES</td>
                                </tr>
                                <tr>
                                    <td>C. Protected classification
                                        characteristics under California
                                        or federal law</td>
                                    <td>Gender and date of birth</td>
                                    <td>Gender and date of birth</td>
                                </tr>
                                <tr>
                                    <td>D. Commercial information </td>
                                    <td>Transaction information, purchase history,
                                        financial details, and payment information </td>
                                    <td>NO</td>
                                </tr>
                                <tr>
                                    <td>E. Biometric information</td>
                                    <td>Fingerprints and voiceprints</td>
                                    <td>NO</td>
                                </tr>
                                <tr>
                                    <td>F. Internet or other similar
                                        network activity
                                    </td>
                                    <td>Browsing history, search history, online behavior,
                                        interest data, and interactions with our and other
                                        websites, applications, systems, and
                                        advertisements
                                    </td>
                                    <td>NO</td>
                                </tr>
                                <tr>
                                    <td>G. Geolocation data
                                    </td>
                                    <td>Device location
                                    </td>
                                    <td>NO</td>
                                </tr>
                                <tr>
                                    <td>H. Audio, electronic, visual,
                                        thermal, olfactory, or similar
                                        information

                                    </td>
                                    <td>Images and audio, video or call recordings
                                        created in connection with our business activities
                                    </td>
                                    <td>NO</td>
                                </tr>
                                <tr>
                                    <td>I. Professional or
                                        employment-related information

                                    </td>
                                    <td>Business contact details in order to provide you
                                        our services at a business level or job title, work
                                        history, and professional qualifications if you
                                        apply for a job with us
                                    </td>
                                    <td>NO</td>
                                </tr>
                                <tr>
                                    <td>J. Education Information
                                    </td>
                                    <td>Student records and directory information

                                    </td>
                                    <td>NO</td>
                                </tr>

                                <tr> <td>K. Inferences drawn from other
                                    personal information

                                </td>
                                    <td>Inferences drawn from any of the collected
                                        personal information listed above to create a
                                        profile or summary about, for example, an
                                        individual’s preferences and characteristics
                                    </td>
                                    <td>NO</td>
                                </tr>
                            </table>
                        </div>


                        {/* <----------table -------------> */}

                        <div className="privacy-content">
                            We may also collect other personal information outside of these categories instances where you
                            interact with us in person, online, or by phone or mail in the context of:
                        </div>

                        <div className="privacy-list-container">
                            <ul className="list-bullets">
                                <li>Receiving help through our customer support channels;</li>
                                <li>Participation in customer surveys or contests; and</li>
                                <li>Facilitation in the delivery of our Services and to respond to your inquiries.</li>
                            </ul>
                        </div>

                        <div className="privacy-subtitle">How do we use and share your personal information?</div>
                        <div className="privacy-sub-container"><div className="privacy-content">
                            More information about our data collection and sharing practices can be found in this privacy notice.
                        </div></div>
                        <div className="privacy-sub-container">
                            <div className="privacy-content">
                                You may contact us by email at info@romance.travel, by visiting https://lovu.travel/contacts, or by
                                referring to the contact details at the bottom of this document.
                            </div>
                        </div>
                        <div className="privacy-sub-container">
                            <div className="privacy-content">
                                If you are using an authorized agent to exercise your right to opt out we may deny a request if the
                                authorized agent does not submit proof that they have been validly authorized to act on your behalf.
                            </div>
                        </div>
                        <div className="privacy-subtitle">Will your information be shared with anyone else?</div>
                        <div className="privacy-sub-container"><div className="privacy-content">
                            We may disclose your personal information with our service providers pursuant to a written contract
                            between us and each service provider. Each service provider is a for-profit entity that processes the
                            information on our behalf.
                        </div></div>
                        <div className="privacy-sub-container">
                            <div className="privacy-content">
                                We may use your personal information for our own business purposes, such as for undertaking
                                internal research for technological development and demonstration. This is not considered to be
                                "selling" of your personal information.
                            </div>
                        </div>
                        <div className="privacy-sub-container">
                            <div className="privacy-content">
                                Lovu Travel Inc. has not disclosed or sold any personal information to third parties for a business or
                                commercial purpose in the preceding twelve (12) months. Lovu Travel Inc. will not sell personal
                                information in the future belonging to website visitors, users, and other consumers.
                            </div>
                        </div>
                        <div className="privacy-subtitle">Your rights with respect to your personal data</div>
                        <div className="privacy-sub-container">
                            <div className="privacy-content">
                                Right to request deletion of the data — Request to delete
                            </div>
                        </div>
                        <div className="privacy-sub-container">
                            <div className="privacy-content">
                                You can ask for the deletion of your personal information. If you ask us to delete your personal
                                information, we will respect your request and delete your personal information, subject to certain
                                exceptions provided by law, such as (but not limited to) the exercise by another consumer of his or
                                her right to free speech, our compliance requirements resulting from a legal obligation, or any
                                processing that may be required to protect against illegal activities.
                            </div>
                        </div>
                        <div className="privacy-sub-container">
                            <div className="privacy-content">
                                Right to be informed — Request to know
                            </div>
                        </div>
                        <div className="privacy-sub-container">
                            <div className="privacy-content">
                                Depending on the circumstances, you have a right to know:
                            </div>
                        </div>


                        <div className="privacy-list-container">
                            <ul className="list-bullets">
                                <li>whether we collect and use your personal information;</li>
                                <li>the categories of personal information that we collect;
                                </li>
                                <li>the purposes for which the collected personal information is used;
                                </li>
                                <li>whether we sell your personal information to third parties;
                                </li>
                                <li>the categories of personal information that we sold or disclosed for a business purpose;</li>
                                <li>the categories of third parties to whom the personal information was sold or disclosed for a
                                    business purpose; and
                                </li>
                                <li> the business or commercial purpose for collecting or selling personal information.
                                </li>
                            </ul>
                        </div>

                        <div className="privacy-sub-container">
                            <div className="privacy-content">
                                In accordance with applicable law, we are not obligated to provide or delete consumer information
                                that is de-identified in response to a consumer request or to re-identify individual data to verify a
                                consumer request.
                            </div>
                        </div>

                        <div className="privacy-sub-container">
                            <div className="privacy-content">
                                Right to Non-Discrimination for the Exercise of a Consumer’s Privacy Right
                            </div>
                        </div>

                        <div className="privacy-sub-container">
                            <div className="privacy-content">
                                We will not discriminate against you if you exercise your privacy rights.
                            </div>
                        </div>
                        <div className="privacy-sub-container">
                            <div className="privacy-content">
                                Verification process
                            </div>
                        </div>
                        <div className="privacy-sub-container">
                            <div className="privacy-content">
                                Upon receiving your request, we will need to verify your identity to determine you are the same
                                person about whom we have the information in our system. These verification efforts require us to
                                ask you to provide information so that we can match it with information you have previously provided
                                us. For instance, depending on the type of request you submit, we may ask you to provide certain
                                information so that we can match the information you provide with the information we already have
                                on file, or we may contact you through a communication method (e.g., phone or email) that you have
                                previously provided to us. We may also use other verification methods as the circumstances dictate.
                            </div>
                        </div>
                        <div className="privacy-sub-container">
                            <div className="privacy-content">
                                We will only use personal information provided in your request to verify your identity or authority to
                                make the request. To the extent possible, we will avoid requesting additional information from you for
                                the purposes of verification. However, if we cannot verify your identity from the information already
                                maintained by us, we may request that you provide additional information for the purposes of
                                verifying your identity and for security or fraud-prevention purposes. We will delete such additionally
                                provided information as soon as we finish verifying you.
                            </div>
                        </div>
                        <div className="privacy-sub-container">
                            <div className="privacy-content">
                                Other privacy rights
                            </div>
                        </div>

                        <div className="privacy-list-container">
                            <ul className="list-bullets">
                                <li>You may object to the processing of your personal information.
                                </li>
                                <li>You may request correction of your personal data if it is incorrect or no longer relevant, or
                                    ask to restrict the processing of the information.

                                </li>
                                <li>You can designate an authorized agent to make a request under the CCPA on your behalf.
                                    We may deny a request from an authorized agent that does not submit proof that they have
                                    been validly authorized to act on your behalf in accordance with the CCPA.

                                </li>
                                <li>You may request to opt out from future selling of your personal information to third parties.
                                    Upon receiving an opt-out request, we will act upon the request as soon as feasibly possible,
                                    but no later than fifteen (15) days from the date of the request submission.
                                </li>
                            </ul>
                        </div>

                        <div className="privacy-sub-container">
                            <div className="privacy-content">
                                To exercise these rights, you can contact us by email at info@romance.travel, by visiting
                                https://lovu.travel/contacts, or by referring to the contact details at the bottom of this document. If you
                                have a complaint about how we handle your data, we would like to hear from you.
                            </div>
                        </div>

                        <h2 className="privacy-main-subtitle" id="policyupdates">
                            13. DO WE MAKE UPDATES TO THIS NOTICE?
                        </h2>

                        <div className="privacy-sub-container">
                            <div className="privacy-content">

                                <div className="privacy-subtitle">In Short:</div> Yes, we will update this notice as necessary to stay compliant with relevant laws.
                            </div>
                            <div className="privacy-content">
                                We may update this privacy notice from time to time. The updated version will be indicated by an updated "Revised" date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy notice frequently to be informed of how we are protecting your information.
                            </div>
                        </div>
                        <h2 className="privacy-main-subtitle" id="contact">
                            14. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
                        </h2>
                        <div className="privacy-sub-container">
                            <div className="privacy-content">
                                If you have questions or comments about this notice, you may email us at info@romance.travel or by post to:
                            </div>
                        </div>
                        <div className="privacy-sub-container">
                            <div className="privacy-content">
                                Lovu Travel Inc.<br />
                                68 Hendrickson Ave<br />
                                Valley Stream, NY 11580<br />
                                United States
                            </div>
                        </div>
                        <h2 className="privacy-main-subtitle" id="request">
                            15. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
                        </h2>
                        <div className="privacy-sub-container">
                            <div className="privacy-content">
                                Based on the applicable laws of your country, you may have the right to request access to the personal information we collect from you, change that information, or delete it in some circumstances. To request to review, update, or delete your personal information, please visit: https://lovu.travel/contacts.
                            </div>
                        </div>

                    </div>
                    <div className="occassionMainContainer">
                    </div>
                </div>
                <Footer className="design-component-instance-node-3" />
            </div>
        </>
    );
};

export default Privacy;