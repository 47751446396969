import React, { useState, useEffect, useRef } from "react";
import dayjs from 'dayjs';
import moment from "moment";
import en_US from "antd/locale/en_US";
import Globe from "../../assets/icons/Globe";
import { Button, DatePicker, Space } from "antd";
import updateLocale from 'dayjs/plugin/updateLocale';
import LocationIcon from "../../assets/icons/LocationIcon"
import HotelIcon from "../../assets/icons/HotelIcon"
import PointOfIntrestIcon from "../../assets/icons/PointOfIntrestIcon"
import OtherIcon from "../../assets/icons/OtherIcon"
import "./style.css";

dayjs.extend(updateLocale);

dayjs.updateLocale('en', {
  weekStart: 1
});

const InputField = ({
  className = "input-field-3",
  text = "Email",
  field,
  isVisible,
  value = "",
  onChange,
  onCountryClick,
  products,
  setProducts,
  handleHotelList,
  NewTravelDate,
  isProductSet,
  setIsproductSet,
  icon = <Globe className="instance-node" />,
  isSearchContainerVisible,
  setIsSearchContainerVisible
}) => {

  const { RangePicker } = DatePicker;

  const [calendarVisible, setCalendarVisible] = useState(false);
  const [formatted_dates_array, setFormatted_dates_array] = useState([]);
  const searchContainerRef = useRef(null);



  useEffect(() => {
    if (NewTravelDate?.length > 0) {
      handleDateFormatFunction(NewTravelDate);
    }
  }, [NewTravelDate])


  const handleDateFormatFunction = (NewTravelDate) => {
    let New_TravelDtae = [];
    NewTravelDate.forEach((date, index) => {
      const formatted_date = moment(date, "YYYY-MM-DD").format("MM/DD/YYYY");
      New_TravelDtae.push(formatted_date);
      setFormatted_dates_array(New_TravelDtae);
    });
  }



  const getButtonLabel = () => {
    if (value && value.length === 2) {
      const startDate = value[0].format('MM/DD/YYYY');
      const endDate = value[1].format('MM/DD/YYYY');
      return `${startDate} - ${endDate}`;
    } else if (value && value.length === 1) {
      const selectedDate = moment(value[0]).format('MM/DD/YYYY');
      return `Selected Date: ${selectedDate}`;
    } else {
      const currentDate = moment().format('MM/DD/YYYY');
      return `${currentDate} - ${currentDate}`;
    }
  };

  const handleRangePicker = () => {
    if (!calendarVisible) {
      setCalendarVisible(true);
    }
  };

  const locale = {
    ...en_US.DatePicker,
    lang: {
      ...en_US.DatePicker.lang,
      monthFormat: "MMMM",
    }
  };

  const handleSearchContainerMouseEnter = () => {
    setIsSearchContainerVisible(true);
  };

  const handleSearchContainerMouseLeave = () => {
    if (!value) {
      setIsSearchContainerVisible(false);
    }
  };


  const handleClickOutsideOfSearchContainer = (event) => {
    if (searchContainerRef.current && !searchContainerRef.current.contains(event.target)) {
      const inputFieldClicked = event.target.closest('.input-field');
      const suggestionClicked = event.target.closest('.suggestion-items-container');
      if (!inputFieldClicked && !suggestionClicked) {
        setIsSearchContainerVisible(false);
      }
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutsideOfSearchContainer);
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideOfSearchContainer);
    };
  }, []);

  function getLocationIcon(type) {
    switch (type) {
      case 'City':
      case 'Country':
      case 'State':
        return <LocationIcon />;
      case "Hotel":
        return <HotelIcon />;
      case "PointOfInterest":
        return <PointOfIntrestIcon />;
      default:
        return <OtherIcon />;
    }
  }


  return (
    <div
      className={`input-field type-icon-leading ${className}`}
    >
      <div className="input-with-label">
        <div className={`label design-component-instance-node`}>
          {<>{isVisible && text}</>}
        </div>

        <div className={`input type-0-icon-leading placeholder sm destructive-false`}>
          {(
            <div className="inputcontent">
              {<>{icon}</>}
              {
                <>
                  <div className={`text-2 input-field-2`}>
                    {
                      field === "text" ? (
                        <div>
                          <input
                            name="myInput"
                            className="input-field"
                            onChange={onChange}
                            value={value}
                            placeholder={"Search Anywhere"}
                            onBlur={() => setIsproductSet(false)}
                            onFocus={() => value != '' && setIsSearchContainerVisible(true)}
                            autoComplete="off"
                          />

                        </div>
                      ) : field === "calendar" ? (
                        <Space direction="vertical" style={{ width: '100%', cursor: 'pointer' }} onClick={handleRangePicker}>
                          {
                            !calendarVisible &&
                            <Button className="date-button">
                              <div className="date-text">
                                {value?.length > 0 ? getButtonLabel() : formatted_dates_array?.length > 0 ? `${formatted_dates_array[0]} - ${formatted_dates_array[1]}` : "Select Dates"}
                              </div>
                            </Button>
                          }
                          {calendarVisible && (
                            <RangePicker
                              minDate={dayjs(moment(new Date()).format("MM/DD/YYYY"))}
                              locale={locale}
                              style={{ color: 'blue' }}
                              open={calendarVisible}
                              format="MM/DD/YYYY"
                              onChange={onChange}
                              value={value}
                              inputReadOnly={true}
                              onOpenChange={(open) => setCalendarVisible(open)}
                              popupStyle={{ transformOrigin: 'top' }}
                              getPopupContainer={(trigger) => trigger.parentNode}
                              dropdownClassName="custom-range-picker-dropdown" 
                            />
                          )}
                        </Space>
                      ) : null
                    }
                  </div>
                </>

              }
            </div>
          )}
          {value !== '' && field === "text" && products?.length > 0 && isSearchContainerVisible &&
            <div
              className="suggestion-items-container"
              ref={searchContainerRef}
              // onClick={(e) => e.stopPropagation()}
              onMouseEnter={handleSearchContainerMouseEnter}
              onMouseLeave={handleSearchContainerMouseLeave}
              style={{ top: isVisible ? '40px' : 'unset', bottom: isVisible ? 'unset' : '40px' }}>
              {
                products?.length > 0 && products?.map((item, index) => (
                  <div className="suggestion-items" key={index + item} onClick={() => handleHotelList(item)}><span style={{ display: 'flex', marginRight: '5px' }} >{getLocationIcon(item?.type)}</span>{item?.fullName} </div>
                ))
              }
            </div>}
          {field === "text" && value?.length !== 0 && products?.length == 0 && isProductSet && <div className="suggestion-items-container">
            <div className="suggestion-no-data">No data</div>
          </div>}
        </div>
      </div>

    </div>
  );
};

export default InputField;