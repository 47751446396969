import React, { useEffect, useRef } from 'react';
import { Input, Modal } from 'antd';
import Room from "../../../assets/icons/Room";
import Plus from "../../../assets/icons/Plus";
import Minus from "../../../assets/icons/Minus";
import People from "../../../assets/icons/People";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ButtonSmall from '../../../Components/ButtonSmall';
import "./style.css";

const GuestModal = ({ visible, onClose, progress = 45, reservation, setReservation, setIsCoupleVisible, setIsBudgetVisible, setIsdestinationVisible }) => {

    const topRef = useRef(null);

    useEffect(() => {
        if (topRef.current) {
            topRef.current.scrollTop = 0;
        }
    }, [visible])

    const ProgressBar = ({ progress }) => {
        return (
            <div className="progress-bar">
                <div className="progress" style={{ width: `${progress}%` }}></div>
            </div>
        );
    };

    const goNext = () => {
        onClose();
        if (reservation.occasion === 'Vow Renewal') {
            setIsBudgetVisible(true);
        } else {
            setIsCoupleVisible(true);
        }
    }
    const handleBack = () => {
        onClose();
        setIsdestinationVisible(true);
    }

    return (
        <div>
            <Modal
                className='guest-maincontainer'
                open={visible}
                onCancel={onClose}
                maskClosable={false}
                footer={[]}
            >
                <div className='guest-modal'>
                    <ArrowBackIcon onClick={handleBack} className='back-arrow' />
                    <ProgressBar progress={progress} />
                    <div className='guest-modal-container' ref={topRef}>
                        <div className='guest-modal-subcontainer'>
                            <div className='guest-title'>How many guests?</div>
                            <div className='num-input-container'>
                                <div onClick={() => {
                                    var values = Number(reservation.persons) - Number(1);
                                    if (values > 0) {
                                        setReservation({
                                            ...reservation,
                                            persons: values,
                                        })
                                    } else {
                                        setReservation({
                                            ...reservation,
                                            persons: 0,
                                        })
                                    }
                                }}>
                                    <Minus className='minus-icon' />
                                </div>

                                <Input className="guest-input"
                                    type="text"
                                    suffix={<span>{<People className='sub-icon' />}</span>}
                                    onChange={e => {
                                        const values = e.target.value;
                                        if (!/^\d*$/.test(values))
                                            return;

                                        setReservation({
                                            ...reservation,
                                            persons: values,
                                        })
                                    }
                                    }
                                    value={reservation.persons}
                                />

                                <div onClick={() => {
                                    if (reservation.persons == null) {
                                        setReservation({
                                            ...reservation,
                                            persons: Number(0) + Number(1),
                                        })
                                    } else {
                                        setReservation({
                                            ...reservation,
                                            persons: Number(reservation.persons) + Number(1),
                                        })
                                    }
                                }}>
                                    <Plus className='minus-icon' />
                                </div>

                            </div>
                        </div>
                        {(reservation.occasion === 'Wedding' ||
                            reservation.occasion === 'Vow Renewal') &&

                            <div className='guest-modal-subcontainer'>
                                <div className='guest-title'>Number of Children? (under 18 years old)</div>
                                <div className='num-input-container'>
                                    <div onClick={() => {
                                        var values = Number(reservation.noofchildrens) - Number(1);
                                        if (values > 0) {
                                            setReservation({
                                                ...reservation,
                                                noofchildrens: values,
                                            })
                                        } else {
                                            setReservation({
                                                ...reservation,
                                                noofchildrens: 0,
                                            })
                                        }
                                    }}>
                                        <Minus className='minus-icon' />
                                    </div>

                                    <Input className="guest-input"
                                        type="text"
                                        suffix={<span>{<People className='sub-icon' />}</span>}
                                        onChange={e => {
                                            const values = e.target.value;
                                            if (!/^\d*$/.test(values))
                                                return;

                                            setReservation({
                                                ...reservation,
                                                noofchildrens: values,
                                            })
                                        }
                                        }
                                        value={reservation.noofchildrens}
                                    />

                                    <div onClick={() => {
                                        if (reservation.noofchildrens == null) {
                                            setReservation({
                                                ...reservation,
                                                noofchildrens: Number(0) + Number(1),
                                            })
                                        } else {
                                            setReservation({
                                                ...reservation,
                                                noofchildrens: Number(reservation.noofchildrens) + Number(1),
                                            })
                                        }
                                    }}>
                                        <Plus className='minus-icon' />
                                    </div>

                                </div>
                            </div>
                        }
                        {reservation.occasion === 'Vow Renewal' ?

                            <div className='guest-modal-subcontainer'>
                                <div className='guest-title'>Number of nights?</div>
                                <div className='num-input-container'>
                                    <div onClick={() => {
                                        var values = Number(reservation.nights) - Number(1);
                                        if (values > 0) {
                                            setReservation({
                                                ...reservation,
                                                nights: values,
                                            })
                                        } else {
                                            setReservation({
                                                ...reservation,
                                                nights: 0,
                                            })
                                        }
                                    }}>
                                        <Minus className='minus-icon' />
                                    </div>

                                    <Input className="guest-input"
                                        type="text"
                                        onChange={e => {
                                            const values = e.target.value;
                                            if (!/^\d*$/.test(values))
                                                return;

                                            setReservation({
                                                ...reservation,
                                                nights: values,
                                            })
                                        }
                                        }
                                        value={reservation.nights}
                                    />

                                    <div onClick={() => {
                                        if (reservation.nights == null) {
                                            setReservation({
                                                ...reservation,
                                                nights: Number(0) + Number(1),
                                            })
                                        } else {
                                            setReservation({
                                                ...reservation,
                                                nights: Number(reservation.nights) + Number(1),
                                            })
                                        }
                                    }}>
                                        <Plus className='minus-icon' />
                                    </div>

                                </div>
                            </div>
                            : <div className='guest-modal-subcontainer'>
                                <div className='guest-title'>Number of rooms?</div>
                                <div className='num-input-container'>
                                    <div onClick={() => {
                                        var values = Number(reservation.rooms) - Number(1);
                                        if (values > 0) {
                                            setReservation({
                                                ...reservation,
                                                rooms: values,
                                            })
                                        } else {
                                            setReservation({
                                                ...reservation,
                                                rooms: 0,
                                            })
                                        }
                                    }}>
                                        <Minus className='minus-icon' />
                                    </div>

                                    <Input className="guest-input"
                                        type="text"
                                        suffix={<span>{<Room className='sub-icon' />}</span>}
                                        onChange={e => {
                                            const values = e.target.value;
                                            if (!/^\d*$/.test(values))
                                                return;

                                            setReservation({
                                                ...reservation,
                                                rooms: values,
                                            })
                                        }
                                        }
                                        value={reservation.rooms}
                                    />

                                    <div onClick={() => {
                                        if (reservation.rooms == null) {
                                            setReservation({
                                                ...reservation,
                                                rooms: Number(0) + Number(1),
                                            })
                                        } else {
                                            setReservation({
                                                ...reservation,
                                                rooms: Number(reservation.rooms) + Number(1),
                                            })
                                        }
                                    }}>
                                        <Plus className='minus-icon' />
                                    </div>

                                </div>
                            </div>}
                        {(reservation.occasion === 'Wedding' ||
                            reservation.occasion === 'Vow Renewal') &&
                            <div className='guest-modal-subcontainer'>
                                <div className='guest-title'>Is it expected that guests will pay for
                                    their own rooms?</div>
                                <div className='guestRowContainer'>
                                    <Input
                                        className={`guest-input-field-choice-${reservation?.guestsOwnRoom === true ? 'selected' : ''}`}
                                        placeholder={"YES"}
                                        style={{ color: '#fff' }}
                                        disabled={reservation?.guestsOwnRoom === true}
                                        onClick={() => setReservation({
                                            ...reservation,
                                            guestsOwnRoom: true,
                                        })}
                                    />
                                    <Input
                                        className={`guest-input-field-choice-${reservation?.guestsOwnRoom === false ? 'selected' : ''}`}
                                        placeholder={"NO"}
                                        disabled={reservation?.guestsOwnRoom === false}
                                        inputStyle={{ color: '#fff' }}
                                        onClick={() => setReservation({
                                            ...reservation,
                                            guestsOwnRoom: false,
                                        })}
                                    />
                                </div>
                            </div>
                        }
                    </div>
                    <div className='next-btn-container'>
                        {reservation.occasion === 'Wedding' ?
                            <ButtonSmall buttonstyle="default " className='destination-next-btn' disabled={reservation.rooms !== null ? '' : 'disabled-destination-btn'} text="Next" handleClick={goNext} />
                            :
                            <ButtonSmall buttonstyle="default " className='destination-next-btn' text="Next" handleClick={goNext} />
                        }
                    </div>


                </div>
            </Modal>
        </div>
    );
};

export default GuestModal;