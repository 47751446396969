import React, { useEffect, useRef, useState } from "react";
import Select, { components } from 'react-select';
import Minus from "../../assets/icons/PlusMinusIcons/Minus"
import Plus from "../../assets/icons/PlusMinusIcons/Plus";
import Delete from "../../assets/icons/PlusMinusIcons/Delete";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const TravelerDetail = ({ isVisible, setIsDropdownVisible, handleDoneHandler, handleAgeChange, options, stickyDropdownRef, inputFieldIcon2, isDropdownVisible, handleAdd, inputValue, handleInputClick, rooms, handleDelete, handleAdultsChange, handleChildrenChange }) => {

    const dropdownRef = useRef(null);
    const [menuIsOpen, setMenuIsOpen] = useState([]);

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            const inputFieldClicked = event.target.closest('.dropdown-menu-container');
            if (!inputFieldClicked) {
                setIsDropdownVisible(false);
            }
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleMenuOpen = (roomIndex, childIndex) => {
        const updatedMenuIsOpenArray = [...menuIsOpen];
        updatedMenuIsOpenArray[`${roomIndex}-${childIndex}`] = true;
        setMenuIsOpen(updatedMenuIsOpenArray);
    };

    const handleMenuClose = (roomIndex, childIndex) => {
        const updatedMenuIsOpenArray = [...menuIsOpen];
        updatedMenuIsOpenArray[`${roomIndex}-${childIndex}`] = false;
        setMenuIsOpen(updatedMenuIsOpenArray);
    };

    const DropdownIndicator = (props) => {
        const { selectProps } = props;
        const isOpen = selectProps.menuIsOpen;
        const handleMouseDown = (e) => {
            e.preventDefault();
            e.stopPropagation();
            if (isOpen) {
                selectProps.onMenuClose();
            } else {
                selectProps.onMenuOpen();
            }
        };

        return (
            <components.DropdownIndicator {...props}>
                <div onMouseDown={handleMouseDown}>
                    {isOpen ? <KeyboardArrowUpIcon fontSize="small" /> : <KeyboardArrowDownIcon fontSize="small" />}
                </div>
            </components.DropdownIndicator>
        );
    };

    useEffect(() => {
        if (!isDropdownVisible) {
            setMenuIsOpen([])
        }
    }, [isDropdownVisible])

    return (
        <div className={`input-field type-icon-leading input-field-instance planned-place`}>
            <div className="input-with-labels">
                <div className={`label design-component-instance-node`}>{<>{isVisible && "Travelers"}</>}</div>
                <div className={`input type-0-icon-leading placeholder sm destructive-false`}>
                    {(
                        <div className="inputcontent">
                            <>{inputFieldIcon2}</>
                            <>
                                <div className={`text-2 input-field-2`}>
                                    <div className="dropdown-main-container">
                                        <input
                                            name="myInput"
                                            className="input-field"
                                            onClick={handleInputClick}
                                            value={inputValue}
                                            placeholder="2 Travelers..."
                                            autoComplete="off"
                                            readOnly
                                        />
                                    </div>
                                </div>
                            </>
                        </div>
                    )}
                    {isDropdownVisible && (
                        <div ref={dropdownRef} className="dropdown-menu-container">
                            <div className="dropdown-menu-card">
                                {rooms.map((room, roomIndex) => (
                                    <div key={roomIndex} className={`dropdown-card ${roomIndex !== rooms.length - 1 ? 'last-room' : ''}`}>
                                        <div className="Room-Name-Tag">
                                            <h3 className="Room-Tag">Room {roomIndex + 1}</h3>
                                            {room.showDelete && (
                                                <div className="room-delete-icon" onClick={() => handleDelete(roomIndex)}>
                                                    <Delete className="icon-cursor" />
                                                </div>
                                            )}
                                        </div>
                                        <div className="Room-category">
                                            <div className="Room-persons">
                                                <div className="Room-Tag" style={{ fontWeight: '500' }}>Adults</div>
                                                <div className="Room-members">
                                                    <span onClick={() => handleAdultsChange(roomIndex, -1)} strokecolor={room.adults === 0 ? 'grey' : 'black'}>
                                                        <Minus className="icon-cursor" />
                                                    </span>
                                                    <div className="Room-Tag" style={{ fontWeight: '500', marginTop: -3 }}>{room.adults}</div>
                                                    <span onClick={() => handleAdultsChange(roomIndex, 1)}>
                                                        <Plus className="icon-cursor" strokecolor={room.adults === 6 ? 'grey' : 'black'} />
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="Room-persons">
                                                <div className="Room-label">
                                                    <div className="Room-Tag" style={{ fontWeight: '500' }}>Children</div>
                                                    <div className="Child-Tag" style={{ fontWeight: '400' }}>Ages 0 to 17</div>
                                                </div>
                                                <div className="Room-members">
                                                    <span onClick={() => handleChildrenChange(roomIndex, -1)}>
                                                        <Minus className="icon-cursor" strokecolor={room.children === 0 ? 'grey' : 'black'} />
                                                    </span>
                                                    <div>{room.children}</div>
                                                    <span onClick={() => handleChildrenChange(roomIndex, 1)}>
                                                        <Plus className="icon-cursor" strokecolor={room.children === 6 ? 'grey' : 'black'} />
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            {Array.from({ length: room.children }, (_, childIndex) => {
                                                return (
                                                    <Select
                                                        key={childIndex}
                                                        className="booking-widget-drop-down"
                                                        classNamePrefix="booking-children-dropdown"
                                                        options={options}
                                                        onChange={(selectedOption) => handleAgeChange(roomIndex, childIndex, selectedOption)}
                                                        value={room.ages[childIndex] || null}
                                                        placeholder="Select age"
                                                        isSearchable={false}
                                                        styles={{
                                                            menuPortal: base => ({ ...base, zIndex: 9999 }),
                                                            menu: provided => ({ ...provided, zIndex: 9999 }),
                                                        }}
                                                        menuPlacement={isVisible ? "bottom" : "top"}
                                                        menuPortalTarget={document.body}
                                                        components={{ DropdownIndicator }}
                                                        onMenuOpen={() => handleMenuOpen(roomIndex, childIndex)}
                                                        onMenuClose={() => handleMenuClose(roomIndex, childIndex)}
                                                        menuIsOpen={menuIsOpen[`${roomIndex}-${childIndex}`]}
                                                    />
                                                )
                                            })}
                                        </div>
                                        {room.error && <p className="zentrumbook-error">{room.error}</p>}
                                    </div>
                                ))}
                                <div className="room-button-container">
                                    <div className="Add-room-button">
                                        <div
                                            className={`Add-room-button-text ${rooms.length >= 8 ? 'disabled' : ''}`}
                                            onClick={rooms.length < 8 ? handleAdd : null}
                                        >
                                            Add Room
                                        </div>
                                    </div>
                                    <div className="Add-room-button" onClick={handleDoneHandler}>
                                        <div className="Done-button-text">Done</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div>

            </div>
        </div>
    )
}

export default TravelerDetail