import React, { createContext, useContext, useState } from 'react';
import ButtonSmall from '../Components/ButtonSmall';
import CenteredPopup from '../Components/CenteredPopup';

const NotificationContext = createContext();

export function NotificationProvider(props) {
    const [notification, createNotification] = useState(false);
    return (
        <NotificationContext.Provider
            value={[notification, createNotification]}>
            {props.children}
            {notification && (
                <>
                    <CenteredPopup
                        visible={true}
                        title={notification.title}
                        content={notification.content}
                        actions={
                            <>
                                {notification.primary && (
                                    <ButtonSmall
                                        className="" buttonstyle="default"
                                        textStyle="notification-text"
                                        text={notification.primary.title}
                                        handleClick={() => {
                                            notification.primary.onPress &&
                                                notification.primary.onPress();
                                            createNotification(false);
                                        }}
                                    />
                                )}
                                {notification.secondary && (
                                    <ButtonSmall
                                        className="button-instance-black" buttonstyle="default"
                                        textStyle="notification-text"
                                        text={notification.secondary.title}
                                        handleClick={() => createNotification(false)}
                                    />
                                )}
                            </>
                        } />
                </>

            )}
        </NotificationContext.Provider>
    );
}

export default function useNotification() {
    return useContext(NotificationContext);
}
