import React, { useEffect, useState } from "react";
import Booking from "../../Components/Booking";
import axios from "axios";
import dayjs from "dayjs";
import moment from "moment";
import { writeData } from "../../Helpers";

const SearchBar = ({ heightToHideFrom, setSearchTerm, searchTerm, LocationId, startDate, name, endDate, NoofPeople, roomArray, occasion, setIsWidgetVisible, iszentrumsearch, isupdatebtn, setDateAndLocation, directHotelDetail, isVisible, setIsVisible }) => {

    // const [isVisible, setIsVisible] = useState(true);
    const [isMobile, setIsMobile] = useState(false)
    const [NewTravelDate, setNewTravelDate] = useState([]);
    const [planToGoText, setPlanToGoText] = useState(!name ? '' : name);
    const [searchDetail, setSearchDetail] = useState();
    const [travelDates, setTravelDates] = useState();
    const [occasions, setOccasions] = useState([{ "value": occasion ? occasion : "Celebrating Love", "label": occasion ? occasion : "Celebrating Love" }]);
    const [noOfPeoples, setNoOfPeoples] = useState([{ "value": NoofPeople ? NoofPeople : "2", "label": NoofPeople ? NoofPeople : "2" }]);
    const [products, setProducts] = useState([]);
    const [isProductSet, setIsproductSet] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);
    const [isStickyDropdownVisible, setIsStickyDropdownVisible] = useState(false);
    const initialRoomsState = roomArray || [
        { adults: 2, children: 0, ages: [{ value: null, label: 'Select age' }], showDelete: false, error: null }
    ];
    const [rooms, setRooms] = useState(initialRoomsState);
    const [isSearchContainerVisible, setIsSearchContainerVisible] = useState(false);



    const options = Array.from({ length: 18 }, (_, i) => ({ value: i, label: i === 0 ? 'Under 1' : `${i}`, }));

    const handleAdultsChange = (index, delta) => {
        const newRooms = [...rooms];
        const newAdultsCount = newRooms[index].adults + delta;

        // Limit the number of adults to a minimum of 1 and a maximum of 6
        if (newAdultsCount >= 1 && newAdultsCount <= 6) {
            newRooms[index].adults = newAdultsCount;
            setRooms(newRooms);
            updateInputValue(newRooms);
        }
    }
    const handleChildrenChange = (index, delta) => {
        const newRooms = [...rooms];
        const newChildrenCount = newRooms[index].children + delta;

        if (newChildrenCount >= 0 && newChildrenCount <= 6) {
            newRooms[index].children = newChildrenCount;

            if (newChildrenCount > rooms[index].children) {
                newRooms[index].ages = [...(newRooms[index].ages || []), ...Array(newChildrenCount - rooms[index].children).fill({ value: null, label: 'Select age' })];
            } else {
                newRooms[index].ages = newRooms[index].ages.slice(0, newChildrenCount);
            }

            newRooms[index].error = null;
            setRooms(newRooms);
            updateInputValue(newRooms);
        }
    }

    useEffect(() => {
        if (roomArray?.length > 0) {
            setRooms(roomArray);
            updateInputValue(roomArray);
        }
    }, [roomArray]);

    const handleAgeChange = (roomIndex, childIndex, selectedOption) => {
        const newRooms = [...rooms];
        newRooms[roomIndex].ages[childIndex] = selectedOption;
        newRooms[roomIndex].error = null;
        setRooms(newRooms);
        updateInputValue(newRooms);
    };

    const updateInputValue = (rooms) => {
        const totalAdults = rooms.reduce((acc, room) => acc + room.adults, 0);
        const totalChildren = rooms.reduce((acc, room) => acc + room.children, 0);
        const totalMembers = totalAdults + totalChildren;
        setInputValue(`${totalMembers} Traveler${totalMembers > 1 ? 's' : ''}...`);
    };


    const handleAdd = () => {
        if (rooms.length < 8) {
            const newRooms = [...rooms, { adults: 1, children: 0, ages: [{ value: null, label: 'Select age' }], showDelete: true, error: null }];
            setRooms(newRooms);
            updateInputValue(newRooms);
        }
    };

    const handleDelete = (index) => {
        const newRooms = [...rooms];
        newRooms.splice(index, 1);
        updateInputValue(newRooms);
        setRooms(newRooms);
    };

    const handleInputClick = () => {
        setIsDropdownVisible(!isDropdownVisible);
        setIsStickyDropdownVisible(!isStickyDropdownVisible);
    };

    const handleDoneHandler = () => {
        const newRooms = rooms.map(room => {
            return { ...room, error: null };
        });
        setRooms(newRooms);

        if (newRooms.some(room => room.error)) {
            return;
        }

        setIsDropdownVisible(!isDropdownVisible);
    }

    async function handleSearchClick(event) {
        setPlanToGoText(event)
        if (event === "") {
            setProducts([]);
            setIsproductSet(false)
            return;
        }
        if (event) {
            setIsSearchContainerVisible(true);
        } else {
            setIsSearchContainerVisible(false);
        }
        const hotelList = await axios.get(`https://autosuggest.us.prod.zentrumhub.com/api/locations/locationcontent/autosuggestv2?term=${event}&isGoogleAutoCompleteEnabled=true`, { headers: { accountId: process.env.REACT_APP_ZENTRUM_ACCOUNT_ID, apiKey: process.env.REACT_APP_GOOGLE_API_KEY, Authorization: null } });
        const hotel = hotelList?.data?.locationSuggestions?.filter((item) => { if (item?.fullName?.toLowerCase().includes(event?.toLowerCase())) { return item; } })
        setProducts(hotel);
        setIsproductSet(true);
    }

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 1024) {
                setIsVisible(false)
                setIsMobile(true)
            }
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);



    const listenToScroll = () => {
        const winScroll = document.body.scrollTop ||
            document.documentElement.scrollTop;

        if (winScroll > heightToHideFrom) {
            isVisible &&
                setIsVisible(false);
        } else {
            setIsVisible(true);
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", listenToScroll);
        return () =>
            window.removeEventListener("scroll", listenToScroll);
    }, [heightToHideFrom])

    useEffect(() => {
        if (startDate && endDate) {
            setNewTravelDate([startDate, endDate])
        }
    }, [startDate, endDate])




    useEffect(() => {
        if (setDateAndLocation) {
            const hotelStartDate = dayjs(moment(new Date()));
            const hotelEndDate = dayjs(moment(new Date())).add(3, 'day');
            setTravelDates([hotelStartDate, hotelEndDate])
            setNewTravelDate([hotelStartDate, hotelEndDate])
        }
    }, [setDateAndLocation])


    return (
        <>
            <div>
                <div style={isMobile ? {} : !isVisible ? { display: "none" } : {}} className={"widget"}>
                    <Booking
                        buttonSmallText={isupdatebtn ? "update search" : "SEARCH OFFERS"}
                        isVisible={true}
                        onChangePlanToGo={(event) => { handleSearchClick(event.target.value) }}
                        onChangeTravelDate={(dates) => { setTravelDates(dates) }}
                        onChangeOccassion={(values) => { setOccasions(values) }}
                        onChangeNoOfPeople={(values) => { setNoOfPeoples(values) }}
                        onCountryClick={(value) => { setPlanToGoText(value); }}
                        setProducts={(value) => setProducts(value)}
                        setSearchDetail={(value) => writeData('searchLocationDetail', value)}
                        searchDetail={searchDetail}
                        planToGo={planToGoText}
                        travelDates={travelDates ? travelDates : iszentrumsearch ? [dayjs(moment(startDate)), dayjs(moment(endDate))] : ''}
                        occasions={occasions}
                        noOfPeoples={noOfPeoples}
                        products={!isMobile ? isVisible ? products : [] : products}
                        NewTravelDate={NewTravelDate}
                        setIsWidgetVisible={setIsWidgetVisible}
                        LocationId={LocationId}
                        isProductSet={isProductSet}
                        setIsproductSet={setIsproductSet}
                        inputValue={inputValue}
                        handleInputClick={handleInputClick}
                        isDropdownVisible={isDropdownVisible}
                        rooms={rooms}
                        handleDelete={handleDelete}
                        handleAdultsChange={handleAdultsChange}
                        handleChildrenChange={handleChildrenChange}
                        setIsDropdownVisible={setIsDropdownVisible}
                        handleAgeChange={handleAgeChange}
                        options={options}
                        handleAdd={handleAdd}
                        handleDoneHandler={handleDoneHandler}
                        setDateAndLocation={setDateAndLocation}
                        directHotelDetail={directHotelDetail}
                        isSearchContainerVisible={isSearchContainerVisible}
                        setIsSearchContainerVisible={setIsSearchContainerVisible}
                        searchTerm={searchTerm}
                        setSearchTerm={setSearchTerm}
                    />
                </div>
                <div className={isVisible ? 'widget-wrap widget-hide' : 'widget-wrap widget-visible'}>
                    <div className={"sticky-widget"}>
                        <Booking
                            buttonSmallText={isupdatebtn ? "update search" : "SEARCH OFFERS"}
                            onChangePlanToGo={(event) => { handleSearchClick(event.target.value); }}
                            onChangeTravelDate={(dates) => { setTravelDates(dates) }}
                            onChangeOccassion={(values) => { setOccasions(values) }}
                            onChangeNoOfPeople={(values) => { setNoOfPeoples(values) }}
                            onCountryClick={(value) => { setPlanToGoText(value); }}
                            setProducts={(value) => setProducts(value)}
                            setSearchDetail={(value) => setSearchDetail(value)}
                            searchDetail={searchDetail}
                            isVisible={false}
                            planToGo={planToGoText}
                            travelDates={travelDates ? travelDates : iszentrumsearch ? [dayjs(moment(startDate)), dayjs(moment(endDate))] : ''}
                            occasions={occasions}
                            noOfPeoples={noOfPeoples}
                            products={products}
                            NewTravelDate={NewTravelDate}
                            LocationId={LocationId}
                            isProductSet={isProductSet}
                            setIsproductSet={setIsproductSet}
                            inputValue={inputValue}
                            handleInputClick={handleInputClick}
                            isDropdownVisible={isDropdownVisible}
                            rooms={rooms}
                            handleDelete={handleDelete}
                            handleAdultsChange={handleAdultsChange}
                            handleChildrenChange={handleChildrenChange}
                            setIsDropdownVisible={setIsDropdownVisible}
                            handleAgeChange={handleAgeChange}
                            options={options}
                            handleAdd={handleAdd}
                            handleDoneHandler={handleDoneHandler}
                            setDateAndLocation={setDateAndLocation}
                            directHotelDetail={directHotelDetail}
                            isSearchContainerVisible={isSearchContainerVisible}
                            setIsSearchContainerVisible={setIsSearchContainerVisible}
                            searchTerm={searchTerm}
                            setSearchTerm={setSearchTerm}
                        />
                    </div>
                </div>
            </div>
        </>
    )
};

export default SearchBar;