import { useState } from "react";
import axios from "axios";
import ButtonSmall from "../../Components/ButtonSmall";
import { PaymentElement } from "@stripe/react-stripe-js";
import { useLocation, useNavigate } from "react-router-dom";
import { useStripe, useElements } from "@stripe/react-stripe-js";


export default function CheckoutForm({ setStripePromise, bookingId, guestEmail, setIsBookingPayment, setIsPaymentSuccess, setFinalBookLoader }) {

    let tokendata = localStorage.getItem('data');
    const stripe = useStripe();
    const elements = useElements();
    const location = useLocation();
    const navigate = useNavigate();
    const searchParams = new URLSearchParams(location.search);
    const ip = searchParams.get('ip');
    const correlationId = searchParams.get('correlationId');


    const [message, setMessage] = useState(null);
    const [isProcessing, setIsProcessing] = useState(false);
    const [isCancelling, setIsCancelling] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }
        setIsProcessing(true);
        const response = await stripe.confirmPayment({
            elements,
            confirmParams: {
                // Make sure to change this to your payment completion page
            },
            redirect: 'if_required'
        });

        if (response.error && response.error.type === "card_error" || response.error && response.error.type === "validation_error") {
            setMessage(response.error.message);
        } else if (response?.paymentIntent?.id) {
            const paymentintentid = response?.paymentIntent?.id;
            try {
                await axios.post(`/payments/${tokendata == null ? 'unauthverifyPaymentIntent' : 'verifyPaymentIntent'}`, {
                    paymentintentid, bookingId, guestEmail
                }).then(result => {
                    if (result?.data?.status == "succeeded") {
                        setIsPaymentSuccess(true);
                        setStripePromise('');
                        setIsBookingPayment(false)

                    } else {
                        setMessage('Payment verification failed please try again after sometimes!..')
                    }
                });
            } catch (error) {
                console.log(error);
            }
        }
        setIsProcessing(false);
    };

    const handleCancelation = async () => {
        setIsCancelling(true);
        setIsBookingPayment(false);
        setFinalBookLoader(false)
        setIsCancelling(false);
    }

    return (
        <form id="payment-form" className="zentrum-booking-payment-popup" onSubmit={handleSubmit}>
            <PaymentElement id="payment-element" />
            <div className="zentrum-payment-btn-container">
                <button disabled={isProcessing || !stripe || !elements} id="submit">
                    <span id="button-text">
                        {isProcessing ? "Processing ... " : "Pay now"}
                    </span>
                </button>
                <ButtonSmall className="button-instance-gold zentrum-book-camcel-btn" buttonstyle="default" text={isCancelling ? "cancelling ..." : "cancel"} handleClick={handleCancelation} />
            </div>
            {message && <div id="payment-message">{message}</div>}
        </form>
    );
}
