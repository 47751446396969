import React from "react";
import ImageTextItem from "./ImageTextItem";
import "./style.css";

const ImageText = ({
  data
}) => {
  return (
    <div className="image-text-wrap">
      {data.map((item, index) => <ImageTextItem key={index} index={index} item={item} />)}
    </div>
  );
};

export default ImageText;