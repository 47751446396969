import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Modal, Spin } from 'antd';
import ButtonSmall from '../../Components/ButtonSmall';
import RedCheck from '../../assets/icons/RedCheck';
import useStore from '../../providers/StoreProvider';
import BadgeVisible from '../../Components/BadgeVisible';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import HorizontalLogo from '../../assets/icons/HorizontalLogo';
import "./style.css";

const Upgrade = ({ visible, onClose, setIsPopupVisible, setIsJoinNow, setIsUpgradePayment, setClientSecret, setSubscriptionId }) => {

    const { store } = useStore();
    const [select, setSelect] = useState('quater');
    const [loading, setLoading] = useState(false);
    const [paymentLoading, setPaymentLoading] = useState(false);
    const [reslut, setResult] = useState();
    const [priceId, setPriceId] = useState();

    useEffect(() => {
        if (visible) {
            getPlanDetail();
        }
    }, [visible])

    const UpgradePerkList = [
        'Dedicated Travel Advisor',
        'Concierge Service',
        'Advisor-Curated Hotel Recommendations',
        'Personalized Itinerary Planning',
        'Exclusive perks and amenities',
        'Hotel Discounts',
    ]

    const getPlanDetail = async () => {
        setLoading(true);
        try {
            await axios.post(`/payments/getStripePlans`).then(result => {
                setResult(result?.data.reverse())
                setLoading(false);
            });
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        if (reslut?.length > 0) {
            setPriceId(reslut[0]?.id)
        }
    }, [reslut])

    const data = localStorage.getItem('data');


    const handleUpgrade = async () => {
        if (data === null) {
            onClose();
            setIsPopupVisible(true);
            setIsJoinNow(true);
        } else {
            setPaymentLoading(true);
            await axios.post(`/payments/createSubscription`, { priceId: priceId }).then(result => {
                setClientSecret(result.data.clientSecret);
                setSubscriptionId(result.data.subscriptionId);
                setPaymentLoading(false);
                setIsUpgradePayment(true);
            });
        }
    }
    return (
        <div>
            <Modal
                className='upgrade-maincontainer'
                open={visible}
                maskClosable={false}
                onCancel={onClose}
                footer={[]}
            >
                <div className='upgrade'>
                    <ArrowBackIcon onClick={() => onClose()} className='back-arrow' />
                    <div className='upgrade-subcontainer'>
                        <div className='upgrade-title'>Introducing</div>
                        <div className='logo-premium'>
                            <HorizontalLogo className="horizontal-logo" />
                            <BadgeVisible
                                className={"premium-visible"}
                                text="premium"
                                textStyle="premium-text"
                            />
                        </div>
                        <div className='upgrade-greet'>With very special perks, tailored for the{' '}romantic at heart.</div>
                        <div className='listcontainer'>
                            {
                                UpgradePerkList?.map((item, index) => {
                                    return (
                                        <div key={index} className='listitem'>
                                            <RedCheck className='red-check' />
                                            <div className='listitem-title'>{item}</div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        {
                            loading ?
                                <Spin style={{ display: 'flex', justifyContent: 'center', marginTop: '15px' }} />
                                :
                                <div className='upgrade-amount'>
                                    {reslut?.map((item, index) => {
                                        return (

                                            <div
                                                key={index}
                                                onClick={() => {
                                                    setSelect(item?.interval === "month" && item?.interval_count === 3 ? 'quater' : (item?.interval === "year" && item?.interval_count === 1) ? 'year' : null)
                                                    setPriceId(item?.id)
                                                }
                                                }
                                                className={`upgrade-monthly ${(select === 'quater' && index === 0) ? 'upgrade-select' : (select === 'year' && index === 1) ? 'upgrade-select' : null}`}
                                            >
                                                <div className='month-title'>
                                                    {item?.interval === "month" && item?.interval_count === 1 ? "monthly" : item?.interval === "month" && item?.interval_count === 3 ? 'quarterly' : item?.interval === "year" && item?.interval_count === 1 ? 'yearly' : null}
                                                </div>
                                                <div className='upgrade-price'>${item?.amount / 100}</div>
                                            </div>
                                        )
                                    })}
                                </div>
                        }
                        {paymentLoading ?
                            <Spin style={{ display: 'flex', justifyContent: 'center', marginTop: '15px' }} />
                            :
                            reslut?.length > 0 && !loading ?
                                <ButtonSmall className="upgrade-btn" buttonstyle="default" text={data !== null ? 'upgrade' : 'join now'} handleClick={handleUpgrade} />
                                :
                                null

                        }
                        <div onClick={() => onClose()} className='upgrade-skip'>Skip</div>
                        <div className='terms-policy-container'>
                            <a href='/terms-and-conditions'><div className='terms-text'>Terms and Conditions</div></a>
                            <a href='/privacy-policy'><div className='terms-text'>Privacy Policy</div></a>
                        </div>
                    </div>
                </div>

            </Modal>
        </div>
    );
};

export default Upgrade;