
import React, { useEffect, useState } from 'react';
import { useForm } from 'antd/es/form/Form';
import { useNavigate } from 'react-router-dom';
import useAuth from '../../../providers/AuthProvider';
import useStore from '../../../providers/StoreProvider';
import { Modal, Input, Button, Spin, Form, message } from 'antd';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import "./style.css";

const ResetPassword = ({
    visible, onClose, setIsModalVisible, email, token, isPasswordChange, onSaveClick
}) => {
    const [form] = Form.useForm();
    const navigate = useNavigate()
    const { resetPassword } = useAuth();
    const { store } = useStore();

    const { data } = useForm({
        email: '',
        password: '',
        remember: false,
    });
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(false);
    }, [visible, store, setIsModalVisible]);

    const onConfirmPassword = (values) => {
        setLoading(true)
        resetPassword({
            token: token,
            email: email,
            password: values.password,
            password_confirmation: values.confirmPassword
        }).finally(() => message.success('Password Updated Successfully.'), setLoading(false), setIsModalVisible(false), navigate('/'));
    };

    const onFinishResetPassword = (values) => {
        setLoading(true)
        onSaveClick(values)
        setLoading(false)
        setIsModalVisible(false)
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <Modal
            className='resetpassword-maincontainer'
            maskClosable={false}
            open={visible}
            onCancel={onClose}
            footer={[]}
        >
            <div className='popupmodal'>
                {
                    isPasswordChange ?
                        <Form
                            form={form}
                            name="basic"
                            labelCol={{ span: 8 }}
                            initialValues={data}
                            onFinish={onFinishResetPassword}
                            onFinishFailed={onFinishFailed}
                            autoComplete="off"
                        >
                            <Form.Item
                                name="current_password"
                                rules={[{ required: true, message: 'Please input your Password!' }]}
                            >
                                <Input.Password placeholder='Enter current password' className='login-input' autoComplete="off" iconRender={visible => (
                                    visible ? <EyeOutlined /> : <EyeInvisibleOutlined />
                                )} />
                            </Form.Item>
                            <Form.Item
                                name="password"
                                rules={[{ required: true, message: 'Please input your Password!' }]}
                            >
                                <Input.Password placeholder='Enter new password' className='login-input' autoComplete="off" iconRender={visible => (
                                    visible ? <EyeOutlined /> : <EyeInvisibleOutlined />
                                )} />
                            </Form.Item>
                            <Form.Item
                                name="password_confirmation"
                                dependencies={['password']}
                                rules={[
                                    { required: true, message: 'Confirm your Password!' },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value || getFieldValue('password') === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error('The confirm password do not match!'));
                                        },
                                    }),
                                ]}
                            >
                                <Input.Password autoComplete="off" placeholder='Re-enter new password' className='login-input' iconRender={visible => (
                                    visible ? <EyeOutlined /> : <EyeInvisibleOutlined />
                                )} />
                            </Form.Item>
                            <Form.Item wrapperCol={{ span: 24 }}>
                                {loading ? <Spin style={{ marginLeft: '8px' }} />
                                    :
                                    <Button type="primary" htmlType="submit" className='submit-button'>
                                        Save
                                    </Button>}
                            </Form.Item>

                        </Form>
                        :
                        <Form
                            form={form}
                            name="basic"
                            labelCol={{ span: 8 }}
                            initialValues={data}
                            onFinish={onConfirmPassword}
                            onFinishFailed={onFinishFailed}
                            autoComplete="off"
                        >
                            <Form.Item
                                name="password"
                                rules={[{ required: true, message: 'Please input your Password!' }]}
                            >
                                <Input.Password placeholder='Password' className='login-input' autoComplete="off" iconRender={visible => (
                                    visible ? <EyeOutlined /> : <EyeInvisibleOutlined />
                                )} />
                            </Form.Item>
                            <Form.Item
                                name="confirmPassword"
                                dependencies={['password']}
                                rules={[
                                    { required: true, message: 'Confirm your Password!' },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value || getFieldValue('password') === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error('The confirm password do not match!'));
                                        },
                                    }),
                                ]}
                            >
                                <Input.Password autoComplete="off" placeholder='confirm password' className='login-input' iconRender={visible => (
                                    visible ? <EyeOutlined /> : <EyeInvisibleOutlined />
                                )} />
                            </Form.Item>
                            <Form.Item wrapperCol={{ span: 24 }}>
                                {loading ? <Spin style={{ marginLeft: '8px' }} />
                                    :
                                    <Button type="primary" htmlType="submit" className='submit-button'>
                                        Set new password
                                    </Button>}
                            </Form.Item>

                        </Form>
                }
            </div>
        </Modal>
    );
};

export default ResetPassword;