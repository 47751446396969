import React, { useEffect, useState } from "react";
import Post from "../../../api/models/Post"
import Card from "../../../Components/Card";
import Banner from "../../../Components/Banner";
import Footer from "../../../Components/Footer";
import NavBar from "../../../Components/NavBar";
import MetaTags from "../../../Components/MetaTags";
import Metatag from "../../../api/models/Metatag.js";
import useStore from "../../../providers/StoreProvider";
import ButtonSmall from "../../../Components/ButtonSmall";
import bannerImage from "../../../assets/images/ZentiumSearchBanner.jpeg"

let page;
let lastPage;

const Special = ({ setProgress }) => {

    let tokendata = localStorage.getItem('data');

    const [seo, setSeo] = useState();
    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setProgress(10)
        setProgress(20)
        setProgress(60)
        setProgress(100)
    }, [setProgress])

    useEffect(() => {
        Metatag.metaslug('special').then(result =>
            setSeo(result)
        );
        loadPage(true);
        // if (tokendata) {
        // }
    }, []);

    function loadPage(first = false, refresh = false) {
        if (first) {
            setPosts([])
            page = 1;
            lastPage = 1;
        }
        if (page > lastPage) {
            return;
        }
        setLoading(true);
        let types = 'offer';
        Post.alls(page, types)
            .then(data => {
                page === 1
                    ? setPosts(data.data)
                    : setPosts(posts.concat(data.data));
                lastPage = data.meta.last_page;
                page = data.links.next == null ? page + 1 : data?.links?.next.split('?')[1].split('=')[1];
            })
            .catch(() => { })
            .finally(() => {
                setLoading(false);
            });
    }

    let text =
        "😢 We're busy curating awesome hotels and marketplace, check back soon!";

    if (!tokendata) {
        text = `😢 Marketplace only available for registered users.`;
    }

    return (
        <>
            <MetaTags
                title={seo?.title != null ? seo?.title : "LOVU: Marketplace"}
                description={seo?.description != null ? seo?.description : "Discover handpicked romantic destinations with LOVU. Connect with expert Romance Travel Advisors to plan the perfect honeymoon, anniversary, or getaway."}
                imageScr={seo?.meta_image[0]?.original_url != undefined ? seo?.meta_image[0]?.original_url : bannerImage}
            />
            <div className={`favorite`}>
                <NavBar navigationDark={true} />
                {/* <Banner
                    isSearch={true}
                    imageSrc={bannerImage}
                /> */}
                <div className="pageContent">

                    <div className="title-wrap special">
                        <h2 className="favorite-title">Marketplace</h2>
                    </div>
                    <div className="card-list-container">
                        <div className="card-list-wrap">
                            {posts.map((v, i) => <Card
                                key={i}
                                imageSrc={v?.featured_image?.original_url}
                                title={v?.title}
                                type={v?.type}
                                city={v?.city}
                                country={v?.country}
                                hasRatingPrice={v?.rating ? true : false}
                                starRating={v?.rating}
                                price={v?.price}
                                backgroundColor={v?.image_color ? v?.image_color : null}
                                cardStyle={v?.template_type}
                                id={v?.id}
                                length={v?.length}
                                url={v?.website}
                            />)}
                        </div>
                        {
                            (posts?.length === 0 && !loading) && <div style={{ color: 'black' }}>{text}</div>
                        }
                        {loading ? <div className="card-list-wrap" style={{ marginTop: -38 }}>
                            <div className="card skeleton-box" />
                            <div className="card skeleton-box" />
                            <div className="card skeleton-box" />
                            <div className="card skeleton-box" />
                        </div> : page > lastPage ? null : tokendata && <ButtonSmall handleClick={() => loadPage()} className="button-instance-black-outline " buttonstyle="button-outline" addStyle='additionalsmall' text="LOAD MORE" />}
                    </div>
                </div>
                <Footer className="design-component-instance-node-3" />
            </div>
        </>
    );
};

export default Special;