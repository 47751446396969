import React from "react";
import ButtonSmall from "../ButtonSmall";
import NavBar from "../../Components/NavBar";
import Banner from "../../Components/Banner";
import MetaTags from "../../Components/MetaTags"
import Cupid1 from "../../assets/images/Cupid1.jpg"
import Cupid2 from "../../assets/images/Cupid2.jpg"
import Cupid3 from "../../assets/images/Cupid3.jpg"
import Cupid4 from "../../assets/images/Cupid4.jpg"

const CupidDesign = () => {

    const actionButton = () => {
        return <ButtonSmall className="" buttonstyle="default" text="check out our destinations" />

    }

    const images = [
        { url: Cupid1, title: 'Cupid Trip Designer', heading: 'Plan and book your perect honeymoon', actionButton: actionButton() },
        { url: Cupid2 },
        { url: Cupid3 },
        { url: Cupid4 },
    ];

    return (
        <>
            <MetaTags
                title="LOVU: Cupid Trip Designer"
                imageScr={Cupid1}
            />
            <div className={`homepage cupid`}>
                <Banner
                    imageSrc={images}
                />
                <NavBar />
            </div>
        </>
    );
};

export default CupidDesign;