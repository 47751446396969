import React from "react";

const OtherIcon = ({ className }) => {
    return (
        <svg className={className} width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.125 2.18066C13.125 1.41504 13.5352 0.704102 14.2188 0.293945C14.875 -0.0888672 15.7227 -0.0888672 16.4062 0.293945C17.0625 0.704102 17.5 1.41504 17.5 2.18066C17.5 2.97363 17.0625 3.68457 16.4062 4.09473C15.7227 4.47754 14.875 4.47754 14.2188 4.09473C13.5352 3.68457 13.125 2.97363 13.125 2.18066ZM11.2656 7.7041L12.5781 5.54395C12.7148 5.37988 12.9062 5.24316 13.125 5.24316C13.3164 5.24316 13.5352 5.37988 13.6445 5.54395L17.2539 11.4502C17.3906 11.7236 17.5 12.0244 17.5 12.3252C17.5 13.2549 16.7344 13.9932 15.8047 13.9932H12.4688H1.50391C0.683594 13.9932 0 13.3096 0 12.4893C0 12.1885 0.0820312 11.915 0.21875 11.6689L6.15234 2.20801C6.31641 1.93457 6.64453 1.74316 7 1.74316C7.32812 1.74316 7.65625 1.93457 7.82031 2.20801L11.2656 7.7041ZM12.0586 8.96191L13.7539 11.6689C13.918 11.915 14 12.1885 14 12.4619C14 12.5439 13.9727 12.626 13.9727 12.6807H15.8047C16.0234 12.6807 16.1602 12.5166 16.1602 12.3252C16.1602 12.2705 16.1602 12.1885 16.1328 12.1338L13.125 7.21191L12.0586 8.96191ZM12.4688 12.6807C12.5781 12.6807 12.6875 12.5986 12.6875 12.4893C12.6875 12.4346 12.6602 12.4072 12.6328 12.3525L7 3.35645L1.33984 12.3525C1.3125 12.4072 1.3125 12.4346 1.3125 12.4893C1.3125 12.5986 1.39453 12.6807 1.50391 12.6807H12.4688Z" fill="#344054" />
        </svg>
    );
};

export default OtherIcon;