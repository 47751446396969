import React from "react";

const ItinearariesIcon = ({ className, fillcolor }) => {
    return (

        <svg className={className} width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path className={fillcolor} d="M12 1.5H2C1.71875 1.5 1.5 1.75 1.5 2V14C1.5 14.2812 1.71875 14.5 2 14.5H12C12.25 14.5 12.5 14.2812 12.5 14V2C12.5 1.75 12.25 1.5 12 1.5ZM2 0H12C13.0938 0 14 0.90625 14 2V14C14 15.125 13.0938 16 12 16H2C0.875 16 0 15.125 0 14V2C0 0.90625 0.875 0 2 0ZM3 12.5C3 12.25 3.21875 12 3.5 12H10.5C10.75 12 11 12.25 11 12.5C11 12.7812 10.75 13 10.5 13H3.5C3.21875 13 3 12.7812 3 12.5ZM4.03125 7C4.1875 8 4.84375 8.8125 5.71875 9.21875C5.5 8.6875 5.3125 7.96875 5.25 7H4.03125ZM7 9.5H6.96875H7C7.0625 9.40625 7.15625 9.25 7.28125 9C7.46875 8.5625 7.65625 7.9375 7.71875 7H6.25C6.3125 7.9375 6.5 8.5625 6.6875 9C6.8125 9.25 6.90625 9.40625 6.96875 9.5H7ZM9.9375 7H8.71875C8.65625 7.96875 8.46875 8.6875 8.28125 9.21875C9.125 8.8125 9.78125 8 9.9375 7ZM9.9375 6C9.78125 5.03125 9.125 4.21875 8.28125 3.8125C8.46875 4.34375 8.6875 5.0625 8.71875 6H9.9375ZM7 3.5H6.96875C6.90625 3.625 6.8125 3.78125 6.6875 4.03125C6.5 4.46875 6.3125 5.09375 6.25 6H7.71875C7.65625 5.09375 7.46875 4.46875 7.28125 4.03125C7.1875 3.78125 7.0625 3.625 7 3.5ZM4.03125 6H5.25C5.3125 5.0625 5.5 4.34375 5.71875 3.8125C4.84375 4.21875 4.1875 5.03125 4.03125 6ZM7 10.5C5.5625 10.5 4.25 9.75 3.53125 8.5C2.8125 7.28125 2.8125 5.75 3.53125 4.5C4.25 3.28125 5.5625 2.5 7 2.5C8.40625 2.5 9.71875 3.28125 10.4375 4.5C11.1562 5.75 11.1562 7.28125 10.4375 8.5C9.71875 9.75 8.40625 10.5 7 10.5Z" fill="url(#paint0_linear_6929_15847)" />
            <defs>
                <linearGradient id="paint0_linear_6929_15847" x1="-4.81987" y1="5.30029" x2="8.17233" y2="21.0214" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#393E45" />
                    <stop offset="1" stopColor="#0A0D10" />
                </linearGradient>
            </defs>
        </svg>


    );
};

export default ItinearariesIcon;