import React from 'react';
import axios from 'axios';
import { message } from 'antd';

export default props => {

    axios.defaults.baseURL = process.env.REACT_APP_API_URL;
    axios.defaults.headers.common.Accept = 'application/json';
    axios.defaults.headers.common['Content-Type'] = 'application/json';

    axios.interceptors.response.use(undefined, function (error) {
        if (error.response === undefined) {
            return Promise.reject(error);
        }
        if (!error.response.status) {
            message.error({
                title: 'No internet',
                content: "No internet connection. Can't reach the server.",
                primary: {
                    title: 'OK',
                },
            });
            return Promise.reject(error);
        }
        switch (error.response.status) {
            case 503:
                message.error({
                    title: 'Server Maintenance',
                    content: 'Please try again later!',
                    primary: {
                        title: 'OK',
                    },
                });
                break;
            case 500:
                message.error({
                    title: 'Server Error',
                    content:
                        'Oops! Something went wrong.\nPlease try again later!',
                    primary: {
                        title: 'OK',
                    },
                });
                break;
            case 422:
                // let data = error.response.data;
                // let errors = Object.keys(data.errors).map(key =>
                //     data.errors[key].join('\n'),
                // );
                // message.error({
                //     title: error.response.data.message,
                //     content: errors.join('\n'),
                //     primary: {
                //         title: 'OK',
                //     },
                // });
                break;
            case 401:
                // alert('Unauthorized');
                //Log OUT
                // logout();
                break;
            case 403:
                //Alert.alert('Forbidden.');
                break;
        }
        return Promise.reject(error);
    });

    return <>{props.children}</>;
};
