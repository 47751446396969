
import React from "react";

const RedCheck = ({ className }) => {
    return (
        <svg className={className} width="38" height="27" viewBox="0 0 38 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M36.5547 1.08228C37.6211 2.06665 37.6211 3.78931 36.5547 4.77368L15.5547 25.7737C14.5703 26.8401 12.8477 26.8401 11.8633 25.7737L1.36328 15.2737C0.296875 14.2893 0.296875 12.5667 1.36328 11.5823C2.34766 10.5159 4.07031 10.5159 5.05469 11.5823L13.75 20.1956L32.8633 1.08228C33.8477 0.0158691 35.5703 0.0158691 36.5547 1.08228Z" fill="url(#paint0_linear_3128_7990)" />
            <defs>
                <linearGradient id="paint0_linear_3128_7990" x1="-2" y1="-7.86304" x2="-2" y2="34.137" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#EC0D66" />
                    <stop offset="1" stopColor="#C21A46" />
                </linearGradient>
            </defs>
        </svg>


    );
};

export default RedCheck
