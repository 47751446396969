import React, { createContext, useContext } from 'react';
import { readData, removeData, writeData } from '../Helpers';
import axios from 'axios';
import User from '../api/models/User';
import useStore from './StoreProvider';
import { message } from 'antd';

const AuthContext = createContext();

export function AuthProvider(props) {

    const { store, setStore, setInitialStore } = useStore();

    const authenticate = async () => {
        const data = await readData();

        if (!data) {
            await removeData();
            setInitialStore();
            return false;
        }
        axios.defaults.headers.common.Authorization = `Bearer ${data.token}`;
        return User.authenticated().then(user => {
            localStorage.setItem('user', JSON.stringify(user));
            setStore({ ...store, token: data.token, user: user });
        });
    };

    const logout = async () => {
        removeData().then(() => setInitialStore());
    };

    const socialSignIn = async value => {
        // if (value?.loginurl) {
        //     const tripswindow = window.open(value?.loginurl, '_blank');
        //     setTimeout(() => {
        //         tripswindow.close();
        //     }, 3000);
        // }
        const token = value?.token;
        await writeData('data', { token });
        return authenticate();
    };

    const showErrorModal = (data) => {
        message.error(data.message)
    }

    const signIn = async data => {
        try {
            const { token, loginurl } = await User.login(data);
            if (token) {
                await writeData('data', { token });
                // if (loginurl) {
                //     const tripswindow = window.open(loginurl, '_blank');
                //     setTimeout(() => {
                //         tripswindow.close();
                //     }, 3000);
                // }
                return authenticate();
            }
        } catch (error) {
            if (error.response && error.response.status === 422) {
                let data = error.response.data;
                showErrorModal(data);
            } else {
            }
        }

    };

    const register = async data => {
        try {
            const { token, loginurl } = await User.register(data);
            await writeData('data', { token });
            // if (loginurl) {
            //     const tripswindow = window.open(loginurl, '_blank');
            //     setTimeout(() => {
            //         tripswindow.close();
            //     }, 3000);
            // }
            return authenticate();
        } catch (error) {
            if (error.response && error.response.status === 422) {
                let data = error.response.data;
                showErrorModal(data);
            } else {
            }
        }
    };

    const resetPassword = async data => {
        try {
            const { token } = await User.resetPassword(data);
            await writeData('data', { token });
            return authenticate();
        } catch (error) {
            if (error.response && error.response.status === 422) {
                let data = error.response.data;
                showErrorModal(data);
            } else {
            }
        }
    };

    return (
        <AuthContext.Provider
            value={{
                authenticate,
                logout,
                signIn,
                socialSignIn,
                register,
                resetPassword,
            }}>
            {props.children}
        </AuthContext.Provider>
    );
}

export default function useAuth() {
    return useContext(AuthContext);
}
