import axios from 'axios';

export async function sendReservation(id, data, cancelToken = null) {
    return axios({
        url: `/reservations/${id}`,
        method: 'POST',
        data: data,
    })
        .then(response => {
            return response.data.data
        })
}

export async function getOnboarding(cancelToken = null) {
    return axios({
        url: '/onboarding',
        method: 'GET',
    })
        .then(response => response.data.data)
}
