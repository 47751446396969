import React from "react";

const Room = ({ className }) => {
    return (
        <svg className={className} width="30" height="30" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.01" fill-rule="evenodd" clip-rule="evenodd" d="M0 0V50H50V0H0Z" fill="#F81C1C" fill-opacity="0.338428" />
            <path d="M17.4997 24.5835C19.7971 24.5835 21.6663 22.7142 21.6663 20.4168C21.6663 18.1194 19.7971 16.2502 17.4997 16.2502C15.2023 16.2502 13.333 18.1194 13.333 20.4168C13.333 22.7142 15.2023 24.5835 17.4997 24.5835ZM35.833 17.9168H24.1663C23.7059 17.9168 23.333 18.2897 23.333 18.7502V26.2502H11.6663V15.4168C11.6663 14.9564 11.2934 14.5835 10.833 14.5835H9.16634C8.70592 14.5835 8.33301 14.9564 8.33301 15.4168V33.7502C8.33301 34.2106 8.70592 34.5835 9.16634 34.5835H10.833C11.2934 34.5835 11.6663 34.2106 11.6663 33.7502V31.2502H38.333V33.7502C38.333 34.2106 38.7059 34.5835 39.1663 34.5835H40.833C41.2934 34.5835 41.6663 34.2106 41.6663 33.7502V23.7502C41.6663 20.5283 39.0549 17.9168 35.833 17.9168Z" fill="white" />
        </svg>

    );
};

export default Room;