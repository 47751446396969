import React from "react";
import { Helmet } from "react-helmet";

const MetaTags = ({ title = "LOVU: Romantic Getaways, Hotels &amp; Travel Advisors | Plan Your Dream Trip", description = "Discover handpicked romantic destinations with LOVU. Connect with expert Romance Travel Advisors to plan the perfect honeymoon, anniversary, or getaway.", imageScr = "https://lovu.travel/wp-content/uploads/2023/05/hero10.png" }) => {

  const url = window.location.href;
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{title}</title>
      <meta name="description" content={description} />
      <link rel="canonical" href={url} />
      <meta name='robots' content={process.env.REACT_APP_ROBOTS} />
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={url} />
      <meta property="og:site_name" content="LOVU" />
      <meta property="og:image" content={imageScr} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={imageScr} />
    </Helmet>
  );
};

export default MetaTags