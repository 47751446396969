import React, { useEffect, useState } from "react";
import Content from "../Content";
import ButtonSmall from "../ButtonSmall";
import { useNavigate } from "react-router-dom";
import SimpleImageSlider from "react-simple-image-slider";
import bannerImage from "../../assets/images/no image.jpg";
import { Button as Btn, Modal, Box, Typography } from '@mui/material';
import "./style.css";

const ZentrumRoomCard = ({
    description,
    name,
    images,
    rate,
    refundability,
    handlePolicyHandler,
    policies,
    onClose,
    isPolicyVisible,
    HotelId,
    hotelTitle,
    tokenForNewHotel,
    ip,
    startDate,
    endDate,
    recommendationId,
    correlationId,
    setIsWelcomeVisible,
    rateDescription,
    rooms,
    LocationId,
    occasion,
    people,
    locationTitle,
}) => {

    const navigate = useNavigate();
    let token = localStorage.getItem('data');

    const [showNav, setShowNav] = useState(true);

    useEffect(() => {
        if (images?.length === 1) {
            setShowNav(false);
            return;
        }
    }, [images]);


    const handleRoom = () => {
        navigate(`/hotelbooking/${HotelId}?filter=${hotelTitle}&token=${tokenForNewHotel}&ip=${ip}&startDate=${startDate}&endDate=${endDate}&recommendationId=${recommendationId}&correlationId=${correlationId}&LocationId=${LocationId}&occasion=${occasion}&people=${people}&location=${locationTitle}&rooms=${rooms}`);
    }

    return (
        <>
            <div className="zentrum-roomcard">
                <div className="zentrum-roomcard-image-container">
                    {
                        images?.length > 0 ?
                            <SimpleImageSlider
                                style={{ position: 'absolute' }}
                                width={'100%'}
                                height={'100%'}
                                images={images}
                                showNavs={showNav}
                                navSize={{ width: "10px" }}
                            />
                            :
                            <img className="zentrum-roomcard-image" src={bannerImage} alt="no image" />
                    }



                </div>
                <div className="zentrum-roomcard-content-container">
                    <div className="zentrum-roomcard-content-subcontainer-one">
                        <div className="seacrh-title">{name} {rateDescription.toLowerCase() == 'roomonly' ? '' : '+ ' + rateDescription.split(/(?=[A-Z])/).join(' ')}</div>
                        <div className="seacrh-address" dangerouslySetInnerHTML={{__html: description}} ></div>
                    </div>
                    <div className="zentrum-roomcard-content-subcontainer-two">
                        <div className="zentrum-room-policy">
                            <div className="zentrum-room-policy-content">All-Inclusive</div>
                            <div className="zentrum-dot"></div>
                            <div className="zentrum-room-policy-content">{refundability ? "Refundable" : "Non-Refundable"}</div>
                            <div className="zentrum-dot"></div>
                            <div className="zentrum-room-policy-content" onClick={() => handlePolicyHandler()}>View Policy</div>
                        </div>
                        <div className="zentrum-room-rates">
                            <div className="zentrum-room-price">${rate} <span className="zentrum-room-per-night">per night</span></div>

                            <ButtonSmall className="zentrum-book-btn" buttonstyle="default" text="book this room" handleClick={handleRoom} />
                        </div>
                    </div>
                </div>

            </div>
            <Modal
                open={isPolicyVisible}
                onClose={onClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                BackdropProps={{ style: { backgroundColor: 'transparent' } }}
                sx={{ '& .MuiDialog-paper': { overflowY: 'hidden', boxShadow: 'none' } }}
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        bgcolor: 'background.paper',
                        boxShadow: 1,
                        p: 4,
                        width: 400,
                        maxHeight: 600,
                        overflowY: 'auto',
                        scrollbarWidth: 'none',
                        padding: '20px'
                    }}
                >
                    {
                        policies?.length > 0 &&
                        <Typography id="modal-modal-title" variant="h6" component="h2" color="textPrimary" sx={{ fontSize: '24px', letterSpacing: 1 }} >
                            Policies
                        </Typography>
                    }
                    <div className="policies-list-container">
                        {policies?.length > 0 ? policies?.map((item, index) => {
                            return (
                                <ul className="list-bullets">
                                    <li>{item?.type}</li>
                                    <Content
                                        data={item?.text}
                                    />
                                </ul>
                            )
                        }) : <p>No policies available</p>}
                    </div>{
                        policies?.length > 0 ?
                            <Btn onClick={onClose}>Close</Btn>
                            :
                            <Btn onClick={onClose} style={{ paddingTop: 19, marginLeft: -9 }}>Close</Btn>
                    }
                </Box>
            </Modal>

        </>
    );
};

export default ZentrumRoomCard