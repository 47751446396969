import React from "react";

const PointOfIntrestIcon = ({ className }) => {
    return (
        <svg className={className} width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.30078 0.0820312L13.5078 3.52734C13.8086 3.69141 14 3.99219 14 4.32031C14 4.83984 13.5625 5.25 13.043 5.25H0.929688C0.410156 5.25 0 4.83984 0 4.32031C0 3.99219 0.164062 3.69141 0.464844 3.52734L6.67188 0.0820312C6.86328 -0.0273438 7.10938 -0.0273438 7.30078 0.0820312ZM7 1.42188L2.40625 3.9375H11.5664L7 1.42188ZM3.0625 6.125V10.5H4.8125V6.125H6.125V10.5H7.875V6.125H9.1875V10.5H10.9375V6.125H12.25V10.5H12.4688C12.8242 10.5 13.125 10.8008 13.125 11.1562C13.125 11.5391 12.8242 11.8125 12.4688 11.8125H1.53125C1.14844 11.8125 0.875 11.5391 0.875 11.1562C0.875 10.8008 1.14844 10.5 1.53125 10.5H1.75V6.125H3.0625ZM0 13.3438C0 12.9883 0.273438 12.6875 0.65625 12.6875H13.3438C13.6992 12.6875 14 12.9883 14 13.3438C14 13.7266 13.6992 14 13.3438 14H0.65625C0.273438 14 0 13.7266 0 13.3438Z" fill="#344054" />
        </svg>
    );
};

export default PointOfIntrestIcon;