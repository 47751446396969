import React from "react";
import PropTypes from "prop-types";
import { useReducer } from "react";
import Placeholder91 from "../../assets/icons/Placeholder91";
import Placeholder330 from "../../assets/icons/Placeholder330";
import "./style.css";

const ButtonsButton = ({
  iconTrailing = true,
  iconLeading = true,
  size,
  hierarchy,
  icon,
  stateProp,
  className,
  textPaddingClassName,
  text = "Button CTA",
}) => {
  const [state, dispatch] = useReducer(reducer, {
    size: size || "md",
    hierarchy: hierarchy || "primary",
    icon: icon || "dot-leading",
    state: stateProp || "default",
  });

  return (
    <div
      className={`buttons-button ${state.hierarchy} icon-${state.icon} size-3-${state.size} state-8-${state.state} ${className}`}
      onMouseLeave={() => {
        dispatch("mouse_leave");
      }}
      onMouseEnter={() => {
        dispatch("mouse_enter");
      }}
    >
      {state.icon === "dot-leading" && (
        <>
          <div className="dot-wrapper">
            <div className="dot-2" />
          </div>
          <div className="text-10">{text}</div>
        </>
      )}

      {state.icon === "default" && (
        <>
          <>
            {iconLeading && (
              <>
                <>
                  {["lg", "md", "sm", "xl"].includes(state.size) && (
                    <Placeholder330
                      className="placeholder-330"
                      color={
                        state.hierarchy === "primary" && ["default", "focused", "hover"].includes(state.state)
                          ? "white"
                          : (state.hierarchy === "link-gray" && state.state === "default") ||
                            (state.hierarchy === "link-gray" && state.state === "focused") ||
                            (state.hierarchy === "tertiary-gray" && state.state === "default") ||
                            (state.hierarchy === "tertiary-gray" && state.state === "focused")
                            ? "#475467"
                            : (state.hierarchy === "link-gray" && state.state === "hover") ||
                              (state.hierarchy === "secondary-gray" && state.state === "default") ||
                              (state.hierarchy === "secondary-gray" && state.state === "focused") ||
                              (state.hierarchy === "tertiary-gray" && state.state === "hover")
                              ? "#344054"
                              : state.state === "disabled"
                                ? "#98A2B3"
                                : state.state === "hover" &&
                                  ["link-color", "secondary-color", "tertiary-color"].includes(state.hierarchy)
                                  ? "#53389E"
                                  : state.hierarchy === "secondary-gray" && state.state === "hover"
                                    ? "#182230"
                                    : "#6941C6"
                      }
                    />
                  )}

                  {state.size === "two-xl" && (
                    <Placeholder91
                      className="placeholder-91"
                      color={
                        state.hierarchy === "primary" && ["default", "focused", "hover"].includes(state.state)
                          ? "white"
                          : (state.hierarchy === "link-gray" && state.state === "default") ||
                            (state.hierarchy === "link-gray" && state.state === "focused") ||
                            (state.hierarchy === "tertiary-gray" && state.state === "default") ||
                            (state.hierarchy === "tertiary-gray" && state.state === "focused")
                            ? "#475467"
                            : (state.hierarchy === "link-gray" && state.state === "hover") ||
                              (state.hierarchy === "secondary-gray" && state.state === "default") ||
                              (state.hierarchy === "secondary-gray" && state.state === "focused") ||
                              (state.hierarchy === "tertiary-gray" && state.state === "hover")
                              ? "#344054"
                              : state.state === "disabled"
                                ? "#98A2B3"
                                : state.state === "hover" &&
                                  ["link-color", "secondary-color", "tertiary-color"].includes(state.hierarchy)
                                  ? "#53389E"
                                  : state.hierarchy === "secondary-gray" && state.state === "hover"
                                    ? "#182230"
                                    : "#6941C6"
                      }
                    />
                  )}
                </>
              </>
            )}
          </>
          <div className={`text-padding-2 ${textPaddingClassName}`}>
            {["primary", "secondary-color", "secondary-gray", "tertiary-color", "tertiary-gray"].includes(
              state.hierarchy
            ) && <div className="text-11">{text}</div>}

            {["link-color", "link-gray"].includes(state.hierarchy) && <>{text}</>}
          </div>
          <>
            {iconTrailing && (
              <>
                <>
                  {["lg", "md", "sm", "xl"].includes(state.size) && (
                    <Placeholder330
                      className="placeholder-330"
                      color={
                        state.hierarchy === "primary" && ["default", "focused", "hover"].includes(state.state)
                          ? "white"
                          : (state.hierarchy === "link-gray" && state.state === "default") ||
                            (state.hierarchy === "link-gray" && state.state === "focused") ||
                            (state.hierarchy === "tertiary-gray" && state.state === "default") ||
                            (state.hierarchy === "tertiary-gray" && state.state === "focused")
                            ? "#475467"
                            : (state.hierarchy === "link-gray" && state.state === "hover") ||
                              (state.hierarchy === "secondary-gray" && state.state === "default") ||
                              (state.hierarchy === "secondary-gray" && state.state === "focused") ||
                              (state.hierarchy === "tertiary-gray" && state.state === "hover")
                              ? "#344054"
                              : state.state === "disabled"
                                ? "#98A2B3"
                                : state.state === "hover" &&
                                  ["link-color", "secondary-color", "tertiary-color"].includes(state.hierarchy)
                                  ? "#53389E"
                                  : state.hierarchy === "secondary-gray" && state.state === "hover"
                                    ? "#182230"
                                    : "#6941C6"
                      }
                    />
                  )}

                  {state.size === "two-xl" && (
                    <Placeholder91
                      className="placeholder-91"
                      color={
                        state.hierarchy === "primary" && ["default", "focused", "hover"].includes(state.state)
                          ? "white"
                          : (state.hierarchy === "link-gray" && state.state === "default") ||
                            (state.hierarchy === "link-gray" && state.state === "focused") ||
                            (state.hierarchy === "tertiary-gray" && state.state === "default")
                            ? "#475467"
                            : (state.hierarchy === "link-gray" && state.state === "hover") ||
                              (state.hierarchy === "secondary-gray" && state.state === "default") ||
                              (state.hierarchy === "secondary-gray" && state.state === "focused") ||
                              (state.hierarchy === "tertiary-gray" && state.state === "focused") ||
                              (state.hierarchy === "tertiary-gray" && state.state === "hover")
                              ? "#344054"
                              : state.state === "disabled"
                                ? "#98A2B3"
                                : state.state === "hover" &&
                                  ["link-color", "secondary-color", "tertiary-color"].includes(state.hierarchy)
                                  ? "#53389E"
                                  : state.hierarchy === "secondary-gray" && state.state === "hover"
                                    ? "#182230"
                                    : "#6941C6"
                      }
                    />
                  )}
                </>
              </>
            )}
          </>
        </>
      )}

      {((state.icon === "only" && state.size === "lg") ||
        (state.icon === "only" && state.size === "md") ||
        (state.icon === "only" && state.size === "sm") ||
        (state.icon === "only" && state.size === "xl")) && (
          <Placeholder330
            className="placeholder-330"
            color={
              state.hierarchy === "primary" && ["default", "focused", "hover"].includes(state.state)
                ? "white"
                : (state.hierarchy === "secondary-color" && state.state === "default") ||
                  (state.hierarchy === "secondary-color" && state.state === "focused") ||
                  (state.hierarchy === "tertiary-color" && state.state === "default") ||
                  (state.hierarchy === "tertiary-color" && state.state === "focused")
                  ? "#6941C6"
                  : state.hierarchy === "tertiary-gray" && ["default", "focused"].includes(state.state)
                    ? "#475467"
                    : (state.hierarchy === "secondary-gray" && state.state === "default") ||
                      (state.hierarchy === "secondary-gray" && state.state === "focused") ||
                      (state.hierarchy === "tertiary-gray" && state.state === "hover")
                      ? "#344054"
                      : state.state === "disabled"
                        ? "#98A2B3"
                        : state.state === "hover" && ["secondary-color", "tertiary-color"].includes(state.hierarchy)
                          ? "#53389E"
                          : state.hierarchy === "secondary-gray" && state.state === "hover"
                            ? "#182230"
                            : undefined
            }
          />
        )}

      {state.icon === "only" && state.size === "two-xl" && (
        <Placeholder91
          className="placeholder-91"
          color={
            state.hierarchy === "primary" && ["default", "focused", "hover"].includes(state.state)
              ? "white"
              : (state.hierarchy === "secondary-color" && state.state === "default") ||
                (state.hierarchy === "secondary-color" && state.state === "focused") ||
                (state.hierarchy === "tertiary-color" && state.state === "default") ||
                (state.hierarchy === "tertiary-color" && state.state === "focused")
                ? "#6941C6"
                : state.hierarchy === "tertiary-gray" && ["default", "focused"].includes(state.state)
                  ? "#475467"
                  : (state.hierarchy === "secondary-gray" && state.state === "default") ||
                    (state.hierarchy === "secondary-gray" && state.state === "focused") ||
                    (state.hierarchy === "tertiary-gray" && state.state === "hover")
                    ? "#344054"
                    : state.state === "disabled"
                      ? "#98A2B3"
                      : state.state === "hover" && ["secondary-color", "tertiary-color"].includes(state.hierarchy)
                        ? "#53389E"
                        : state.hierarchy === "secondary-gray" && state.state === "hover"
                          ? "#182230"
                          : undefined
          }
        />
      )}
    </div>
  );
};

function reducer(state, action) {
  switch (action) {
    case "mouse_enter":
      return {
        ...state,
        state: "hover",
      };

    case "mouse_leave":
      return {
        ...state,
        state: "default",
      };
  }

  return state;
}

ButtonsButton.propTypes = {
  iconTrailing: PropTypes.bool,
  iconLeading: PropTypes.bool,
  size: PropTypes.oneOf(["xl", "lg", "two-xl", "sm", "md"]),
  hierarchy: PropTypes.oneOf([
    "tertiary-color",
    "link-color",
    "tertiary-gray",
    "secondary-color",
    "link-gray",
    "primary",
    "secondary-gray",
  ]),
  icon: PropTypes.oneOf(["dot-leading", "only", "default"]),
  stateProp: PropTypes.oneOf(["disabled", "focused", "hover", "default"]),
  text: PropTypes.string,
};

export default ButtonsButton;