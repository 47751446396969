import React, { useState } from "react";
import "./style.css";
import AdvisorList from "../../Pages/AdvisorList";
import OccasionModal from "../../Pages/Requests/Occasion";
import DestinationModal from "../../Pages/Requests/Destination";
import GuestModal from "../../Pages/Requests/Guests";
import BudgetModal from "../../Pages/Requests/Budget";
import CoupleModal from "../../Pages/Requests/Couples";
import SubmitModal from "../../Pages/Requests/SubmitRequest";
import RequestSuccessModal from "../../Pages/RequestSuccess";
import WelcomeModal from "../../Pages/Auth/WelcomeScreen";
import PopUpModal from "../../Pages/Auth/PopUpModal";

const ModalManager = ({ isAdvisorList,
    isPopupVisible, post,
    destinationPost,
    destinationAdvisor, setIsdestinationVisible,
    isDestinationVisible, setIsAdvisorList, reservation,
    setReservation, closeModal, setIsPopupVisible,
    setDestinationPost, isWelcomeVisible,
    setDestinationAdvisor, setIsWelcomeVisible, occasionpreference }) => {
    const [isOccasionVisible, setIsOccasionVisible] = useState(false);
    const [isGuestVisible, setIsGuestVisible] = useState(false);
    const [isCoupleVisible, setIsCoupleVisible] = useState(false);
    const [isBudgetVisible, setIsBudgetVisible] = useState(false);
    const [isSubmitVisible, setIsSubmitVisible] = useState(false);
    const [isRequestSubmitVisible, setIsRequestSubmitVisible] = useState(false)
    const [isJoinNow, setIsJoinNow] = useState(false);


    return (
        <>
            <AdvisorList
                post={post}
                visible={isAdvisorList}
                onClose={() => setIsAdvisorList(false)}
                setIsdestinationVisible={setIsdestinationVisible}
                setDestinationPost={setDestinationPost}
                setDestinationAdvisor={setDestinationAdvisor}
                reservation={reservation}
                setReservation={setReservation}
                occasionpreference={occasionpreference}
            />
            <OccasionModal
                visible={isOccasionVisible}
                onClose={() => setIsOccasionVisible(false)}
                reservation={reservation}
                setReservation={setReservation}
                setIsdestinationVisible={setIsdestinationVisible}
            />
            <DestinationModal
                visible={isDestinationVisible}
                onClose={() => setIsdestinationVisible(false)}
                post={destinationPost}
                reservation={reservation}
                setReservation={setReservation}
                setIsOccasionVisible={setIsOccasionVisible}
                setIsGuestVisible={setIsGuestVisible}
                setIsAdvisorList={setIsAdvisorList}
                setDestinationAdvisor={setDestinationAdvisor}
                setIsRequestSubmitVisible={setIsRequestSubmitVisible}
            />
            <GuestModal
                visible={isGuestVisible}
                onClose={() => setIsGuestVisible(false)}
                reservation={reservation}
                setReservation={setReservation}
                setIsBudgetVisible={setIsBudgetVisible}
                setIsCoupleVisible={setIsCoupleVisible}
                setIsdestinationVisible={setIsdestinationVisible}
            />
            <BudgetModal
                visible={isBudgetVisible}
                onClose={() => setIsBudgetVisible(false)}
                reservation={reservation}
                setReservation={setReservation}
                setIsSubmitVisible={setIsSubmitVisible}
                setIsGuestVisible={setIsGuestVisible}
                setIsCoupleVisible={setIsCoupleVisible}
            />
            <CoupleModal
                visible={isCoupleVisible}
                onClose={() => setIsCoupleVisible(false)}
                reservation={reservation}
                setReservation={setReservation}
                setIsBudgetVisible={setIsBudgetVisible}
                setIsGuestVisible={setIsGuestVisible}
            />
            <SubmitModal
                visible={isSubmitVisible}
                onClose={() => setIsSubmitVisible(false)}
                reservation={reservation}
                setReservation={setReservation}
                setIsBudgetVisible={setIsBudgetVisible}
                post={destinationPost}
                advisor={destinationAdvisor}
                setIsRequestSubmitVisible={setIsRequestSubmitVisible}
            />
            <RequestSuccessModal
                visible={isRequestSubmitVisible}
                onClose={() => setIsRequestSubmitVisible(false)}
                data={destinationAdvisor ? destinationAdvisor : destinationPost}
            />
            <WelcomeModal
                visible={isWelcomeVisible}
                onClose={() => setIsWelcomeVisible(false)}
                setIsPopupVisible={setIsPopupVisible}
                setIsJoinNow={setIsJoinNow}
            />
            <PopUpModal
                visible={isPopupVisible}
                onClose={closeModal}
                isJoinNow={isJoinNow}
                setIsJoinNow={setIsJoinNow}
                setIsModalVisible={setIsPopupVisible}
            />
        </>

    );
};

export default ModalManager;