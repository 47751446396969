import React from "react";

const OccasionIcon = ({ className, fillcolor }) => {
    return (

        <svg className={className} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path className={fillcolor} d="M3.71875 3.3125L3.03125 1.71875C2.96875 1.59375 2.96875 1.4375 3.03125 1.28125L3.53125 0.28125C3.625 0.125 3.78125 0 4 0H7C7.1875 0 7.34375 0.125 7.4375 0.28125L7.9375 1.28125C8 1.4375 8 1.59375 7.9375 1.71875L7.25 3.3125C9.40625 4.03125 11 6.09375 11 8.5C11 10.3438 10.0938 11.9375 8.75 12.9375C8.3125 12.6562 8 12.25 7.78125 11.7812C8.8125 11.0625 9.5 9.875 9.5 8.5C9.5 7.875 9.34375 7.28125 9.09375 6.78125C7.5625 7.34375 6.5 8.8125 6.5 10.5C6.5 11.1562 6.625 11.7188 6.875 12.25C7.125 12.7188 7.4375 13.1562 7.8125 13.5C8.53125 14.125 9.46875 14.5 10.5 14.5C12.6875 14.5 14.5 12.7188 14.5 10.5C14.5 8.75 13.3438 7.25 11.7188 6.71875C11.5625 6.125 11.3125 5.5625 10.9688 5.03125C13.7812 5.28125 16 7.65625 16 10.5C16 13.5625 13.5312 16 10.5 16C8.75 16 7.21875 15.2188 6.21875 13.9688C5.96875 14 5.71875 14 5.5 14C2.4375 14 0 11.5625 0 8.5C0 6.09375 1.5625 4.03125 3.71875 3.3125ZM4.59375 1.5V1.53125L5.125 2.75H5.84375L6.375 1.53125V1.5H4.59375ZM5 10.5C5 8.3125 6.28125 6.40625 8.15625 5.53125C7.4375 4.90625 6.5 4.5 5.5 4.5C3.28125 4.5 1.5 6.3125 1.5 8.5C1.5 10.6875 3.21875 12.4375 5.375 12.5C5.125 11.9062 5 11.2188 5 10.5Z" fill="url(#paint0_linear_6929_15840)" />
            <defs>
                <linearGradient id="paint0_linear_6929_15840" x1="-4.81987" y1="5.30029" x2="8.17233" y2="21.0214" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#393E45" />
                    <stop offset="1" stopColor="#0A0D10" />
                </linearGradient>
            </defs>
        </svg>

    );
};

export default OccasionIcon;