import React from "react";
import Skeleton from "react-loading-skeleton";
import "./style.css";

const BadgeVisible = ({ text, className, textStyle }) => {
  return (
    text && <div className={`badge-visible ${className ? className : ''}`}>
      <div className={`label-2 ${textStyle ? textStyle : ''}`}>{text || <Skeleton />}</div>
    </div>
  );
};

export default BadgeVisible