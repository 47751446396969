import React, { useEffect, useState } from "react";
import Banner from "../../../Components/Banner";
import Footer from "../../../Components/Footer";
import NavBar from "../../../Components/NavBar";
import MetaTags from "../../../Components/MetaTags";
import Metatag from "../../../api/models/Metatag.js";
import bannerImage from "../../../assets/images/ZentiumSearchBanner.jpeg"
import "./style.css";


const FAQ = ({ setProgress }) => {

    const [seo, setSeo] = useState();

    useEffect(() => {
        setProgress(10)
        setProgress(20)
        setProgress(60)
        setProgress(100)
    }, [setProgress])

    useEffect(() => {
        Metatag.metaslug('faq').then(result =>
            setSeo(result)
        );
    }, []);

    return (
        <>
            <MetaTags
                title={seo?.title != null ? seo?.title : "LOVU: Frequently Asked Questions"}
                description={seo?.description != null ? seo?.description : 'What problem is LOVU solving?'}
                imageScr={seo?.meta_image[0]?.original_url != undefined ? seo?.meta_image[0]?.original_url : bannerImage}
            />
            <div className="homepage privacy">
                <NavBar navigationDark={true} />
                {/* <Banner
                    isSearch={true}
                    imageSrc={bannerImage}
                /> */}
                <div className="pageContent">
                    <div className="primarySection">
                        <h1 className="FAQ-main-title">Frequently Asked Questions</h1>
                        <h2 className="FAQ-main-subtitle">What problem is LOVU solving?</h2>
                        <div className="FAQ-content">LOVU is filling a gap where other online travel sites provide little value. We are reducing the countless hours you send research and planning, removing the overwhelmingness of doing it yourself, and streamlining the booking process for everyone involved in curating your romantic travel experiences.</div>

                        <h2 className="FAQ-main-subtitle">I use Expedia, Bookings, and others. Why should I use LOVU?</h2>
                        <div className="FAQ-content">LOVU is not a booking site like Expedia, Booking.com, and others. Unlike other booking sites, LOVU is an exclusive platform devoted to romance travel and provides users with curated hotels, resorts, itineraries, expert advice and recommendations from travel experts, curated experiences, and personalized offers.</div>

                        <h2 className="FAQ-main-subtitle">What makes LOVU different?</h2>
                        <div className="FAQ-content">We guarantee personalized travel offers within 48 hrs. We syndicate your offer request to multiple travel advisors who specialize in your type of trip and destinations. Get multiple quotes on your trip, and you decide the best option for you to book. Additionally, we guarantee the following:
                        </div>





                        <div className="FAQ-list-container">
                            <ul className="list-bullets">
                                <li>It saves you time and money</li>
                                <li>Curated world-class hotels, resorts, and villas</li>
                                <li>Direct connections to romance and destination travel experts</li>
                                <li>Personalized offer request engine</li>
                                <li>Itinerary booking tool to curate your own trips</li>
                                <li>Bigger and better discounts</li>
                                <li>Exclusive perks not available anywhere</li>
                                <li>Special offers</li>
                            </ul>
                        </div>
                        <h2 className="FAQ-main-subtitle">How do we curate hotels, destinations, and experiences?</h2>
                        <div className="FAQ-content">LOVU curates 4- and 5-star rated hotels based on reviews, romance-focused hotels, and destinations based on popularity and experiences.
                        </div>
                        <h2 className="FAQ-main-subtitle">How are Travel Advisors selected or qualified?</h2>
                        <div className="FAQ-content">To be accepted as LOVU Travel Advisors, travel advisors must meet three of seven strict industry criteria and submit supporting documentation.
                        </div>
                        <h2 className="FAQ-main-subtitle">How much does LOVU cost?</h2>
                        <div className="FAQ-content">You can use LOVU at no cost; however, a subscription is needed at a minimal cost to continue to use the personalized offer request engine. You can send three personalized offer requests at no charge.
                        </div>
                        <h2 className="FAQ-main-subtitle">What is your membership/subscription cancellation policy?</h2>
                        <div className="FAQ-content">You can cancel anytime for any reason. However, membership/subscription fees are non-refundable.
                        </div>
                        <h2 className="FAQ-main-subtitle">What is your cancellation policy?</h2>
                        <div className="FAQ-content">Hotel cancellation terms are unique to each hotel and are based on rates and booking dates. Some rates are fully refundable, and some are not.
                        </div>
                        <h2 className="FAQ-main-subtitle">Is this an Apple or Android app?</h2>
                        <div className="FAQ-content">LOVU will be available on both platforms. The LOVU app is primarily used to request private offers and personalized offers from hotels and travel advisors.
                        </div>
                    </div>
                </div>
                <Footer className="design-component-instance-node-3" />
            </div>
        </>
    );
};

export default FAQ;