import React, { useEffect, useState } from 'react';
import { Modal } from 'antd';
import CheckoutForm from '../CheckoutForm';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const UpgradePayment = ({ visible, onClose, clientSecret, upgradeClose, subscriptionid }) => {

    const [stripePromise, setStripePromise] = useState('');
    const [success, setSuccess] = useState(false);
    const [stateCount, setStateCount] = useState(5);
    const [countInterval, setCountInterval] = useState(null);

    useEffect(() => {
        if (visible) {
            upgradeClose();
            setStripePromise(process.env.REACT_APP_STRIPE_PROMISE);
        }

    }, [visible])

    useEffect(() => {
        if (success) {
            setCountInterval(
                setInterval(() => {
                    setStateCount(prevCount => {
                        if (prevCount === 0) {
                            clearInterval(countInterval);
                            setCountInterval(null);
                            return 5;
                        }
                        return prevCount - 1;
                    });
                }, 1000),
            );
        }

    }, [success])

    useEffect(() => {
        if (stateCount === 0) {
            onClose();
            window.location.reload();
        }
    }, [stateCount])

    const appearance = {
        theme: 'stripe',

        variables: {
            colorBackground: '#282935',
            colorText: '#fff',
            colorDanger: '#df1b41',
            fontFamily: 'Ideal Sans, system-ui, sans-serif',
            borderRadius: '8px',
            // tabIconColor: '#000'
        },
        rules: {
            '.Input': {
                border: '1px solid #cdba86',
            },
            '.Input:focus': {
                border: '1px solid #cdba86',
                boxShadow: 'unset',
            },
            '.Tab': {
                border: '1px solid #cdba86',
            },
            '.Tab--selected': {
                border: '2px solid #fff',
                boxShadow: 'unset',
            },
            '.Tab--selected:focus': {
                border: '2px solid #fff',
                boxShadow: 'unset',
            },
            '.Tab--selected:active': {
                border: '2px solid #fff',
                boxShadow: 'unset',
            },
            '.TabLabel--selected': {
                color: '#fff'
            },
            '.TabLabel--selected:focus': {
                color: '#fff'
            },
            '.TabLabel--selected:active': {
                color: '#fff'
            },
        }
    };

    return (
        <div>
            <Modal
                className={`upgrade-maincontainer ${success && 'success'}`}
                open={visible}
                maskClosable={false}
                onCancel={onClose}
                footer={[]}
            >
                <div className={`upgrade ${success && 'success'}`}>
                    <div className={`upgrade-subcontainer ${success && 'success'}`}>
                        {
                            stripePromise && clientSecret &&
                            <Elements stripe={loadStripe(stripePromise)} options={{ clientSecret, appearance }}>
                                <CheckoutForm subscriptionid={subscriptionid} setStripePromise={setStripePromise} setSuccess={setSuccess} />
                            </Elements>
                        }
                        {
                            success &&
                            <>
                                <div className='subscription-success-container'>
                                    <CheckCircleOutlineIcon className='check-success' />
                                </div>
                                <div className='subscription-subcontainer'>
                                    <div className='subscription-title'>Success</div>
                                    <div className='subscription-description'>Payment Successfully</div>
                                    <div className='subscription-reload'>You will be redirect in {stateCount} seconds</div>
                                </div>
                            </>
                        }
                    </div>
                </div>

            </Modal>
        </div>
    );
};

export default UpgradePayment;