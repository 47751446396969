import React from "react";
import ImageTextCardItem from "./ImageTextCardItem";
import "./style.css";

const ImageTextCard = ({
  data
}) => {
  return (
    <div className="image-text-card-wrap">
      {data.map((item, index) => <ImageTextCardItem key={index} index={index} item={item} />)}
    </div>
  );
};

export default ImageTextCard;