import React from 'react'
import "./style.css";


const UnorderList = ({ data }) => {
    return (
        <ul className='list-view'>
            {data.map((item, index) => { return item.props && <li key={index} className='list'>{item?.props?.children}</li> })}
        </ul>
    )
}

export default UnorderList