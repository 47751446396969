import React from "react";

const LogoLight = ({ className }) => {
  return (
    <svg
      className={`${className}`}
      fill="none"
      height="51"
      viewBox="0 0 150 51"
      width="150"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M25.4936 51C30.186 51 33.99 49.954 33.99 48.6636C33.99 47.3733 30.186 46.3272 25.4936 46.3272C20.8011 46.3272 16.9972 47.3733 16.9972 48.6636C16.9972 49.954 20.8011 51 25.4936 51Z"
        fill="#EEEEEE"
      />
      <path
        className="path"
        clipRule="evenodd"
        d="M25.4936 8.46112C22.5944 2.82149 18.8898 0.00167846 14.3799 0.00167846C5.96401 0.00167846 0.00445084 7.93743 0.00445084 16.7594C-0.116351 20.3849 2.17889 28.8846 12.1652 36.2564C18.8093 41.1709 23.2387 45.2395 25.4131 48.5025C27.3862 46.3272 31.8961 42.2586 38.9832 36.2564C49.5734 27.2733 50.8217 20.828 51.0231 16.7594C50.7412 7.49431 45.0233 0.122528 37.1309 0.00167846C32.3391 -0.0788876 28.4734 2.74093 25.4936 8.46112Z"
        fill="url(#paint0_radial_3779_118)"
        fillRule="evenodd"
      />
      <path
        className="path"
        clipRule="evenodd"
        d="M10.3532 8.50137C14.541 8.50137 18.4067 9.91129 21.467 12.2477C21.7086 12.4491 21.5878 12.852 21.2656 12.8922L18.7288 12.9728C11.5612 13.2145 8.13852 13.4965 8.42039 13.7785C8.74252 14.1007 13.6954 14.4633 23.279 14.9064C23.9635 14.9467 24.6078 15.2689 25.0105 15.7926C25.9366 17.0817 26.742 18.4513 27.3057 19.9418C27.4265 20.3043 27.1849 20.7071 26.8225 20.7071L25.9769 20.7877C23.3193 20.9891 22.0307 21.2308 22.1515 21.4322C22.2723 21.6739 24.0844 21.9962 27.6279 22.3185C27.8695 22.3185 28.1111 22.5199 28.1513 22.7616C28.3929 23.8895 28.5137 25.0577 28.5137 26.2259C28.5137 26.3468 28.5137 26.5079 28.5137 26.6287C28.5137 26.9107 28.2721 27.1524 27.9903 27.1927L27.346 27.233C25.6145 27.3941 24.7689 27.5553 24.7286 27.7164C24.6884 27.9178 25.7353 28.0789 27.7889 28.2401C28.1111 28.2803 28.3527 28.5623 28.3124 28.9249C27.9097 31.3821 27.0239 33.638 25.7756 35.6522C19.212 37.2635 14.3799 37.3843 11.7626 35.8536C2.01789 28.5623 -0.0760115 20.1029 0.0850576 16.5177C0.0850576 14.6647 0.366938 12.8117 0.850145 11.0795C3.54805 9.46818 6.84996 8.50137 10.3532 8.50137Z"
        fill="url(#paint1_linear_3779_118)"
        fillRule="evenodd"
      />
      <path
        className="path"
        clipRule="evenodd"
        d="M29.2384 17.0414C37.01 17.3234 34.2718 29.4486 25.8962 30.6168C36.2449 30.6168 39.6274 19.257 34.755 14.8661C32.7014 14.8661 30.6881 15.6718 29.2384 17.0414Z"
        fill="#B21C68"
        fillRule="evenodd"
      />
      <path
        className="path"
        clipRule="evenodd"
        d="M43.6542 1.97556C48.2849 4.99678 50.7815 10.435 50.9828 16.84V17.122C50.862 19.4584 50.2983 22.5602 47.9225 26.3871C48.607 22.963 48.0433 20.1432 46.0702 17.9679C49.7748 13.4562 48.2849 5.6413 43.8958 2.25753L43.6542 1.97556Z"
        fill="#971356"
        fillRule="evenodd"
      />
      <path
        className="path"
        clipRule="evenodd"
        d="M30.2853 14.4633C36.2852 7.73605 48.6875 11.5227 50.4995 20.3849C49.6136 24.2118 46.956 29.4889 39.3052 36.055L37.5334 37.5858C31.3726 42.8629 27.2653 46.4883 25.4533 48.5025C23.3191 45.3604 19.1716 41.4932 12.8496 36.7801L12.0443 36.2162C5.64178 31.6642 2.62172 26.5885 1.13184 22.5602C8.09807 25.5814 15.6281 30.496 24.0037 30.496C36.7684 30.4557 41.7615 13.9799 30.2853 14.4633Z"
        fill="url(#paint2_radial_3779_118)"
        fillRule="evenodd"
      />
      <path
        className="path"
        clipRule="evenodd"
        d="M30.2854 14.4633C34.9564 10.5961 42.1642 12.288 46.2312 16.3969C48.607 18.8139 49.5734 20.7475 49.3318 23.4867L49.2513 23.9701C47.7211 27.2733 45.144 30.9391 39.6274 35.773L39.2247 36.0953C48.5667 23.8895 43.332 11.6032 30.2854 14.4633Z"
        fill="url(#paint3_linear_3779_118)"
        fillRule="evenodd"
      />
      <path
        className="path"
        clipRule="evenodd"
        d="M30.2854 14.4633C35.0369 8.74313 43.2515 10.5559 47.8017 15.0676C49.3318 16.558 50.0969 18.4513 50.4996 20.3849C49.9358 22.8422 48.8889 25.1786 46.8755 28.0387C50.5398 18.6125 41.9226 11.8852 30.2854 14.4633Z"
        fill="url(#paint4_linear_3779_118)"
        fillRule="evenodd"
      />
      <path
        className="path"
        clipRule="evenodd"
        d="M39.5469 27.4747C42.6072 29.1666 42.6475 32.067 41.077 34.4839C40.7146 34.8062 40.3522 35.1688 39.9495 35.491C40.3925 34.0811 40.2717 31.3419 39.6274 27.7164L39.5469 27.4747Z"
        fill="url(#paint5_linear_3779_118)"
        fillRule="evenodd"
      />
      <path
        className="path"
        clipRule="evenodd"
        d="M39.5469 27.4747C41.8421 26.8302 44.2984 27.4344 46.3521 28.7638C44.983 30.5765 43.332 32.4698 41.077 34.484C42.6072 32.1073 42.5267 29.1263 39.5469 27.4747Z"
        fill="#D8EEF8"
        fillRule="evenodd"
      />
      <path
        className="path"
        d="M84.3241 31.503V33.1949C84.3241 33.6381 83.9617 34.0409 83.4785 34.0409H69.1836C68.7407 34.0409 68.338 33.6783 68.338 33.1949V17.7665C68.338 17.3234 68.7004 16.9206 69.1836 16.9206H71.4386C71.8815 16.9206 72.2842 17.2831 72.2842 17.7665V30.214C72.2842 30.4557 72.4856 30.6571 72.7272 30.6571H83.5188C83.9617 30.6571 84.3241 31.0599 84.3241 31.503Z"
        fill="white"
      />
      <path
        className="path"
        d="M85.3307 25.5008C85.3307 19.7807 89.5185 16.4372 96.1626 16.4372C102.807 16.4372 106.994 19.8209 106.994 25.5008C106.994 31.1808 102.807 34.5645 96.1626 34.5645C89.5185 34.5645 85.3307 31.221 85.3307 25.5008ZM103.048 25.5008C103.048 21.8351 100.391 19.7807 96.1626 19.7807C91.9345 19.7807 89.2769 21.8351 89.2769 25.5008C89.2769 29.1666 91.8942 31.221 96.1626 31.221C100.391 31.221 103.048 29.1666 103.048 25.5008Z"
        fill="white"
      />
      <path
        className="path"
        d="M111.947 17.4443L117.746 30.3751C117.907 30.6974 118.35 30.6974 118.511 30.3751L124.309 17.4443C124.43 17.1623 124.752 16.9609 125.075 16.9609H127.41C128.014 16.9609 128.417 17.6054 128.135 18.1694L120.363 33.5978C120.202 33.8798 119.92 34.0409 119.639 34.0409H116.538C116.216 34.0409 115.934 33.8798 115.813 33.5978L108.042 18.1694C107.76 17.6054 108.162 16.9609 108.766 16.9609H111.182C111.545 16.9609 111.827 17.1623 111.947 17.4443Z"
        fill="white"
      />
      <path
        className="path"
        d="M149.154 16.9609C149.597 16.9609 150 17.3234 150 17.8068V25.9843C150 31.1002 146.618 34.5646 140.416 34.5646C134.215 34.5646 130.833 31.1002 130.833 25.9843V17.8068C130.833 17.3637 131.195 16.9609 131.678 16.9609H133.933C134.376 16.9609 134.779 17.3234 134.779 17.8068V25.7023C134.779 29.1264 136.792 31.1808 140.457 31.1808C144.121 31.1808 146.134 29.1264 146.134 25.7023V17.8068C146.134 17.3637 146.497 16.9609 146.98 16.9609H149.154Z"
        fill="white"
      />
      <defs className="defs">
        <radialGradient
          className="radial-gradient"
          cx="0"
          cy="0"
          gradientTransform="translate(12.2975 12.2859) rotate(29.6276) scale(55.8025 55.8137)"
          gradientUnits="userSpaceOnUse"
          id="paint0_radial_3779_118"
          r="1"
        >
          <stop className="stop" stopColor="#FF0874" />
          <stop className="stop" offset="1" stopColor="#952725" />
        </radialGradient>
        <linearGradient
          className="linear-gradient"
          gradientUnits="userSpaceOnUse"
          id="paint1_linear_3779_118"
          x1="13.748"
          x2="14.8806"
          y1="10.8773"
          y2="36.9924"
        >
          <stop className="stop" stopColor="#F7C772" />
          <stop className="stop" offset="1" stopColor="#FF880F" />
        </linearGradient>
        <radialGradient
          className="radial-gradient"
          cx="0"
          cy="0"
          gradientTransform="translate(44.2244 22.1295) rotate(158.027) scale(38.4769 39.3869)"
          gradientUnits="userSpaceOnUse"
          id="paint2_radial_3779_118"
          r="1"
        >
          <stop className="stop" stopColor="#85D0EF" />
          <stop className="stop" offset="0.4376" stopColor="#3D98BF" />
          <stop className="stop" offset="1" stopColor="#3054A5" />
        </radialGradient>
        <linearGradient
          className="linear-gradient"
          gradientUnits="userSpaceOnUse"
          id="paint3_linear_3779_118"
          x1="58.0996"
          x2="30.7702"
          y1="-3.93071"
          y2="18.7801"
        >
          <stop className="stop" stopColor="white" />
          <stop className="stop" offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          className="linear-gradient"
          gradientUnits="userSpaceOnUse"
          id="paint4_linear_3779_118"
          x1="72.107"
          x2="37.9221"
          y1="6.95864"
          y2="26.3076"
        >
          <stop className="stop" stopColor="#77C5E5" />
          <stop className="stop" offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          className="linear-gradient"
          gradientUnits="userSpaceOnUse"
          id="paint5_linear_3779_118"
          x1="41.1801"
          x2="37.3126"
          y1="22.1182"
          y2="30.1976"
        >
          <stop className="stop" stopColor="white" />
          <stop className="stop" offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default LogoLight