import React, { useEffect, useRef } from 'react';
import dayjs from 'dayjs';
import moment from 'moment';
import { DatePicker, Input, Modal } from 'antd';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ButtonSmall from '../../../Components/ButtonSmall';
import DateCalender from "../../../assets/icons/DateCalender";
import useNotification from '../../../providers/NotificationProvider';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import "./style.css";

const DestinationModal = ({ visible, onClose, post, reservation, setReservation, setIsOccasionVisible, progress = 30, setIsGuestVisible, setIsAdvisorList, setDestinationAdvisor }) => {

    const [notification, createNotification] = useNotification();
    const topRef = useRef(null);

    useEffect(() => {
        if (topRef.current) {
            topRef.current.scrollTop = 0;
        }
    }, [visible])

    const ProgressBar = ({ progress }) => {
        return (
            <div className="progress-bar">
                <div className="progress" style={{ width: `${progress}%` }}></div>
            </div>
        );
    };

    const handleOccasionVisisble = () => {
        onClose();
        setIsOccasionVisible(true);
    }

    const handleBack = () => {
        if (post?.type === 'advisor') {
            onClose();
            setDestinationAdvisor(null)
        } else {
            setDestinationAdvisor(null)
            onClose();
            setIsAdvisorList(true);
        }
    }

    const currentDate = new Date();
    const defaultDepartureDate = new Date(currentDate);
    defaultDepartureDate.setDate(currentDate.getDate() + 6);
    const formattedDepartureDate = defaultDepartureDate.toISOString();

    const goNext = () => {
        const isDepartureManuallyModified =
            reservation.departure_date !== formattedDepartureDate;
        if (isDepartureManuallyModified && moment(reservation.departure_date).isSameOrBefore(reservation.arrival_date)) {
            createNotification({
                title: 'Warning!',
                content: "Departure date cannot be before arrival date",
                primary: {
                    title: 'OK',
                },
            });
            return;
        } else {
            onClose();
            setIsGuestVisible(true);
        }
    }

    useEffect(() => {
        setReservation({
            ...reservation,
            departure_date: moment(new Date(reservation?.arrival_date)).add(6, 'days').format("MM/DD/YYYY"),
        })

    }, [reservation?.arrival_date])

    return (
        <div>
            <Modal
                className='destination-maincontainer'
                open={visible}
                onCancel={onClose}
                maskClosable={false}
                footer={[]}
            >
                <div className='destination-modal'>
                    <ArrowBackIcon onClick={handleBack} className='back-arrow' />
                    <ProgressBar progress={progress} />
                    <div className='destination-modal-container' ref={topRef}>
                        {
                            reservation.occasion === 'Wedding' ?
                                (<h2 className='destination-title'>Destination {reservation?.occasion} Dates</h2>)
                                :
                                (<h2 className='destination-title'>{reservation?.occasion} Dates</h2>)
                        }
                        <div className='destinationContainer'>
                            <h2 className='destination-subTitle'>Occasion</h2>
                            <div onClick={handleOccasionVisisble} className='occasion-container'>
                                <div className='display-occasion'>{reservation?.occasion ? reservation?.occasion : 'Select Occasion'}</div>
                                <KeyboardArrowDownIcon style={{ color: '#fff' }} />
                            </div>
                        </div>
                        <div className='destinationContainer'>
                            <h2 className='destination-subTitle'>
                                {
                                    reservation.occasion === 'Honeymoon' ||
                                        reservation.occasion === 'Romantic Getaway' ||
                                        reservation.occasion === 'Anniversary' ||
                                        reservation.occasion === 'Celebrating Love' ||
                                        reservation.occasion === 'Babymoon' ||
                                        reservation.occasion === 'Wedding'
                                        ? ' When are you arriving?'
                                        : 'When would you prefer arriving?'
                                }
                            </h2>
                            <DatePicker
                                onChange={(date) => setReservation({
                                    ...reservation,
                                    arrival_date: dayjs(new Date(date)).format("MM/DD/YYYY"),
                                })}

                                inputReadOnly={true}
                                minDate={dayjs(moment(new Date()).format("MM/DD/YYYY"))}
                                suffixIcon={<DateCalender />}
                                defaultValue={dayjs(new Date()).format("MM/DD/YYYY")}
                                value={dayjs(reservation?.arrival_date)}
                                format="MM/DD/YYYY"
                                showToday={false}
                                className='destination-date-picker'
                            />,
                        </div>
                        <div className='destinationContainer'>
                            <h2 className='destination-subTitle'>
                                {
                                    reservation.occasion === 'Honeymoon' ||
                                        reservation.occasion ===
                                        'Romantic Getaway' ||
                                        reservation.occasion === 'Anniversary' ||
                                        reservation.occasion ===
                                        'Celebrating Love' ||
                                        reservation.occasion === 'Babymoon' ||
                                        reservation.occasion === 'Wedding'
                                        ? ' When are you departing?'
                                        : 'When would you prefer departing?'
                                }

                            </h2>
                            <DatePicker
                                onChange={(date) => setReservation({
                                    ...reservation,
                                    departure_date: moment(new Date(date)).format("MM/DD/YYYY"),
                                })}
                                inputReadOnly={true}
                                defaultValue={dayjs(new Date(), "MM/DD/YYYY")}
                                suffixIcon={<DateCalender />}
                                value={dayjs(reservation?.departure_date)}
                                minDate={dayjs(reservation?.arrival_date, "MM/DD/YYYY")}
                                format="MM/DD/YYYY"
                                showToday={false}
                                className='destination-date-picker'
                            />
                        </div>
                        <div className='destinationContainer'>
                            <h2 className='destination-subTitle'>Leaving From?</h2>
                            <Input className="destination-input-field"
                                value={reservation?.city}
                                onChange={text =>
                                    setReservation({
                                        ...reservation,
                                        city: text.target.value,
                                    })} placeholder={"Enter Your City"} />
                        </div>
                        {(reservation.property_id !== null ||
                            post?.type === 'advisor') &&
                            <div className='destinationContainer'>
                                <h2 className='destination-subTitle'>Do you need flights?</h2>
                                <div className='destinationRowContainer'>
                                    <Input
                                        className={`destination-input-field-choice-${reservation?.flights === true ? 'selected' : ''}`}
                                        placeholder={"YES"}
                                        style={{ color: '#fff' }}
                                        disabled={reservation?.flights === true}
                                        onClick={() => setReservation({
                                            ...reservation,
                                            flights: true,
                                        })}
                                    />
                                    <Input
                                        className={`destination-input-field-choice-${reservation?.flights === false ? 'selected' : ''}`}
                                        placeholder={"NO"}
                                        disabled={reservation?.flights === false}
                                        inputStyle={{ color: '#fff' }}
                                        onClick={() => setReservation({
                                            ...reservation,
                                            flights: false,
                                        })}
                                    />
                                </div>
                            </div>
                        }
                        <div className='destinationContainer'>
                            <h2 className='destination-subTitle'>Flexible Dates?</h2>
                            <div className='destinationRowContainer'>
                                <Input
                                    className={`destination-input-field-choice-${reservation?.flexible_dates === true ? 'selected' : ''}`}
                                    placeholder={"YES"}
                                    style={{ color: '#fff' }}
                                    disabled={reservation?.flexible_dates === true}
                                    onClick={() => setReservation({
                                        ...reservation,
                                        flexible_dates: true,
                                    })}
                                />
                                <Input
                                    className={`destination-input-field-choice-${reservation?.flexible_dates === false ? 'selected' : ''}`}
                                    placeholder={"NO"}
                                    disabled={reservation?.flexible_dates === false}
                                    inputStyle={{ color: '#fff' }}
                                    onClick={() => setReservation({
                                        ...reservation,
                                        flexible_dates: false,
                                    })}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='next-btn-container'>
                        <ButtonSmall buttonstyle="default " className='destination-next-btn' disabled={reservation.occasion != null && reservation.city ? '' : 'disabled-destination-btn'} text="Next" handleClick={goNext} />
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default DestinationModal;