import React, { useEffect, useState } from "react";
import moment from "moment";
import { Spin, message } from 'antd';
import PropTypes from "prop-types";
import Dropdown from "../Dropdown";
import InputField from "../InputField";
import ButtonSmall from "../ButtonSmall";
import Globe from "../../assets/icons/Globe";
import Stars from "../../assets/icons/Stars";
import TravelerDetail from "../TravelerDetail";
import { useNavigate } from "react-router-dom";
import Calendar from "../../assets/icons/Calendar";
import FaceWink from "../../assets/icons/FaceWink";
import X from "../../assets/images/icons/cancel-icon.png";
import "./style.css";
import dayjs from "dayjs";

const Booking = ({
  inputFieldIcon = <Globe className="icon-instance-node" />,
  override = <Calendar className="icon-instance-node" />,
  inputFieldIcon1 = <Stars className="icon-instance" />,
  inputFieldIcon2 = <FaceWink className="icon-instance-node" />,
  buttonSmallText = "GET STARTED",
  isVisible,
  onChangePlanToGo,
  onChangeTravelDate,
  onChangeOccassion,
  onChangeNoOfPeople,
  planToGo,
  travelDates,
  occasions,
  noOfPeoples,
  products,
  onCountryClick,
  setProducts,
  NewTravelDate,
  setIsWidgetVisible,
  LocationId,
  isProductSet,
  setIsproductSet,
  inputValue,
  handleInputClick,
  isDropdownVisible,
  rooms,
  handleDelete,
  handleAdultsChange,
  handleChildrenChange,
  setIsDropdownVisible,
  handleAdd,
  handleAgeChange,
  options,
  handleDoneHandler,
  setSearchDetail,
  setDateAndLocation,
  directHotelDetail,
  isSearchContainerVisible,
  setIsSearchContainerVisible,
  setSearchTerm,
  searchTerm
}) => {
  const navigate = useNavigate();

  const occasionOptions = [
    {
      value: "Honeymoon",
      label: "Honeymoon"
    },
    {
      value: "Proposal",
      label: "Proposal"
    },
    {
      value: "Babymoon",
      label: "Babymoon"
    },
    {
      value: "Wedding",
      label: "Wedding"
    },
    {
      value: "Celebrating Love",
      label: "Celebrating Love"
    },
    {
      value: "Romantic Getaway",
      label: "Romantic Getaway"
    },
    {
      value: "Vow Renewal",
      label: "Vow Renewal"
    },
    {
      value: "Anniversary",
      label: "Anniversary"
    },
    {
      value: "Besties",
      label: "Besties"
    }
  ];

  const [loader, setLoader] = useState(false);
  const [locationId, setLocationId] = useState();
  const [locationType, setLocationType] = useState();
  const [coordinateslat, setCoordinateslat] = useState();
  const [coordinateslong, setCoordinateslong] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  useEffect(() => {
    if (LocationId != undefined) {
      setLocationId(LocationId);
      setIsproductSet(false)
    }
  }, [LocationId])

  useEffect(() => {

    if (NewTravelDate != undefined && setDateAndLocation) {
      setStartDate(dayjs(NewTravelDate[0]).format('YYYY-MM-DD'));
      setEndDate(dayjs(NewTravelDate[1]).format('YYYY-MM-DD'))
    } else {
      setStartDate(moment(NewTravelDate[0]).format('YYYY-MM-DD'));
      setEndDate(moment(NewTravelDate[1]).format('YYYY-MM-DD'))
    }
  }, [NewTravelDate])



  const handleHotelList = async (event) => {
    if (event) {
      setSearchDetail(event);
      onCountryClick(event?.fullName);
      setLocationId(event?.id);
      setLocationType(event?.type);
      if (event?.coordinates) {
        setCoordinateslat(event?.coordinates.lat);
        setCoordinateslong(event?.coordinates.long);
      }
    }

    setProducts([], () => {
      onCountryClick(event?.fullName);
    });
  }

  useEffect(() => {
    if (directHotelDetail) {
      setSearchDetail(directHotelDetail);
      onCountryClick(directHotelDetail?.fullName);
      setLocationId(directHotelDetail?.id);
      setLocationType(directHotelDetail?.type);
      if (directHotelDetail?.coordinates) {
        setCoordinateslat(directHotelDetail?.coordinates.lat);
        setCoordinateslong(directHotelDetail?.coordinates.long);
      }
    }

  }, [setDateAndLocation])

  async function handleTravelDateClick(event) {
    onChangeTravelDate(event)
    const startDate = event[0]?.format('YYYY-MM-DD');
    const endDate = event[1]?.format('YYYY-MM-DD');
    setStartDate(startDate);
    setEndDate(endDate);
  }


  const displayHotelList = async () => {
    const encodedRooms = encodeURIComponent(JSON.stringify(rooms));
    if (planToGo == "" || planToGo == undefined) {
      message.info("Please Add Destinations.")
    } else if (!travelDates || travelDates?.length == 0) {
      message.info("Please Add Travel Dates.")
    } else if (occasions.length == 0) {
      message.info("Please Add occasions.")
    } else {
      const NoofPeople = noOfPeoples[0]?.value;
      const occasion = occasions[0]?.value;
      setLoader(true);
      if (searchTerm != undefined) {
        setSearchTerm('');
      }
      if (coordinateslat && coordinateslong) {
        navigate(`/search/${locationId != undefined ? locationId : LocationId}?occasion=${occasion}&people=${"2"}&startdate=${startDate}&enddate=${endDate}&location=${planToGo}&rooms=${encodedRooms}&searchtype=${locationType}&coordinateslat=${coordinateslat}&coordinateslong=${coordinateslong}`);
      } else {
        navigate(`/search/${locationId != undefined ? locationId : LocationId}?occasion=${occasion}&people=${"2"}&startdate=${startDate}&enddate=${endDate}&location=${planToGo}&rooms=${encodedRooms}&searchtype=${locationType}`);
      }
      setLoader(false)
    }
  }

  const handleChangeTravelDate = (dates) => {
    handleTravelDateClick(dates);
  }
  
  return (
    <>
      {
        isVisible ?
          <div className={`booking-add-title`}>
            <img src={X} alt="cancel" className="widget-close" onClick={() => setIsWidgetVisible(false)} />
            <p className="booking-widget-title">Find Romantic Hotspots</p>
            <div className="booking design-component-instance-node-3">
              <p className="booking-widget-title">Find Romantic Hotspots</p>
              <InputField
                className="input-field-instance planned-place"
                icon={inputFieldIcon}
                text="Where do you plan to go"
                field="text"
                value={planToGo}
                onChange={onChangePlanToGo}
                isVisible={isVisible}
                products={products}
                setProducts={setProducts}
                handleHotelList={(event) => handleHotelList(event)}
                isProductSet={isProductSet}
                setIsproductSet={setIsproductSet}
                isSearchContainerVisible={isSearchContainerVisible}
                setIsSearchContainerVisible={setIsSearchContainerVisible}
              />
              <InputField
                className="input-field-instance"
                icon={override}
                text="Travel dates"
                field="calendar"
                onChange={handleChangeTravelDate}
                value={travelDates}
                isVisible={isVisible}
                NewTravelDate={NewTravelDate}
              />
              <Dropdown
                className="input-field-instance"
                icon={inputFieldIcon1}
                text="What is the occasion?"
                field="drop-down"
                placeholder="Select occasion"
                value={occasions}
                onChange={onChangeOccassion}
                options={occasionOptions}
                isVisible={isVisible}
              />
              <TravelerDetail
                isVisible={isVisible}
                inputFieldIcon2={inputFieldIcon2}
                inputValue={inputValue}
                handleInputClick={handleInputClick}
                isDropdownVisible={isDropdownVisible}
                rooms={rooms}
                handleDelete={handleDelete}
                handleAdultsChange={handleAdultsChange}
                handleChildrenChange={handleChildrenChange}
                setIsDropdownVisible={setIsDropdownVisible}
                handleAdd={handleAdd}
                handleAgeChange={handleAgeChange}
                options={options}
                handleDoneHandler={handleDoneHandler}
              />

              {loader ? <Spin style={{ width: '12.5%', display: 'flex', justifyContent: 'center', marginBottom: '9px' }} />
                :
                <ButtonSmall textStyle="search-btn" buttonstyle="default" text={buttonSmallText} handleClick={displayHotelList} />
              }
            </div>


          </div >
          :
          <div className={`booking-sticky design-component-instance-node-3`}>
            <InputField
              className="input-field-instance planned-place1"
              icon={inputFieldIcon}
              text="Where do you plan to go"
              value={planToGo}
              onChange={onChangePlanToGo}
              field="text"
              products={products}
              setProducts={setProducts}
              handleHotelList={(event) => handleHotelList(event)}
              isProductSet={isProductSet}
              setIsproductSet={setIsproductSet}
              isSearchContainerVisible={isSearchContainerVisible}
              setIsSearchContainerVisible={setIsSearchContainerVisible}
            />
            <InputField
              className="input-field-instance"
              icon={override}
              onChange={handleChangeTravelDate}
              value={travelDates}
              text="Travel dates"
              field="calendar"
              NewTravelDate={NewTravelDate}
            />
            <Dropdown
              className="input-field-instance"
              icon={inputFieldIcon1}
              onChange={onChangeOccassion}
              value={occasions}
              text="What is the occassion?"
              field="drop-down"
              placeholder="Honeymoon"
              options={occasionOptions}
            />
            <TravelerDetail
              inputFieldIcon2={inputFieldIcon2}
              inputValue={inputValue}
              handleInputClick={handleInputClick}
              isDropdownVisible={isDropdownVisible}
              rooms={rooms}
              handleDelete={handleDelete}
              handleAdultsChange={handleAdultsChange}
              handleChildrenChange={handleChildrenChange}
              setIsDropdownVisible={setIsDropdownVisible}
              handleAdd={handleAdd}
              handleAgeChange={handleAgeChange}
              options={options}
              handleDoneHandler={handleDoneHandler}
            />

            {loader ? <Spin style={{ width: '10.8%', display: 'flex', justifyContent: 'center', marginBottom: '9px' }} />
              :
              <ButtonSmall textStyle="search-btn" buttonstyle="default" text={buttonSmallText} handleClick={displayHotelList} />
            }
          </div>
      }
    </>
  )
};

Booking.propTypes = {
  buttonSmallText: PropTypes.string,
};

export default Booking;