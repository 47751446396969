import React from "react";

const NotificationIcon = ({ className, fillColor }) => {
    return (
        <svg className={className ? className : ''} width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path className={fillColor ? fillColor : ''} d="M9.75 0C10.4141 0 11 0.585938 11 1.25V1.95312C13.8125 2.42188 16 4.88281 16 7.8125V9.14062C16 10.8984 16.5859 12.6172 17.6797 14.0234L18.2656 14.7266C18.5 15.0391 18.5391 15.4297 18.3828 15.7422C18.2266 16.0547 17.9141 16.25 17.5625 16.25H1.9375C1.54688 16.25 1.23438 16.0547 1.07812 15.7422C0.921875 15.4297 0.960938 15.0391 1.19531 14.7266L1.78125 14.0234C2.875 12.6172 3.5 10.8984 3.5 9.14062V7.8125C3.5 4.88281 5.64844 2.42188 8.5 1.95312V1.25C8.5 0.585938 9.04688 0 9.75 0ZM9.75 3.75H9.4375C7.17188 3.75 5.375 5.58594 5.375 7.8125V9.14062C5.375 11.0156 4.82812 12.8125 3.8125 14.375H15.6484C14.6328 12.8125 14.125 11.0156 14.125 9.14062V7.8125C14.125 5.58594 12.2891 3.75 10.0625 3.75H9.75ZM12.25 17.5C12.25 18.1641 11.9766 18.8281 11.5078 19.2969C11.0391 19.7656 10.4141 20 9.75 20C9.08594 20 8.42188 19.7656 7.95312 19.2969C7.48438 18.8281 7.25 18.1641 7.25 17.5H9.75H12.25Z" fill="white" />
        </svg>

    );
};

export default NotificationIcon