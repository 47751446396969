import React, { useEffect, useState } from "react";
import Banner from "../../Components/Banner";
import Footer from "../../Components/Footer";
import NavBar from "../../Components/NavBar";
import MetaTags from "../../Components/MetaTags"
import SearchBar from '../../Components/SearchBar'
import Metatag from "../../api/models/Metatag.js";
import ButtonSmall from "../../Components/ButtonSmall";
import bannerImage from "../../assets/images/OccasionBanner.jpeg"
import Proposals from "../../assets/images/occasion/occasions/proposal.jpg"
import Babymoons from "../../assets/images/occasion/occasions/babymoons.jpg"
import Honeymoon from "../../assets/images/occasion/occasions/honeymoon.jpg"
import VowRenewal from "../../assets/images/occasion/occasions/vow-renewal.jpg"
import Anniversaries from "../../assets/images/occasion/occasions/anniversaries.jpg"
import DestinationWedding from "../../assets/images/occasion/occasions/destination-wedding.jpg"
import "./style.css";



const Occassions = ({ setProgress }) => {

    const [seo, setSeo] = useState();
    const [isVisible, setIsVisible] = useState(true);


    useEffect(() => {
        setProgress(10)
        setProgress(20)
        setProgress(60)
        setProgress(100)
    }, [setProgress])

    useEffect(() => {
        Metatag.metaslug('occasion').then(result =>
            setSeo(result)
        );
    }, []);

    return (
        <>
            <MetaTags
                title={seo?.title != null ? seo?.title : "LOVU: Occassions | Celebrating Moments of Affection & Crafting Your Love Story"}
                description={seo?.description != null ? seo?.description : "Chapters of Love: Celebrating Life's Romantic Milestones, From Whispered Vows to Endless Adventures: Crafting Your Love Story"}
                imageScr={seo?.meta_image[0]?.original_url != undefined ? seo?.meta_image[0]?.original_url : bannerImage}
            />
            <div className="homepage occassions">
                <Banner
                    isProperties
                    imageSrc={bannerImage}
                    title="Celebrating Moments of Affection"
                    heading="Crafting Your Love Story"
                    widget={<SearchBar heightToHideFrom={520}
                        isVisible={isVisible}
                        setIsVisible={setIsVisible} />}
                />
                <div className="pageContent">
                    <div className="primarySection">
                        <h2 className="occassions-main-title">Chapters of Love: Celebrating Life's Romantic Milestones</h2>
                        <h2 className="occassions-main-subtitle">From Whispered Vows to Endless Adventures: Crafting Your Love Story</h2>
                        <div className="occassions-main-content">Whether you're whispering "I do" under a foreign sky, tracing the sands of a far-flung beach on your honeymoon, or celebrating years of shared bliss, each milestone is a new chapter in your love story, waiting to be written with memories that sparkle brighter than a diamond in the sun.</div>
                    </div>
                    <div className="occassionMainContainer">
                        <div className="occassion-Container">
                            <div className="image-container">
                                <img className="occasion-image" src={DestinationWedding} alt="imgsrc" />
                            </div>
                            <div className="occassion-content-card-container">
                                <div className="occassion-content-card">
                                    <div className="content-container">
                                        <h2 className="title">Destination Wedding</h2>
                                        <div className="content">Imagine exchanging vows in the lush embrace of Riviera Maya or the quaint charm of Tulum. Destination weddings are not just ceremonies; they're an adventure for you and your guests, a blend of culture, celebration, and unforgettable moments.</div>
                                        <a href="/occassions/destinationwedding">
                                            <ButtonSmall className="button-instance-black-outline " buttonstyle="button-outline" text="LEARN MORE" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="occassion-Container-reverse">
                            <div className="occassion-content-card-container">
                                <div className="occassion-content-card">
                                    <div className="content-container">
                                        <h2 className="title">Honeymoon</h2>
                                        <div className="content">The journey after "I do" should be nothing short of magical. Picture yourselves in the serenity of the Maldives or amidst the vibrant streets of Paris. Honeymoons are your first adventure as a married couple, a time to bask in love's glow in a setting as unique as your bond.</div>
                                        <a href="/occassions/honeymoon">
                                            <ButtonSmall className="button-instance-black-outline " buttonstyle="button-outline" text="LEARN MORE" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="image-container">

                                <img className="occasion-image" src={Honeymoon} alt="imgsrc" /></div>
                        </div>
                        <div className="occassion-Container">
                            <div className="image-container">

                                <img className="occasion-image" src={Anniversaries} alt="imgsrc" /></div>
                            <div className="occassion-content-card-container">
                                <div className="occassion-content-card">
                                    <div className="content-container">
                                        <h2 className="title">Anniversaries</h2>
                                        <div className="content">Anniversaries are the annual milestones that deserve to be celebrated in style. Whether it's a quiet getaway to the vineyards of Napa or a thrilling safari in Kenya, every year brings a new opportunity to fall in love all over again.</div>
                                        <a href="/occassions/anniversary">
                                            <ButtonSmall className="button-instance-black-outline " buttonstyle="button-outline" text="LEARN MORE" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="occassion-Container-reverse">
                            <div className="occassion-content-card-container">
                                <div className="occassion-content-card">
                                    <div className="content-container">
                                        <h2 className="title">Babymoons</h2>
                                        <div className="content">Before the whirlwind of parenthood begins, a babymoon offers a peaceful retreat. It's a chance to enjoy each other's company and relish the calm before your bundle of joy arrives, perhaps in the gentle sea breezes of Santorini or the tranquil beaches of Bali.</div>
                                        <a href="/occassions/babymoon">
                                            <ButtonSmall className="button-instance-black-outline " buttonstyle="button-outline" text="LEARN MORE" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="image-container">
                                <img className="occasion-image" src={Babymoons} alt="imgsrc" /></div>
                        </div>
                        <div className="occassion-Container">
                            <div className="image-container">
                                <img className="occasion-image" src={VowRenewal} alt="imgsrc" /></div>
                            <div className="occassion-content-card-container">
                                <div className="occassion-content-card">
                                    <div className="content-container">
                                        <h2 className="title">Vow Renewal</h2>
                                        <div className="content">Renewing your vows is a heartwarming affirmation of your journey together. This event can be as intimate or as grand as you wish, from a simple ceremony on a secluded beach to a festive gathering in a historic castle.</div>
                                        <a href="/occassions/vowrenewal">
                                            <ButtonSmall className="button-instance-black-outline " buttonstyle="button-outline" text="LEARN MORE" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="occassion-Container-reverse">
                            <div className="occassion-content-card-container">
                                <div className="occassion-content-card">
                                    <div className="content-container">
                                        <h2 className="title">Proposals</h2>
                                        <div className="content">The moment you propose or say "yes" should be as extraordinary as your love story. Whether it's a hot air balloon ride at sunrise or a candlelit dinner under the stars, make this milestone unforgettable with a backdrop that takes your breath away.</div>
                                        <a href="/occassions/proposal">
                                            <ButtonSmall className="button-instance-black-outline " buttonstyle="button-outline" text="LEARN MORE" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="image-container">
                                <img className="occasion-image" src={Proposals} alt="imgsrc" /></div>
                        </div>
                    </div>
                </div>
                <Footer className="design-component-instance-node-3" isVisible={isVisible} />
                <NavBar navigationDark={true} />

            </div>
        </>
    );
};

export default Occassions;