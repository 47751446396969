import React from "react";

const Cancel = ({ className }) => {
    return (
        <svg width="17" height="41" viewBox="0 0 17 41" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.7656 13.8125L9.71094 21L15.7656 28.2266C16.0781 28.6172 16.0391 29.2031 15.6484 29.5547C15.2578 29.8672 14.6719 29.8281 14.3203 29.4375L8.5 22.4844L2.64062 29.4375C2.28906 29.8281 1.70312 29.8672 1.3125 29.5547C0.921875 29.2031 0.882812 28.6172 1.19531 28.2266L7.25 21L1.19531 13.8125C0.882812 13.4219 0.921875 12.8359 1.3125 12.4844C1.70312 12.1719 2.28906 12.2109 2.64062 12.6016L8.5 19.5547L14.3203 12.6016C14.6719 12.2109 15.2578 12.1719 15.6484 12.4844C16.0391 12.8359 16.0781 13.4219 15.7656 13.8125Z" fill="white" />
        </svg>

    );
};

export default Cancel