import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Modal } from 'antd';
import CheckoutForm from '../CheckoutForm';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useNavigate } from 'react-router-dom';
import ButtonSmall from '../../../Components/ButtonSmall';

const BookingPayment = ({ visible, onClose, total, bookingId, guestEmail, setIsBookingPayment, setIsPaymentSuccess, success, failed, setFinalBookLoader }) => {

    const navigate = useNavigate();

    const [stripePromise, setStripePromise] = useState('');
    const [isTimeUp, setIsTimeUp] = useState(false);
    const [stateCount, setStateCount] = useState(5);
    const [countInterval, setCountInterval] = useState(null);
    const [clientSecret, setClientSecret] = useState("");
    const [timeLeft, setTimeLeft] = useState(600);
    const items = [{ amount: total }];


    useEffect(() => {
        if (visible) {
            if (success) {
                setStripePromise('')
            } else {
                setTimeLeft(600);
                setStripePromise(process.env.REACT_APP_BOOKING_STRIPE_PROMISE);
            }
            if (failed) {
                setStripePromise('')
            }
            let timer;
            if (!failed) {
                timer = setTimeout(() => {
                    setIsTimeUp(true);
                }, 600000);
            }

            const countdown = setInterval(() => {
                setTimeLeft(prevTimeLeft => {
                    if (prevTimeLeft > 0) {
                        return prevTimeLeft - 1;
                    } else {
                        clearInterval(countdown);
                        return 0;
                    }
                });
            }, 1000);

            return () => {
                clearTimeout(timer);
                clearInterval(countdown);
            };
        }


    }, [visible])

    useEffect(() => {
        if (success) {
            setCountInterval(
                setInterval(() => {
                    setStateCount(prevCount => {
                        if (prevCount === 0) {
                            clearInterval(countInterval);
                            setCountInterval(null);
                            return 5;
                        }
                        return prevCount - 1;
                    });
                }, 1000),
            );
        }
        if (isTimeUp) {
            setCountInterval(
                setInterval(() => {
                    navigate('/');
                }, 5000),
            );
        }

    }, [success, isTimeUp])

    useEffect(() => {
        if (stateCount === 0) {
            onClose();
            navigate('/');
        }
    }, [stateCount])

    useEffect(() => {
        if (visible == true) {
            fetchClientSecret();
        }
    }, [visible]);


    const handleFailedNavigation = () => {
        navigate('/');
    }



    const fetchClientSecret = async () => {
        try {
            const response = await axios.post(`/payments/order/pay`, {
                items,
            });
            setClientSecret(response.data.clientSecret);
        } catch (error) {
            console.log(error);
        }
    }

    const appearance = {
        theme: 'stripe',

        variables: {
            colorBackground: '#282935',
            colorText: '#fff',
            colorDanger: '#df1b41',
            fontFamily: 'Ideal Sans, system-ui, sans-serif',
            borderRadius: '8px',
        },
        rules: {
            '.Input': {
                border: '1px solid #cdba86',
            },
            '.Input:focus': {
                border: '1px solid #cdba86',
                boxShadow: 'unset',
            },
            '.Tab': {
                border: '1px solid #cdba86',
            },
            '.Tab--selected': {
                border: '2px solid #fff',
                boxShadow: 'unset',
            },
            '.Tab--selected:focus': {
                border: '2px solid #fff',
                boxShadow: 'unset',
            },
            '.Tab--selected:active': {
                border: '2px solid #fff',
                boxShadow: 'unset',
            },
            '.TabLabel--selected': {
                color: '#fff'
            },
            '.TabLabel--selected:focus': {
                color: '#fff'
            },
            '.TabLabel--selected:active': {
                color: '#fff'
            },
        }
    };

    return (
        <div>
            <Modal
                className={`upgrade-maincontainer ${success && 'success'}`}
                open={visible}
                maskClosable={false}
                onCancel={onClose}
                footer={[]}
            >
                <div className={`upgrade ${success && 'success'}`}>
                    <div className={`upgrade-subcontainer ${success && 'success'}`}>
                        {!isTimeUp ?
                            stripePromise && clientSecret &&
                            <Elements stripe={loadStripe(stripePromise)} options={{ clientSecret, appearance }}>
                                <div className='zentrum-booking-timer-container'>
                                    <span className='zentrum-booking-session'>Session End In :</span>
                                    <span className='zentrum-booking-timer'> {Math.floor(timeLeft / 60)}:{('0' + (timeLeft % 60)).slice(-2)}</span>
                                </div>
                                <CheckoutForm setStripePromise={setStripePromise} bookingId={bookingId} guestEmail={guestEmail} setIsBookingPayment={setIsBookingPayment} setIsPaymentSuccess={setIsPaymentSuccess} setFinalBookLoader={setFinalBookLoader} />
                            </Elements>
                            :
                            !success && !failed && <div className='subscription-description'>Your booking token is expired you will be navigate to home page</div>
                        }
                        {
                            success &&
                            <>
                                <div className='subscription-success-container'>
                                    <CheckCircleOutlineIcon className='check-success' />
                                </div>
                                <div className='subscription-subcontainer'>
                                    <div className='subscription-title'>Success</div>
                                    <div className='subscription-description'>Payment Successfully</div>
                                    <div className='subscription-reload'>You will be redirect in {stateCount} seconds</div>
                                </div>
                            </>
                        }
                        {
                            failed &&
                            <>
                                <div className='subscription-failed-container'>
                                    <ErrorOutlineIcon className='check-failed' />
                                </div>
                                <div className='subscription-subcontainer'>
                                    <div className='subscription-title'>Oops!</div>
                                    <div className='subscription-description'>Something went wrong please try again later</div>
                                    <div className='subscription-description'>The amount charged will be refuded</div>
                                    <div className='failed-close-container'>
                                        <ButtonSmall buttonstyle="default" className="navigation-ok-btn" text='Back To Home' handleClick={handleFailedNavigation} />
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                </div>

            </Modal>
        </div>
    );
};

export default BookingPayment;