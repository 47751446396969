
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useForm } from 'antd/es/form/Form';
import User from '../../../api/models/User';
import { auth } from '../../../firebaseConfig';
import useAuth from '../../../providers/AuthProvider';
import useStore from '../../../providers/StoreProvider';
import AppleIcon from '../../../assets/icons/social/apple.svg';
import { Modal, Input, Button, Spin, Form, message } from 'antd';
import GoogleIcon from '../../../assets/icons/social/google.svg';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import FacebookIcon from '../../../assets/icons/button-icons/facebook.svg';
import { FacebookAuthProvider, GoogleAuthProvider, OAuthProvider, signInWithPopup } from 'firebase/auth';
import "./style.css";

const PopUpModal = ({
    visible, onClose, onSignIn, setIsModalVisible, setIsJoinNow, isJoinNow }) => {
    const [form] = Form.useForm();
    const { signIn, socialSignIn, register } = useAuth();
    const { store, setStore } = useStore();
    const [sending, setSending] = useState(false);
    const { data } = useForm({
        email: '',
        password: '',
        remember: false,
    });

    const [loading, setLoading] = useState(false);
    const [state, setState] = useState({ forgotConfirmation: false });
    const [isPasswordShown, setIsPasswordShown] = useState(false);
    const [socialToken, setSocialToken] = useState();
    const [stateCount, setStateCount] = useState(59);
    const [countInterval, setCountInterval] = useState(null);
    const [socialProvider, setSocialProvider] = useState('');

    function showConfirmation() {
        setState({ ...state, forgotConfirmation: true });
    }

    useEffect(() => {
        if (socialToken) {
            setSending(true);
            socialSignIn(socialToken).finally(() => setSending(false));
            message.success({
                content: `Login Successfully in ${socialProvider}`
            });
        }
    }, [socialToken]);

    useEffect(() => {
        setLoading(false);
        setIsPasswordShown(false);
        if (store.token) {
            setIsModalVisible(false)
            // form.resetFields();
        }
    }, [visible, store, setIsModalVisible]);

    const onFinishRegisterIn = (values) => {
        setLoading(true);
        const state = { email: values.email, password: values.password };
        register(state).finally(() => setLoading(false));
    };

    const onFinishSignIn = (values) => {
        setLoading(true)
        signIn(values).finally(() => setLoading(false));
        if (store.token) return setIsModalVisible(false);
    };


    const onFinishForgetPassword = (values) => {
        if (countInterval) {
            return;
        }
        if (values != null || undefined) {
            User.forgotPassword({ email: values?.email })
                .then(() => {
                    showConfirmation();
                    setCountInterval(
                        setInterval(() => {
                            setStateCount(prevCount => {
                                if (prevCount === 0) {
                                    clearInterval(countInterval);
                                    setCountInterval(null);
                                    return 59;
                                }
                                return prevCount - 1;
                            });
                        }, 1000),
                    );
                })
                .catch(() => { });
        }
    }
    useEffect(() => {
        return () => {
            clearInterval(countInterval);
            setCountInterval(null);
        };
    }, []);

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const handleSignInButton = () => {
        setIsJoinNow(!isJoinNow)
    }

    function handleForgetPasswordShown() {
        setIsJoinNow(false)
        setIsPasswordShown(true)
    }

    const FacebookSignInButton = async () => {
        const provider = new FacebookAuthProvider();
        setSocialProvider('facebook');
        try {
            await signInWithPopup(auth, provider).then((result) => {
                const credential = FacebookAuthProvider.credentialFromResult(result);
                const token = credential.accessToken;
                axios.get(`/login/facebook/callback?access_token=${token}&plateform=web`).then((res) => res?.data && setSocialToken(res?.data))
            }).catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                const email = error.customData.email;
                const credential = FacebookAuthProvider.credentialFromError(error);
            });
        } catch (error) {
            console.error(error);
        }

    };


    const GoogleSignInButton = async () => {
        const provider = new GoogleAuthProvider();
        provider.addScope('https://www.googleapis.com/auth/userinfo.email');
        provider.addScope('https://www.googleapis.com/auth/userinfo.profile');
        setSocialProvider('google');
        try {
            await signInWithPopup(auth, provider).then((result) => {
                const credential = GoogleAuthProvider.credentialFromResult(result);
                const token = credential.accessToken;
                axios.get(`/login/google/callback?code=${token}&plateform=web`).then((res) => res?.data && setSocialToken(res?.data))
            }).catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                const email = error.customData.email;
                const credential = GoogleAuthProvider.credentialFromError(error);
            });
        } catch (error) {
            console.error(error);
        }
    };

    const AppleSignInButton = async () => {
        const provider = new OAuthProvider('apple.com');
        provider.addScope('email');
        provider.addScope('name');
        setSocialProvider('apple');
        try {
            signInWithPopup(auth, provider)
                .then((result) => {
                    const credential = OAuthProvider.credentialFromResult(result);
                    const token = credential.idToken;
                    axios.get(`/login/apple/callback?access_token=${token}&plateform=web`).then((res) => res?.data && setSocialToken(res?.data))
                })
                .catch((error) => {
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    const email = error.customData.email;
                    const credential = OAuthProvider.credentialFromError(error);
                });
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <Modal
            className='popupmodal-maincontainer'
            open={visible}
            maskClosable={false}
            onCancel={onClose}
            footer={[]}
        >
            <div className='popupmodal'>
                <div style={{ margin: 'auto 0' }}>
                    {
                        isJoinNow ? (
                            !isPasswordShown &&
                            <Form
                                form={form}
                                name="signup"
                                labelCol={{ span: 8 }}
                                initialValues={data}
                                onFinish={onFinishRegisterIn}
                                onFinishFailed={onFinishFailed}
                                autoComplete="off"
                            >
                                <Form.Item
                                    name="email"
                                    rules={[{ required: true, message: 'Please Enter Your Email' },
                                    {
                                        pattern: new RegExp(/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i),
                                        message: "Enter a Valid Email",
                                    }]}
                                    hasFeedback
                                >

                                    <Input placeholder='Email' className='login-input' autoComplete="off" />
                                </Form.Item>
                                <Form.Item
                                    name="password"
                                    rules={[{ required: true, message: 'Please input your Password!' }]}
                                >
                                    <Input.Password placeholder='Password' className='login-input' autoComplete="off" iconRender={visible => (
                                        visible ? <EyeOutlined /> : <EyeInvisibleOutlined />
                                    )} />
                                </Form.Item>
                                <Form.Item
                                    name="confirmPassword"
                                    dependencies={['password']}
                                    rules={[
                                        { required: true, message: 'Confirm your Password!' },
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                if (!value || getFieldValue('password') === value) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject(new Error('The confirm password do not match!'));
                                            },
                                        }),
                                    ]}
                                >
                                    <Input.Password autoComplete="off" placeholder='Confirm Password' className='login-input' iconRender={visible => (
                                        visible ? <EyeOutlined /> : <EyeInvisibleOutlined />
                                    )} />
                                </Form.Item>
                                <Form.Item wrapperCol={{ span: 24 }}>
                                    {loading ? <Spin style={{ marginLeft: '8px' }} />
                                        :
                                        <Button type="primary" htmlType="submit" className='submit-button'>
                                            Create Account
                                        </Button>}
                                </Form.Item>

                            </Form>
                        ) : (
                            !isPasswordShown && <Form
                                form={form}
                                name="signin"
                                labelCol={{ span: 8 }}
                                initialValues={data}
                                onFinish={onFinishSignIn}
                                onFinishFailed={onFinishFailed}
                                autoComplete="off"
                            >
                                <Form.Item
                                    name="email"
                                    rules={[{ required: true, message: 'Please Enter Your Email' },
                                    {
                                        pattern: new RegExp(/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i),
                                        message: "Enter a Valid Email",
                                    }]}
                                    hasFeedback
                                >

                                    <Input autoComplete="off" placeholder='Email' className='login-input' />
                                </Form.Item>
                                <Form.Item
                                    name="password"
                                    rules={[{ required: true, message: 'Please input your Password!' }]}
                                >
                                    <Input.Password placeholder='Password' autoComplete="off" className='login-input' iconRender={visible => (
                                        visible ? <EyeOutlined /> : <EyeInvisibleOutlined />
                                    )} />
                                </Form.Item>
                                <Form.Item wrapperCol={{ span: 24 }}>
                                    {loading ? <Spin style={{ textAlign: 'center' }} />
                                        :
                                        <Button type="primary" htmlType="submit" className='submit-button'>
                                            Sign In
                                        </Button>
                                    }
                                </Form.Item>
                            </Form>
                        )
                    }
                    {
                        isPasswordShown &&
                        <Form
                            form={form}
                            name="basic"
                            labelCol={{ span: 8 }}
                            initialValues={data}
                            onFinish={onFinishForgetPassword}
                            onFinishFailed={onFinishFailed}
                            autoComplete="off"
                        >

                            <Form.Item
                                name="email"
                                rules={[{ required: true, message: 'Please Enter Your Email' },
                                {
                                    pattern: new RegExp(/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i),
                                    message: "Enter a Valid Email",
                                }]}
                                hasFeedback
                            >

                                <Input autoComplete="off" placeholder='Email' className='login-input' />
                            </Form.Item>
                            <Form.Item wrapperCol={{ span: 24 }}>
                                <Button type="primary" htmlType="submit" className='submit-button'>
                                    {countInterval ? `Send again (${stateCount})` : 'Reset Password'}
                                </Button>
                            </Form.Item>
                        </Form>
                    }
                    {
                        !isPasswordShown &&
                        <div className='buttoncontainer'>
                            <h3 className='forget-pass-text'><span onClick={handleForgetPasswordShown}>{isJoinNow ? "" : "Forget your password"}</span></h3>

                            <h2 className='sign-up-text'>Or {isJoinNow ? "Sign Up" : "Sign In"} Using One Of These</h2>
                            {socialProvider === 'facebook' && sending ?
                                <Spin style={{ display: 'flex', justifyContent: 'center' }} />
                                :
                                <div
                                    className='button-icon-container-facebook'
                                    onClick={FacebookSignInButton}
                                >
                                    <img className="button-icon" alt="Image" src={FacebookIcon} />
                                    <h2 className='button-title'>continue with Facebook</h2>
                                </div>
                            }
                            {socialProvider === 'google' && sending ?
                                <Spin style={{ display: 'flex', justifyContent: 'center' }} />
                                :
                                <div
                                    className='button-icon-container-google'
                                    onClick={GoogleSignInButton}
                                >

                                    <img className="button-icon" alt="Image" src={GoogleIcon} />
                                    <h2 className='button-title'>continue with Google</h2>
                                </div>
                            }
                            {socialProvider === 'apple' && sending ?
                                <Spin style={{ display: 'flex', justifyContent: 'center' }} />
                                :
                                <div
                                    className='button-icon-container-apple'
                                    onClick={AppleSignInButton}
                                >
                                    <img className="button-icon" alt="Image" src={AppleIcon} />
                                    <h2 className='button-title'>continue with Apple</h2>
                                </div>
                            }
                        </div>
                    }
                    {
                        isJoinNow ?
                            !isPasswordShown && <h2 className='sign-in-text'>already have an account ? <span onClick={handleSignInButton}>SIGN IN</span></h2>
                            :
                            !isPasswordShown && <h2 className='sign-in-text'>not a member ? <span onClick={handleSignInButton}>JOIN IN</span></h2>

                    }
                </div>
            </div>
        </Modal >
    );
};

export default PopUpModal;