import React, { useEffect, useRef, useState } from 'react';
import { Input, Modal, Spin } from 'antd';
import * as Requests from '../../../api/Requests';
import useStore from '../../../providers/StoreProvider';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ButtonSmall from '../../../Components/ButtonSmall';
import useNotification from '../../../providers/NotificationProvider';
import "./style.css";

const SubmitModal = ({ visible, onClose, progress = 100, reservation, setReservation, setIsBudgetVisible, post, advisor, setIsRequestSubmitVisible }) => {

    const [notification, createNotification] = useNotification();
    const { store, setStore } = useStore();
    const user = store.user;
    const [loading, setLoading] = useState(false);
    const { TextArea } = Input;
    const topRef = useRef(null);

    useEffect(() => {
        if (topRef.current) {
            topRef.current.scrollTop = 0;
        }
    }, [visible])

    const ProgressBar = ({ progress }) => {
        return (
            <div className="progress-bar">
                <div className="progress" style={{ width: `${progress}%` }}></div>
            </div>
        );
    };

    const submitRequest = () => {
        if (
            store?.user?.requests_count >= 3 &&
            !store?.user?.subscription_expires_at
        ) {
            createNotification({
                title: 'Premium required!',
                content:
                    'You’ve sent three offer requests already. To send more you need to upgrade premium account.',
                primary: {
                    title: 'OK',
                },
            });
            return;
        }
        setLoading(true);
        Requests.sendReservation(
            advisor ? advisor?.id : post?.id,
            reservation,
        ).then(() => {
            setStore({
                ...store,
                user: user.copy({ requests_count: user.requests_count + 1 }),
            });
            setLoading(false);
            onClose();
            setIsRequestSubmitVisible(true);
        });
    }
    const handleBack = () => {
        onClose();
        setIsBudgetVisible(true);
    }
    const onChange = (e) => {
        setReservation({ ...reservation, message: e.target.value })
    };

    return (
        <div>
            <Modal
                className='submit-maincontainer'
                open={visible}
                onCancel={onClose}
                maskClosable={false}
                footer={[]}
            >
                <div className='submit-modal'>
                    <ArrowBackIcon onClick={handleBack} className='back-arrow' />
                    <ProgressBar progress={progress} />
                    <div className='submit-modal-container'>
                        {post?.type === 'advisor' ?
                            <>
                                <div className='desir-destination'>Top Desired Destination</div>
                                <Input
                                    value={reservation?.desire_destination1}
                                    onChange={e =>
                                        setReservation({
                                            ...reservation,
                                            desire_destination1: e.target.value,
                                        })}
                                />
                            </>
                            :
                            null
                        }
                        <div className='guest-title'>Additional details</div>
                        <TextArea
                            className='submit-textarea'
                            value={reservation.message}
                            onChange={onChange}
                            placeholder="I’m thinking about…leave nothing out!"
                        />
                    </div>
                    <div className='next-btn-container'>
                        {loading ? <Spin style={{ display: 'flex', justifyContent: 'center' }} />
                            :

                            <ButtonSmall buttonstyle="default " className='destination-next-btn' disabled={post?.type !== 'advisor' || (post?.type === 'advisor' &&
                                reservation?.desire_destination1 !== '') ? '' : 'disabled-destination-btn'} text="Submit" handleClick={submitRequest} />
                        }
                    </div>

                </div>
            </Modal>
        </div>
    );
};

export default SubmitModal;